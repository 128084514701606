import { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import currencyAndUnitConverter from "../../utils/currencyAndUnitConverter";
import { getCoordinatesFromGeocoder } from "../../service/mapbox";
import { getWebMercatorViewport } from "../../utils/getWebMercatorViewport";
import { printCanvas } from "../../utils/printToPDF";

/**
 * Custom React hook that provides functionality for interacting with map layers.
 * @category Hooks
 * @component
 * @param {Object} options - An object containing the necessary options for interacting with the map.
 * @param {Object} options.map - The map object.
 * @param {Object} options.clickPopupInfo - Information about the clicked popup.
 * @param {Function} options.closePopup - Function to close the popup.
 * @param {Object} options.viewport - The current viewport of the map.
 * @param {Function} options.setViewport - Function to set the viewport of the map.
 * @param {Function} options.getBeforeIdLayerId - Function to get the layer ID before which a new layer should be added.
 * @returns {Object} An object containing various functions and state variables for interacting with map layers.
 */
const useLayerFunctionality = ({
  map,
  clickPopupInfo,
  closePopup,
  viewport,
  setViewport,
  getBeforeIdLayerId,
}) => {
  /**
   * The AppContext object that provides access to various states and functions.
   */
  const {
    pathLocation,
    convertedLayerUnits,
    checkedLayers,
    setCheckedLayers,
    openCostFilterDisclaimer,
    setOpenCostFilterDisclaimer,
    costFilterDisclaimerCount,
    setCostFilterDisclaimerCount,
    setToggleLayer,
    layerConfigHandlers,
  } = useContext(AppContext);

  /**
   * An object that contains various functions for handling layer configurations.
   */
  const {
    appendCostValue,
    resetCostValue,
    changeLayer,
    changeOpacity,
    resetOpacity,
    changeCostPercentage,
    changeHydropowerType,
    changeGroundwaterStatus,
    showInteractivityInfo,
    renderLegend,
    disableScreenshot,
  } = layerConfigHandlers;

  /**
   * State variable that determines which expandable list is open.
   */
  const [openExpandableList, setOpenExpandableList] = useState(6);

  /**
   * State variable that contains an object with group indices.
   */
  const [groupIndex, setGroupIndex] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: ["fact"],
  });

  /**
   * State variable that determines whether a screenshot is being loaded.
   */
  const [loadingScreenshot, setLoadingScreenshot] = useState(false);

  /**
   * State variable that contains options for the screenshot format.
   */
  const [formatOptions, setFormatOptions] = useState({
    pdf: true,
    image: false,
  });

  /**
   * State variable that determines whether the screenshot disclaimer is open.
   */
  const [openScreenshotDisclaimer, setOpenScreenshotDisclaimer] =
    useState(false);

  /**
   * State variable that determines whether the screenshot dialog is open.
   */
  const [openScreenshotDialog, setOpenScreenshotDialog] = useState(false);

  /**
   * State variable that contains information about the screenshot region.
   */
  const [screenshotRegion, setScreenshotRegion] = useState({
    name: "",
    isoCode: "",
    gid0: "",
  });

  /**
   * Displays the cost filter disclaimer if it has not been displayed before.
   */
  const costFilterDisclaimer = () => {
    if (!openCostFilterDisclaimer && costFilterDisclaimerCount === 0) {
      setOpenCostFilterDisclaimer(true);
      setCostFilterDisclaimerCount(1);
    }
  };

  /**
   * Resets the filter for a given layer.
   * @param {string} layerId - The ID of the layer to reset the filter for.
   */
  const resetMapFilter = (layerId) => {
    if (/lcoe/.test(layerId)) {
      map && map.setFilter(`${layerId}circle`, null);
      map && map.setFilter(layerId, null);
    } else {
      map && map.setFilter(layerId, null);
    }
  };

  /**
   * Handles the change of the cost slider for a given layer.
   * @param {string} layerId - The ID of the layer to change the cost slider for.
   * @param {string} defaultUnit - The default unit for the layer.
   * @returns {Function} A function that handles the change of the cost slider.
   */
  const handleCostSliderChange =
    (layerId, defaultUnit) => (event, newValue) => {
      if (clickPopupInfo && clickPopupInfo.data.category === "nonLCOE") {
        const LCOH =
          clickPopupInfo.data.nonLCOEData.region.unrestricted[0].value;

        //verify that input value is converted to default unit
        const unitConverted = convertedLayerUnits[layerId];
        const convertedValue = currencyAndUnitConverter.convert(
          unitConverted,
          defaultUnit,
          newValue
        );
        if (parseFloat(convertedValue) >= LCOH) {
          costFilterDisclaimer();
        }
      }

      if (newValue === 0) {
        appendCostValue("", layerId);
      } else if (!isNaN(newValue)) {
        appendCostValue(newValue, layerId);
      }
    };

  /**
   * Handles the change of the cost input for a given layer.
   * @param {string} layerId - The ID of the layer to change the cost input for.
   * @param {string} defaultUnit - The default unit for the layer.
   * @returns {Function} A function that handles the change of the cost input.
   */
  const handleCostInputChange = (layerId, defaultUnit) => (event) => {
    const value = event.target.value;
    if (clickPopupInfo && clickPopupInfo.data.category === "nonLCOE") {
      const LCOH = clickPopupInfo.data.nonLCOEData.region.unrestricted[0].value;
      //verify that input value is converted to default unit
      const unitConverted = convertedLayerUnits[layerId];
      const convertedValue = currencyAndUnitConverter.convert(
        unitConverted,
        defaultUnit,
        value
      );
      if (parseFloat(convertedValue) >= LCOH) {
        costFilterDisclaimer();
      }
    }
    if (Number(value) === 0) {
      appendCostValue("", layerId);
    } else if (!isNaN(value)) {
      appendCostValue(value === "" ? "" : Number(value), layerId);
    }
  };

  /**
   * Resets the filter and cost value for a given layer.
   * @param {string} layerId - The ID of the layer to reset the filter and cost value for.
   */
  const handleResetFilter = (layerId) => {
    resetMapFilter(layerId);
    resetCostValue(layerId);
  };

  /**
   * Handles the opening and closing of the expandable list.
   * @param {number} index - The index of the expandable list.
   */
  const handleExpandableList = (index) => {
    openExpandableList === index
      ? setOpenExpandableList("")
      : setOpenExpandableList(index);
  };

  /**
   * Handles the change of a layer.
   * @param {Object} event - The event object.
   * @param {number} groupId - The ID of the group.
   * @param {string} layerId - The ID of the layer.
   * @param {string} type - The type of the layer.
   * @param {Object} interactivity - The interactivity object.
   */
  const handleLayerChange = (event, groupId, layerId, type, interactivity) => {
    changeLayer(layerId);
    renderLegend(layerId);
    disableScreenshot(layerId);
    interactivity?.count < 1 && showInteractivityInfo(layerId);
    closePopup();
    if (event.target.checked) {
      for (let key in groupIndex) {
        if (groupId === parseInt(key)) {
          groupIndex[key].push(layerId);
          setGroupIndex({ ...groupIndex, [key]: groupIndex[key] });
        }
      }
      //to handle dynamic reordering of added layers
      const layerIdsFromStylesheet = map
        ?.getStyle()
        .layers.filter((layer) => layer.id.includes(layerId))
        .map((layer) => layer.id);

      setCheckedLayers([...checkedLayers, ...layerIdsFromStylesheet]);
    } else {
      //to handle dynamic reordering of added layers
      const _newCheckedLayers = checkedLayers.filter(
        (layer) => !layer.includes(layerId)
      );

      setCheckedLayers(_newCheckedLayers);
      resetOpacity(layerId);
      handleResetFilter(layerId, type);
      setCostFilterDisclaimerCount(0);
      for (let key in groupIndex) {
        const index = groupIndex[key].indexOf(layerId);
        if (index > -1) {
          groupIndex[key].splice(index, 1);
          setGroupIndex({ ...groupIndex, [key]: groupIndex[key] });
        }
      }
    }
  };

  const handleToggleLayerLegendPanel = () => {
    setToggleLayer((prev) => !prev);
  };

  const handleOpacityChange = (id, type1, type2) => (e, value) => {
    changeOpacity(value, id);
    map && map.setPaintProperty(id, `${type1 || type2}-opacity`, value / 100);
  };

  const handleCostPercentageToggler =
    ({ layerId }) =>
    (event, newCostPercentage) => {
      if (newCostPercentage !== null) {
        changeCostPercentage(newCostPercentage, layerId);
        setCostFilterDisclaimerCount(0);
        // handleResetFilter(layerId, type);
      }
    };

  const handleHydropowerTypeToggler =
    ({ layerId, hydropowerType }) =>
    (event, newType) => {
      if (newType !== null) {
        changeHydropowerType(newType, layerId);
        handleResetFilter(layerId, hydropowerType);
      }
    };

  const handleGroundwaterStatusToggler = (layer) => (event, newType) => {
    const { layerId, mapboxId, groundwaterStatus } = layer;
    console.log(newType);
    if (newType !== null) {
      closePopup();
      changeGroundwaterStatus(newType, layerId);
      const newTilesId = mapboxId.replace(/.{3}$/, newType);

      if (map.getSource(mapboxId)) {
        map.removeLayer(layerId);
        map.removeSource(mapboxId);

        map.addSource(mapboxId, {
          type: "raster",
          id: mapboxId,
          tiles: [
            `https://api.mapbox.com/v4/fzj.${newTilesId}/{z}/{x}/{y}.png`,
          ],
        });
        handleResetFilter(layerId, groundwaterStatus);
      }
    }
  };

  const handleScreenshot = async (LAYER_CONFIG) => {
    if (!map) return;
    setLoadingScreenshot(true);
    const mapCanvas = map.getCanvas();
    const renderedLayers = LAYER_CONFIG.filter((layer) => layer.renderLayer);
    if (renderedLayers.length > 1) {
      setOpenScreenshotDisclaimer(true);
      setLoadingScreenshot(false);
    } else {
      const { name, layerId, legendInfo, fieldValue } = renderedLayers[0];
      const filteredRangeValues =
        fieldValue.length &&
        fieldValue.map((v) =>
          layerId.includes("lcoe") || layerId.includes("hydro") ? v * 100 : v
        );

      if (screenshotRegion.name) {
        setOpenScreenshotDialog(false);
        const isoCode = screenshotRegion.isoCode;
        const geocoderCoordinates = await getCoordinatesFromGeocoder(
          screenshotRegion.name,
          isoCode
        );
        if (!geocoderCoordinates) {
          setLoadingScreenshot(false);
          return;
        }
        const { southwest, northeast } = geocoderCoordinates;
        const { clientWidth, clientHeight } = map && map.getContainer();
        const mercartorViewport = getWebMercatorViewport(
          viewport,
          southwest,
          northeast,
          clientWidth,
          clientHeight
        );
        setViewport((prevViewport) => {
          map.addLayer(
            {
              id: `screenshot-layer`,
              type: "fill",
              filter: ["!=", ["get", "GID_0"], screenshotRegion.gid0],
              source: pathLocation.includes("ecowas")
                ? "ecowas_region"
                : pathLocation.includes("sadc")
                ? "sadc_region"
                : pathLocation.includes("east")
                ? "east_region"
                : null,
              "source-layer": "region",
              paint: {
                "fill-color": "#eee8e0",
              },
            },
            getBeforeIdLayerId()
          );
          return {
            ...prevViewport,
            ...mercartorViewport,
          };
        });
      }

      map.once("idle", async () => {
        const pdf = await printCanvas(
          mapCanvas,
          filteredRangeValues,
          name,
          layerId,
          legendInfo.unit,
          screenshotRegion.name,
          pathLocation,
          formatOptions
        );
        if (pdf) {
          setLoadingScreenshot(false);
          setViewport((prevViewport) => {
            map.removeLayer("screenshot-layer");
            return {
              ...prevViewport,
              ...viewport,
            };
          });
        }
      });
    }
  };

  const handleScreenshotDialog = (status) => setOpenScreenshotDialog(status);

  const handleScreenshotDisclaimer = (status) =>
    setOpenScreenshotDisclaimer(status);

  const handleScreenshotRegion = (regionOptions) => {
    setScreenshotRegion(regionOptions);
  };

  const states = {
    openExpandableList,
    groupIndex,
    loadingScreenshot,
    formatOptions,
    openScreenshotDialog,
    openScreenshotDisclaimer,
    screenshotRegion,
  };

  const handlers = {
    handleCostSliderChange,
    handleResetFilter,
    handleCostInputChange,
    handleExpandableList,
    handleLayerChange,
    handleToggleLayerLegendPanel,
    handleOpacityChange,
    handleCostPercentageToggler,
    handleHydropowerTypeToggler,
    handleGroundwaterStatusToggler,
    handleScreenshotRegion,
    handleScreenshotDialog,
    handleScreenshotDisclaimer,
    handleScreenshot,
  };

  const setters = {
    setFormatOptions,
  };

  return [states, handlers, setters];
};

export default useLayerFunctionality;
