export const roundToDecimal = (number, decimalPlaces) => {
  const factor = 10 ** decimalPlaces;
  const roundedNum = Math.round(number * factor) / factor;
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPlaces,
  });
  return typeof number === "number" && number !== 0
    ? formatter.format(roundedNum)
    : typeof number === "undefined"
    ? "N/A"
    : number;
};
