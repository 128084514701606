import { roundToDecimal } from "../../../utils/roundToDecimal";

import { useEffect, useState } from "react";

/**
 * A custom React hook that returns land eligibility facts based on remote national data.
 * @category Hooks
 * @component
 * @param {Object} options - The options object.
 * @param {Object} options.remoteNationalData - The remote national data object.
 * @returns {Object} An object containing land eligibility facts.
 */
const useLandEligibilityFacts = ({ remoteNationalData }) => {
  /**
   * The state variable that holds the land eligibility facts.
   * @type {Object|null}
   */
  const [landEligibilityFacts, setLandEligibilityFacts] = useState(null);

  /**
   * A function that returns the land eligibility facts based on the remote national data.
   * @returns {Object} An object containing land eligibility facts.
   */
  const getLandEligibilityFacts = () => ({
    heading: "Land eligibility",
    main: [
      {
        title: "Wind Onshore",
        value: `${roundToDecimal(remoteNationalData.eligibility.onshore, 2)}%`,
        annotation: "",
      },
      {
        title: "Open-field PV",
        value: `${roundToDecimal(remoteNationalData.eligibility.pv, 2)}%`,
        annotation: "",
      },
    ],
    footnotes: "",
  });

  /**
   * A React useEffect hook that sets the land eligibility facts state variable based on the remote national data.
   */
  useEffect(() => {
    if (!remoteNationalData) return;
    setLandEligibilityFacts(getLandEligibilityFacts());
  }, [remoteNationalData]);

  return { landEligibilityFacts };
};

export default useLandEligibilityFacts;
