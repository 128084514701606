import React from "react";
import { MapContext } from "react-map-gl";
import { Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { AppProvider } from "./contexts/AppContext";
import Map from "./components/Map/Map";
import UI from "./components/UI/UI";
import "./App.css";
import Home from "./components/UI/Home/Home";
import LoadScreen from "./components/UI/LoadScreen/LoadScreen";
import ModellerInterface from "./components/UI/ModellerInterface/ModellerInterface";
import themeConfig from "./MUIThemeConfig";

// Create a Material-UI theme using the theme configuration
const theme = createTheme(themeConfig);

/**
 * The main application component responsible for routing and rendering different pages.
 * @category App
 * @component
 * @returns {JSX.Element} The rendered application.
 */
const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <MapContext.Provider>
          <div>
            <Switch>
              <Route exact path="/">
                {/* Renders the Home component within a LoadScreen. */}
                <LoadScreen component={<Home />} />
              </Route>
              {/* <Route exact path="/ecowas">
                <LoadScreen
                  component={
                    <AppProvider>
                      <UI />
                      <Map />
                    </AppProvider>
                  }
                />
              </Route>
              <Route exact path="/sadc">
                <LoadScreen
                  component={
                    <AppProvider>
                      <UI />
                      <Map />
                    </AppProvider>
                  }
                />
              </Route> */}
              <Route exact path="/east">
                <LoadScreen
                  component={
                    <AppProvider>
                      <UI />
                      <Map />
                    </AppProvider>
                  }
                />
              </Route>
              {process.env.REACT_APP_ENV === "development" && (
                <Route exact path="/ecowas/modeller-interface">
                  {/* Renders the ModellerInterface component within an AppProvider, with a hidden Map component. */}
                  <LoadScreen
                    component={
                      <AppProvider>
                        <div style={{ display: "none" }}>
                          <Map />
                        </div>
                        <ModellerInterface />
                      </AppProvider>
                    }
                  />
                </Route>
              )}

              {process.env.REACT_APP_ENV === "development" && (
                <Route exact path="/sadc/modeller-interface">
                  {/* Renders the ModellerInterface component within an AppProvider, with a hidden Map component. */}
                  <LoadScreen
                    component={
                      <AppProvider>
                        <div style={{ display: "none" }}>
                          <Map />
                        </div>
                        <ModellerInterface />
                      </AppProvider>
                    }
                  />
                </Route>
              )}
              {process.env.REACT_APP_ENV === "development" && (
                <Route exact path="/east/modeller-interface">
                  {/* Renders the ModellerInterface component within an AppProvider, with a hidden Map component. */}
                  <LoadScreen
                    component={
                      <AppProvider>
                        <div style={{ display: "none" }}>
                          <Map />
                        </div>
                        <ModellerInterface />
                      </AppProvider>
                    }
                  />
                </Route>
              )}
            </Switch>
          </div>
        </MapContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
