import React, { createContext, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import PropTypes from "prop-types"; // Import PropTypes

/**
 * UIContext provides a context for managing UI-related state.
 * @category Context
 */
const UIContext = createContext();

/**
 * UIProvider is a component that provides the UIContext to its children.
 * @component
 * @category Context
 */
const UIProvider = ({ children }) => {
  const { width } = useWindowDimensions();
  const [runGuide, setRunGuide] = useState(false);
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
  const [openCookiePolicyDialog, setOpenCookieDialog] = useState(false);
  const [openCookieBanner, setOpenCookieBanner] = useState(true);
  const [cookieConsent, setCookieConsent] = useState("");

  /**
   * The value object holds various state variables related to the UI.
   * @type {Object}
   * @property {boolean} openCookieBanner - Determines whether the cookie banner is open.
   * @property {function} setOpenCookieBanner - Function to set the state of the cookie banner.
   * @property {boolean} openCookiePolicyDialog - Determines whether the cookie policy dialog is open.
   * @property {function} setOpenCookieDialog - Function to set the state of the cookie policy dialog.
   * @property {string} cookieConsent - Stores the user's cookie consent.
   * @property {function} setCookieConsent - Function to set the user's cookie consent.
   * @property {boolean} runGuide - Determines whether a guide is running.
   * @property {function} setRunGuide - Function to set the state of the guide.
   * @property {boolean} openWelcomeDialog - Determines whether the welcome dialog is open.
   * @property {function} setOpenWelcomeDialog - Function to set the state of the welcome dialog.
   * @property {number} width - Stores the width of the window.
   */

  const value = {
    openCookieBanner,
    setOpenCookieBanner,
    openCookiePolicyDialog,
    setOpenCookieDialog,
    cookieConsent,
    setCookieConsent,
    runGuide,
    setRunGuide,
    openWelcomeDialog,
    setOpenWelcomeDialog,
    width,
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

// Define PropTypes for UIProvider
UIProvider.propTypes = {
  children: PropTypes.node, // The child components to be wrapped by UIContext.
};

export { UIContext, UIProvider };
