import React, { useState, useCallback, useEffect, useContext } from "react";
import MapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./Map.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapToolsBar from "./MapToolsBar/MapToolsbar";
import LayerLegendPanel from "./LayerLegendPanel/LayerLegendPanel";
import MapStyle from "./MapStyle/MapStyle";
import Geolocate from "./Geolocate/Geolocate";
import Draw from "./Draw/Draw";
import Layers from "./Layers/Layers";
import ResultsPanel from "./ResultsPanel/ResultsPanel";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { AppContext } from "../../contexts/AppContext";
import Popups from "./Popups/Popups";
import useClickToShow from "../../hooks/Map/useClickToShow";
import useMap from "../../hooks/Map/useMap";
import useViewport from "../../hooks/Map/useViewport";
import MapAttachments from "./MapAttachments/MapAttachments";
import useDependency from "../../hooks/Map/useDependency";
import PropTypes from "prop-types";
import {
  DEFAULT_STREETS_STYLE_STRING,
  INTERACTIVE_LAYER_IDS,
} from "../../constants/constants";

/**
 * @component
 * @category Native
 * @subcategory Map
 * @description A component that renders a Map using MapGL and displays various layers and tools on it.
 * @param {Object} props - Object containing the component's props
 * @param {string} [props.pathLocation] - The location path of the component
 * @param {Object} [props.LAYER_CONFIG] - The configuration object for the layers
 * @returns {JSX.Element} - Rendered Map component
 */
const Map = () => {
  const { pathLocation, LAYER_CONFIG } = useContext(AppContext);
  const { height, width } = useWindowDimensions();
  const [
    { map, mapRef, showCoordinates },
    { loadAndAddSprites, handleCoordinates, handleOnLoad, getBeforeIdLayerId },
  ] = useMap(LAYER_CONFIG);
  const [
    {
      resultPanelParams,
      clickEventObject,
      clickPopupInfo,
      latLng,
      errorMessage,
      popupLoading,
      mapScroll,
    },
    {
      handleClickedNonLCOELayers,
      handleClickedLCOELayer,
      handleClickedToShowResultLayers,
      handleClickedRasterLayers,
      handleLatLng,
      handleClickEvent,
      closePopup,
      handleMapScroll,
    },
  ] = useClickToShow(LAYER_CONFIG);
  const [{ viewport }, { onViewportChange, resetViewport, setViewport }] =
    useViewport(width, height);
  const COST_PERCENTAGE_DEPENDENCY = useDependency(
    LAYER_CONFIG,
    "costPercentage"
  );
  const COST_VALUE_DEPENDENCY = useDependency(LAYER_CONFIG, "costValue");
  const HYDROPOWER_TYPE_DEPENDENCY = useDependency(
    LAYER_CONFIG,
    "hydropowerType"
  );
  const GROUNDWATER_STATUS_DEPENDENCY = useDependency(
    LAYER_CONFIG,
    "groundwaterStatus"
  );
  const [hoverInfo, setHoverInfo] = useState(null);

  /**
   * Function that handles the click event on the map and shows the appropriate popup
   * @param {Object} event - The click event object
   * @returns {void}
   */
  const clickToShowPopup = async (event) => {
    if (event) {
      handleClickEvent(event);
    }
    const currentClickEventObject = event || clickEventObject;

    handleLatLng(currentClickEventObject);

    let clickedLCOELayer;

    const clickedToShowResultLayers = currentClickEventObject.features.filter(
      (feature) => /fact/.test(feature.layer.id)
    );
    const clickedNonLCOELayers = currentClickEventObject.features.find(
      (feature) => feature.layer.id.includes("h2")
    );
    clickedLCOELayer =
      currentClickEventObject.features.find((feature) =>
        feature.layer.id.includes("region")
      ) ||
      currentClickEventObject.features.find((feature) =>
        feature.layer.id.includes("hydro")
      );
    const clickedRasterLayers = currentClickEventObject.features.find(
      (feature) =>
        feature.layer.id.includes("exc") || feature.layer.id.includes("gw")
    );

    if (clickedToShowResultLayers.length !== 0) {
      handleClickedToShowResultLayers(currentClickEventObject);
    }
    if (clickedRasterLayers) {
      handleClickedRasterLayers(currentClickEventObject);
    } else if (clickedNonLCOELayers) {
      handleClickedNonLCOELayers(currentClickEventObject);
    } else if (clickedLCOELayer) {
      handleClickedLCOELayer(currentClickEventObject);
    } else {
      closePopup();
    }
  };

  /**
   * Function that handles the hover event on a layer and sets the hoverInfo state
   * @param {Object} event - The hover event object
   * @returns {void}
   */
  const onHover = useCallback((event) => {
    const feature = event.features && event.features[0];
    if (feature && feature.sourceLayer === "region") {
      setHoverInfo(feature && feature.properties.region_id);
    } else if (feature && feature.sourceLayer === "hydrogen") {
      setHoverInfo(feature && feature.properties.region_id);
    } else {
      setHoverInfo(null);
    }
  }, []);

  useEffect(() => {
    if (clickEventObject) {
      clickToShowPopup();
    }
  }, [
    COST_PERCENTAGE_DEPENDENCY,
    HYDROPOWER_TYPE_DEPENDENCY,
    COST_VALUE_DEPENDENCY,
    GROUNDWATER_STATUS_DEPENDENCY,
    clickEventObject,
  ]);

  return (
    <div id="map-container">
      <MapGL
        onLoad={handleOnLoad}
        ref={mapRef}
        {...viewport}
        onMouseMove={handleCoordinates}
        onHover={onHover}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        width="100vw"
        height="calc(100vh - 180px)"
        mapStyle={DEFAULT_STREETS_STYLE_STRING}
        onClick={clickToShowPopup}
        onViewportChange={onViewportChange}
        interactiveLayerIds={INTERACTIVE_LAYER_IDS}
        doubleClickZoom={false}
        scrollZoom={!mapScroll ? false : true}
        preventStyleDiffing={true}
        preserveDrawingBuffer={true}
      >
        <Layers
          map={map}
          getBeforeIdLayerId={getBeforeIdLayerId}
          hoverInfo={hoverInfo}
        />

        {/* Isolate certain map-specific components when on the modeller interface */}
        {!pathLocation.includes("modeller-interface") && (
          <div>
            <Draw
              viewport={viewport}
              map={map}
              getBeforeIdLayerId={getBeforeIdLayerId}
              resetViewport={resetViewport}
              setHoverInfo={setHoverInfo}
            />
            {latLng && (
              <Popups
                clickPopupInfo={clickPopupInfo}
                latLng={latLng}
                handleMapScroll={handleMapScroll}
                popupLoading={popupLoading}
                closePopup={closePopup}
                errorMessage={errorMessage}
                clickToShowPopup={clickToShowPopup}
              />
            )}
            <MapAttachments showCoordinates={showCoordinates} />
            <MapToolsBar
              onViewportChange={onViewportChange}
              resetViewport={resetViewport}
            />
            <Geolocate
              mapRef={mapRef}
              viewport={viewport}
              onViewportChange={onViewportChange}
            />
          </div>
        )}
      </MapGL>

      {/* Isolate certain map-specific components when on the modeller interface */}
      {!pathLocation.includes("modeller-interface") && (
        <div>
          <MapStyle
            map={map}
            loadAndAddSprites={loadAndAddSprites}
            setHoverInfo={setHoverInfo}
          />
          <LayerLegendPanel
            viewport={viewport}
            setViewport={setViewport}
            map={map}
            closePopup={closePopup}
            getBeforeIdLayerId={getBeforeIdLayerId}
            clickPopupInfo={clickPopupInfo}
            setHoverInfo={setHoverInfo}
          />
          <ResultsPanel
            map={map}
            viewport={viewport}
            onViewportChange={onViewportChange}
            resultPanelParams={resultPanelParams}
            setHoverInfo={setHoverInfo}
          />
        </div>
      )}
    </div>
  );
};

Map.propTypes = {
  // The router path of the component
  pathLocation: PropTypes.string,
  // The configuration object for the layers
  LAYER_CONFIG: PropTypes.object,
};

export default Map;

//TODO: Translate popups (New supplementary layers) [check]
//TODO: Translate new information in tutorial
//TODO: Translate Disclaimers from LCOE layers
