import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const sectorIndicatorMapValueToStrings = (translate) => [
  { value: "-3", label: translate("legendInfo.Less Favorable") },
  { value: "-2", label: translate("legendInfo.Less Favorable") },
  { value: "-1", label: translate("legendInfo.Less Favorable") },
  { value: "0", label: translate("legendInfo.Favorable") },
  { value: "1", label: translate("legendInfo.Highly Favorable") },
  { value: "2", label: translate("legendInfo.Highly Favorable") },
  { value: "3", label: translate("legendInfo.Highly Favorable") },
];

/**
 * A custom React hook that returns an object containing indicators facts.
 * @category Hooks
 * @component
 * @param {Object} param - An object containing remoteNationalData.
 * @param {Object} param.remoteNationalData - An object containing remote national data.
 * @returns {Object} An object containing indicators facts.
 */
const useIndicatorsFacts = ({ remoteNationalData }) => {
  /**
   * A state variable that holds the indicators facts.
   */
  const [indicatorsFacts, setIndicatorsFacts] = useState(null);

  /**
   * A hook that provides translation functionality.
   */
  const { t } = useTranslation();

  /**
   * A function that returns an object containing indicators facts.
   * @returns {Object} An object containing indicators facts.
   */
  const getIndicatorsFacts = () => ({
    heading: "Socio-economic impact assessment",
    main: [
      {
        title: "Current and future Energy and Power sectors performances",
        value: sectorIndicatorMapValueToStrings(t).find(
          (ind) => ind.value === remoteNationalData.indicators.energy.toString()
        )?.label,
        annotation: "",
      },
      {
        title: "Export and Transport Infrastructure",
        value: sectorIndicatorMapValueToStrings(t).find(
          (ind) => ind.value === remoteNationalData.indicators.export.toString()
        )?.label,
        annotation: "",
      },
      {
        title: "Eco-political framework",
        value: sectorIndicatorMapValueToStrings(t).find(
          (ind) =>
            ind.value === remoteNationalData.indicators.political.toString()
        )?.label,
        annotation: "",
      },
    ],
    footnotes: [
      { label: "indFirst" },
      { label: "indSecond" },
      { label: "indThird" },
      { label: "indFourth" },
    ],
    references: [
      {
        label:
          "2000-2020 Energy Balances, Department of Economic and Social Affairs Statistics Division, United Nations, 2000-2020",
        referenceAnnotation: 1,
      },
      {
        label:
          "Based on partners countries energy policies, and Nationally Determined Contributions accessible in NDC registry https://unfccc.int/NDCREG",
        referenceAnnotation: 2,
      },
      {
        label:
          "World Bank, 2016-2020. 'Doing Business 2016-2020,' World Bank Publications, The World Bank Group",
        referenceAnnotation: 3,
      },
      {
        label:
          "Kaufmann, Daniel & Kraay, Aart & Mastruzzi, Massimo, 2010. 'The worldwide governance indicators: methodology and analytical issues'",
        referenceAnnotation: 4,
      },
    ],
  });

  /**
   * A hook that runs when remoteNationalData changes.
   * It sets the indicatorsFacts state variable to the result of getIndicatorsFacts.
   */
  useEffect(() => {
    if (!remoteNationalData) return;
    setIndicatorsFacts(getIndicatorsFacts());
  }, [remoteNationalData]);

  return { indicatorsFacts };
};

export default useIndicatorsFacts;
