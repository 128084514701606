/**
 * This file contains the style for the MapToolsBar component using Material-UI.
 * @name MapToolsBarMUIStyle
 * @category MUI
 * @param {Object} theme - The Material-UI theme object.
 * @returns {Object} The Material-UI styling.
 */
const style = (theme) => ({
  mapToolsWrapper: {
    [theme.breakpoints.down("md")]: {
      top: "5px",
    },
    backgroundColor: theme.palette.background.paper,
    height: "29px",
    width: "150px",
    position: "absolute",
    zIndex: 100,
    top: "5px",
    right: 0,
    boxSizing: "border-box",
    boxShadow: "0px 0px 3px #434343",
    borderRadius: "5px",
  },
  mapToolsWrapperCollapsible: {
    backgroundColor: theme.palette.background.paper,
    width: 50,
    position: "fixed",
    top: "11vh",
    right: 0,
    boxSizing: "border-box",
    boxShadow: "0px 0px 3px #434343",
    borderRadius: "5px",
    "& .MuiList-padding": {
      padding: 0,
    },
  },
  menu: {
    right: 0,
    "& ul": {
      display: "flex",
    },

    "& .MuiList-padding": {
      padding: 0,
    },
  },
  menuItem: {},
  resetViewportStyle: {
    position: "absolute",
    right: "116px",
  },

  fullScreenControlStyle: {
    position: "absolute",
    // top: "5px",
    right: "0px",
  },

  navStyle: {
    position: "absolute",
    // top: "5px",
    right: "30px",
  },
  iconButton: {
    "&:hover": {
      padding: "0px",
    },
    borderRadius: "unset",
  },
});

export default style;
