import { Box } from "@mui/material";
import currencyAndUnitConverter from "../../../utils/currencyAndUnitConverter";
import { List, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { symbolsConverter } from "../../../utils/symbolsConverter";
import { roundToDecimal } from "../../../utils/roundToDecimal";
import usePaintLegendConfig from "../../../hooks/Layers/usePaintLegendConfig";

//eslint-disable-next-line
const classValuesPattern = /^[0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
const minMaxValuesPattern = /[<>]/;

import PropTypes from "prop-types";

/**
 * A component that displays a color ramp and its corresponding values for a given layer.
 * @category Native
 * @subcategory ColorRamp
 * @component
 * @returns {JSX.Element} - The ColorRamp component.
 */
const ColorRamp = ({ unitConverted, defaultUnit, layer }) => {
  console.log(unitConverted, "converted");
  /**
   * A function that returns the translated string for a given key.
   * @param {string} key - The key to translate.
   * @returns {string} - The translated string.
   */
  const { t } = useTranslation();

  /**
   * A hook that returns the paint legend configuration for a given layer.
   * @returns {Object} - The paint legend configuration.
   */
  const { legend } = usePaintLegendConfig(layer);

  if (!legend) {
    return null;
  }

  return (
    <Box display="flex" py={1}>
      {layer.groupName.includes("land_eligibility") ? (
        <Box flexDirection="column">
          {legend.colors.map((color, index) => (
            <Typography
              key={index}
              sx={{
                display: "block",
                background: color,
                height: `100px`,
                width: "50px",
              }}
            ></Typography>
          ))}
        </Box>
      ) : (
        <Typography
          sx={{
            display: "block",
            background: `linear-gradient(to bottom, ${legend.colors.join(
              ", "
            )})`,
            height: `200px`,
            width: "50px",
          }}
        ></Typography>
      )}

      <List
        disablePadding
        sx={{
          position: "relative",
          marginTop: 0,
          listStyle: "none",
        }}
      >
        {legend.values.map((value, index, arr) => {
          const convertedValue = roundToDecimal(
            currencyAndUnitConverter.convert(defaultUnit, unitConverted, value),
            2
          );
          const newValue =
            legend.maxValueStatus === "over" && index === arr.length - 1
              ? `> ${convertedValue}`
              : value;

          return (
            <ListItem
              key={index}
              disableGutters
              disablePadding
              sx={{
                position: "absolute",
                top: `${(index / (arr.length - 1)) * 100 * 0.9}%`,
                left: 2,
                width: "10rem",
                p: 0,
                m: 0,
                fontSize: "0.9rem",
              }}
            >
              {typeof parseFloat(newValue) === "number"
                ? convertedValue
                : classValuesPattern.test(newValue) ||
                  minMaxValuesPattern.test(newValue)
                ? newValue
                : t(`legendInfo.${newValue}`)}{" "}
              <Typography
                ml={0.2}
                fontSize="0.7rem"
                fontStyle="italic"
                lineHeight={0}
                display="inline"
                component="span"
              >
                {symbolsConverter(unitConverted)}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

ColorRamp.propTypes = {
  /**
   * The converted layer units.
   */
  unitConverted: PropTypes.string,
  /**
   * The default unit.
   */
  defaultUnit: PropTypes.string,
  /**
   * The layer object.
   */
  layer: PropTypes.object,
};

export default ColorRamp;
