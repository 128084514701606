import { useContext } from "react";
import { Popup } from "react-map-gl";
import Loader from "../../UI/LoadScreen/Loader";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { symbolsConverter } from "../../../utils/symbolsConverter";

import { AppContext } from "../../../contexts/AppContext";
import currencyAndUnitConverter from "../../../utils/currencyAndUnitConverter";
import MyTooltip from "../../UI/Tooltip/MyTooltip";

/**
 * Renders a popup component on the map with information about a selected location or layer.
 * @component
 * @category Native
 * @subcategory Popups
 * @param {Object} props - The component props.
 * @param {Object} props.clickPopupInfo - Information about the selected location or layer.
 * @param {Object} props.latLng - The latitude and longitude of the selected location.
 * @param {boolean} props.popupLoading - Indicates whether the popup is currently loading.
 * @param {Function} props.handleMapScroll - Function to handle map scrolling.
 * @param {Function} props.closePopup - Function to close the popup.
 * @returns {JSX.Element} - The rendered component.
 */
const Popups = ({
  clickPopupInfo,
  latLng,
  popupLoading,
  handleMapScroll,
  closePopup,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { convertedLayerUnits } = useContext(AppContext);

  /**
   * Returns the converted and rounded up value of a given value from a default unit to a new unit.
   * @param {string} defaultUnit - The default unit of the value.
   * @param {string} newUnit - The new unit to convert the value to.
   * @param {number} value - The value to convert.
   * @returns {number} - The converted and rounded up value.
   */
  const getConvertedandRoundedUpValue = (defaultUnit, newUnit, value) => {
    return currencyAndUnitConverter.convert(defaultUnit, newUnit, value);
  };

  if (!clickPopupInfo) {
    return (
      <Popup
        style={{ position: "absolute" }}
        latitude={latLng.lat}
        longitude={latLng.lng}
        closeButton={false}
        anchor="top"
      >
        <Loader size={50} />
      </Popup>
    );
  }

  const {
    lcoeData,
    nonLCOEData,
    rasterData,
    costPercentage,
    name,
    costYear,
    year,
    title,
    category,
    layerId,
    defaultUnit,
  } = clickPopupInfo.data;

  return (
    <Box
      onMouseEnter={() => handleMapScroll(false)}
      onMouseLeave={() => handleMapScroll(true)}
      p={10}
      onClick={closePopup}
    >
      <Popup
        style={{ position: "absolute", padding: "10px" }}
        latitude={latLng.lat}
        longitude={latLng.lng}
        closeButton={popupLoading ? false : true}
        closeOnClick={false}
        // onClose={closePopup}
        anchor="top"
      >
        {popupLoading ? (
          <Loader size={50} />
        ) : category === "rasters" ? (
          <Box pt={2} px={1} width="fit-content">
            <DialogTitle component="div" sx={{ m: 0, p: 0 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Stack direction="column">
                  <Typography display="inline-block" fontWeight={900}>
                    {t(`layerLegend.layers.name.${name}`)}
                  </Typography>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent
              component="div"
              sx={{ pr: 1, py: 0, pl: 0, maxHeight: 250, overflowY: "auto" }}
              dividers
            >
              {Object.keys(rasterData).map((d, index) => (
                <DialogContentText
                  component={DialogContent}
                  textAlign="center"
                  key={index}
                  sx={{ p: 0 }}
                  dividers
                >
                  <Typography
                    variant="subtitle"
                    fontSize="0.8rem"
                    sx={{ fontWeight: 900 }}
                  >
                    {rasterData[d].location}
                  </Typography>
                  <Stack
                    mb={0.5}
                    gap={2}
                    fontSize={theme.typography.body2}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Chip
                      label={t(`map.popup.${rasterData[d].label}`)}
                      size="small"
                      color="primary"
                      sx={{ fontSize: theme.typography.caption }}
                    />
                    <Typography variant="body2">
                      {rasterData[d].value}
                      {rasterData[d].unit}
                    </Typography>
                  </Stack>
                </DialogContentText>
              ))}
            </DialogContent>
          </Box>
        ) : category === "LCOE" ? (
          <Box width="fit-content" px={1} pt={2}>
            <DialogTitle component="div" sx={{ m: 0, p: 0 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Stack direction="column">
                  <Typography display="inline-block" fontWeight={900}>
                    {t(`map.popup.${title}`)} {year}
                  </Typography>
                </Stack>
              </Stack>
            </DialogTitle>

            <DialogContent
              component="div"
              sx={{ pr: 1, py: 0, pl: 0, maxHeight: 250, overflowY: "auto" }}
              dividers
            >
              {Object.keys(lcoeData).map((d, index) => (
                <DialogContentText
                  component={DialogContent}
                  textAlign="center"
                  key={index}
                  sx={{ p: 0 }}
                  dividers
                >
                  <>
                    <Typography
                      variant="subtitle"
                      fontSize="0.8rem"
                      sx={{ fontWeight: 900 }}
                    >
                      {lcoeData[d].location}{" "}
                      {lcoeData[d].locationLabel &&
                        t(`map.popup.${lcoeData[d].locationLabel}`)}
                    </Typography>
                    <Stack
                      direction="row"
                      fontSize={theme.typography.caption}
                      fontWeight={900}
                    >
                      <Typography
                        display="inline"
                        variant="caption"
                        fontWeight={900}
                      >
                        {t(`map.popup.unrestricted`)}
                      </Typography>
                      <MyTooltip
                        title={t(`map.popup.tooltip.unrestrictedTitle`)}
                      />
                    </Stack>
                    {lcoeData[d]?.unrestricted?.map((item, index) => (
                      <Box key={index}>
                        <Stack
                          mb={0.5}
                          gap={2}
                          fontSize={theme.typography.body2}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Chip
                            label={t(`map.popup.${item.label}`)}
                            size="small"
                            color="primary"
                            sx={{ fontSize: theme.typography.caption }}
                          />
                          <Box>
                            <Typography variant="body2" display="inline">
                              {item.label.toLowerCase().includes("cost")
                                ? getConvertedandRoundedUpValue(
                                    defaultUnit,
                                    convertedLayerUnits[layerId],
                                    item.value
                                  )
                                : item.value}{" "}
                              {item.label.toLowerCase().includes("cost")
                                ? convertedLayerUnits[layerId]
                                : item.unit}
                            </Typography>
                            <MyTooltip
                              title={t(
                                `map.popup.tooltip.${lcoeData[d].type}.unrestricted.${item.label}`
                              )}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    ))}
                    {lcoeData[d]?.costFiltered && (
                      <>
                        <Stack
                          direction="row"
                          fontSize={theme.typography.caption}
                          fontWeight={800}
                        >
                          <Typography
                            variant="caption"
                            display="inline"
                            fontWeight={900}
                          >
                            {t(`map.popup.costFiltered`)}
                          </Typography>
                          <MyTooltip
                            title={t(`map.popup.tooltip.costFilteredTitle`)}
                          />
                        </Stack>
                        {lcoeData[d]?.costFiltered?.map((item, index) => (
                          <Box key={index}>
                            <Stack
                              mb={0.5}
                              gap={2}
                              fontSize={theme.typography.body2}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Chip
                                label={t(`map.popup.${item.label}`)}
                                size="small"
                                color="success"
                                sx={{ fontSize: theme.typography.caption }}
                              />
                              <Box>
                                <Typography variant="body2" display="inline">
                                  {item.value}{" "}
                                  {!item.value
                                    ? null
                                    : item.label.toLowerCase().includes("cost")
                                    ? convertedLayerUnits[layerId]
                                    : item.unit}
                                </Typography>
                                <MyTooltip
                                  title={t(
                                    `map.popup.tooltip.${lcoeData[d].type}.costFiltered.${item.label}`
                                  )}
                                />
                              </Box>
                            </Stack>
                          </Box>
                        ))}
                      </>
                    )}
                  </>
                </DialogContentText>
              ))}
            </DialogContent>
          </Box>
        ) : (
          <Box pt={2} px={1} width="fit-content">
            <DialogTitle component="div" sx={{ m: 0, p: 0 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Stack direction="column">
                  <Typography display="inline-block" fontWeight={900}>
                    {t(`map.popup.${title}`)}{" "}
                  </Typography>
                  <Typography variant="caption">
                    {" "}
                    {costPercentage && costYear
                      ? `(${costYear}, max. ${costPercentage} ${t(
                          "map.popup.potential expansion"
                        )})`
                      : costPercentage
                      ? `(max. ${costPercentage} ${t(
                          "map.popup.potential expansion"
                        )})`
                      : null}
                  </Typography>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent
              component="div"
              sx={{ pr: 1, py: 0, pl: 0, maxHeight: 250, overflowY: "auto" }}
            >
              {Object.keys(nonLCOEData).map((d, index) => (
                <DialogContentText
                  component={DialogContent}
                  textAlign="center"
                  key={index}
                  sx={{ p: 0 }}
                  dividers
                >
                  {" "}
                  <>
                    <Typography
                      variant="subtitle"
                      fontSize="0.8rem"
                      sx={{ fontWeight: 900 }}
                    >
                      {nonLCOEData[d].location}
                    </Typography>
                    <Stack
                      direction="row"
                      fontSize={theme.typography.body2}
                      fontWeight={800}
                    >
                      <Box>
                        {" "}
                        <Typography
                          display="inline"
                          variant="caption"
                          fontWeight={900}
                        >
                          {t(`map.popup.unrestricted`)}
                        </Typography>
                        <MyTooltip
                          title={t(`map.popup.tooltip.unrestrictedTitle`)}
                        />
                      </Box>
                    </Stack>
                    {layerId.includes("lcoh")
                      ? nonLCOEData[d].unrestricted.map((item, index) => (
                          <Box key={index}>
                            <Stack
                              mb={0.5}
                              direction="row"
                              gap={2}
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Chip
                                label={t(`map.popup.${item.label}`)}
                                size="small"
                                color="primary"
                                sx={{ fontSize: theme.typography.caption }}
                              />
                              <Box>
                                <Typography variant="body2" display="inline">
                                  {item.label !== "hydrogenProduction"
                                    ? getConvertedandRoundedUpValue(
                                        defaultUnit,
                                        convertedLayerUnits[layerId],
                                        item.value
                                      )
                                    : item.value}{" "}
                                  {item.label === "hydrogenProduction"
                                    ? item.unit
                                    : symbolsConverter(
                                        convertedLayerUnits[layerId]
                                      )}
                                </Typography>
                                <MyTooltip
                                  title={t(
                                    `map.popup.tooltip.${nonLCOEData[d].type}.unrestricted.${item.label}`
                                  )}
                                />
                              </Box>
                            </Stack>
                          </Box>
                        ))
                      : nonLCOEData[d].unrestricted
                          .filter(
                            (item) =>
                              item.label !== "hydrogenProduction" &&
                              item.label !== "costAtOnePerc" &&
                              !item.label.includes("min") &&
                              !item.label.includes("max")
                          )
                          .map((item, index) => (
                            <Stack
                              key={index}
                              mb={0.5}
                              gap={2}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Chip
                                label={t(`map.popup.${item.label}`)}
                                size="small"
                                color="primary"
                                sx={{ fontSize: theme.typography.caption }}
                              />
                              <Box>
                                <Typography variant="body2" display="inline">
                                  {getConvertedandRoundedUpValue(
                                    defaultUnit,
                                    convertedLayerUnits[layerId],
                                    item.value
                                  )}{" "}
                                  {item.label === "hydrogenProduction"
                                    ? item.unit
                                    : symbolsConverter(
                                        convertedLayerUnits[layerId]
                                      )}
                                </Typography>
                                {/* <MyTooltip
                                  title={t(
                                    `map.popup.tooltip.${nonLCOEData[d].type}.unrestricted.${item.label}`
                                  )}
                                /> */}
                              </Box>
                            </Stack>
                          ))}
                    {layerId.includes("lcoh") && (
                      <>
                        <Stack
                          direction="row"
                          fontSize={theme.typography.body2}
                          fontWeight={800}
                        >
                          <Box>
                            <Typography
                              variant="caption"
                              display="inline"
                              fontWeight={900}
                            >
                              {t(`map.popup.costFiltered`)}
                            </Typography>
                            <MyTooltip
                              title={t(`map.popup.tooltip.costFilteredTitle`)}
                            />
                          </Box>
                        </Stack>
                        {nonLCOEData[d].costFiltered.map((item, index) => {
                          return (
                            <Stack
                              key={index}
                              mb={0.5}
                              gap={2}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Chip
                                label={t(`map.popup.${item.label}`)}
                                size="small"
                                color="success"
                                sx={{ fontSize: theme.typography.caption }}
                              />
                              <Box>
                                <Typography variant="body2" display="inline">
                                  {item.value}{" "}
                                  {!item.value
                                    ? null
                                    : item.label === "hydrogenProduction"
                                    ? item.unit
                                    : symbolsConverter(
                                        convertedLayerUnits[layerId]
                                      )}
                                </Typography>
                                <MyTooltip
                                  title={t(
                                    `map.popup.tooltip.${nonLCOEData[d].type}.costFiltered.${item.label}`
                                  )}
                                />
                              </Box>
                            </Stack>
                          );
                        })}
                      </>
                    )}
                  </>
                </DialogContentText>
              ))}
            </DialogContent>
          </Box>
        )}
      </Popup>
    </Box>
  );
};

Popups.propTypes = {
  /**
   * Information about the selected location or layer.
   */
  clickPopupInfo: PropTypes.shape({
    data: PropTypes.shape({
      lcoeData: PropTypes.number,
      nonLCOEData: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      rasterData: PropTypes.objectOf(
        PropTypes.shape({
          label: PropTypes.string,
          location: PropTypes.string,
          unit: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      costPercentage: PropTypes.number,
      name: PropTypes.string,
      costYear: PropTypes.number,
      year: PropTypes.number,
      title: PropTypes.string,
      category: PropTypes.string,
      layerId: PropTypes.string,
      defaultUnit: PropTypes.string,
    }),
  }),
  /**
   * The latitude and longitude of the selected location.
   */
  latLng: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  /**
   * Indicates whether the popup is currently loading.
   */
  popupLoading: PropTypes.bool,
  /**
   * Function to handle map scrolling.
   */
  handleMapScroll: PropTypes.func,
  /**
   * Function to close the popup.
   */
  closePopup: PropTypes.func,
};

export default Popups;
