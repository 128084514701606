import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

/**
 * A dialog component that displays a disclaimer message.
 * @component
 * @category Native
 * @subcategory InfoDialog
 * @returns {JSX.Element}
 */
const DisclaimerDialog = ({ open, onClose, onClick, info }) => {
  const { t } = useTranslation();

  /**
   * Callback function to handle the click event.
   */
  const handleClick = () => {
    onClick();
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("footer.disclaimer")}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <>{info}</>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClick} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DisclaimerDialog.propTypes = {
  /**
   * Determines if the dialog is open or not.
   */
  open: PropTypes.bool,
  /**
   * Callback function to close the dialog.
   */
  onClose: PropTypes.func,
  /**
   * Callback function to handle the click event.
   */
  onClick: PropTypes.func,
  /**
   * The disclaimer message to be displayed.
   */
  info: PropTypes.string,
};

export default DisclaimerDialog;
