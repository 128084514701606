import { GROUNDWATER_LEGEND_LABELS } from "../constants/constants";
/**
 * Generates an array of groups of layers based on the provided region.
 * @param {string} region - The region for which groups are generated - ecowas, sadc, or (east) africa
 * @private
 * @returns {Array} An array of layer groups.
 */

const groups = (region) => [
  // {
  //   groupName: "Indicators",
  //   id: 1,
  //   status: "non-interactive",
  //   items: [
  //     {
  //       name: "Social Indicators",
  //       regions: ["ecowas", "sadc", "east"],
  //       sourceId: `${region}_si`,
  //       sourceLayer: "indicators",
  //       layerId: "socInd",
  //       renderLayer: false,
  //       renderLegend: false,
  //       disableScreenshot: true,
  //       mapboxId: `${region}_si`,
  //       opacity: 100,
  //       type: ["fill"],
  //       fieldAttribute: "SocialBen",
  //       groupId: 1,
  //       groupName: "indicators",
  //       format: ["vector"],
  //       fieldValue: [-3, 3],
  //       legendIntervals: [-3, 3],
  //       legendIntervalFactor: 1,
  //       legendMaxValue: "equal",
  //       colorScheme: "RdYlBu",
  //       uploadGroup: "indicators",
  //       legendLabels: {
  //         low: "Very Low",
  //         medium: "Medium",
  //         high: "Very High",
  //       },
  //       mappingStrategy: "step",
  //       mapboxName: "social_indicators",
  //       legendInfo: {
  //         unit: "",
  //       },
  //       interactivity: { info: "info", count: 0, status: false },
  //     },
  //     {
  //       name: "Energy Sector Framework and Policies Indicator",
  //       regions: ["ecowas", "sadc", "east"],
  //       sourceId: `${region}_sei`,
  //       sourceLayer: "indicators",
  //       layerId: "enInd",
  //       renderLayer: false,
  //       renderLegend: false,
  //       disableScreenshot: true,
  //       fieldValue: [-3, 3],
  //       legendIntervals: [-3, 3],
  //       legendIntervalFactor: 1,
  //       legendMaxValue: "equal",
  //       mapboxId: `${region}_sei`,
  //       mapboxName: "sector_indicators",
  //       uploadGroup: "indicators",
  //       opacity: 100,
  //       type: ["fill"],
  //       fieldAttribute: "CF_I-II",
  //       drawAttribute: "ISO_A3",
  //       groupId: 1,
  //       groupName: "indicators",
  //       format: ["vector"],
  //       legendLabels: {
  //         low: "Less Favorable",
  //         medium: "Favorable",
  //         high: "Highly Favorable",
  //       },
  //       mappingStrategy: "step",
  //       colorScheme: "RdYlBu",
  //       legendInfo: {
  //         unit: "",
  //       },
  //       interactivity: { info: "info", count: 0, status: false },
  //     },
  //     {
  //       name: "Export and Transport Infrastructure Indicator",
  //       regions: ["ecowas", "sadc", "east"],
  //       sourceId: `${region}_sei`,
  //       sourceLayer: "indicators",

  //       layerId: "exInd",
  //       renderLayer: false,
  //       renderLegend: false,
  //       disableScreenshot: true,
  //       fieldValue: [-2, 2],
  //       legendIntervals: [-2, 2],
  //       legendIntervalFactor: 1,
  //       legendMaxValue: "equal",
  //       mapboxId: `${region}_sei`,

  //       opacity: 100,
  //       mapboxName: "sector_indicators",
  //       uploadGroup: "indicators",
  //       type: ["fill"],
  //       groupId: 1,
  //       groupName: "indicators",
  //       format: ["vector"],
  //       fieldAttribute: "CF_III",
  //       drawAttribute: "ISO_A3",

  //       colorScheme: "RdYlBu",
  //       legendLabels: {
  //         low: "Less Favorable",
  //         medium: "Favorable",
  //         high: "Highly Favorable",
  //       },
  //       mappingStrategy: "step",
  //       legendInfo: {
  //         unit: "",
  //       },
  //       interactivity: { info: "info", count: 0, status: false },
  //     },
  //     {
  //       name: "Political and Regulatory Framework Indicator",
  //       regions: ["ecowas", "sadc", "east"],
  //       sourceId: `${region}_sei`,
  //       sourceLayer: "indicators",

  //       layerId: "poInd",
  //       renderLayer: false,
  //       renderLegend: false,
  //       disableScreenshot: true,
  //       fieldValue: [-2, 2],
  //       legendIntervals: [-2, 2],
  //       legendIntervalFactor: 1,
  //       legendMaxValue: "equal",
  //       mapboxId: `${region}_sei`,

  //       opacity: 100,
  //       mapboxName: "sector_indicators",
  //       uploadGroup: "indicators",
  //       type: ["fill"],
  //       fieldAttribute: "CF_IV-V",
  //       drawAttribute: "ISO_A3",
  //       groupId: 1,
  //       groupName: "indicators",
  //       format: ["vector"],

  //       colorScheme: "RdYlBu",
  //       legendLabels: {
  //         low: "Less Favorable",
  //         medium: "Favorable",
  //         high: "Highly Favorable",
  //       },
  //       mappingStrategy: "step",
  //       legendInfo: {
  //         unit: "",
  //       },
  //       interactivity: { info: "info", count: 0, status: false },
  //     },
  //   ],
  // },
  {
    groupName: "Land Eligibility",
    id: 2,
    status: "interactive",
    items: [
      {
        name: "Excluded Areas (Onshore Wind Turbines)",
        regions: ["ecowas", "sadc", "east"],
        sourceId: `${region}_oea`,
        layerId: "excOn",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [0, 100],
        legendIntervals: [0, 100],
        legendIntervalFactor: 100,
        legendMaxValue: "equal",
        mapboxId: `${region}_oea`,

        opacity: 100,
        type: ["raster"],
        groupId: 2,
        groupName: "land_eligibility",
        format: ["raster"],
        mapboxName: "onshore_excluded_areas",
        uploadGroup: "raster",

        colorScheme: ["#6b0824", "#d3d3d3"],
        legendLabels: {
          low: "Excluded Areas - 0",
          high: "Eligible Areas - 100",
        },
        fieldAttribute: "",
        mappingStrategy: "step",
        rasterQuantityTranslationTag: "Eligibility",
        legendInfo: {
          unit: "%",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      // {
      //   name: "Excluded Areas (Offshore Wind Turbines)",
      //   regions: ["ecowas"],
      //   sourceId: `${region}_offshore_excluded_areas`,
      //
      //   layerId: "excOff",
      //   renderLayer: false,
      //   renderLegend: false,
      //   disableScreenshot: true,
      //   fieldValue: [0, 100],
      //   legendIntervals: [0, 100],
      //   legendIntervalFactor: 100,
      //   mapboxId: `${region}_offshore_excluded_areas`,
      //   opacity: 100,
      //   groupId: 2,
      //   groupName: "land_eligibility",
      //   format: ["raster"],
      //   fieldAttribute: "",
      //   mapboxName: "offshore_excluded_areas",
      // uploadGroup:"rasters",
      //
      //   type: ["raster"],
      //
      //
      //
      //   colorScheme: ["#6b0824", "#d3d3d3"],
      //   legendLabels: {
      //     low: "Excluded Areas",
      //     high: "Eligible Areas",
      //   },
      //   legendInfo: {
      //
      //     unit: "",
      //   },
      // },
      {
        name: "Excluded Areas (Solar PV)",
        regions: ["ecowas", "sadc", "east"],
        sourceId: `${region}_pvea`,

        layerId: "excPV",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [0, 100],
        legendIntervals: [0, 100],
        legendIntervalFactor: 100,
        legendMaxValue: "equal",
        mapboxId: `${region}_pvea`,

        opacity: 100,
        mapboxName: "pv_excluded_areas",
        uploadGroup: "raster",

        groupId: 2,
        groupName: "land_eligibility",
        fieldAttribute: "",
        type: ["raster"],
        format: ["raster"],

        rasterQuantityTranslationTag: "Eligibility",
        colorScheme: ["#6b0824", "#d3d3d3"],
        legendLabels: {
          low: "Excluded Areas - 0",
          high: "Eligible Areas - 100",
        },
        mappingStrategy: "step",
        legendInfo: {
          unit: "%",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
    ],
  },

  {
    groupName: "Renewable Energy Sources",
    id: 3,
    status: "interactive",
    items: [
      {
        name: "Avg. LCOE for Onshore Wind Turbines (2020)", // name of the layer
        regions: ["ecowas", "sadc", "east"], // regions the layer is available for
        translationTag: "turbinePoints", // translation tag for the layer
        year: 2020, // year of the layer
        sourceId: `${region}_low`, // source id of the layer
        layerId: "lcoeOnsh_2020", // layer id of the layer
        renderLayer: false, // render layer on map
        renderLegend: false, // render legend on map
        disableScreenshot: true, // disable screenshot
        fieldValue: [0.01, 0.06], // field value of the layer used for cost filtering.
        legendIntervals: [0.01, 0.06], // legend intervals used to make the color ramp for legend
        legendIntervalFactor: 0.01, // legend interval factor used to make the color ramp for legend
        legendMaxValue: "over", // legend max value used to make the color ramp for legend
        prodUnit: "TWh/yr", // production unit of the layer
        capUnit: "GW", // capacity unit of the layer
        mapboxId: `${region}_low`, // mapbox id of the layer. This must correspond to the tileset for this layer in mapbox studio.
        opacity: 100, // opacity of the layer
        mapboxName: "lcoe_onshore_wind", // mapbox name of the layer
        uploadGroup: "renewable", // upload group of the layer
        costYear: "2020", // cost year of the layer
        iconId: "windTurbine", // icon id of the layer
        sourceLayer: "renewable_energy", // source layer of the layer
        groupId: 3, // group id of the layer
        groupName: "renewable_energy", // group name of the layer
        type: ["icon", "lcoe", "draw"], // type of the layer
        fieldAttribute: "LCOE2020", // field attribute of the layer used in the backend API. This is the field that is used to query the data from the database.
        label: "Onshore Wind LCOE", // label of the layer
        layerType: "point", // layer type of the layer
        format: ["vector"], // format of the layer
        colorScheme: "RdYlBu", // color scheme of the layer
        costValue: "", // cost value of the layer
        legendInfo: {
          // legend info of the layer
          unit: "€ct/kWh",
        },
        interactivity: { info: "info", count: 0, status: false }, // interactivity of the layer
      },
      {
        name: "Avg. LCOE for Onshore Wind Turbines (2050)",
        regions: ["ecowas", "sadc", "east"],
        translationTag: "turbinePoints",
        year: 2050,
        sourceId: `${region}_low`,
        layerId: "lcoeOnsh_2050",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [0.01, 0.06],
        legendIntervals: [0.01, 0.06],
        legendIntervalFactor: 0.01,
        legendMaxValue: "over",
        prodUnit: "TWh/yr",
        capUnit: "GW",
        mapboxId: `${region}_low`,
        mapboxName: "lcoe_onshore_wind",
        uploadGroup: "renewable",
        opacity: 100,
        costYear: "2050",
        iconId: "windTurbine",
        sourceLayer: "renewable_energy",
        groupId: 3,
        groupName: "renewable_energy",
        type: ["icon", "lcoe", "draw"],
        fieldAttribute: "LCOE2050",
        label: "Onshore Wind LCOE",
        format: ["vector"],
        costValue: "",
        colorScheme: "RdYlBu",
        layerType: "point",
        legendInfo: {
          unit: "€ct/kWh",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      // {
      //   name: "Avg. LCOE for Offshore Wind Turbines (2020)",
      //   regions: ["ecowas", "sadc"],
      //   translationTag: "turbinePoints",
      //   year: 2020,
      //   sourceId: `${region}-offshore-wind`,
      //   mapboxId: `${region}-offshore-wind`,
      //   prodUnit: "TWh/yr",
      //   capUnit: "GW",
      //   sourceLayer: `${region}-offshore-wind`,
      //   layerId: "lcoeOffsh",
      //   renderLayer: false,
      //   renderLegend: false,
      //   disableScreenshot: true,
      //   fieldValue: [0.01, 0.06],
      //   legendIntervals: [0.01, 0.06],
      //   legendIntervalFactor: 0.01,
      //   opacity: 100,
      //   costYear: "2020",
      //   mapboxName: "lcoe_offshore_wind",
      //   uploadGroup:"renewable",
      //   iconId: "windTurbine",
      //   groupId: 3,
      //   groupName: "renewable_energy",
      //   type: ["icon", "lcoe"],
      //   fieldAttribute: "LCOE",
      //   label: "Offshore Wind LCOE",
      //   format: ["vector"],
      //   layerType: "point",
      //   colorScheme: "RdYlBu",
      //   legendInfo: {
      //
      //       "Avg. Levelised Cost of Electricity(LCOE) for Offshore Wind Turbines",
      //     unit: "€ct/kWh",
      //
      //
      //
      //   },
      // },
      {
        name: "Avg. LCOE for PV (2020)",
        regions: ["ecowas", "sadc", "east"],
        translationTag: "pvPanel",
        year: 2020,
        sourceId: `${region}_lpv`,
        mapboxId: `${region}_lpv`,
        prodUnit: "TWh/yr",
        capUnit: "GW",
        layerId: "lcoepv_2020",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [0.01, 0.06],
        legendIntervals: [0.01, 0.06],
        legendIntervalFactor: 0.01,
        legendMaxValue: "over",
        opacity: 100,
        mapboxName: "lcoe_pv",
        uploadGroup: "renewable",
        costYear: "2020",
        iconId: "pvPanel",
        sourceLayer: "renewable_energy",
        groupId: 3,
        groupName: "renewable_energy",
        type: ["icon", "lcoe", "draw"],
        fieldAttribute: "LCOE2020",
        format: ["vector"],
        label: "PV LCOE",
        layerType: "point",
        costValue: "",
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "€ct/kWh",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Avg. LCOE for PV (2050)",
        regions: ["ecowas", "sadc", "east"],
        translationTag: "pvPanel",
        year: 2050,
        sourceId: `${region}_lpv`,
        mapboxId: `${region}_lpv`,
        prodUnit: "TWh/yr",
        capUnit: "GW",
        layerId: "lcoepv_2050",
        mapboxName: "lcoe_pv",
        uploadGroup: "renewable",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [0.01, 0.06],
        legendIntervals: [0.01, 0.06],
        legendIntervalFactor: 0.01,
        legendMaxValue: "over",
        opacity: 100,
        costYear: "2050",
        iconId: "pvPanel",
        sourceLayer: "renewable_energy",
        groupId: 3,
        groupName: "renewable_energy",
        type: ["icon", "lcoe", "draw"],
        fieldAttribute: "LCOE2050",
        label: "PV LCOE",
        layerType: "point",
        format: ["vector"],
        costValue: "",
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "€ct/kWh",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Avg. LCOE for Hydropower (2020)",
        regions: ["ecowas", "sadc", "east"],
        translationTag: "hydropowerPoints",
        year: 2020,
        sourceId: `${region}_lhydro_2020`,
        mapboxId: `${region}_lhydro_2020`,
        prodUnit: "TWh/yr",
        capUnit: "GW",
        layerId: "hydro_2020",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [0.01, 0.06],
        legendIntervals: [0.01, 0.06],
        legendIntervalFactor: 0.01,
        legendMaxValue: "over",
        opacity: 100,
        mapboxName: "lcoe_hydro",
        uploadGroup: "hydropower",
        costYear: "2020",
        iconId: "hydro",
        sourceLayer: "renewable_energy",
        groupId: 3,
        groupName: "renewable_energy",
        type: ["icon", "lcoe", "draw"],
        fieldAttribute: "LCOE2020",
        fieldAttribute_committed: "LCOE2020",
        drawAttribute: "name",
        format: ["vector"],
        label: "Hydro LCOE",
        layerType: "point",
        hydropowerType: "all",
        costValue: "",
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "€ct/kWh",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Avg. LCOE for Hydropower (2050)",
        regions: ["ecowas", "sadc", "east"],
        translationTag: "hydropowerPoints",
        year: 2050,
        sourceId: `${region}_lhydro_2050`,
        mapboxId: `${region}_lhydro_2050`,

        prodUnit: "TWh/yr",
        capUnit: "GW",

        layerId: "hydro_2050",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [0.01, 0.06],
        legendIntervals: [0.01, 0.06],
        legendIntervalFactor: 0.01,
        legendMaxValue: "over",
        opacity: 100,
        mapboxName: "lcoe_hydro",
        uploadGroup: "hydropower",
        costYear: "2050",
        iconId: "hydro",
        sourceLayer: "renewable_energy",
        groupId: 3,
        groupName: "renewable_energy",
        type: ["icon", "lcoe", "draw"],
        fieldAttribute: "LCOE2050",
        fieldAttribute_committed: "LCOE2050",
        drawAttribute: "name",
        format: ["vector"],
        label: "Hydro LCOE",
        layerType: "point",
        hydropowerType: "all",
        costValue: "",
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "€ct/kWh",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
    ],
  },
  {
    groupName: "Hydrogen",
    id: 4,
    status: "interactive",
    items: [
      {
        name: "Max. Technical H2 Potential per Area", // name of the layer
        regions: ["ecowas", "sadc", "east"], // regions the layer is available for
        h2QuantityTranslationTag: "h2PotentialArea", // translation tag for the layer
        sourceId: `${region}_h2`, // source id of the layer
        layerId: "h2potarea", // layer id of the layer
        renderLayer: false, // render layer on map
        renderLegend: false, // render legend on map
        disableScreenshot: true, // disable screenshot
        fieldValue: [0, 50], // field value of the layer used for cost filtering.
        legendIntervals: [0, 50], // legend intervals used to make the color ramp for legend
        legendIntervalFactor: 10, // legend interval factor used to make the color ramp for legend
        legendMaxValue: "over", // legend max value used to make the color ramp for legend
        mapboxId: `${region}_h2`, // mapbox id of the layer. This must correspond to the tileset for this layer in mapbox studio.
        opacity: 100, // opacity of the layer
        sourceLayer: "hydrogen", // source layer of the layer
        type: ["fill", "draw"], // type of the layer
        groupId: 4, // group id of the layer
        groupName: "Hydrogen", // group name of the layer
        fieldAttribute: "H2TPAkWhm2", // field attribute of the layer used in the backend API. This is the field that is used to query the data from the database.
        nationalFieldAttribute: "NATPTWha", // national field attribute of the layer used in the backend API. This is the field that is used to query the data from the database.
        drawAttribute: "NAME_2", // draw attribute of the layer used in the draw feature.
        mapboxName: "hydrogen", // mapbox name of the layer
        uploadGroup: "hydrogen", // upload group of the layer
        format: ["vector"], // format of the layer
        colorScheme: "RdYlBu", // color scheme of the layer
        legendInfo: {
          unit: "kWh/(a*m2)", // default legend unit of the layer
        },
        interactivity: { info: "info", count: 0, status: false }, // interactivity of the layer
      },
      {
        name: "Max. Absolute Technical H2 Potential",
        regions: ["ecowas", "sadc", "east"],
        h2QuantityTranslationTag: "h2AbsolutePotential",
        sourceId: `${region}_h2`,
        layerId: "h2potabs",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [0, 50000],
        legendIntervals: [0, 500000],
        legendIntervalFactor: 100000,
        legendMaxValue: "over",
        mapboxId: `${region}_h2`,
        opacity: 100,
        sourceLayer: "hydrogen",
        type: ["fill", "draw"],
        groupId: 4,
        groupName: "Hydrogen",
        fieldAttribute: "01TPGWha",
        fieldAttribute_25: "25TPGWha",
        fieldAttribute_50: "50TPGWha",
        fieldAttribute_100: "00TPGWha",
        nationalFieldAttribute: "NATPTWha",
        nationalFieldAttribute_25: "NATPTWha",
        nationalFieldAttribute_50: "NATPTWha",
        nationalFieldAttribute_100: "NATPTWha",
        drawAttribute: "NAME_2",
        costPercentage: "25%",
        mapboxName: "hydrogen",
        uploadGroup: "hydrogen",
        format: ["vector"],
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "TWh/yr",
        },
        interactivity: { info: "info", count: 0, status: false },
      },

      {
        name: "Levelized Cost of H2 (2020)",
        regions: ["ecowas", "sadc", "east"],
        h2QuantityTranslationTag: "LCOHCost",
        prodUnit: "TWh/yr",
        costYear: "2020",
        mapboxId: `${region}_h2`,
        sourceId: `${region}_h2`,
        sourceLayer: "hydrogen",
        layerId: "h2lcoh_2020",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        fieldValue: [1.5, 4.5],
        legendIntervals: [1.5, 4.5],
        legendIntervalFactor: 1,
        legendMaxValue: "over",
        opacity: 100,
        mapboxName: "hydrogen",
        uploadGroup: "hydrogen",
        type: ["fill", "lcoh", "draw"],
        groupId: 4,
        groupName: "Hydrogen",
        format: ["vector"],
        fieldAttribute: "01LhEkg20",
        fieldAttribute_25: "25LhEkg20",
        fieldAttribute_50: "50LhEkg20",
        fieldAttribute_100: "00LhEkg20",
        nationalFieldAttribute: "NA01LTEkg2",
        nationalFieldAttribute_25: "NA25LTEkg2",
        nationalFieldAttribute_50: "NA50LTEkg2",
        nationalFieldAttribute_100: "NA00LTEkg2",
        drawAttribute: "NAME_2",
        costPercentage: "25%",
        label: "Levelized Cost of H2",
        costValue: "",
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "€/kg",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Levelized Cost of H2 (2050)",
        regions: ["ecowas", "sadc", "east"],
        h2QuantityTranslationTag: "LCOHCost",
        prodUnit: "TWh/yr",
        mapboxId: `${region}_h2`,
        sourceId: `${region}_h2`,
        sourceLayer: "hydrogen",
        costYear: "2050",
        layerId: "h2lcoh_2050",
        renderLayer: false,
        renderLegend: false,
        mapboxName: "hydrogen",
        uploadGroup: "hydrogen",
        disableScreenshot: true,
        fieldValue: [1.5, 4.5],
        legendIntervals: [1.5, 4.5],
        legendIntervalFactor: 1,
        legendMaxValue: "over",
        opacity: 100,
        type: ["fill", "lcoh", "draw"],
        groupId: 4,
        groupName: "Hydrogen",
        format: ["vector"],
        fieldAttribute: "01LhEkg50",
        drawAttribute: "NAME_2",
        fieldAttribute_25: "25LhEkg50",
        fieldAttribute_50: "50LhEkg50",
        fieldAttribute_100: "00LhEkg50",
        nationalFieldAttribute: "NA01LTEkg5",
        nationalFieldAttribute_25: "NA25LTEkg5",
        nationalFieldAttribute_50: "NA50LTEkg5",
        nationalFieldAttribute_100: "NA00LTEkg5",
        costPercentage: "25%",
        label: "Levelized Cost of H2",
        costValue: "",
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "€/kg",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Max. H2 Producible from Groundwater (% H2 Potential)",
        regions: ["ecowas", "sadc", "east"],
        h2QuantityTranslationTag: "groundWaterPer",
        sourceId: `${region}_h2`,
        layerId: "h2potgrwaperc",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        mapboxName: "hydrogen",
        uploadGroup: "hydrogen",
        fieldValue: [0, 100],
        legendIntervals: [0, 100],
        legendIntervalFactor: 20,
        legendMaxValue: "equal",
        mapboxId: `${region}_h2`,
        opacity: 100,
        sourceLayer: "hydrogen",
        type: ["fill", "draw"],
        groupId: 4,
        groupName: "Hydrogen",
        format: ["vector"],
        fieldAttribute: "H2GTPperc",
        nationalFieldAttribute: "NAGTPperc",
        drawAttribute: "NAME_2",
        colorScheme: "Blues",
        legendInfo: {
          unit: "%",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Max. H2 Producible from Groundwater per Area",
        regions: ["ecowas", "sadc", "east"],
        h2QuantityTranslationTag: "groundWaterArea",
        sourceId: `${region}_h2`,
        layerId: "h2potgrwaarea",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        mapboxName: "hydrogen",
        uploadGroup: "hydrogen",
        fieldValue: [0, 50],
        legendIntervals: [0, 50],
        legendIntervalFactor: 10,
        legendMaxValue: "over",
        mapboxId: `${region}_h2`,
        opacity: 100,
        sourceLayer: "hydrogen",
        type: ["fill"],
        groupId: 4,
        groupName: "Hydrogen",
        format: ["vector", "draw"],
        fieldAttribute: "H2SPAkWhm2",
        nationalFieldAttribute: "NASPTWha",
        drawAttribute: "NAME_2",
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "kWh/(a*m2)",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
    ],
  },
  {
    groupName: "Groundwater",
    id: 5,
    status: "interactive",
    items: [
      {
        name: "Groundwater sustainable yield 2020 (Conservative)", // name of the layer
        regions: ["ecowas", "sadc", "east"], // regions the layer is available for
        sourceId: `${region}_gw`, // source id of the layer
        layerId: "gwConservative", // layer id of the layer
        renderLayer: false, // render layer on map
        renderLegend: false, // render legend on map
        disableScreenshot: true, // disable screenshot
        mapboxId: `${region}_gw_cons_2020`, // mapbox id of the layer. This must correspond to the tileset for this layer in mapbox studio.
        mapboxName: "ground_water_cons_2020", // mapbox name of the layer
        uploadGroup: "raster", // upload group of the layer
        opacity: 100, // opacity of the layer
        type: ["raster"], // type of the layer
        groupId: 5, // group id of the layer
        groupName: "groundwater", // group name of the layer
        format: ["raster"], // format of the layer
        fieldAttribute: "", // field attribute of the layer used in the backend API. This is the field that is used to query the data from the database.
        fieldValue:
          region === "ecowas" ? [0, 34, 68, 102, 136] : [0, 24, 48, 72, 96], // field value of the layer. Not really needed since it cost filtering does not happen for raster layers.
        legendIntervals: [0, 2.5, 10, 25, 50, 100, 250], // legend intervals used to make the color ramp for legend
        legendLabels: GROUNDWATER_LEGEND_LABELS,
        mappingStrategy: "complete", // mapping strategy of the layer
        legendMaxValue: "equal", // legend max value used to make the color ramp for legend
        colorScheme: "RdYlBu",
        rasterQuantityTranslationTag: "Groundwater sustainable yield", // translation tag for the layer
        legendInfo: {
          unit: "mm/yr", // default legend unit of the layer
        },
        interactivity: { info: "info", count: 0, status: false }, // interactivity of the layer
      },
      {
        name: "Groundwater sustainable yield 2050 (Conservative)",
        regions: ["ecowas", "sadc", "east"],
        sourceId: `${region}_gw_2050_opt`,
        layerId: "gwConservative2050",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        mapboxId: `${region}_gw_cons_2050_opt`,
        mapboxName: "ground_water",
        uploadGroup: "raster",
        groundwaterStatus: "opt",
        opacity: 100,
        type: ["raster"],
        groupId: 5,
        groupName: "groundwater",
        format: ["raster"],
        fieldAttribute: "",
        fieldValue:
          region === "ecowas" ? [0, 34, 68, 102, 136] : [0, 24, 48, 72, 96],
        legendIntervals: [0, 2.5, 10, 25, 50, 100, 250],
        legendLabels: GROUNDWATER_LEGEND_LABELS,
        mappingStrategy: "complete",
        legendMaxValue: "equal",
        colorScheme: "RdYlBu",
        rasterQuantityTranslationTag: "Groundwater sustainable yield",
        legendInfo: {
          unit: "mm/yr",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Groundwater sustainable yield 2020 (Medium)",
        regions: ["ecowas", "sadc", "east"],
        sourceId: `${region}_gw`,
        layerId: "gwMedium",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        mapboxId: `${region}_gw_med_2020`,
        mapboxName: "ground_water_med_2020",
        uploadGroup: "raster",
        opacity: 100,
        type: ["raster"],
        groupId: 5,
        groupName: "groundwater",
        format: ["raster"],
        fieldAttribute: "",
        fieldValue:
          region === "ecowas"
            ? [0, 139, 277, 416, 555]
            : [0, 97, 194, 292, 389],
        legendIntervals: [0, 2.5, 10, 25, 50, 100, 250],
        legendLabels: GROUNDWATER_LEGEND_LABELS,
        legendMaxValue: "equal",
        colorScheme: "RdYlBu",
        mappingStrategy: "complete",
        rasterQuantityTranslationTag: "Groundwater sustainable yield",
        legendInfo: {
          unit: "mm/yr",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Groundwater sustainable yield 2050 (Medium)",
        regions: ["ecowas", "sadc", "east"],
        sourceId: `${region}_gw_2050_opt`,
        layerId: "gwMedium2050",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        mapboxId: `${region}_gw_med_2050_opt`,
        groundwaterStatus: "opt",
        mapboxName: "ground_water",
        uploadGroup: "raster",
        opacity: 100,
        type: ["raster"],
        groupId: 5,
        groupName: "groundwater",
        format: ["raster"],
        fieldAttribute: "",
        fieldValue:
          region === "ecowas"
            ? [0, 139, 277, 416, 555]
            : [0, 97, 194, 292, 389],
        legendIntervals: [0, 2.5, 10, 25, 50, 100, 250],
        legendLabels: GROUNDWATER_LEGEND_LABELS,
        legendMaxValue: "equal",
        colorScheme: "RdYlBu",
        mappingStrategy: "complete",
        rasterQuantityTranslationTag: "Groundwater sustainable yield",
        legendInfo: {
          unit: "mm/yr",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Groundwater sustainable yield 2020 (Extreme)",
        regions: ["ecowas", "sadc", "east"],
        sourceId: `${region}_gw`,
        layerId: "gwExtreme",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        mapboxId: `${region}_gw_extr_2020`,
        mapboxName: "ground_water_extr_2020",
        uploadGroup: "raster",
        opacity: 100,
        type: ["raster"],
        groupId: 5,
        groupName: "groundwater",
        format: ["raster"],
        fieldAttribute: "",
        fieldValue:
          region === "ecowas"
            ? [0, 243, 487, 730, 974]
            : [0, 173, 345, 518, 691],
        legendIntervals: [0, 2.5, 10, 25, 50, 100, 250],
        legendLabels: GROUNDWATER_LEGEND_LABELS,
        legendMaxValue: "equal",
        colorScheme: "RdYlBu",
        mappingStrategy: "complete",
        rasterQuantityTranslationTag: "Groundwater sustainable yield",
        legendInfo: {
          unit: "mm/yr",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
      {
        name: "Groundwater sustainable yield 2050 (Extreme)",
        regions: ["ecowas", "sadc", "east"],
        sourceId: `${region}_gw_2050_opt`,
        layerId: "gwExtreme2050",
        renderLayer: false,
        renderLegend: false,
        disableScreenshot: true,
        mapboxId: `${region}_gw_extr_2050_opt`,
        groundwaterStatus: "opt",
        mapboxName: "ground_water",
        uploadGroup: "raster",
        opacity: 100,
        type: ["raster"],
        groupId: 5,
        groupName: "groundwater",
        format: ["raster"],
        fieldAttribute: "",
        fieldValue:
          region === "ecowas"
            ? [0, 243, 487, 730, 974]
            : [0, 173, 345, 518, 691],
        legendIntervals: [0, 2.5, 10, 25, 50, 100, 250],
        legendLabels: GROUNDWATER_LEGEND_LABELS,
        legendMaxValue: "equal",
        colorScheme: "RdYlBu",
        mappingStrategy: "complete",
        rasterQuantityTranslationTag: "Groundwater sustainable yield",
        legendInfo: {
          unit: "mm/yr",
        },
        interactivity: { info: "info", count: 0, status: false },
      },
    ],
  },
  {
    groupName: "National Fact Sheet",
    id: 6,
    status: "interactive",
    items: [
      {
        name: "National Fact Sheet",
        regions: ["ecowas", "sadc", "east"],
        sourceId: `${region}_fact`,

        layerId: "fact",
        renderLayer: true,
        renderLegend: true,
        disableScreenshot: false,
        mapboxName: "fact",
        uploadGroup: "fact",
        fieldValue: [0, 255],
        legendIntervals: [0, 255],
        legendIntervalFactor: 1,
        mapboxId: `${region}_fact`,
        opacity: 100,
        sourceLayer: "fact",
        type: ["fill"],
        groupId: 6,
        groupName: "fact",
        format: ["vector"],
        fieldAttribute: "ADM0_CODE",
        colorScheme: "RdYlBu",
        legendInfo: {
          unit: "",
        },
        interactivity: { info: "info", count: 1, status: true },
      },
    ],
  },
];

/**
 * Create an array of filtered layer groups for a specific region.
 *
 * @param {string} region - The region for which to filter layer groups.
 * @category Config
 * @returns {Array} An array of filtered layer groups.
 */
const LayerGroups = (region) => {
  return groups(region).map((group) => {
    const { items, ...rest } = group;
    const filteredItems = items.filter((item) => item.regions.includes(region));
    return { ...rest, items: filteredItems };
  });
};

export default LayerGroups;

//TODO: Translate everything "hydropower"
