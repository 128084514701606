import { RENDER_STATE } from "react-map-gl-draw";

/**
 * Returns the style object for a given edit handle state.
 * @private
 * @param {Object} editHandle - The edit handle object.
 * @param {string} editHandle.state - The state of the edit handle.
 * @returns {Object} - The style object for the edit handle.
 */
function getEditHandleStyle({ state }) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        fill: "rgb(251, 176, 59)",
        fillOpacity: 1,
        stroke: "rgb(255, 255, 255)",
        strokeWidth: 2,
        r: 7,
      };

    default:
      return {
        fill: "rgb(251, 176, 59)",
        fillOpacity: 1,
        stroke: "rgb(255, 255, 255)",
        strokeWidth: 2,
        r: 5,
      };
  }
}

/**
 * Returns the style object for a given feature state.
 * @private
 * @param {Object} feature - The feature object.
 * @param {string} feature.state - The state of the feature.
 * @returns {Object} - The style object for the feature.
 */
function getFeatureStyle({ state }) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: "rgb(251, 176, 59)",
        strokeWidth: 2,
        fill: "rgb(251, 176, 59)",
        fillOpacity: 0,
        strokeDasharray: "4,2",
      };

    default:
      return {
        stroke: "rgb(60, 178, 208)",
        strokeWidth: 2,
        fill: "rgb(60, 178, 208)",
        fillOpacity: 0.1,
      };
  }
}

export { getEditHandleStyle, getFeatureStyle };
