import { useCallback, useState, useContext } from "react";
import { FlyToInterpolator } from "react-map-gl";
import { AppContext } from "../../contexts/AppContext";
import {
  getECOWASViewport,
  getEastViewport,
  getSADCViewport,
} from "../../config/initialViewports";

/**
 * A custom React hook that returns the viewport state and handlers for a map component.
 * @category Hooks
 * @component
 * @param {number} windowWidth - The width of the window.
 * @param {number} windowHeight - The height of the window.
 * @returns {[Object, Object]} - An array containing the viewport state and handlers.
 */
const useViewport = (windowWidth, windowHeight) => {
  /**
   * Get the path location from the AppContext.
   */
  const { pathLocation } = useContext(AppContext);

  /**
   * Get the initial viewport based on the path location.
   */
  const VIEWPORT = pathLocation.includes("sadc")
    ? getSADCViewport(windowWidth, windowHeight)
    : pathLocation.includes("east")
    ? getEastViewport(windowWidth, windowHeight)
    : pathLocation.includes("ecowas")
    ? getECOWASViewport(windowWidth, windowHeight)
    : {};

  /**
   * Set the viewport state using the initial viewport.
   */
  const [viewport, setViewport] = useState(VIEWPORT);

  /**
   * A callback function that updates the viewport state when the viewport changes.
   * @param {Object} viewport - The new viewport.
   */
  const onViewportChange = useCallback((viewport) => {
    windowWidth <= 600
      ? setViewport({
          ...viewport,
          minZoom: VIEWPORT.minZoom - 1,
          maxZoom: VIEWPORT.maxZoom,
        })
      : setViewport({
          ...viewport,
          minZoom: VIEWPORT.minZoom - 1,
          maxZoom: VIEWPORT.maxZoom,
        });
  }, []);

  /**
   * A function that resets the viewport to the initial viewport.
   * @param {Object} event - The event that triggered the reset.
   */
  const resetViewport = (event) => {
    event.stopPropagation();
    const flyToViewport = {
      ...viewport,
      longitude: VIEWPORT.longitude,
      latitude: VIEWPORT.latitude,
      zoom: VIEWPORT.minZoom,
      transitionDuration: 500,
      transitionInterpolator: new FlyToInterpolator(),
    };
    setViewport(flyToViewport);
  };

  /**
   * The viewport state object.
   */
  const states = {
    viewport,
  };

  /**
   * The viewport handlers object.
   */
  const handlers = {
    onViewportChange,
    resetViewport,
    setViewport,
  };

  return [states, handlers];
};

export default useViewport;
