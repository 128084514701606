import { roundToDecimal } from "./roundToDecimal";

export const averageQuantity = (featuresArray) => {
  const res = featuresArray?.reduce((a, b) => a + b, 0) / featuresArray?.length;
  return typeof res === "number" ? roundToDecimal(res, 2) : "N/A";
};

export const maxQuantity = (featuresArray) => {
  const res = featuresArray && Math.max(...featuresArray);
  return typeof res === "number" ? roundToDecimal(res, 2) : "N/A";
};

export const minQuantity = (featuresArray) => {
  const res = featuresArray ? Math.min(...featuresArray) : null;
  return typeof res === "number" ? roundToDecimal(res, 2) : "N/A";
};

export const sumQuantity = (featuresArray) => {
  const res = featuresArray?.reduce((a, b) => a + b, 0);
  return typeof res === "number" ? roundToDecimal(res, 2) : "N/A";
};
