import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.atlas.extraLight,
  color: theme.palette.primary.main,
  width: "10rem",
}));

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.atlas.light,
  color: theme.palette.primary.main,
}));

const StyledTableCellTitle = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.atlas.main,
  color: theme.palette.primary.main,
  textTransform: "capitalize",
  fontSize: "1.2rem",
}));

/**
 * Renders a table of other facts based on the given `factCategory`.
 * @component
 * @category Native
 * @subcategory NationalFactsSheet
 * @param {Object} props - The component props.
 * @param {Function} props.getFacts - A function that returns an object of facts.
 * @param {number} props.factCategory - The category of facts to display.
 * @param {string} props.idForExport - The ID to use for exporting the table.
 * @param {string} props.display - The display style for the table.
 * @returns {JSX.Element} - The rendered component.
 */
const OtherFacts = ({ getFacts, factCategory, idForExport, display }) => {
  const otherFacts = getFacts(factCategory);
  const { t } = useTranslation();

  if (otherFacts) {
    return (
      <Paper sx={{ width: "100%", backgroundColor: "unset", display: display }}>
        <TableContainer>
          <Table
            id={`facts-sheet-table-${idForExport}`}
            stickyHeader
            size="small"
          >
            <TableHead>
              <TableRow>
                <StyledTableCellTitle colSpan={5}>
                  {t(
                    `nationalFactsheet.titles.${otherFacts[factCategory].heading}`
                  )}
                </StyledTableCellTitle>
              </TableRow>
            </TableHead>
            {factCategory === "Green hydrogen potential" && (
              <TableHead>
                <TableRow>
                  <StyledTableCellHead
                    align="center"
                    colSpan={1}
                  ></StyledTableCellHead>
                  <StyledTableCellHead align="center" colSpan={1}>
                    100%* {t("layerLegend.layers.group.Hydrogen")}
                  </StyledTableCellHead>
                  <StyledTableCellHead align="center" colSpan={1}>
                    50%* {t("layerLegend.layers.group.Hydrogen")}
                  </StyledTableCellHead>
                  <StyledTableCellHead align="center" colSpan={1}>
                    25%* {t("layerLegend.layers.group.Hydrogen")}
                  </StyledTableCellHead>
                  <StyledTableCellHead align="center" colSpan={1}>
                    1%* {t("layerLegend.layers.group.Hydrogen")}
                  </StyledTableCellHead>
                </TableRow>
              </TableHead>
            )}

            {factCategory === "Groundwater assessment" && (
              <TableHead>
                <TableRow>
                  <StyledTableCellHead
                    align="center"
                    colSpan={1}
                  ></StyledTableCellHead>
                  <StyledTableCellHead align="center" colSpan={1}>
                    2020
                  </StyledTableCellHead>
                  <StyledTableCellHead align="center" colSpan={1}>
                    2050 ({t(`nationalFactsheet.titles.Optimistic`)})
                  </StyledTableCellHead>
                  <StyledTableCellHead align="center" colSpan={1}>
                    2050 ({t(`nationalFactsheet.titles.Pessimistic`)})
                  </StyledTableCellHead>
                </TableRow>
              </TableHead>
            )}

            {factCategory === "Renewable energy potential"
              ? Object.values(otherFacts[factCategory].main).map(
                  (fact, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <StyledTableCellHead align="center" colSpan={1}>
                          {" "}
                          {t(`nationalFactsheet.titles.${fact.layer}`)}
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>
                          2020
                        </StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>
                          2050
                        </StyledTableCellHead>
                      </TableRow>
                      {fact.info.map((item, index) => (
                        <TableRow key={index}>
                          <StyledTableCell colSpan={1}>
                            {t(`nationalFactsheet.titles.${item.title}`)}{" "}
                            {item.unit && `[${item.unit}]`}
                            {item.annotation}
                          </StyledTableCell>
                          <TableCell align="center" colSpan={1}>
                            {item.value_2020}
                          </TableCell>

                          <TableCell align="center" colSpan={1}>
                            {item.value_2050}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )
                )
              : factCategory === "Groundwater assessment"
              ? Object.values(otherFacts[factCategory].main).map(
                  (fact, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <StyledTableCell colSpan={1}>
                          {t(`nationalFactsheet.titles.${fact.title}`)}{" "}
                          {fact.unit && `[${fact.unit}]`}
                          {fact.annotation}
                        </StyledTableCell>
                        <TableCell align="center" colSpan={1}>
                          {fact.value_2020}
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          {fact.value_2050_optimistic}
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          {fact.value_2050_pessimistic}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                )
              : factCategory === "Green hydrogen potential"
              ? Object.values(otherFacts[factCategory].main).map(
                  (fact, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <StyledTableCell>
                          {t(`nationalFactsheet.titles.${fact.title}`)}{" "}
                          {fact.year && `(${fact.year})`}{" "}
                          {fact.unit && `[${fact.unit}]`}
                          {fact.annotation}
                        </StyledTableCell>
                        {fact.value.map((value, index) => (
                          <TableCell key={index}>
                            {!value.includes("N/A") ? value : ""}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  )
                )
              : Object.values(otherFacts[factCategory].main).map(
                  (fact, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <StyledTableCell>
                          {t(`nationalFactsheet.titles.${fact.title}`)}
                          <sup>{fact.annotation}</sup>{" "}
                          {fact.year && `(${fact.year})`}{" "}
                          {fact.unit && `[${fact.unit}]`}
                        </StyledTableCell>

                        <TableCell>{fact.value}</TableCell>
                      </TableRow>
                    </TableBody>
                  )
                )}
          </Table>
          {/* <CostCurve
            idForExport={idForExport}
            data={otherFacts[factCategory].potentialCurve}
          /> */}
          {otherFacts[factCategory].footnotes && (
            <Table
              sx={{ width: "fit-content", mt: 2 }}
              id={`facts-sheet-footnote-${idForExport}`}
              size="small"
            >
              <TableFooter>
                <TableRow>
                  <TableCell
                    sx={{ fontStyle: "italic", fontWeight: 900 }}
                    colSpan={3}
                  >
                    {t("nationalFactsheet.titles.Notes")}
                  </TableCell>
                </TableRow>
                {Object.values(otherFacts[factCategory].footnotes).map(
                  (note, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ fontStyle: "italic", color: "#000000" }}>
                        {" "}
                        <li style={{ listStyle: "none" }}>
                          {" "}
                          {note.noteAnnotation && note.noteAnnotation}{" "}
                          {t(`nationalFactsheet.footnotes.${note.label}`)}
                        </li>{" "}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableFooter>
            </Table>
          )}
          {otherFacts[factCategory].references && (
            <Table
              sx={{ width: "fit-content", mt: 2 }}
              id={`facts-sheet-reference-${idForExport}`}
              size="small"
            >
              <TableFooter>
                <TableRow>
                  <TableCell
                    sx={{ fontStyle: "italic", fontWeight: 900 }}
                    colSpan={3}
                  >
                    {t("nationalFactsheet.titles.References")}
                  </TableCell>
                </TableRow>
                {Object.values(otherFacts[factCategory].references).map(
                  (reference, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ fontStyle: "italic", color: "#000000" }}>
                        {" "}
                        <li style={{ listStyle: "none" }}>
                          {" "}
                          [{reference.referenceAnnotation}] {reference.label}
                        </li>{" "}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </Paper>
    );
  } else {
    return <></>;
  }
};

OtherFacts.propTypes = {
  /**
   * A function that returns an object of facts.
   */
  getFacts: PropTypes.func,
  /**
   * The category of facts to display.
   */
  factCategory: PropTypes.string,
  /**
   * The ID to use for exporting the table.
   */
  idForExport: PropTypes.number,
  /**
   * The display style for the table.
   */
  display: PropTypes.string,
};

export default OtherFacts;
