/**
 * Returns a stringified array of the property of each layer in the layer config.
 * @name useDependency
 * @category Hooks
 * @component
 * @param {Object[]} LAYER_CONFIG - Array of layer config objects.
 * @param {string} property - Property name to return.
 * @returns {string} A stringified array of the property of each layer in the layer config.
 */

const useDependency = (LAYER_CONFIG, property) => {
  /**
   * Maps through the layer config array and returns the value of the given property for each layer.
   * @param {Object} layer - A layer configuration object.
   * @returns {*} The value of the given property for the layer.
   */
  const mapLayerConfig = (layer) => layer[property];

  const dependency = JSON.stringify(LAYER_CONFIG.map(mapLayerConfig));

  return dependency;
};

export default useDependency;
