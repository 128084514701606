import React from "react";
import { NavigationControl, FullscreenControl } from "react-map-gl";
import "./MapToolsBar.css";
import IconButton from "@mui/material/IconButton";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import style from "./MUIStyle";

const useStyles = makeStyles(style);

import PropTypes from "prop-types";

/**
 * A toolbar component for the map with reset, fullscreen and navigation controls.
 * @component
 * @category Native
 * @subcategory MapToolsBar
 */
const MapToolsbar = ({ resetViewport, onViewportChange }) => {
  const theme = useTheme();
  const classes = useStyles();

  /**
   * Resets the viewport of the map.
   */
  const handleResetViewport = () => {
    resetViewport();
  };

  return (
    <div>
      <div
        data-testid="map-tools"
        className={`${classes.mapToolsWrapper} map-tools-wrapper`}
      >
        <div
          className={`mapboxgl-ctrl-group mapboxgl-ctrl draw-icons-polygon ${classes.resetViewportStyle}`}
        >
          <IconButton
            className={classes.iconButton}
            onClick={handleResetViewport}
            title="Reset Viewport"
            color="inherit"
            size="small"
            sx={{
              "& .MuiToggleButton-root.Mui-selected": {
                backgroundColor: theme.palette.atlas.main,
                "&:hover": {
                  backgroundColor: theme.palette.atlas.main,
                },
              },
            }}
          >
            <RotateLeftIcon />
          </IconButton>
        </div>

        <div className={classes.fullScreenControlStyle}>
          <FullscreenControl style={{ position: "normal" }} />
        </div>

        <div className={classes.navStyle}>
          <NavigationControl
            onViewportChange={onViewportChange}
            style={{ position: "normal" }}
          />
        </div>
      </div>
    </div>
  );
};

MapToolsbar.propTypes = {
  /**
   * A function that resets the viewport of the map.
   */
  resetViewport: PropTypes.func,
  /**
   * A function that is called when the viewport of the map changes.
   */
  onViewportChange: PropTypes.func,
};

export default MapToolsbar;
