/**
 * This module exports various constants and arrays used in the application. These constants
 * are categorized based on their purpose and provide essential data for configuring the
 * application's functionality and user interfaces.
 *
 */

/**
 * Default Mapbox style URL for streets.
 * @type {string}
 * @category Constants
 */
const DEFAULT_STREETS_STYLE_STRING = `mapbox://styles/fzj/ckl258cga13yx18p3bgt6xsc8`;

/**
 * Array of interactive layer IDs for the map.
 * @type {string[]}
 * @category Constants
 */
const INTERACTIVE_LAYER_IDS = [
  "excOn-region",
  "excPV-region",
  "gwConservative-region",
  "gwConservative2050-region",
  "gwMedium-region",
  "gwMedium2050-region",
  "gwExtreme-region",
  "gwExtreme2050-region",
  "lcoeOnsh_2020-region",
  "lcoeOnsh_2050-region",
  "lcoepv_2020-region",
  "lcoepv_2050-region",
  // "hydro_2020-region",
  // "hydro_2050-region",
  "hydro_2020",
  "hydro_2050",
  "h2potarea",
  "h2potabs",
  "h2lcoh_2020",
  "h2lcoh_2050",
  "h2potgrwaperc",
  "h2potgrwaarea",
  "fact",
];

/**
 * Array of LCOH cost percentages with labels and types.
 * @type {Array<{ label: string, type: string }>}
 * @category Constants
 */
const LCOHCOSTPERCENTAGES = [
  { label: "1%", type: "1%" },
  { label: "25%", type: "25%" },
  { label: "50%", type: "50%" },
  { label: "100%", type: "100%" },
];

/**
 * Array of hydro power types with labels and types.
 * @type {Array<{ label: string, type: string }>}
 * @category Constants
 */
const HYDROPOWERTYPE = [
  { label: "conventional", type: "conventional" },
  { label: "Run-of-river plant", type: "ror" },
  { label: "all", type: "all" },
];

/**
 * Array of groundwater status with labels and types.
 * @type {Array<{ label: string, type: string }>}
 * @category Constants
 */
const GROUNDWATERSTATUS = [
  { label: "Optimistic", type: "opt" },
  { label: "Pessimistic", type: "pess" },
];

/**
 * Array of Mapbox styles with names and URLs.
 * @type {Array<{ name: string, url: string }>}
 * @category Constants
 */
const MAPBOX_STYLES = [
  { name: "streets", url: "ckl258cga13yx18p3bgt6xsc8" },
  { name: "satellite", url: "ckl2648pk150f17msa8pdt5ba" },
];

/**
 * Array of LCOH cost percentages for the factsheet with labels and values.
 * @type {Array<{ label: string, value: string }>}
 * @category Constants
 */
const LCOHCOSTPERCENTAGES_FACTSHEET = [
  { label: "100%", value: "00" },
  { label: "1%", value: "01" },
  { label: "50%", value: "50" },
  { label: "25%", value: "25" },
];

/**
 * Categories for feedback.
 * @type {string[]}
 * @category Constants
 */
const FEEDBACK_CATEGORIES = ["GENERAL", "BUG", "FEATURE REQUEST"];

/**
 * Assignee categories with category names and corresponding IDs.
 * @type {Array<{ category: string, ids: number[] }>}
 * @category Constants
 */
const ASIGNEES_CATEGORIES = [
  { category: "GUI Developers", ids: [1229] },
  { category: "Modellers", ids: [974, 976, 984] },
];

/**
 * Legend labels for groundwater layer group.
 * @type {Object}
 * @category Constants
 */

const GROUNDWATER_LEGEND_LABELS = {
  0: "< 2.5",
  2.5: "2.5 - 10",
  10: "10 - 25",
  25: "25 - 50",
  50: "50 - 100",
  100: "100 - 250",
  250: "> 250",
};

export {
  DEFAULT_STREETS_STYLE_STRING,
  INTERACTIVE_LAYER_IDS,
  LCOHCOSTPERCENTAGES,
  HYDROPOWERTYPE,
  GROUNDWATERSTATUS,
  MAPBOX_STYLES,
  LCOHCOSTPERCENTAGES_FACTSHEET,
  FEEDBACK_CATEGORIES,
  ASIGNEES_CATEGORIES,
  GROUNDWATER_LEGEND_LABELS,
};
