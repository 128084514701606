import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import convex from "@turf/convex";
import area from "@turf/area";
import length from "@turf/length";
import bbox from "@turf/bbox";
import booleanIntersects from "@turf/boolean-intersects";
import {
  averageQuantity,
  maxQuantity,
  minQuantity,
} from "../../utils/mathsAnalysis";
import { roundToDecimal } from "../../utils/roundToDecimal";

/**
 * Custom React hook for drawing features on a map using Mapbox GL JS.
 *
 * @category Hooks
 * @component
 * @param {Object} options - An object containing the required options for drawing features.
 * @param {Object} options.map - The Mapbox GL JS map instance.
 * @param {Function} options.getBeforeIdLayerId - A function that returns the layer ID to insert new layers before.
 * @param {Object} options.DRAW_MODES - An object containing the available draw modes and their handlers.
 * @returns {Object} An object containing the necessary state and functions for drawing features on the map.
 */
const useDraw = ({ map, getBeforeIdLayerId, DRAW_MODES }) => {
  const {
    LAYER_CONFIG,
    checkedLayers,
    setToggleResultPanel,
    toggleResultPanel,
    setDrawInfoDetails,
    featuresLength,
    setFeaturesLength,
  } = useContext(AppContext);
  const [drawRef, setDrawRef] = useState(null);
  const [toggleButtonMode, setToggleButtonMode] = useState("");
  const [modeHandler, setModeHandler] = useState(null);
  const [inactiveClickingDisclaimer, setInactiveClickingDisclaimer] =
    useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const [deleteShapeDisclaimer, setDeleteShapeDisclaimer] = useState(false);
  const [modeId, setModeId] = useState(null);
  const [enableDrawDisclaimer, setEnableDrawDisclaimer] = useState(false);

  /**
   * Get the rendered features from the map that intersect with the user-drawn feature.
   *
   * @param {Object} visibleLayers - The visible layers on the map.
   * @param {Object} userFeature - The user-drawn feature.
   * @returns {Array} An array of features that intersect with the user-drawn feature.
   */
  const getRenderedFeatures = (visibleLayers, userFeature) => {
    const polygonBoundingBox = bbox(userFeature);
    const southWest = [polygonBoundingBox[0], polygonBoundingBox[1]];
    const northEast = [polygonBoundingBox[2], polygonBoundingBox[3]];
    const northEastPointPixel = map.project(northEast);
    const southWestPointPixel = map.project(southWest);

    const features = visibleLayers[0].type.includes("icon")
      ? map.queryRenderedFeatures([southWestPointPixel, northEastPointPixel], {
          layers: [`${visibleLayers[0].layerId}-hidden`],
        })
      : map.queryRenderedFeatures([southWestPointPixel, northEastPointPixel], {
          layers: [`${visibleLayers[0].layerId}`],
        });
    return features;
  };

  /**
   * Create a filter for the map based on the visible layers and the rendered features that intersect with the user-drawn feature.
   *
   * @param {Object} visibleLayers - The visible layers on the map.
   * @param {Array} renderedFeatures - An array of features that intersect with the user-drawn feature.
   * @param {Object} userFeature - The user-drawn feature.
   * @returns {Array} A filter for the map based on the visible layers and the rendered features that intersect with the user-drawn feature.
   */
  const createMapFilter = (visibleLayers, renderedFeatures, userFeature) => {
    const filter = renderedFeatures.reduce(
      function (memo, feature) {
        if (booleanIntersects(feature, userFeature)) {
          // only add the property, if the feature intersects with the polygon drawn by the user
          memo.push(
            feature.properties[
              visibleLayers[0].drawAttribute || visibleLayers[0].fieldAttribute
            ]
          );
        }
        return memo;
      },
      ["in", visibleLayers[0].drawAttribute || visibleLayers[0].fieldAttribute]
    );
    return filter;
  };

  /**
   * Add layers to the map for highlighting the selected feature.
   *
   * @param {Object} visibleLayers - The visible layers on the map.
   * @param {number} index - The index of the selected feature.
   */
  const addLayersToDraw = (visibleLayers, index) => {
    if (visibleLayers[0].type.includes("icon")) {
      map.addLayer({
        id: `features-highlighted-${index}`,
        beforeId: getBeforeIdLayerId(),
        type: "circle",
        source: visibleLayers[0].sourceId,
        "source-layer": visibleLayers[0].sourceLayer,
        paint: {
          "circle-color": "#ffffff",
          "circle-opacity": 0.75,
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],

            3.5,
            2.0,
            3.9,
            3.0,
          ],
        },
      });
    } else {
      map.addLayer({
        id: `features-highlighted-${index}`,
        beforeId: getBeforeIdLayerId(),
        type: "fill",
        source: visibleLayers[0].sourceId,
        "source-layer": visibleLayers[0].sourceLayer,
        paint: {
          "fill-color": "#ffffff",
          "fill-opacity": 0.75,
        },
      });
      map.addLayer({
        id: `features-highlighted-${index}-line`,
        beforeId: getBeforeIdLayerId(),
        type: "line",
        source: visibleLayers[0].sourceId,
        "source-layer": visibleLayers[0].sourceLayer,
        paint: {
          "line-width": 5,
          "line-color": "#fdfdfd",
        },
      });
    }
  };

  useEffect(() => {
    if (featuresLength && featuresLength.length > 0) {
      setToggleResultPanel(() => ({
        ...toggleResultPanel,
        resultPanelOpen: true,
        accordionOpen: true,
        panel: "drawInfo",
      }));
    }
    const features = featuresLength?.map((d) => ({
      payload: d.payload,
    }));
    setDrawInfoDetails(features);
  }, [featuresLength?.length, featuresLength, setDrawInfoDetails]);

  /**
   * Change the draw mode for the map.
   *
   * @param {Object} e - The event object.
   */
  const changeDrawMode = (e) => {
    const id =
      e?.currentTarget.value === modeId ? modeId : e?.currentTarget.value;
    const mode = DRAW_MODES.find((m) => m.id === id);
    const modeHandler = mode ? new mode.handler() : null;
    setModeId(id);
    setModeHandler(modeHandler);
  };

  /**
   * Handle the selection of a feature on the map.
   *
   * @param {Object} options - The options for the selected feature.
   */
  const onSelect = (options) => {
    const mode = DRAW_MODES.find((m) => m.id === modeId);
    if (options.selectedFeatureIndex === null && mode.id === "edit") {
      setInactiveClickingDisclaimer(true);
    }
    setSelectedFeatureIndex(options && options.selectedFeatureIndex);
  };

  const onDelete = useCallback(() => {
    const selectedIndex = selectedFeatureIndex;
    if (selectedIndex !== null && selectedIndex >= 0) {
      drawRef.deleteFeatures(selectedIndex);
      handleRemoveHighlightedLayer(selectedIndex);
      const newFeaturesLength = featuresLength.filter(
        (d, index) => index !== selectedIndex
      );
      setFeaturesLength(newFeaturesLength);
      setSelectedFeatureIndex(null);
      setTimeout(() => {
        const mode = DRAW_MODES.find((m) => m.id === "edit");
        const modeHandler = mode ? new mode.handler() : null;
        if (!newFeaturesLength.length) {
          setToggleButtonMode("");
          setModeHandler(null);
        } else {
          setModeHandler(modeHandler);
          setToggleButtonMode(mode.id);
          setModeId(mode.id);
        }
      }, 1000);
    } else {
      setDeleteShapeDisclaimer(true);
    }
  });

  const onUpdate = (options) => {
    const features = drawRef && drawRef.getFeatures();
    const updatedFeatureLength = options.data.map((d, index) => {
      const drawnFeature = features[index];
      const polygonBoundingBox = convex(drawnFeature);
      const southWestCoordinates =
        polygonBoundingBox.geometry.coordinates[0][0];

      const geometryType = drawnFeature.geometry.type;
      const value =
        geometryType === "Polygon"
          ? roundToDecimal(area(drawnFeature) / 1000000, 2)
          : roundToDecimal(length(drawnFeature) / 1000000, 2);
      const highlightedFeatures = handleHighlightDrawnFeatures(
        drawnFeature,
        index
      );
      const featuresArray = highlightedFeatures?.features
        ?.map((feature) => {
          return highlightedFeatures?.type.includes("lcoe")
            ? feature.properties[highlightedFeatures.fieldAttribute] * 100
            : feature.properties[highlightedFeatures.fieldAttribute];
        })
        ?.filter((feature) => typeof feature === "number");
      return {
        payload: {
          type: geometryType,
          value,
          coordinates: southWestCoordinates,
          feature: highlightedFeatures &&
            featuresArray?.length > 0 && {
              averageQuantity: averageQuantity(featuresArray),
              maxQuantity: maxQuantity(featuresArray),
              minQuantity: minQuantity(featuresArray),
              numberOfFeatures: featuresArray?.length,
              fieldAttribute: highlightedFeatures?.fieldAttribute,
              layerName: highlightedFeatures?.layerName,
              layerId: highlightedFeatures?.layerId,
              unit: highlightedFeatures?.unit,
              layerType: highlightedFeatures?.type,
            },
        },
        index,
      };
    });
    setFeaturesLength(updatedFeatureLength);
    if (options.editType === "addFeature") {
      setTimeout(() => {
        const mode = DRAW_MODES.find((m) => m.id === "edit");
        const modeHandler = mode ? new mode.handler() : null;
        setModeHandler(modeHandler);
        setToggleButtonMode(mode.id);
        setModeId(mode.id);
      }, 1000);
    }
  };

  const handleHighlightDrawnFeatures = (userFeature, index) => {
    const lastSelectedLayer = checkedLayers[checkedLayers.length - 1];
    if (!lastSelectedLayer) {
      return;
    }
    const visibleLayers = LAYER_CONFIG.filter((layer) =>
      lastSelectedLayer?.includes(layer.layerId)
    );
    const isDrawLayer = visibleLayers[0].type.includes("draw");
    if (isDrawLayer && userFeature) {
      const renderedFeatures = getRenderedFeatures(visibleLayers, userFeature);
      const filter = createMapFilter(
        visibleLayers,
        renderedFeatures,
        userFeature
      );
      addLayersToDraw(visibleLayers, index);
      map.setFilter(`features-highlighted-${index}`, filter);
      map.setFilter(`features-highlighted-${index}-line`, filter);

      return {
        features: renderedFeatures,
        fieldAttribute: visibleLayers[0].fieldAttribute,
        unit: visibleLayers[0].legendInfo.unit,
        layerName: visibleLayers[0].name,
        layerId: visibleLayers[0].layerId,
        type: visibleLayers[0].type,
      };
    } else {
      setEnableDrawDisclaimer(true);
    }
  };

  const handleRemoveHighlightedLayer = (index) => {
    const drawnFeatures = drawRef?.getFeatures();
    let newLayers = [];
    const mapStyle = map?.getStyle();
    //remove highlighted layer
    const newStyleLayers = mapStyle?.layers.filter(
      (d) => !d.id.includes(`highlighted-${index}`)
    );
    mapStyle.layers = [...newStyleLayers];
    drawnFeatures?.forEach((d, i) => {
      const newIndex = i - 1;
      if (newIndex >= 0) {
        newLayers = mapStyle.layers.map((layer) => {
          if (
            layer.id.includes(`features-highlighted-${i}`) &&
            layer.type.includes("fill")
          ) {
            layer.id = `features-highlighted-${newIndex}`;
          }
          if (
            layer.id.includes(`features-highlighted-${i}`) &&
            layer.type.includes("line")
          ) {
            layer.id = `features-highlighted-${newIndex}-line`;
          }
          return layer;
        });
      }
    });
    if (newLayers.length > 0) {
      mapStyle.layers = [...newLayers];
    }
    map?.setStyle(mapStyle);
  };

  const states = {
    drawRef,
    toggleButtonMode,
    modeHandler,
    inactiveClickingDisclaimer,
    selectedFeatureIndex,
    deleteShapeDisclaimer,
    modeId,
    enableDrawDisclaimer,
  };
  const handlers = { changeDrawMode, onSelect, onUpdate, onDelete };
  const setters = {
    setDrawRef,
    setToggleButtonMode,
    setInactiveClickingDisclaimer,
    setDeleteShapeDisclaimer,
    setEnableDrawDisclaimer,
  };

  return [states, handlers, setters];
};

export default useDraw;
