import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";

import Typography from "@mui/material/Typography";
import { symbolsConverter } from "../../../utils/symbolsConverter";
import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

/**
 * A component that renders a dropdown menu for selecting units of measurement.
 * @component
 * @category Native
 * @subcategory UnitSelector
 * @param {Object} props
 * @param {string} props.layerId - The id of the layer.
 * @param {string} props.defaultUnit - The default unit of measurement.
 * @param {function} props.resetCostValue - A function that resets the cost value.
 * @returns {JSX.Element} - Rendered component.
 */
const UnitSelector = ({ layerId, defaultUnit, resetCostValue }) => {
  const { t } = useTranslation();
  const { convertedLayerUnits, setConvertedLayerUnits } =
    useContext(AppContext);

  /**
   * A function that handles setting the unit of measurement.
   * @param {string} layerId - The id of the layer.
   * @returns {function} - A function that sets the unit of measurement.
   */
  const handleSetUnit = (layerId) => (e) => {
    resetCostValue(layerId);
    setConvertedLayerUnits({
      ...convertedLayerUnits,
      [layerId]: e.target.value,
    });
  };

  console.log(convertedLayerUnits, "converted");

  return (
    <FormControl>
      <TextField
        select
        variant="standard"
        SelectProps={{
          style: {
            fontSize: "0.65rem",
          },
        }}
        inputProps={{
          sx: {
            padding: "5px",
          },
        }}
        data-testid={`unit-converter-${layerId}`}
        onChange={handleSetUnit(layerId)}
        value={convertedLayerUnits[layerId] ? convertedLayerUnits[layerId] : ""}
      >
        <MenuItem disabled>Change Units</MenuItem>
        {defaultUnit === "€/kWh" &&
          ["€/kWh", "€ct/kWh", "€/MWh"].map((item, index) => (
            <MenuItem key={index} value={item}>
              {symbolsConverter(item)}
            </MenuItem>
          ))}
        {defaultUnit === "€ct/kWh" &&
          ["€ct/kWh", "€/kWh", "€/MWh"].map((item, index) => (
            <MenuItem key={index} value={item}>
              {symbolsConverter(item)}
            </MenuItem>
          ))}
        {defaultUnit === "TWh/yr" &&
          ["TWh/yr", "GWh/yr"].map((item, index) => (
            <MenuItem key={index} value={item}>
              {symbolsConverter(item)}
            </MenuItem>
          ))}

        {defaultUnit === "kWh/(a*m2)" &&
          ["kWh/(a*m2)", "MWh/(a*m2)", "GWh/(a*m2)"].map((item, index) => (
            <MenuItem selected key={index} value={item}>
              {symbolsConverter(item)}
            </MenuItem>
          ))}
        {defaultUnit === "€/kg" &&
          ["€/kg", "€/kWh"].map((item, index) => (
            <MenuItem selected key={index} value={item}>
              {symbolsConverter(item)}
            </MenuItem>
          ))}
        {defaultUnit === "mm/yr" &&
          ["mm/yr"].map((item, index) => (
            <MenuItem selected key={index} value={item}>
              {symbolsConverter(item)}
            </MenuItem>
          ))}
        {defaultUnit === "%" &&
          ["%"].map((item, index) => (
            <MenuItem selected key={index} value={item}>
              {symbolsConverter(item)}
            </MenuItem>
          ))}
        {defaultUnit === "€/ML" &&
          ["€/ML", "€/H"].map((item, index) => (
            <MenuItem selected key={index} value={item}>
              {symbolsConverter(item)}
            </MenuItem>
          ))}
      </TextField>

      <Typography
        sx={{ fontSize: 10, fontWeight: 600, display: "block" }}
        variant="caption"
      >
        {t("layerLegend.layers.unit")}
      </Typography>
    </FormControl>
  );
};

UnitSelector.propTypes = {
  layerId: PropTypes.string,
  defaultUnit: PropTypes.string,
  resetCostValue: PropTypes.func,
};

export default UnitSelector;
