import React, { useContext } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { UIContext } from "../../../contexts/UIContext";
import { AppContext } from "../../../contexts/AppContext";
import "./Tutorial.css";
import joyrideSteps from "./joyrideSteps";

/**
 * A component that renders a tutorial using the Joyride library.
 * @component
 * @category Native
 * @subcategory Tutorial
 * @returns {JSX.Element} - Rendered component.
 */
const Tutorial = () => {
  const { setStepIndex } = useContext(AppContext);
  const { runGuide, setRunGuide } = useContext(UIContext);

  /**
   * A function that handles the Joyride callback.
   * @param {Object} data - The Joyride data object.
   * @param {string} data.action - The Joyride action.
   * @param {number} data.index - The Joyride index.
   * @param {string} data.status - The Joyride status.
   * @param {string} data.type - The Joyride type.
   */
  const handleJoyRideCallback = (data) => {
    const { action, index, status, type } = data;
    const testStatus = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRunGuide(false);
    } else {
      if (testStatus.includes(type)) {
        const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        if (stepIndex === 5) {
          setStepIndex(0);
        } else if (stepIndex === 6) {
          setStepIndex(1);
        }
      }
    }
  };

  return (
    <div>
      <Joyride
        disableScrolling={true}
        disableScrollParentFix={true}
        callback={handleJoyRideCallback}
        continuous={true}
        hideCloseButton={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={joyrideSteps}
        stepIndex={setStepIndex}
        run={runGuide}
        spotlightClicks={true}
        styles={{
          overlay: { height: "100%" },
          options: {
            zIndex: 1000,
          },
          buttonClose: {
            display: "none",
          },
          tooltipContainer: {
            textAlign: "left",
          },
        }}
      />
    </div>
  );
};

export default Tutorial;
