import { useRef, useState, useEffect } from "react";
import windTurbineImage from "../../assets/wind.png";
import pvPanelImage from "../../assets/solar-panel.png";
import hydroImage from "../../assets/hydro.png";
import useDependency from "./useDependency";

/**
 * Custom hook that returns states and handlers for a map component.
 * @category Hooks
 * @component
 * @param {Object} LAYER_CONFIG - Configuration object for the map layer.
 * @returns {Array} Array containing states and handlers.
 */
const useMap = (LAYER_CONFIG) => {
  /**
   * Reference to the map component.
   */
  const mapRef = useRef();
  /**
   * State that holds the map component.
   */
  const [map, setMap] = useState(null);
  /**
   * State that holds the coordinates to be displayed.
   */
  const [showCoordinates, setShowCoordinates] = useState(null);
  /**
   * Dependency for the cost percentage.
   */
  const COST_PERCENTAGE_DEPENDENCY = useDependency(
    LAYER_CONFIG,
    "costPercentage"
  );
  /**
   * Dependency for the cost value.
   */
  const COST_VALUE_DEPENDENCY = useDependency(LAYER_CONFIG, "costValue");
  /**
   * Dependency for the hydropower type.
   */
  const HYDROPOWER_TYPE_DEPENDENCY = useDependency(
    LAYER_CONFIG,
    "hydropowerType"
  );
  /**
   * Dependency for the groundwater status.
   */
  const GROUNDWATER_STATUS_DEPENDENCY = useDependency(
    LAYER_CONFIG,
    "groundwaterStatus"
  );

  /**
   * Handler for when the map component is loaded.
   */
  const handleOnLoad = () => {
    const map = mapRef.current.getMap();
    setMap(map);
  };

  /**
   * Function that gets the id of the layer before the map label.
   * @returns {string} The id of the layer before the map label.
   */
  const getBeforeIdLayerId = () => {
    const layers = map && map.getStyle().layers;
    let mapLabelId;
    if (map) {
      for (var i = 0; i < layers.length; i++) {
        if (layers[i]["source-layer"] === "road") {
          mapLabelId = layers[i].id;
          break;
        }
      }
    }
    return mapLabelId;
  };

  /**
   * Effect that loads and adds the sprites to the map component.
   */
  useEffect(() => {
    loadAndAddSprites();
  }, [
    map,
    COST_PERCENTAGE_DEPENDENCY,
    HYDROPOWER_TYPE_DEPENDENCY,
    COST_VALUE_DEPENDENCY,
    GROUNDWATER_STATUS_DEPENDENCY,
  ]);

  /**
   * Function that loads and adds the sprites to the map component.
   */
  const loadAndAddSprites = () => {
    if (map) {
      !map.hasImage("windTurbine") &&
        map.loadImage(windTurbineImage, function (error, image) {
          if (error) throw error;
          map.addImage("windTurbine", image, { sdf: true });
        });
      !map.hasImage("hydro") &&
        map.loadImage(hydroImage, function (error, image) {
          if (error) throw error;
          map.addImage("hydro", image, { sdf: true });
        });
      !map.hasImage("pvPanel") &&
        map.loadImage(pvPanelImage, function (error, image) {
          if (error) throw error;
          map.addImage("pvPanel", image, { sdf: true });
        });
    }
  };

  /**
   * Handler for when the coordinates are clicked.
   * @param {Object} e - The event object.
   */
  const handleCoordinates = (e) => {
    const [lng, lat] = e.lngLat;
    setShowCoordinates({ Longitude: lng, Latitude: lat });
  };

  /**
   * Object containing the states of the hook.
   */
  const states = {
    mapRef,
    map,
    showCoordinates,
  };

  /**
   * Object containing the handlers of the hook.
   */
  const handlers = {
    handleOnLoad,
    getBeforeIdLayerId,
    loadAndAddSprites,
    handleCoordinates,
  };

  return [states, handlers];
};

export default useMap;
