import React, { useState, useContext, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { Chip, Grow } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import makeStyles from "@mui/styles/makeStyles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import "./LayerLegendPanel.css";
import { symbolsConverter } from "../../../utils/symbolsConverter";
import { AppContext } from "../../../contexts/AppContext";
import InfoDialog from "../../UI/InfoDialog/InfoDialog";
import DisclaimerDialog from "../../UI/InfoDialog/DisclaimerDialog";
import ColorRamp from "../ColorRamp/ColorRamp";
import ScreenshotDialog from "../../UI/ScreenshotDialog/ScreenshotDialog";
import currencyAndUnitConverter from "../../../utils/currencyAndUnitConverter";
import MyTooltip from "../../UI/Tooltip/MyTooltip";
import CostFilterDisclaimerContent from "../../UI/CostFilterDisclaimerContent/CostFilterDisclaimer";
import {
  GROUNDWATERSTATUS,
  HYDROPOWERTYPE,
  LCOHCOSTPERCENTAGES,
} from "../../../constants/constants";
import { getMUIStyle } from "./MUIStyle";
import UnitSelector from "../UnitSelector/UnitSelector";
import Toggler from "../Toggler/Toggler";
import useLayerFunctionality from "../../../hooks/Layers/useLayerFunctionality";

const useStyles = makeStyles(getMUIStyle);

/**
 * LayerLegendPanel component displays a list of layers with their respective legend and functionality options.
 * @component
 * @category Native
 * @subcategory LayerLegendPanel
 * @param {Object} props - The props object.
 * @param {Object} props.map - The map object.
 * @param {Object} props.viewport - The viewport object.
 * @param {Function} props.setViewport - The function to set the viewport.
 * @param {Function} props.closePopup - The function to close the popup.
 * @param {Function} props.getBeforeIdLayerId - The function to get the before ID layer ID.
 * @param {Function} props.clickPopupInfo - The function to handle click popup info.
 * @param {Function} props.setHoverInfo - The function to set the hover info.
 * @returns {JSX.Element} - The LayerLegendPanel component.
 */
const LayerLegendPanel = ({
  map,
  viewport,
  setViewport,
  closePopup,
  getBeforeIdLayerId,
  clickPopupInfo,
  setHoverInfo,
}) => {
  const {
    LAYER_CONFIG,
    LAYER_GROUP_CONFIG,
    checkedLayers,
    setCheckedLayers,
    toggleLayer,
    convertedLayerUnits,
    openCostFilterDisclaimer,
    setOpenCostFilterDisclaimer,
    layerConfigHandlers: {
      showInteractivityInfo,
      hideInteractivityInfo,
      resetCostValue,
    },
  } = useContext(AppContext);
  const [
    {
      openExpandableList,
      groupIndex,
      loadingScreenshot,
      formatOptions,
      openScreenshotDialog,
      openScreenshotDisclaimer,
      screenshotRegion,
    },
    {
      handleCostSliderChange,
      handleResetFilter,
      handleCostInputChange,
      handleExpandableList,
      handleLayerChange,
      handleToggleLayerLegendPanel,
      handleOpacityChange,
      handleCostPercentageToggler,
      handleHydropowerTypeToggler,
      handleGroundwaterStatusToggler,
      handleScreenshotRegion,
      handleScreenshotDialog,
      handleScreenshotDisclaimer,
      handleScreenshot,
    },
    { setFormatOptions },
  ] = useLayerFunctionality({
    map,
    closePopup,
    clickPopupInfo,
    viewport,
    setViewport,
    getBeforeIdLayerId,
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const [tooltipEnter, setTooltipEnter] = useState(false);
  const classes = useStyles();

  //to handle dynamic reordering of added layers
  useEffect(() => {
    const visibleLayer = LAYER_CONFIG.filter((layer) => layer.renderLayer)[0];
    if (map) {
      if (checkedLayers[checkedLayers.length - 1]) {
        checkedLayers.forEach((layer) =>
          map.moveLayer(layer, getBeforeIdLayerId())
        );
      } else if (checkedLayers.length < 1 && visibleLayer) {
        setCheckedLayers([...checkedLayers, visibleLayer?.layerId]);
      }
    }
  }, [checkedLayers[checkedLayers.length - 1], map, LAYER_CONFIG]);

  return (
    <div>
      <Grow in={toggleLayer}>
        <Container
          data-testid="layer-legend-panel"
          className={`${classes.container} layer-wrapper`}
          id="layer-wrapper"
          onMouseEnter={() => setHoverInfo(null)}
        >
          <Typography className={classes.containerTitle} variant="h5">
            {t("layerLegend.title")}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleToggleLayerLegendPanel}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <div className="layer-list-wrapper">
            {LAYER_GROUP_CONFIG.map((group) => {
              const groupName = group.groupName;
              const groupId = group.id;
              return (
                <List id={groupId} key={groupId}>
                  <ListItem
                    className={classes.listItem}
                    onClick={() => handleExpandableList(groupId)}
                  >
                    <ListItemText
                      primary={
                        groupIndex[groupId] &&
                        groupIndex[groupId].length !== 0 ? (
                          <Box
                            display="flex"
                            alignItems="baseline"
                            // justifyContent="space-between"
                          >
                            <Box flexGrow={1}>
                              <span className={classes.listItemText}>
                                {t(`layerLegend.layers.group.${groupName}`)}
                              </span>
                              <Chip
                                label={group.status}
                                size="small"
                                color="primary"
                                sx={{ fontSize: theme.typography.caption }}
                              />
                            </Box>

                            <Avatar
                              sx={{
                                height: 9,
                                width: 9,
                                padding: "3px",
                                fontSize: "0.6rem",
                                color: "white",
                                bgcolor: theme.palette.primary.main,
                              }}
                            >
                              {groupIndex[groupId].length}
                            </Avatar>
                          </Box>
                        ) : (
                          <Box flexGrow={1}>
                            <span style={{ marginRight: "5px" }}>
                              {t(`layerLegend.layers.group.${groupName}`)}
                            </span>
                            <Chip
                              label={group.status}
                              size="small"
                              color="primary"
                              sx={{ fontSize: theme.typography.caption }}
                            />
                          </Box>
                        )
                      }
                    />
                    {openExpandableList === groupId ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  {group.items.map((layer, index) => {
                    const layerId = layer.layerId;
                    const layerName = layer.name;
                    const layerUnit = layer.legendInfo.unit;
                    const renderLayer = layer.renderLayer;
                    const layerType = layer.layerType;
                    const groundwaterStatus = layer.groundwaterStatus;
                    const type = layer.type;
                    const costPercentage = layer.costPercentage;
                    const unitConverted = convertedLayerUnits[layerId];
                    const interactivity = layer.interactivity;
                    const hydropowerType = layer.hydropowerType;
                    const convertedMaxCostValue = parseFloat(
                      currencyAndUnitConverter.convert(
                        layerUnit,
                        unitConverted,
                        type.includes("lcoe") ? 20 : 10
                      )
                    );

                    return (
                      <Collapse
                        in={openExpandableList === groupId}
                        timeout="auto"
                        unmountOnExit
                        key={index}
                        id={`layer-legend-wrapper`}
                      >
                        <List component="div" disablePadding key={index}>
                          <ListItem className={classes.listItem}>
                            <FormControlLabel
                              className={classes.formControl}
                              control={
                                <>
                                  <Checkbox
                                    className="myCheckBox"
                                    size="small"
                                    data-testid="layer-checkbox"
                                    color="primary"
                                    checked={renderLayer}
                                    onChange={(event) =>
                                      handleLayerChange(
                                        event,
                                        groupId,
                                        layerId,
                                        layerType,
                                        interactivity
                                      )
                                    }
                                    name="first"
                                    value={layerId}
                                    id={layerId}
                                  />
                                </>
                              }
                              label={
                                <div style={{ textOverflow: "ellipsis" }}>
                                  <Typography variant="caption">
                                    {symbolsConverter(
                                      t(`layerLegend.layers.name.${layerName}`)
                                    )}
                                  </Typography>
                                </div>
                              }
                            />

                            <Tooltip
                              className={classes.tooltip}
                              placement="bottom-end"
                              onClick={() => showInteractivityInfo(layerId)}
                              onMouseEnter={() => setTooltipEnter(true)}
                              onMouseLeave={() => setTooltipEnter(false)}
                              componentsProps={{
                                tooltip: {
                                  style: { maxHeight: 200, overflow: "auto" },
                                },
                              }}
                              enterDelay={500}
                              leaveDelay={200}
                              title={
                                <Typography variant="caption">
                                  {t(
                                    `layerLegend.layers.layerInfo.main.${layerId}`
                                  )}
                                </Typography>
                              }
                            >
                              <IconButton
                                className={classes.screenshotButton}
                                size="large"
                              >
                                {!tooltipEnter ? (
                                  <InfoIcon fontSize="small" />
                                ) : (
                                  <Link
                                    sx={{
                                      textTransform: "none",
                                      fontSize: "0.6rem",
                                      paddingTop: "0.3rem",
                                      paddingBottom: "0.3rem",
                                      // minWidth: "4.7rem",
                                    }}
                                  >
                                    view in modal
                                  </Link>
                                )}
                              </IconButton>
                            </Tooltip>

                            {width > 600 && layerId !== "fact" && (
                              <IconButton
                                className={classes.screenshotButton}
                                disabled={layer.disableScreenshot}
                                onClick={() => handleScreenshotDialog(true)}
                                title={t(`layerLegend.layers.screenshot`)}
                                size="large"
                              >
                                <CameraAltIcon fontSize={"small"} />
                              </IconButton>
                            )}

                            {/* {loading[layerId] && (
                            <CircularProgress size={15} thickness={8.0} />
                          )} */}
                          </ListItem>
                          <Box className={classes.legendWrapper}>
                            {layer.renderLegend &&
                              (costPercentage ? (
                                <Toggler
                                  layerId={layerId}
                                  layerProperty={costPercentage}
                                  layerType={layerType}
                                  layer={layer}
                                  toggleList={LCOHCOSTPERCENTAGES}
                                  handleToggler={handleCostPercentageToggler(
                                    layer
                                  )}
                                />
                              ) : layerId.includes("hydro") ? (
                                <Toggler
                                  layerId={layerId}
                                  layerProperty={hydropowerType}
                                  layerType={layerType}
                                  layer={layer}
                                  toggleList={HYDROPOWERTYPE}
                                  handleToggler={handleHydropowerTypeToggler(
                                    layer
                                  )}
                                />
                              ) : layerId.includes("gw") &&
                                groundwaterStatus ? (
                                <Toggler
                                  layerId={layerId}
                                  layerProperty={groundwaterStatus}
                                  layerType={layerType}
                                  layer={layer}
                                  toggleList={GROUNDWATERSTATUS}
                                  handleToggler={handleGroundwaterStatusToggler(
                                    layer
                                  )}
                                />
                              ) : null)}
                            {layerId !== "fact" && layer.renderLegend && (
                              <Grid
                                className={classes.legendAttachmentsWrapper}
                                container
                                spacing={2}
                              >
                                <Grid item xs={4}>
                                  <Stack
                                    direction="column"
                                    alignItems="start"
                                    justifyContent="space-between"
                                  >
                                    {" "}
                                    {layer.legendInfo.unit && (
                                      <UnitSelector
                                        resetCostValue={resetCostValue}
                                        defaultUnit={layerUnit}
                                        layerId={layerId}
                                      />
                                    )}
                                    <Box>
                                      <Slider
                                        size="small"
                                        data-testid={`opacity-slider-${layerId}`}
                                        classes={{
                                          valueLabel: classes.sliderValueLabel,
                                          root: classes.sliderRootOpacity,
                                        }}
                                        defaultValue={100}
                                        valueLabelDisplay="auto"
                                        value={layer.opacity}
                                        onChange={handleOpacityChange(
                                          layerId,
                                          layer.type[0],
                                          layer.type[1]
                                        )}
                                        getAriaValueText={() =>
                                          `${layer.opacity}%`
                                        }
                                        step={10}
                                        min={0}
                                        max={100}
                                      />
                                      <Typography
                                        sx={{
                                          fontSize: 10,
                                          fontWeight: 600,
                                          display: "block",
                                        }}
                                        variant="caption"
                                      >
                                        {t("layerLegend.layers.layerOpacity")}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Grid>

                                <Grid item xs={4}>
                                  {/* eslint-disable-next-line */}
                                  {layer.hasOwnProperty("costValue") && (
                                    <Stack
                                      direction="column"
                                      alignItems="center"
                                    >
                                      <Slider
                                        classes={{
                                          valueLabel: classes.sliderValueLabel,
                                          root: classes.sliderRootFilter,
                                        }}
                                        value={layer.costValue || 0}
                                        onChange={handleCostSliderChange(
                                          layerId,
                                          layerUnit
                                        )}
                                        valueLabelDisplay={
                                          typeof layer.costValue === "string"
                                            ? "off"
                                            : "auto"
                                        }
                                        step={0.01}
                                        min={0}
                                        max={convertedMaxCostValue}
                                      />

                                      <Input
                                        className={classes.filterInput}
                                        value={layer.costValue}
                                        placeholder={t(
                                          "layerLegend.layers.cost"
                                        )}
                                        size="small"
                                        onChange={handleCostInputChange(
                                          layerId,
                                          layerUnit
                                        )}
                                        inputProps={{
                                          step: 0.01,
                                          min: 0,
                                          max: parseFloat(
                                            currencyAndUnitConverter.convert(
                                              layerUnit,
                                              unitConverted,
                                              type.includes("lcoe") ? 20 : 10
                                            )
                                          ),
                                          type: "number",
                                          "aria-labelledby": "input-slider",
                                        }}
                                      />
                                      <Stack direction="row">
                                        <Typography
                                          sx={{
                                            fontSize: 10,
                                            fontWeight: 600,
                                            display: "block",
                                          }}
                                          variant="caption"
                                        >
                                          {t("layerLegend.layers.costFilter")}
                                        </Typography>
                                        <MyTooltip
                                          disableFocusListener={false}
                                          disableHoverListener={false}
                                          disableTouchListener={false}
                                          title={
                                            <Typography variant="caption">
                                              {type.includes("lcoe")
                                                ? t(
                                                    "layerLegend.layers.costFilterLCOETooltip"
                                                  )
                                                : t(
                                                    "layerLegend.layers.costFilterLCOHTooltip"
                                                  )}
                                            </Typography>
                                          }
                                        />
                                      </Stack>
                                    </Stack>
                                  )}
                                </Grid>
                                <Grid item xs={4}>
                                  {/* eslint-disable-next-line */}
                                  {layer.hasOwnProperty("costValue") && (
                                    <Stack direction="column">
                                      <Button
                                        data-testid={`attribute-filter-reset-${layerId}`}
                                        style={{
                                          backgroundColor: "red",
                                          color: "#ffffff",
                                        }}
                                        variant="contained"
                                        className={classes.layerButton}
                                        size="small"
                                        onClick={() =>
                                          handleResetFilter(layerId, type)
                                        }
                                      >
                                        {t("layerLegend.layers.reset")}
                                      </Button>
                                    </Stack>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                            <div id={`legend-wrapper-${layerId}`}>
                              {layer.renderLegend && layerId !== "fact" && (
                                <ColorRamp
                                  // convertedLayerUnits={unit[layerId]}
                                  unitConverted={unitConverted}
                                  defaultUnit={layerUnit}
                                  layer={layer}
                                />
                              )}
                            </div>
                            {interactivity && (
                              <InfoDialog
                                open={interactivity.status}
                                onClose={() => hideInteractivityInfo(layerId)}
                                onClick={() => hideInteractivityInfo(layerId)}
                                info={
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      gutterBottom
                                      textAlign="center"
                                    >
                                      {symbolsConverter(
                                        t(
                                          `layerLegend.layers.name.${layerName}`
                                        )
                                      )}
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                      {t("layerLegend.layers.Description")}
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      textAlign="justify"
                                    >
                                      {t(
                                        `layerLegend.layers.layerInfo.main.${layerId}`
                                      )}
                                    </Typography>
                                    <Typography variant="h6" gutterBottom>
                                      {t("layerLegend.layers.Interactivity")}
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      textAlign="justify"
                                    >
                                      {t(
                                        `layerLegend.layers.layerInfo.interactivityInfo.${layerId}`
                                      )}
                                    </Typography>
                                  </Box>
                                }
                              />
                            )}
                          </Box>
                        </List>
                      </Collapse>
                    );
                  })}
                </List>
              );
            })}
          </div>
        </Container>
      </Grow>

      <DisclaimerDialog
        open={openScreenshotDisclaimer}
        onClose={() => handleScreenshotDisclaimer(false)}
        onClick={() => handleScreenshotDisclaimer(false)}
        info={t(`layerPanelDisclaimer.screenshotDisclaimer`)}
      />
      <DisclaimerDialog
        open={openCostFilterDisclaimer}
        onClose={() => setOpenCostFilterDisclaimer(false)}
        onClick={() => setOpenCostFilterDisclaimer(false)}
        info={<CostFilterDisclaimerContent clickPopupInfo={clickPopupInfo} />}
      />
      <ScreenshotDialog
        open={openScreenshotDialog}
        onClose={() => handleScreenshotDialog(false)}
        onClick={() => handleScreenshot(LAYER_CONFIG)}
        handleScreenshotRegion={handleScreenshotRegion}
        screenshotRegion={screenshotRegion}
        loadingScreenshot={loadingScreenshot}
        formatOptions={formatOptions}
        setFormatOptions={setFormatOptions}
      />
    </div>
  );
};
export default LayerLegendPanel;

//TODO: Modify German translation of Opacity filter to make it shorter
//TODO: All Translations for LCOH layer
//TODO: Make Togglers a component
//TODO: Make all disclaimers a component
