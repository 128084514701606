import React, { useState, useContext, useRef } from "react";
import { MapContext } from "react-map-gl";

import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import LanguageIcon from "@mui/icons-material/Language";
import { Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { history } from "../../../utils/history";

import FZJLogo from "../../../assets/Logo_FZ.svg";
import { UIContext } from "../../../contexts/UIContext";
import { AppContext } from "../../../contexts/AppContext";
import About from "../About/About";
import style from "./MUIStyle";

const useStyles = makeStyles(style);

/**
 * Navbar component that displays the navigation bar at the top of the page.
 * Uses various context hooks and state variables to handle user interactions and language switching.
 * @component
 * @category Native
 * @subcategory Navbar
 * @returns {JSX.Element} The JSX element representing the Navbar component.
 */
const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { setToggleLayer, setToggleResultPanel, toggleResultPanel } =
    useContext(AppContext);
  const { setOpenWelcomeDialog, setRunGuide } = useContext(UIContext);
  const { map } = useContext(MapContext);

  const [openAboutDialog, setOpenAboutDialog] = useState(false);
  const [languageCode, setLanguageCode] = useState("EN");
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const open = Boolean(langAnchorEl);
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  const handleLanguageMenu = (event) => {
    setLangAnchorEl(event.currentTarget);
  };

  /**
   * Changes the language of the app and the basemap
   * @param {string} code - The language code to switch to
   */
  const changeLanguage = (code) => {
    setLangAnchorEl(null);
    setLanguageCode(code);
    i18n.changeLanguage(code); // i18n language switch/toggle function
    map &&
      map.setLayoutProperty("country-label", "text-field", [
        "get",
        `name_${code}`,
      ]); // basemap language switch
  };

  // start tutorial

  /**
   * Handles the click event for the start guide button
   * @param {object} e - The click event object
   */
  const handleClickStartGuide = (e) => {
    e.preventDefault();
    setRunGuide(true);
    setOpenWelcomeDialog(false);
  };

  /**
   * Handles the click event for the home button
   */
  const handleGoToHome = () => {
    history.push("/");
  };

  /**
   * Toggles the layer panel
   */
  const handleToggleLayer = () => {
    setToggleLayer((prev) => !prev);
  };

  /**
   * Toggles the result panel
   */
  const handleToggleResultPanel = () => {
    setToggleResultPanel({
      ...toggleResultPanel,
      resultPanelOpen: !toggleResultPanel.resultPanelOpen,
      accordionOpen: !toggleResultPanel.accordionOpen,
      panel: "",
    });
  };

  /**
   * Handles the click event for the menu button
   * @param {object} event - The click event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the menu
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   * Closes the about dialog
   */
  const handleCloseAboutDialog = () => {
    setOpenAboutDialog(false);
  };

  const classes = useStyles();

  return (
    <AppBar
      id="appBar"
      className={`${classes.appBar} appBar`}
      position="static"
      color="default"
    >
      <div className={classes.fzjLogo}>
        <Link
          data-testid="header-logo"
          href="https://www.fz-juelich.de/"
          target="_blank"
        >
          <img src={FZJLogo} width="200" alt="FZJ homepage" />
        </Link>
      </div>

      <Toolbar className={`${classes.toolbar}`}>
        <div className="toolbar-wrapper">
          <Button
            className={classes.buttonToolbar}
            onClick={handleGoToHome}
            color="inherit"
          >
            <Typography variant="h5" className={classes.title}>
              {t("navbar.home")}
            </Typography>
          </Button>
          <Button
            className={classes.buttonToolbar}
            onClick={handleToggleLayer}
            color="inherit"
          >
            <Typography variant="h5" className={classes.title}>
              {t("navbar.layers")}
            </Typography>
          </Button>

          <Button
            className={classes.buttonToolbar}
            onClick={handleToggleResultPanel}
            color="inherit"
          >
            <Typography variant="h5" className={classes.title}>
              {t("navbar.results")}
            </Typography>
          </Button>
          <Button
            data-testid="tutorial-button"
            className={classes.buttonToolbar}
            onClick={handleClickStartGuide}
            color="inherit"
          >
            <Typography variant="h5" className={classes.title}>
              {t("navbar.tutorial")}
            </Typography>
          </Button>
          <Button
            ref={anchorRef}
            data-testid="about-button"
            className={classes.buttonToolbar}
            color="inherit"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => setOpenAboutDialog(true)}
          >
            <Typography variant="h5" className={classes.title}>
              {t("navbar.about")}
            </Typography>
          </Button>
          <About
            handleCloseAboutDialog={handleCloseAboutDialog}
            openAboutDialog={openAboutDialog}
          />
        </div>
      </Toolbar>

      <div>
        <div className={classes.translationBar}>
          <IconButton
            className={classes.buttonTranslationBar}
            size="small"
            onClick={handleLanguageMenu}
            color="inherit"
          >
            <LanguageIcon fontSize="small" />
            {languageCode
              ? languageCode
              : localStorage.getItem("i18nextLng").slice(0, 2)}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={langAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={() => setLangAnchorEl(null)}
          >
            <MenuItem disableRipple onClick={() => changeLanguage("en")}>
              EN
            </MenuItem>
            <MenuItem disableRipple onClick={() => changeLanguage("de")}>
              DE
            </MenuItem>
            <MenuItem disableRipple onClick={() => changeLanguage("fr")}>
              FR
            </MenuItem>
            <MenuItem disableRipple onClick={() => changeLanguage("pt")}>
              PT
            </MenuItem>
          </Menu>
        </div>

        {/* Start Mobile View */}
        <Toolbar className={classes.toolbarCollapse}>
          <div>
            <IconButton onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                className={classes.buttonToolbar}
                onClick={handleGoToHome}
              >
                {t("navbar.home")}
              </MenuItem>
              <MenuItem
                className={classes.buttonToolbar}
                onClick={handleToggleLayer}
              >
                {t("navbar.layers")}
              </MenuItem>
              {/* <MenuItem className={classes.buttonToolbar} onClick={handleClickStartGuide}>
            TUTORIAL
          </MenuItem> */}
              <MenuItem
                className={classes.buttonToolbar}
                onClick={() => setOpenAboutDialog(true)}
              >
                {t("navbar.about")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>

        {/* End Mobile View */}
      </div>
    </AppBar>
  );
};

export default Navbar;
