import { useMemo, useReducer } from "react";
import { history } from "../../utils/history";
import LayerGroups from "../../config/initialLayerGroups";

const pathLocation = history.location.pathname;
const layerGroups = pathLocation.includes("/sadc")
  ? LayerGroups("sadc")
  : pathLocation.includes("/ecowas")
  ? LayerGroups("ecowas")
  : pathLocation.includes("/east")
  ? LayerGroups("east")
  : [];

/**
 * A reducer function that handles the map layers.
 * @private
 * @param {Object[]} STATE - The current state of the map layers.
 * @param {Object} ACTION - The action to be performed on the state.
 * @returns {Object[]} - The new state of the map layers.
 */
const MapLayersReducer = (STATE, ACTION) => {
  /**
   * A function that maps through the state and modifies each group.
   *
   * @param {Function} callback - The function to be performed on each group.
   * @returns {Array} - The new state of the map layers.
   */
  const STATE_GROUP = (callback) =>
    STATE.map((group) => ({ ...group, items: group.items.map(callback) }));

  switch (ACTION.type) {
    case "toggle layers":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, renderLayer: !layer.renderLayer };
        } else return layer;
      });
    case "render legend":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, renderLegend: !layer.renderLegend };
        } else return layer;
      });
    case "enable screenshot":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, disableScreenshot: !layer.disableScreenshot };
        } else return layer;
      });
    case "change opacity":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, opacity: ACTION.payload };
        } else return layer;
      });
    case "change cost layer percentage":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, costPercentage: ACTION.payload };
        } else return layer;
      });
    case "change hydropower layer type":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, hydropowerType: ACTION.payload };
        } else return layer;
      });
    case "change groundwater 2050 layer type":
      return STATE_GROUP((layer) => {
        const newSourceId = layer.sourceId.replace(
          /_[^_]*$/,
          `_${ACTION.payload}`
        );
        if (layer.layerId === ACTION.id) {
          return {
            ...layer,
            groundwaterStatus: ACTION.payload,
            sourceId: newSourceId,
          };
        } else return layer;
      });
    case "show interactivity info once":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id && layer.interactivity) {
          return {
            ...layer,
            interactivity: {
              ...layer.interactivity,
              count: layer.interactivity.count + 1,
              status: true,
            },
          };
        } else return layer;
      });
    case "hide interactivity info":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id && layer.interactivity) {
          return {
            ...layer,
            interactivity: {
              ...layer.interactivity,
              status: false,
            },
          };
        } else return layer;
      });

    case "reset opacity":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, opacity: 100 };
        } else return layer;
      });
    case "append field values":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, fieldValue: [ACTION.minValue, ACTION.maxValue] };
        } else return layer;
      });
    case "append cost value":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id) {
          return { ...layer, costValue: ACTION.payload };
        } else return layer;
      });

    case "reset cost value":
      return STATE_GROUP((layer) => {
        if (layer.layerId === ACTION.id && layer.costValue) {
          return {
            ...layer,
            costValue: ACTION.payload,
          };
        } else return layer;
      });

    default:
      return STATE_GROUP;
  }
};

/**
 * A custom hook that handles the layer configuration.
 * @kind function
 * @component
 * @category Hooks
 * @returns {Array} - An array containing the layer configuration and the handlers.
 */

const useLayerConfig = () => {
  const [LAYER_GROUP_CONFIG, mapLayersDispatch] = useReducer(
    MapLayersReducer,
    layerGroups
  );

  console.log(LAYER_GROUP_CONFIG, "LAYER_GROUP_CONFIG");
  const LAYER_CONFIG = LAYER_GROUP_CONFIG.map((group) => group.items).flat();
  console.log(LAYER_CONFIG, "LAYER_CONFIG");

  const handlers = useMemo(
    () => ({
      changeLayer: (layerId) =>
        mapLayersDispatch({ type: "toggle layers", id: layerId }),
      changeOpacity: (value, layerId) =>
        mapLayersDispatch({
          type: "change opacity",
          id: layerId,
          payload: value,
        }),
      resetOpacity: (layerId) =>
        mapLayersDispatch({ type: "reset opacity", id: layerId }),
      changeCostPercentage: (value, layerId) =>
        mapLayersDispatch({
          type: "change cost layer percentage",
          id: layerId,
          payload: value,
        }),
      changeHydropowerType: (value, layerId) =>
        mapLayersDispatch({
          type: "change hydropower layer type",
          id: layerId,
          payload: value,
        }),
      changeGroundwaterStatus: (value, layerId) =>
        mapLayersDispatch({
          type: "change groundwater 2050 layer type",
          id: layerId,
          payload: value,
        }),
      showInteractivityInfo: (layerId) =>
        mapLayersDispatch({
          type: "show interactivity info once",
          id: layerId,
        }),
      hideInteractivityInfo: (layerId) =>
        mapLayersDispatch({ type: "hide interactivity info", id: layerId }),
      renderLegend: (layerId) =>
        mapLayersDispatch({ type: "render legend", id: layerId }),
      disableScreenshot: (layerId) =>
        mapLayersDispatch({ type: "enable screenshot", id: layerId }),
      appendFieldValue: (minValue, maxValue, layerId) =>
        mapLayersDispatch({
          type: "append field values",
          id: layerId,
          minValue: minValue,
          maxValue: maxValue,
        }),
      appendCostValue: (costValue, layerId) =>
        mapLayersDispatch({
          type: "append cost value",
          id: layerId,
          payload: costValue,
        }),
      resetCostValue: (layerId) =>
        mapLayersDispatch({
          type: "reset cost value",
          id: layerId,
          payload: "",
        }),
    }),
    []
  );

  return [{ LAYER_GROUP_CONFIG, LAYER_CONFIG }, handlers];
};

export default useLayerConfig;
