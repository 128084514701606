/**
 * This file contains the Material UI styles used in the LayerLegendPanel component.
 * @name LayerLegendPanelMUIStyle
 * @category MUI
 *
 * @param {Object} theme - The material UI theme object.
 * @returns {Object} The Material UI styling.
 */
const getMUIStyle = (theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      width: "15rem",
    },
    position: "absolute",
    zIndex: 100,
    top: "100px",
    right: 0,
    maxWidth: "26rem",
    maxHeight: "calc(100vh - 200px)",
    backgroundColor: theme.palette.background.paper,
    paddingTop: "10px",
    paddingBottom: "10px",
    boxShadow: "0px 0px 3px #434343",
    borderRadius: "5px",
    paddingRight: "10px",
    paddingLeft: "10px",
    textOverflow: "wrap",
  },
  containerTitle: {
    textTransform: "uppercase",
  },

  listItem: {
    lineHeight: 0,
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: 0,
    paddingBottom: 0,
    cursor: "pointer",
  },
  legendItem: {
    paddingBottom: "0px",
    paddingTop: "0px",
    height: "100%",
    marginTop: "0px",
  },
  legendItemIcon: {
    minWidth: 0,
  },
  formControl: {
    marginLeft: 0,
    marginRight: 0,
  },
  listItemText: {
    fontWeight: 900,
    display: "inline",
    marginRight: "5px",
  },
  listItemRibbon: {
    fontWeight: 900,
    border: "1px solid",
    backgroundColor: "black",
    borderRadius: "50%",
    width: "15px",
    color: "white",
    lineHeight: "15px",
    marginLeft: "5px",
    textAlign: "center",
    fontSize: "0.7rem",
  },
  tooltip: {
    marginLeft: "auto",
    marginRight: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    padding: "0px",
    margin: "0px",
    color: theme.palette.common.black,
  },
  dialogBoxPaper: {
    backgroundColor: theme.palette.background.paper,
  },
  sliderValueLabel: {
    // left: "calc(-50% + 12px)",
    top: -12,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  sliderRootOpacity: {
    padding: 0,
    width: 50,
  },
  sliderRootFilter: {},
  filterInput: {
    width: 50,
    fontSize: 12,
    "& .MuiInputBase-input": {
      paddingTop: 10,
    },
  },
  legendAttachmentsWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    textAlign: "center",
    marginBottom: 10,
  },
  legendWrapper: {
    paddingLeft: 20,
    marginBottom: 5,
    display: "flex",
    flexDirection: "column",
  },
  unitSelector: {
    "& .MuiSelect-select.MuiSelect-select": {
      // fontSize: "0.8rem",
    },
  },
  layerButton: {
    margin: theme.spacing(1),
    padding: theme.spacing(0.8),
    fontSize: "0.55rem",
  },
  toggleGroup: {
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  screenshotButton: {
    minWidth: 0,
    padding: 0,
    color: "unset",
  },
});

export { getMUIStyle };
