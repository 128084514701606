import { useTranslation } from "react-i18next";
import { AppContext } from "../../../contexts/AppContext";
import { useContext } from "react";
import * as DOMPurify from "dompurify";
import PropTypes from "prop-types";

/**
 * @component
 * @category Native
 * @subcategory CostFilterDisclaimerContent
 * @description A component that displays a disclaimer text for the cost filter.
 * @param {Object} props - The component's props object.
 * @param {Object} props.clickPopupInfo - An object containing data for the clicked popup.
 * @returns {JSX.Element} - Rendered component.
 */
const CostFilterDisclaimerContent = ({ clickPopupInfo }) => {
  /**
   * A hook that provides access to the AppContext.
   * @kind Hook
   * @name useContext
   * @returns {Object} - The AppContext object.
   */
  const { convertedLayerUnits } = useContext(AppContext);

  /**
   * A hook that provides access to the i18next translation function.
   * @kind Hook
   * @name useTranslation
   * @returns {Object} - The i18next translation function.
   */
  const { t } = useTranslation();

  // If there is no clickPopupInfo data, return null
  if (!clickPopupInfo?.data) return null;

  // Destructure the clickPopupInfo data
  const {
    layerId,
    costPercentage: expansionFactor,
    nonLCOEData,
  } = clickPopupInfo.data;

  // Get region, h2Potential, h2PotentialUnit, LCOH, and LCOHUnit from nonLCOEData
  const region = nonLCOEData.region.location;
  const h2Potential = nonLCOEData.region.unrestricted[1].value;
  const h2PotentialUnit = nonLCOEData.region.unrestricted[1].unit;
  const LCOH = nonLCOEData.region.unrestricted[0].value;
  const LCOHUnit = convertedLayerUnits[layerId];

  /**
   * A function that sanitizes the translated text and prevents XSS attacks.
   * @kind Function
   * @name sanitizeText
   * @param {string} text - The text to be sanitized.
   * @returns {string} - The sanitized text.
   */
  const sanitizeText = (text) => {
    return DOMPurify.sanitize(text, {
      USE_PROFILES: { html: true },
    });
  };

  // Translate the disclaimer text using t function and the extracted variables
  const translatedText = t("layerLegend.layers.costFilterDisclaimer", {
    LCOH,
    LCOHUnit,
    region,
    h2Potential,
    h2PotentialUnit,
    expansionFactor,
  });

  // Sanitize the translated text
  const cleanHTML = sanitizeText(translatedText);

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: cleanHTML,
      }}
    ></span>
  );
};

// Declare the component's propTypes
CostFilterDisclaimerContent.propTypes = {
  /**
   * An object containing data for the clicked popup.
   */
  clickPopupInfo: PropTypes.shape({
    data: PropTypes.shape({
      layerId: PropTypes.string,
      costPercentage: PropTypes.number,
      nonLCOEData: PropTypes.shape({
        region: PropTypes.shape({
          location: PropTypes.string,
          unrestricted: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.number,
              unit: PropTypes.string,
            })
          ),
        }),
      }),
    }),
  }),
};

export default CostFilterDisclaimerContent;
