import { Trans } from "react-i18next";
import compassIcon from "../../../assets/compass.svg";
import plusIcon from "../../../assets/plus.svg";
import minusIcon from "../../../assets/minus.svg";
import fullScreenIcon from "../../../assets/full-screen.svg";
import checkboxIcon from "../../../assets/checkboxIcon.PNG";
import infoIcon from "../../../assets/infoIcon.PNG";
import legendBlockIcon from "../../../assets/legendBlockIcon.PNG";
import costfilterIcon from "../../../assets/costfilter.PNG";
import resetIcon from "../../../assets/reset.PNG";
import updateIcon from "../../../assets/update.PNG";
import opacityChangeIcon from "../../../assets/opacityChangeIcon.PNG";
import unitChangeIcon from "../../../assets/unitChangeIcon.PNG";
import translationIcon from "../../../assets/translationIcon.PNG";
import lcohToggler from "../../../assets/lcohToggler.PNG";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";

/**
 * An object representing the locale for the Joyride tutorial feature.
 * @typedef {Object} JoyrideLocale
 * @private
 * @property {React.ReactNode} skip - The skip button text.
 * @property {React.ReactNode} back - The back button text.
 * @property {React.ReactNode} next - The next button text.
 * @property {React.ReactNode} last - The last button text.
 */
const joyrideLocale = {
  skip: <Trans i18nKey="tutorial.skip">Skip</Trans>,
  back: <Trans i18nKey="tutorial.back">Back</Trans>,
  next: <Trans i18nKey="tutorial.next">Next</Trans>,
  last: <Trans i18nKey="tutorial.last">Last</Trans>,
};

/**
 * An array of objects representing the steps for the Joyride tutorial feature.
 * Each object contains the content to be displayed, the locale, the target element,
 * and other options such as title and placement.
 * @typedef {Object[]} JoyrideSteps
 * @private
 * @property {React.ReactNode} content - The content to be displayed for the step.
 * @property {string} locale - The locale for the step.
 * @property {boolean} disableBeacon - Whether to disable the beacon for the step.
 * @property {string} target - The CSS selector for the target element of the step.
 * @property {React.ReactNode} title - The title for the step.
 * @property {string} placement - The placement of the step relative to the target element.
 * @property {boolean} isFixed - Whether the step is fixed in position.
 */

const joyrideSteps = [
  {
    content: (
      <Trans i18nKey="tutorial.appBar.body">
        <div className="step-wrapper"></div>
        <p>
          <b>Layer</b>: To toggle visibility of the layer panel
        </p>
        {/* <p>
              <b>Result</b>: To toggle visibility of the Result panel
            </p> */}
        <p>
          <b>Tutorial</b>: To begin the interactive tour guide of the GUI
        </p>
        <p>
          <b>About</b>: For more information on the details about the GUI
          development, including the datasets used, software, and contributors.
        </p>
        <p>
          <img src={translationIcon} alt="" /> To switch between different
          languages.
        </p>
      </Trans>
    ),
    locale: joyrideLocale,
    disableBeacon: true,
    target: ".toolbar-wrapper",
    title: <Trans i18nKey="tutorial.appBar.title">App Bar</Trans>,
  },

  {
    content: (
      <div className="step-wrapper">
        <Trans i18nKey="tutorial.mapTools.body">
          <p>
            <RotateLeftIcon /> To reset the map viewport to the original
            configuration.
          </p>
          <p>
            <img src={plusIcon} alt="" /> To increment the zoom level by 1.
            Alternatively the mouse wheel can be used.
          </p>
          <p>
            <img src={minusIcon} alt="" /> To decrement the zoom level by 1.
            Alternatively the mouse wheel can be used.
          </p>
          <p>
            <img src={compassIcon} alt="" /> To reset axis to North, when map
            has been rotated.
          </p>
          <p>
            <img src={fullScreenIcon} alt="" /> To transition map to full
            screen.
          </p>
        </Trans>
      </div>
    ),
    locale: joyrideLocale,
    disableBeacon: true,
    placement: "left",
    target: ".map-tools-wrapper",
    title: <Trans i18nKey="tutorial.mapTools.title">Map Control Tools</Trans>,
  },

  {
    content: (
      <div className="step-wrapper">
        <Trans i18nKey="tutorial.drawTools.body">
          <p>
            Draw a shape on the map, firstly clicking on a point on the map,
            then releasing the mouse. For line, and polygon shapes, end your
            drawing by double-clicking.
          </p>
          <p>
            <Icon fontSize="small" className="fas fa-grip-lines-vertical" /> To
            draw a line from one point to another to analyse features and
            measure distance.
          </p>
          <p>
            <Icon className="fas fa-vector-square" /> To draw a rectangle over
            an area to analyse features and measurement.
          </p>
          <p>
            <Icon className="fas fa-draw-polygon" /> To draw a polygon over an
            area to analyse features and measurement.
          </p>
          <p>
            <Icon className="fas fa-edit" /> To edit a drawn shape
          </p>
          <p>
            <Icon className="fas fa-trash-alt" /> To erase a drawn shape.
          </p>
        </Trans>
      </div>
    ),
    locale: joyrideLocale,
    disableBeacon: true,
    placement: "left",
    target: "#draw-tools-wrapper",
    title: <Trans i18nKey="tutorial.drawTools.title">Draw Tools</Trans>,
  },

  {
    content: (
      <div className="step-wrapper">
        <Trans i18nKey="tutorial.layer.body">
          {" "}
          A layer category in the panel may consist of all or any of the
          following:
          <div>
            <img src={checkboxIcon} /> <p>To make visible or hide a layer.</p>
          </div>
          <div>
            <img src={infoIcon} />{" "}
            <p>To view detailed information of a layer.</p>
          </div>
          <div>
            <CameraAltIcon />
            <p>To download screenshot of layers in pdf.</p>
          </div>
          <div>
            {" "}
            <img src={unitChangeIcon} />{" "}
            <p>To select a unit dependent on the unit a layer supports.</p>
          </div>
          <div>
            {" "}
            <img
              src={legendBlockIcon}
              style={{ verticalAlign: "unset" }}
            />{" "}
            <p>
              To display layer data distributed in different ranges, indicated
              by colored icons.
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <img src={opacityChangeIcon} />{" "}
            <p>To adjust the opacity of the layer paint property.</p>
          </div>
          <div style={{ display: "flex" }}>
            <img src={lcohToggler} />{" "}
            <p>
              To switch between different calculated percentages of the proposed
              hydrogen cost in a specified decade.
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <img src={costfilterIcon} />{" "}
            <p>To filter cost parameter for only cost layers.</p>
          </div>
          <div style={{ display: "flex" }}>
            <img src={updateIcon} />{" "}
            <p>To update map popup information after cost filtering.</p>
          </div>
          <div style={{ display: "flex" }}>
            <img src={resetIcon} /> <p>To reset cost filter parameters.</p>
          </div>
        </Trans>
      </div>
    ),
    locale: joyrideLocale,
    disableBeacon: true,
    target: `#layer-legend-wrapper`,
    placement: "left-end",
    isFixed: true,
    title: <Trans i18nKey="tutorial.layer.title">Layer</Trans>,
  },

  {
    content: (
      <div className="step-wrapper">
        <Trans i18nKey="tutorial.mapStyle.body">
          {" "}
          <p>To switch between street and satellite map styles.</p>
        </Trans>
      </div>
    ),
    locale: joyrideLocale,
    disableBeacon: true,
    placement: "top",
    target: "#map-style-switch",
    title: <Trans i18nKey="tutorial.mapStyle.title">Map Style Switch</Trans>,
  },
  {
    content: (
      <div className="step-wrapper">
        <Trans i18nKey="tutorial.geocoder.body">
          <p>
            Specific areas on the map can be filled in and searched for using
            the geocoder search bar. Reverse search is also possible by
            inputting the coordinates in 'lat, long' format. For example,
            inputting "15.7,2.4", where "15.7" is latitude, and "2.4" is
            longitude brings up a valid result.
          </p>
        </Trans>
      </div>
    ),
    locale: joyrideLocale,
    disableBeacon: true,
    placement: "bottom",
    target: ".mapboxgl-ctrl-geocoder--input",
    title: <Trans i18nKey="tutorial.geocoder.title">Geocoder</Trans>,
  },
];

joyrideSteps.propTypes = {
  /**
   * The content to be displayed for the step.
   */
  content: PropTypes.node,
  /**
   * The locale for the step.
   */
  locale: PropTypes.string,
  /**
   * Whether to disable the beacon for the step.
   */
  disableBeacon: PropTypes.bool,
  /**
   * The CSS selector for the target element of the step.
   */
  target: PropTypes.string,
  /**
   * The title for the step.
   */
  title: PropTypes.node,
  /**
   * The placement of the step relative to the target element.
   */
  placement: PropTypes.string,
  /**
   * Whether the step is fixed in position.
   */
  isFixed: PropTypes.bool,
};

export default joyrideSteps;
