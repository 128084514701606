import makeStyles from "@mui/styles/makeStyles";
import React, { useCallback, useRef } from "react";
import Geocoder from "react-map-gl-geocoder";
import { useTranslation } from "react-i18next";
import "./Geolocate.css";
import style from "./MUIStyle";

const useStyles = makeStyles(style);

import PropTypes from "prop-types";

/**
 * A component that allows the user to geolocate on a map.
 *
 * @component
 * @category Native
 * @subcategory Geolocate
 * @param {Object} props - The component props.
 * @param {Object} props.mapRef - The reference to the map component.
 * @param {Function} props.onViewportChange - The function to call when the viewport changes.
 * @returns {JSX.Element} - The component's markup.
 */
const Geolocate = ({ mapRef, onViewportChange }) => {
  const { t } = useTranslation();
  /**
   * Handles the geocoder viewport change.
   *
   * @param {Object} newViewport - The new viewport.
   * @returns {Object} - The updated viewport.
   */
  const handleGeocoderViewportChange = useCallback((newViewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return onViewportChange({
      ...newViewport,
      ...geocoderDefaultOverrides,
    });
  }, []);

  const geocoderContainerRef = useRef();
  const classes = useStyles();

  return (
    <div>
      <div ref={geocoderContainerRef} className={classes.geolocateWrapper} />

      <Geocoder
        containerRef={geocoderContainerRef}
        mapRef={mapRef}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        placeholder={t("geolocate.placeholder")}
        onViewportChange={handleGeocoderViewportChange}
        zoom={10}
        reverseGeocode={true}
        marker={true}
        clearOnBlur={true}
        clearAndBlurOnEsc={true}
      />
    </div>
  );
};

Geolocate.propTypes = {
  mapRef: PropTypes.object,
  onViewportChange: PropTypes.func,
  t: PropTypes.func,
  classes: PropTypes.object,
};

export default Geolocate;
