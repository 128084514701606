import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppContext } from "../../../contexts/AppContext";
import NationalFactsSheet from "./NationalFactsSheet/NationalFactsSheet";
import { useTranslation } from "react-i18next";
import ShapeDetails from "../Draw/ShapeDetails";
import style from "./MUIStyle";
import PropTypes from "prop-types";

const useStyles = makeStyles(style);

//component

/**
 * ResultsPanel component.
 * @category Native
 * @subcategory ResultsPanel
 * @component
 * @param {Object} resultPanelParams - The result panel parameters.
 * @param {Function} setHoverInfo - The function to set the hover information.
 * @returns {JSX.Element} - The ResultsPanel component.
 */
const ResultsPanel = ({ resultPanelParams, setHoverInfo }) => {
  /**
   * The context of the app.
   * @type {Object}
   */
  const { toggleResultPanel, setToggleResultPanel, drawInfoDetails } =
    useContext(AppContext);

  /**
   * The translation function.
   * @type {Function}
   */
  const { t } = useTranslation();

  /**
   * The function to handle change in section.
   * @param {string} panel - The panel to change.
   * @returns {Function} - The function to handle change in section.
   */
  const handleChangeSection = (panel) => (event, isExpanded) => {
    setToggleResultPanel({
      ...toggleResultPanel,
      accordionOpen: isExpanded,
      panel: panel,
    });
  };

  /**
   * The function to handle toggle result panel.
   * @returns {undefined}
   */
  const handleToggleResultPanel = () => {
    setToggleResultPanel((prev) => ({
      ...prev,
      resultPanelOpen: !prev.resultPanelOpen,
    }));
  };

  /**
   * The styles for the component.
   * @type {Object}
   */
  const classes = useStyles();

  return (
    <Slide
      in={toggleResultPanel?.resultPanelOpen}
      direction="right"
      mountOnEnter
      unmountOnExit
    >
      <Container
        className={classes.container}
        onMouseEnter={() => setHoverInfo(null)}
      >
        <IconButton
          size="small"
          aria-label="close"
          className={classes.closeButton}
          onClick={handleToggleResultPanel}
        >
          <CloseIcon />
        </IconButton>
        {/* <NationalFactsSheet resultPanelParams={resultPanelParams} /> */}

        <Accordion
          sx={{
            "&.Mui-expanded": {
              height: "85%",
              overflow: "auto",
              "@media (width: 1280px) and (height: 600px)": {
                height: "75%",
              },
            },
          }}
          expanded={
            toggleResultPanel?.panel === "nationalFactsheet" &&
            toggleResultPanel?.accordionOpen
          }
          onChange={handleChangeSection("nationalFactsheet")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>
              {t("layerLegend.layers.group.National Fact Sheet")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!resultPanelParams ? (
              <Typography> {t("resultPanel.noResult")}</Typography>
            ) : (
              <NationalFactsSheet resultPanelParams={resultPanelParams} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={
            toggleResultPanel?.panel === "drawInfo" &&
            toggleResultPanel?.accordionOpen
          }
          onChange={handleChangeSection("drawInfo")}
          sx={{
            "&.Mui-expanded": {
              height: "75%",
              overflow: "auto",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography> {t(`drawMenu.drawInfo`)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!drawInfoDetails || !drawInfoDetails.length ? (
              <Typography>{t("resultPanel.addInfo")}</Typography>
            ) : (
              <ShapeDetails />
            )}
          </AccordionDetails>
        </Accordion>
      </Container>
    </Slide>
  );
};

ResultsPanel.propTypes = {
  /**
   * The function to set the hover information.
   */
  setHoverInfo: PropTypes.func,
  /**
   * The result panel parameters.
   * @type {Object}
   *
   */
  resultPanelParams: PropTypes.object,
};

export default ResultsPanel;
