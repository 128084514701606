import { Box, Typography } from "@mui/material";
import { ScaleControl } from "react-map-gl";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

/**
 * Renders the MapAttachments component.
 * @component
 * @category Native
 * @subcategory MapAttachments
 * @description A component that renders a map with coordinates and a scale viewer.
 * @returns {JSX.Element} - The rendered component.
 */
const MapAttachments = ({ showCoordinates }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  /**
   * Renders the coordinates of the map.
   * @param {Object} showCoordinates - An object containing the coordinates to be displayed.
   * @returns {JSX.Element} - The rendered coordinates.
   */
  const renderCoordinates = (showCoordinates) => {
    return (
      <Box
        data-testid="map-coordinate-viewer"
        style={{
          position: "absolute",
          bottom: "60px",
          left: 0,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {Object.keys(showCoordinates).map((v, index) => (
          <Typography
            style={{ padding: "0.1rem" }}
            display="inline-block"
            fontSize="0.8rem"
            key={index}
            fontFamily="inherit"
          >
            <strong>
              <em>{t(`others.${v.toLowerCase()}`)}</em>
            </strong>
            {"   "}
            {showCoordinates[v]}
          </Typography>
        ))}
      </Box>
    );
  };

  if (!showCoordinates) return null;

  return (
    <Box>
      <Box
        data-testid="map-scale-viewer"
        style={{
          position: "absolute",
          bottom: "52px",
          left: 0,
        }}
      >
        <ScaleControl />
      </Box>
      {renderCoordinates(showCoordinates)}
    </Box>
  );
};

MapAttachments.propTypes = {
  /**
   * An object containing the coordinates to be displayed.
   */
  showCoordinates: PropTypes.object,
};

export default MapAttachments;
