import React from "react";
import CookiesBanner from "./Cookies/CookiesBanner";
import Tutorial from "./Tutorial/Tutorial";
import Welcome from "./Welcome/Welcome";
import Footer from "./Footer/Footer";
import { UIProvider } from "../../contexts/UIContext";
import Navbar from "./Navbar/Navbar";
import Refresh from "./Refresh/Refresh";

/**
 * UI component that renders the user interface of the application.
 * @category Native
 * @subcategory UI
 * @component
 * @returns {JSX.Element} The UI component.
 */
const UI = () => {
  return (
    <div>
      <UIProvider>
        <Refresh />
        <Navbar />
        <CookiesBanner />
        <Tutorial />
        <Welcome />
        <div className="footer">
          <Footer />
        </div>
      </UIProvider>
    </div>
  );
};

export default UI;
