import chroma from "chroma-js";
import currencyAndUnitConverter from "../../utils/currencyAndUnitConverter";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";

/**
 * @category Hooks
 * @component
 * Returns an object containing color, legend, and filter properties based on the provided layer object.
 * @param {Object} layer - The layer object containing various properties such as colorScheme, legendIntervalFactor, fieldAttribute, etc.
 * @returns {Object} - An object containing color, legend, and filter properties.
 */
const usePaintLegendConfig = (layer) => {
  /**
   * gets color scale
   * @param {string} colorScheme
   * @param {[number]} colorDomain
   * @returns
   */
  const getChroma = (colorScheme, colorDomain) =>
    chroma.scale(colorScheme).mode("lab").domain(colorDomain);

  /**
   * gets arithmetic sequence generated from min and max values
   * @param {number} min
   * @param {number} max
   * @param {number} factor
   * @returns {[number]}
   */
  function arithmeticSequence(min, max, factor) {
    const length = Math.ceil((max - min) / factor) + 1;
    const sequence = [];
    for (let i = 0; i < length; i++) {
      sequence.push(min + i * factor);
    }
    return sequence;
  }

  /**
   * maps values to strings to create string based legends for some layers
   * @param {[number]} sequence
   * @param { {low:string, medium?: string, high:string}} labels
   * @param {string} mappingStrategy
   * @returns {[string]}
   */
  function mapValuesToStrings(sequence, labels, mappingStrategy) {
    const middleIndex = Math.floor(sequence.length / 2);
    let mappedStrings = [];
    if (mappingStrategy === "complete") {
      mappedStrings = sequence.map((i) => labels[i]);
    } else {
      sequence.forEach((value, index) => {
        if (index === 0) {
          mappedStrings.push(labels.low);
        } else if (index === sequence.length - 1) {
          mappedStrings.push(labels.high);
        } else if (index === middleIndex) {
          mappedStrings.push(labels.medium);
        }
      });
    }

    return mappedStrings;
  }

  const { convertedLayerUnits } = useContext(AppContext);
  if (!layer) return;

  let {
    colorScheme,
    legendIntervalFactor,
    fieldAttribute,
    fieldAttribute_25,
    fieldAttribute_50,
    fieldAttribute_100,
    costYear,
    layerId,
    format,
    type,
    legendLabels,
    legendIntervals,
    hydropowerType,
    mappingStrategy,
    legendMaxValue,
    costValue,
    legendInfo,
  } = layer;

  let selectedColorScheme;
  let colorValueSequence;
  let legendValues;
  let filter;

  const unitConverted = convertedLayerUnits[layerId];

  const selectedAttribute =
    layer.costPercentage === "100%"
      ? fieldAttribute_100
      : layer.costPercentage === "50%"
      ? fieldAttribute_50
      : layer.costPercentage === "25%"
      ? fieldAttribute_25
      : fieldAttribute;

  if (costYear) {
    const newInterval = [...legendIntervals];
    selectedColorScheme = getChroma(colorScheme, newInterval.reverse());
  } else {
    selectedColorScheme = getChroma(colorScheme, legendIntervals);
  }

  const [min, max] = legendIntervals;

  if (format.includes("raster")) {
    colorValueSequence = legendIntervals
      .map((i) => [i, selectedColorScheme(i).hex()])
      .flat();
  } else {
    colorValueSequence = arithmeticSequence(min, max, legendIntervalFactor)
      .map((i) => [i, selectedColorScheme(i).hex()])
      .flat();
  }

  const legendColors = colorValueSequence.filter((v, index) => index % 2);

  if (legendLabels) {
    const valueSequence = colorValueSequence.filter(
      (v, index) => index % 2 === 0
    );
    legendValues = mapValuesToStrings(
      valueSequence,
      legendLabels,
      mappingStrategy
    );
  } else if (layerId === "h2potabs") {
    legendValues = colorValueSequence
      .map((v) => v / 1000)
      .filter((v, index) => index % 2 === 0);
  } else if (type.includes("lcoe")) {
    legendValues = colorValueSequence
      .map((v) => v * 100)
      .filter((v, index) => index % 2 === 0);
  } else {
    legendValues = colorValueSequence.filter((v, index) => index % 2 === 0);
  }

  // coalesce costvalue to Infinty when it is a string, to be used in mapbox expression
  let coalsceCostValue =
    costValue === ""
      ? Infinity
      : parseFloat(
          currencyAndUnitConverter.convert(
            unitConverted,
            legendInfo.unit,
            costValue
          )
        );

  const color =
    layerId === "h2potgrwaperc"
      ? [
          "case",
          ["==", ["get", selectedAttribute], 0],
          "#800000",
          [
            "interpolate",
            ["linear"],
            ["get", selectedAttribute],
            ...colorValueSequence,
          ],
        ]
      : type.includes("lcoh")
      ? [
          "case",
          ["==", ["get", selectedAttribute], null],
          "#808080",

          [
            "all",
            ["has", fieldAttribute],
            ["<=", coalsceCostValue, ["get", fieldAttribute]],
          ],
          "#7a7a7a",

          [
            "interpolate",
            ["linear"],
            ["get", selectedAttribute],
            ...colorValueSequence,
          ],
        ]
      : [
          "case",
          ["==", ["get", selectedAttribute], null],
          "#808080",
          [
            "interpolate",
            ["linear"],
            ["get", selectedAttribute],
            ...colorValueSequence,
          ],
        ];

  const legend = {
    colors: legendColors,
    values: legendValues,
    maxValueStatus: legendMaxValue,
  };

  if (layerId.includes("hydro")) {
    filter =
      hydropowerType === "all"
        ? ["has", selectedAttribute]
        : ["==", ["get", "type"], hydropowerType];
  } else {
    filter = ["has", selectedAttribute];
  }

  return {
    color,
    legend,
    filter,
  };
};

export default usePaintLegendConfig;
