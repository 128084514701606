import { axiosInstance } from "../utils/axiosConfig";

/**
 * Retrieves renewable energy quantities based on the provided parameters.
 * @async
 * @category Services
 * @param {Object} options - The options object.
 * @param {string} options.location - The location to retrieve renewable energy quantities for.
 * @param {string} options.sourceId - The source ID to retrieve renewable energy quantities for.
 * @param {string} options.mainAttribute - The main attribute.
 * @param {string} options.suppAttribute - The supplementary attribute.
 * @param {string} options.fieldAttribute - The field attribute.
 * @param {number} options.inputValue - The input value.
 * @param {number} options.fid - The feature ID.
 * @returns {Promise<Object>} - The response data.
 */
const getRenewableEnergyQuantities = async ({
  location,
  sourceId,
  mainAttribute,
  suppAttribute,
  fieldAttribute,
  inputValue,
  fid,
}) => {
  const response = await axiosInstance.get(
    `/quantities/renewable-energy?location=${location}&sourceId=${sourceId}&mainAttribute=${mainAttribute}&suppAttribute=${suppAttribute}&lcoeAttribute=${fieldAttribute}&inputValue=${inputValue}&fid=${fid}`
  );

  return response.data;
};

/**
 * Retrieves raster quantities based on the provided parameters.
 * @async
 * @category Services
 * @param {Object} options - The options object.
 * @param {string} options.location - The location to retrieve raster quantities for.
 * @param {string} options.sourceId - The source ID to retrieve raster quantities for.
 * @param {string} options.layerId - The layer ID.
 * @returns {Promise<Object>} - The response data.
 */
const getRasterQuantities = async ({ location, sourceId, layerId }) => {
  const response = await axiosInstance.get(
    `/quantities/rasters?location=${location}&sourceId=${sourceId}&layerId=${layerId}`
  );

  return response.data;
};

/**
 * Retrieves hydrogen quantities based on the provided parameters.
 * @async
 * @category Services
 * @param {Object} options - The options object.
 * @param {string} options.location - The location to retrieve hydrogen quantities for.
 * @param {string} options.sourceId - The source ID to retrieve hydrogen quantities for.
 * @param {string} options.regionalH2QuantityAttribute - The regional hydrogen quantity attribute.
 * @param {string} options.nationalH2QuantityAttribute - The national hydrogen quantity attribute.
 * @param {number} options.inputValue - The input value.
 * @param {number} options.costYear - The cost year.
 * @param {string} options.costPercentage - The cost percentage.
 * @param {string} options.layerId - The layer ID.
 * @returns {Promise<Object>} - The response data.
 */
const getHydrogenQuantities = async ({
  location,
  sourceId,
  regionalH2QuantityAttribute,
  nationalH2QuantityAttribute,
  inputValue,
  costYear,
  costPercentage,
  layerId,
}) => {
  /**
   * Removes the percentage sign from the costPercentage string.
   * @type {string}
   */
  costPercentage =
    costPercentage && costPercentage.slice(0, costPercentage.lastIndexOf("%"));

  const response = await axiosInstance.get(
    `/quantities/hydrogen?location=${location}&layerId=${layerId}&sourceId=${sourceId}&regionalH2QuantityAttribute=${regionalH2QuantityAttribute}&nationalH2QuantityAttribute=${nationalH2QuantityAttribute}&costYear=${costYear}&costPercentage=${costPercentage}&inputValue=${inputValue}`
  );

  return response.data;
};

/**
 * Retrieves remote national data based on the provided parameters.
 * @async
 * @category Services
 * @param {string} location - The location to retrieve remote national data for.
 * @param {string} pathRoute - The path route.
 * @returns {Promise<Object>} - The response data.
 */
const getRemoteNationalData = async (location, pathRoute) => {
  /**
   * Removes any non-alphanumeric characters from the pathRoute string.
   * @type {string}
   */
  const region = pathRoute.replace(/[^\w\s]/gi, "");

  const response = await axiosInstance.get(
    `/factsheet/remote-data?location=${location}&region=${region}`
  );

  return response.data;
};

/**
 * Retrieves the reCAPTCHA response based on the provided token.
 * @async
 * @category Services
 * @param {string} token - The reCAPTCHA token.
 * @returns {Promise<Object>} - The response data.
 */
const getRecaptchaResponse = async (token) => {
  const response = await axiosInstance.post("/recaptcha", {
    token,
  });

  return response.data;
};

export {
  getHydrogenQuantities,
  getRasterQuantities,
  getRecaptchaResponse,
  getRemoteNationalData,
  getRenewableEnergyQuantities,
};
