import React, { useContext, useEffect } from "react";

import makeStyles from "@mui/styles/makeStyles";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { UIContext } from "../../../contexts/UIContext";
import CookiesPolicy from "./CookiesPolicy";
import { createCookie } from "../../../utils/cookieCreator";
import style from "./MUIStyle";

const useStyles = makeStyles(style);

/**
 * A component that displays a banner for cookies consent and handles cookies acceptance and decline.
 * @category Native
 * @subcategory Cookies
 * @component
 * @returns {JSX.Element} The CookiesBanner component.
 */
const CookiesBanner = () => {
  const { t } = useTranslation();
  const {
    openCookieBanner,
    setOpenCookieBanner,
    cookieConsent,
    setCookieConsent,
    setOpenCookieDialog,
    openWelcomeDialog,
    setOpenWelcomeDialog,
    width,
  } = useContext(UIContext);

  const classes = useStyles();

  /**
   * useEffect to implement local cookies
   */
  useEffect(() => {
    let cookies = document.cookie;
    const cookiesArray = cookies.split(";");
    if (
      cookiesArray.find((el) => el.includes("h2_atlas_user")) ||
      width <= 600
    ) {
      setOpenWelcomeDialog(false);
    } else {
      setOpenWelcomeDialog(true);
      createCookie("h2_atlas_user", "true", 150);
    }
  }, [openWelcomeDialog]);

  /**
   * useEffect to implement google analytics
   */
  useEffect(() => {
    let cookies = document.cookie;
    const cookiesArray = cookies.split(";");
    if (
      (cookiesArray.find((el) => el.includes("_gid")) ||
        cookieConsent === "accepted") &&
      document.location.hostname.search("africa.h2atlas.de") !== -1
    ) {
      ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "H2 Atlas Africa",
      });
      // ReactGA.pageview(window.location.pathname + window.location.search);
      setOpenCookieBanner(false);
    }
    if (cookiesArray.find((el) => el.includes("_ga_info"))) {
      setOpenCookieBanner(false);
      return;
    }
    if (cookieConsent === "declined") {
      createCookie("_ga_info", "false", 90);
      return;
    }
  }, [cookieConsent]);

  /**
   * Function to handle cookies acceptance consent
   */
  const handleCookiesAcceptance = () => {
    setCookieConsent("accepted");
    setOpenCookieBanner(false);
  };

  /**
   * Function to handle cookies decline consent
   */
  const handleCookiesDecline = () => {
    setCookieConsent("declined");
    setOpenCookieBanner(false);
  };

  return (
    <div>
      <Snackbar
        open={openCookieBanner}
        className={classes.snackbar}
        message={t("cookiesBanner.body")}
        action={[
          <Button
            size="small"
            color="primary"
            key={1}
            variant="contained"
            onClick={handleCookiesAcceptance}
            classes={{
              root: classes.buttonRoot,
              containedSizeSmall: classes.buttonSize,
            }}
          >
            {t("cookiesBanner.accept")}
          </Button>,
          <Button
            size="small"
            color="primary"
            key={2}
            variant="contained"
            onClick={handleCookiesDecline}
            classes={{
              root: classes.buttonRoot,
              containedSizeSmall: classes.buttonSize,
            }}
          >
            {t("cookiesBanner.decline")}
          </Button>,
          <Button
            size="small"
            color="primary"
            key={3}
            variant="contained"
            onClick={() => setOpenCookieDialog(true)}
            classes={{
              root: classes.buttonRoot,
              containedSizeSmall: classes.buttonSize,
            }}
          >
            {t("cookiesBanner.view")}
          </Button>,
        ]}
      />
      <CookiesPolicy />
    </div>
  );
};

export default CookiesBanner;
