/**
 * This file contains the style for the About component using Material-UI.
 * @name DisclaimerMUIStyle
 * @category MUI
 * @returns {Object} The style object.
 */
const style = (theme) => ({
  disclaimerDialogBox: {
    "& .MuiDialogContentText-root": {
      margin: 0,
    },
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.paper,
    },
  },
});

export default style;
