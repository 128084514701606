import { useContext, useEffect, useLayoutEffect } from "react";
import { UIContext } from "../../contexts/UIContext";

/**
 * Custom hook that manages the welcome dialog state and handles the creation of a tutorial cookie.
 * @category Hooks
 * @component
 * @param {Object} options - An object containing options for the hook.
 * @param {Function} options.createCookie - A function that creates a cookie.
 * @returns {Array} An array containing the hook's states and handlers.
 */
const useWelcome = ({ createCookie }) => {
  const { openWelcomeDialog, setOpenWelcomeDialog, width } =
    useContext(UIContext);

  /**
   * Effect that runs when the component mounts and checks for the tutorial cookie or small screen size.
   */
  useEffect(() => {
    let cookies = document.cookie;
    const cookiesArray = cookies.split(";");
    const tutorialCookie = cookiesArray.find((el) => el.includes("tutorial"));
    if (tutorialCookie || width <= 600) {
      setOpenWelcomeDialog(false);
    } else {
      setOpenWelcomeDialog(true);
      // setRunGuide(true);
    }
  }, [openWelcomeDialog]);

  /**
   * Layout effect that runs when the component mounts and checks for small screen size.
   */
  useLayoutEffect(() => {
    if (width <= 600) {
      setOpenWelcomeDialog(false);
    }
  }, [openWelcomeDialog]);

  /**
   * Handler function that closes the welcome dialog and creates a tutorial cookie.
   */
  const handleWelcomeDialog = () => {
    setOpenWelcomeDialog(false);
    createCookie("tutorial", "false", 150);
  };

  const states = {};
  const handlers = { handleWelcomeDialog };
  return [states, handlers];
};

export default useWelcome;
