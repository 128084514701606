import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
  FormControlLabel,
  Box,
  RadioGroup,
  FormLabel,
  Radio,
  Stack,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { axiosInstance } from "../../../utils/axiosConfig";
import { getRecaptchaResponse } from "../../../service/data";
import UploadWidget from "./UploadWidget";
import {
  ASIGNEES_CATEGORIES,
  FEEDBACK_CATEGORIES,
} from "../../../constants/constants";
import PropTypes from "prop-types";

/**
 * A Yup validation schema for the feedback form
 * @private
 */
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  category: Yup.string().required("Feedback category is required"),
  assignees: Yup.string().required("Assignees are required"),
  title: Yup.string().required("Feedback title is required"),
  description: Yup.string().required("Feedback description is required"),
});

/**
 * A feedback form component that allows users to submit feedback
 * @component
 * @category Native
 * @subcategory FeedbackForm
 * @param {object} props - The props object
 * @param {boolean} props.openFeedbackDialog - Determines if the feedback dialog is open
 * @param {function} props.handleCloseFeedbackDialog - A function to close the feedback dialog
 * @param {string} [props.name] - The name of the component (optional)
 * @returns {JSX.Element} - The FeedbackForm component
 */
const FeedbackForm = ({ openFeedbackDialog, handleCloseFeedbackDialog }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [uploadError, setUploadError] = useState();
  const [captchaValue, setCaptchaValue] = useState("");
  const captchaRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      affiliation: "",
      title: "",
      category: "",
      assignees: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const {
        name,
        email,
        category,
        affiliation,
        description,
        title,
        assignees,
      } = values;
      const fomattedDescription =
        "Name: " +
        name +
        "\n" +
        "\n" +
        "Email: " +
        email +
        "\n" +
        "\n" +
        description +
        "\n" +
        "\n" +
        imageUrl.join("\n");
      const getAssigneedIds = ASIGNEES_CATEGORIES.find(
        (assignee) => assignee.category === assignees
      ).ids;

      setSubmitting(true);
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      try {
        await getRecaptchaResponse(token);
        await axiosInstance.post(
          "https://jugit.fz-juelich.de/api/v4/projects/3028/issues",
          {
            description: fomattedDescription,
            title: `${title} - (${affiliation})`,
            labels: category,
            assignee_ids: getAssigneedIds,
          },
          {
            headers: {
              Authorization:
                "Bearer " + process.env.REACT_APP_BEARER_ACCESS_TOKEN,
            },
          }
        );
        setSubmitting(false);
        setSubmitted(true);
        setCaptchaValue("");
        resetForm();
      } catch (error) {
        console.log(error);
        setSubmitted(false);
        setSubmitting(false);
      }
    },
  });

  const handleClickOK = () => {
    setSubmitted(null);
    handleCloseFeedbackDialog();
  };

  /**
   * A function that handles the upload of images
   * @param {object} error - The error object
   * @param {object} result - The result object
   * @param {object} widget - The widget object
   */
  const handleOnUpload = (error, result, widget) => {
    if (uploadError) {
      setUploadError(uploadError);
      widget.close({
        quiet: true,
      });
      return;
    }
    const gitlabImageString = `![image](${result?.info?.secure_url})`;
    setImageUrl((lastImage) => [...lastImage, gitlabImageString]);
  };

  return (
    <Dialog open={openFeedbackDialog} onClose={handleCloseFeedbackDialog}>
      <DialogContent
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "75vh",
        }}
      >
        {submitted === null ? (
          <>
            <form
              style={{
                filter: isSubmitting ? "blur(1px)" : "unset",
                position: "relative",
                overflowY: "auto",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
              onSubmit={formik.handleSubmit}
            >
              <Typography variant="h6" gutterBottom>
                Feedback Form
              </Typography>
              <TextField
                name="name"
                label="Name"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                margin="normal"
              />
              <TextField
                name="email"
                label="Email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
              />
              <FormControl fullWidth>
                <FormLabel>Affiliation</FormLabel>
                <RadioGroup
                  row
                  name="affiliation"
                  value={formik.values.affiliation}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="WASSCAL"
                    control={<Radio />}
                    label="WASSCAL"
                  />
                  <FormControlLabel
                    value="SASSCAL"
                    control={<Radio />}
                    label="SASSCAL"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
                <TextField
                  select
                  label="Feedback Category"
                  name="category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  margin="normal"
                >
                  {FEEDBACK_CATEGORIES.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </TextField>
                {formik.touched.category && formik.errors.category && (
                  <Typography align="left" variant="caption" color="error">
                    {formik.errors.category}
                  </Typography>
                )}
                <TextField
                  select
                  label="Assign to"
                  name="assignees"
                  value={formik.values.assignees}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.assignees && Boolean(formik.errors.assignees)
                  }
                  margin="normal"
                >
                  {ASIGNEES_CATEGORIES.map((category) => (
                    <MenuItem key={category.category} value={category.category}>
                      {category.category}
                    </MenuItem>
                  ))}
                </TextField>
                {formik.touched.assignees && formik.errors.assignees && (
                  <Typography align="left" variant="caption" color="error">
                    {formik.errors.assignees}
                  </Typography>
                )}
              </FormControl>

              <TextField
                name="title"
                label="title"
                fullWidth
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                margin="normal"
              />

              <TextField
                name="description"
                label="Feedback description"
                fullWidth
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                margin="normal"
              />
              <Stack py={2} width="50%">
                <UploadWidget onUpload={handleOnUpload}>
                  {({ open }) => {
                    return (
                      <Button onClick={open} variant="contained">
                        Attach Image
                      </Button>
                    );
                  }}
                </UploadWidget>
                {uploadError && <p>Error: {uploadError}</p>}

                <Typography variant="caption" color="success">
                  {imageUrl.length > 0 &&
                    `${imageUrl.length} image(s) attached`}
                </Typography>
              </Stack>

              <Box my={2}>
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(value) => setCaptchaValue(value)}
                />
              </Box>

              <Stack alignItems="center">
                <Button
                  disabled={!captchaValue}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Stack>
            </form>
            {/* Loading Screen */}
            {isSubmitting && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress size={80} />
              </Box>
            )}
          </>
        ) : submitted === false ? (
          <Stack alignItems="center" gap={2}>
            <Box>
              <ErrorIcon sx={{ fontSize: 50, color: "red" }} />
            </Box>
            <Typography variant="h5">An error occurred!</Typography>
            <Typography color="secondary">
              Your report was not submitted. Please try again!
            </Typography>
            <Button
              onClick={() => setSubmitted(null)}
              color="primary"
              variant="contained"
              autoFocus
            >
              OK
            </Button>
          </Stack>
        ) : (
          <Stack alignItems="center" gap={2}>
            <Box>
              <CheckCircleIcon sx={{ fontSize: 50, color: "green" }} />
            </Box>
            <Typography variant="h5">Thank you!</Typography>
            <Typography color="secondary">
              Your report has been submitted successfully
            </Typography>
            <Button
              onClick={handleClickOK}
              color="primary"
              variant="contained"
              autoFocus
            >
              OK
            </Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

FeedbackForm.propTypes = {
  /**
   * Determines if the feedback dialog is open
   */
  openFeedbackDialog: PropTypes.bool,
  /**
   * A function to close the feedback dialog
   */
  handleCloseFeedbackDialog: PropTypes.func,
};

export default FeedbackForm;
