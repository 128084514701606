import axios from "axios";

const REACT_APP_PRODUCTION_URL = "https://africa.h2atlas.de/api";
const REACT_APP_STAGING_URL = "http://ecowas-staging.h2atlas.de:5555";
const REACT_APP_DEVELOPMENT_URL = "http://localhost:5555";

export const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === "staging"
      ? REACT_APP_STAGING_URL
      : process.env.REACT_APP_ENV === "development"
      ? REACT_APP_DEVELOPMENT_URL
      : REACT_APP_PRODUCTION_URL,
});

// export const geoserverUrl = (url, geoserverId) =>
//   `${url}/geoserver/gwc/service/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=h2-atlas:${geoserverId}&tiled=true`;
