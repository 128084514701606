import { useEffect, useState } from "react";
import { roundToDecimal } from "../../../utils/roundToDecimal";

/**
 * Custom React hook that returns an object containing renewable energy facts
 * based on the provided `remoteNationalData` object.
 * @category Hooks
 * @component
 * @param {Object} options - Options object.
 * @param {Object} options.remoteNationalData - Object containing remote national data.
 * @returns {Object} Object containing renewable energy facts.
 */
const useRenewablesFacts = ({ remoteNationalData }) => {
  /**
   * State hook to store renewable energy facts.
   */
  const [renewableFacts, setRenewableFacts] = useState(null);

  /**
   * Function that returns an object containing renewable energy facts.
   *
   * @returns {Object} Object containing renewable energy facts.
   */
  const getRenewableFacts = () => ({
    heading: "Renewable energy",
    main: [
      {
        layer: "Wind Onshore",
        info: [
          {
            title: "Minimum cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.windOnshore.minLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.windOnshore.minLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Average cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.windOnshore.avgLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.windOnshore.avgLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Maximum cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.windOnshore.maxLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.windOnshore.maxLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Total renewable energy potential",
            value_2020: `${roundToDecimal(
              remoteNationalData.windOnshore.potential,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.windOnshore.potential,
              2
            )}`,
            unit: "TWh/yr",
            annotation: "*",
          },
        ],
      },
      {
        layer: "Open-field PV",
        info: [
          {
            title: "Minimum cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.pv.minLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.pv.minLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Average cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.pv.avgLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.pv.avgLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Maximum cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.pv.maxLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.pv.maxLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Total renewable energy potential",
            value_2020: `${roundToDecimal(remoteNationalData.pv.potential, 2)}`,
            value_2050: `${roundToDecimal(remoteNationalData.pv.potential, 2)}`,
            unit: "TWh/yr",
            annotation: "*",
          },
        ],
      },
      {
        layer: "Hydropower",
        info: [
          {
            title: "Minimum cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.hydropower.minLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.hydropower.minLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Average cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.hydropower.avgLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.hydropower.avgLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Maximum cost of electricity",
            value_2020: `${roundToDecimal(
              remoteNationalData.hydropower.maxLCOE2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.hydropower.maxLCOE2050,
              2
            )}`,
            unit: "€ct/kWh",
            annotation: "",
          },
          {
            title: "Total renewable energy potential",
            value_2020: `${roundToDecimal(
              remoteNationalData.hydropower.potential_2020,
              2
            )}`,
            value_2050: `${roundToDecimal(
              remoteNationalData.hydropower.potential_2050,
              2
            )}`,
            unit: "TWh/yr",
            annotation: "*",
          },
        ],
      },
    ],

    footnotes: [
      {
        label: "renFirst",
        noteAnnotation: "*",
      },
      {
        label: "costNote",
      },
      {
        label: "lcohLcoeDefinitionMin",
      },
      {
        label: "lcohLcoeDefinitionAve",
      },
      {
        label: "lcohLcoeDefinitionMax",
      },
    ],
  });

  useEffect(() => {
    if (!remoteNationalData) return;
    setRenewableFacts(getRenewableFacts());
  }, [remoteNationalData]);

  return { renewableFacts };
};

export default useRenewablesFacts;
