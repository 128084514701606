import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { getRemoteNationalData } from "../../../service/data";
import { useTranslation } from "react-i18next";

/**
 * Custom hook to fetch remote national data and handle loading and error states
 * @category Hooks
 * @component
 * @param {Object} resultPanelParams - Object containing regionId and regionName
 * @returns {[Object, Object]} - Array containing state and handlers objects
 */
const useRemoteNationalData = ({ resultPanelParams }) => {
  const { pathLocation } = useContext(AppContext);
  const [remoteNationalData, setRemoteNationalData] = useState(null);
  const [factsheetPanelLoading, setFactsheetPanelLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  /**
   * Function to handle fetching remote national data and updating state
   */
  const handleRemoteNationalData = async () => {
    setRemoteNationalData(null);
    if (resultPanelParams) {
      setFactsheetPanelLoading(true);
      try {
        const nationalData = await getRemoteNationalData(
          resultPanelParams.regionId,
          pathLocation
        );
        setRemoteNationalData(nationalData);
        setFactsheetPanelLoading(false);
        setErrorMessage("");
      } catch (error) {
        setFactsheetPanelLoading(false);
        setErrorMessage(t("error.errorMessage"));
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleRemoteNationalData();
  }, [resultPanelParams.regionId]);

  const state = {
    remoteNationalData,
    regionId: resultPanelParams && resultPanelParams.regionId,
    regionName: resultPanelParams && resultPanelParams.regionName,
    errorMessage,
    factsheetPanelLoading,
  };
  const handlers = { handleRemoteNationalData };

  return [state, handlers];
};

export default useRemoteNationalData;
