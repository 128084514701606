import React, { useCallback, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

import "./MapStyle.css";
import { fetchNewStyle } from "../../../service/mapbox";
import { MAPBOX_STYLES } from "../../../constants/constants";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const useStyles = makeStyles((theme) => {
  return {
    toggleGroup: {
      position: "absolute",
      zIndex: 100,
      bottom: "0",
      left: "50%",
      backgroundColor: theme.palette.background.paper,
      cursor: "default",
      transform: "translate(-50%, 0)",
      "& .MuiToggleButton-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
    },
    toggleButton: {
      [theme.breakpoints.down("md")]: {
        fontSize: "8px",
      },
      padding: "2px",
      fontSize: "10px",
      color: "#000000",
      cursor: "default",
    },
  };
});

import PropTypes from "prop-types";

/**
 * A component that allows users to switch between different map styles
 * @component
 * @category Native
 * @subcategory MapStyle
 * @param {Object} props - The component props
 * @param {Object} props.map - The map object
 * @param {Function} props.loadAndAddSprites - A function to load and add sprites
 * @param {Function} props.setHoverInfo - A function to set hover information
 * @returns {JSX.Element} - Rendered component
 */
const MapStyle = ({ map, loadAndAddSprites, setHoverInfo }) => {
  const { t } = useTranslation();
  /**
   * The current alignment state
   * @type {string}
   */
  const [alignment, setAlignment] = useState("ckl258cga13yx18p3bgt6xsc8");

  /**
   * The component styles
   * @type {Object}
   */
  const classes = useStyles();

  /**
   * Handles the alignment change
   * @param {Object} event - The event object
   * @param {string} newAlignment - The new alignment value
   */
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      switchBasemap(map, newAlignment);
    }
  };

  /**
   * Switches the basemap style
   * @param {Object} map - The map object
   * @param {string} styleID - The style ID
   */
  const switchBasemap = useCallback(
    async (map, styleID) => {
      if (styleID) {
        const currentStyle = map && map.getStyle();

        const { data: newStyle } = await fetchNewStyle(
          styleID,
          MAPBOX_ACCESS_TOKEN
        );

        newStyle.sources = Object.assign(
          {},
          currentStyle.sources,
          newStyle.sources
        );

        let labelIndex = newStyle.layers.findIndex((el) => {
          return el.id == "tunnel-oneway-arrow-blue";
        });

        if (labelIndex === -1) {
          labelIndex = newStyle.layers.length;
        }
        const appLayers = currentStyle.layers.filter((el) => {
          return (
            el.source &&
            el.source != "mapbox://mapbox.satellite" &&
            el.source != "mapbox" &&
            el.source != "composite"
          );
        });

        const countryLabel = currentStyle.layers.filter((el) => {
          return el.id === "country-label";
        });
        newStyle.layers = [
          ...newStyle.layers.slice(0, labelIndex),
          ...appLayers,
          ...newStyle.layers.slice(labelIndex, -1),
          ...countryLabel,
        ];
        map.setStyle(newStyle);
        loadAndAddSprites();
      }
    },
    [loadAndAddSprites]
  );

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      onMouseEnter={() => setHoverInfo(null)}
      className={classes.toggleGroup}
      id="map-style-switch"
      data-testid="map-style-switch"
    >
      {MAPBOX_STYLES.map((style, index) => (
        <ToggleButton
          data-testid={style.name}
          key={index}
          value={style.url}
          className={classes.toggleButton}
        >
          {t(`mapStyle.${style.name}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

MapStyle.propTypes = {
  map: PropTypes.object,
  loadAndAddSprites: PropTypes.func,
  setHoverInfo: PropTypes.func,
};

export default MapStyle;
