import React, { useContext } from "react";
import { Layer, Source } from "react-map-gl";
import PropTypes from "prop-types";
import { AppContext } from "../../../contexts/AppContext";
import usePaintLegendConfig from "../../../hooks/Layers/usePaintLegendConfig";

/**
 * Renders map layers and sources
 * @component
 * @category Native
 * @subcategory Layers
 * @param {Object} props - Component props
 * @param {Object} props.map - Map object
 * @param {Function} props.getBeforeIdLayerId - Function to get the id of the layer before which a new layer should be added
 * @param {string} props.hoverInfo - Information about the hovered region
 * @returns {JSX.Element} - Rendered component
 */
const Layers = ({ map, getBeforeIdLayerId, hoverInfo }) => {
  const { LAYER_CONFIG, pathLocation } = useContext(AppContext);

  const paintLegendConfig = LAYER_CONFIG.map((layer) =>
    usePaintLegendConfig(layer)
  );

  /**
   * Returns the source object for a raster layer
   * @param {string} mapboxId - Mapbox id of the layer
   * @returns {Object} - Source object
   */
  const rasterSource = (mapboxId) => ({
    type: "raster",
    id: mapboxId,
    tiles: [`https://api.mapbox.com/v4/fzj.${mapboxId}/{z}/{x}/{y}.png`],
  });

  /**
   * Returns the source object for a vector layer
   * @param {string} mapboxId - Mapbox id of the layer
   * @param {string} sourceId - Id of the source
   * @returns {Object} - Source object
   */
  const vectorSource = (mapboxId, sourceId) => ({
    type: "vector",
    id: sourceId,
    url: `mapbox://fzj.${mapboxId}`,
  });

  /**
   * Returns the source object for an emergency raster layer
   * @param {string} mapboxId - Mapbox id of the layer
   * @returns {Object} - Source object
   */
  const emergencyRasterSource = (mapboxId) => ({
    type: "raster",
    id: mapboxId,
    tiles: [`https://api.mapbox.com/v4/fzj.${mapboxId}/{z}/{x}/{y}.png`],
    tileSize: 512,
  });

  /**
   * Returns the gid base layer
   * @param {Object} param0 - Object containing layerId and renderLayer properties
   * @returns {JSX.Element} - Rendered layer
   */
  const gidBaseLayer = ({ layerId, renderLayer }) => {
    const sourceId = pathLocation.includes("ecowas")
      ? "ecowas_region"
      : pathLocation.includes("sadc")
      ? "sadc_region"
      : pathLocation.includes("east")
      ? "east_region"
      : "ecowas_region";
    return (
      <Source {...vectorSource(sourceId, sourceId)}>
        <Layer
          id={`${layerId}-region`}
          source-layer="region"
          beforeId={getBeforeIdLayerId()}
          type="fill"
          layout={{
            visibility: renderLayer ? "visible" : "none",
          }}
          paint={{
            "fill-color": "transparent",
          }}
        />
        {hoverInfo && (
          <Layer
            id={`${layerId}-region-highlighted`}
            source-layer="region"
            // beforeId={getBeforeIdLayerId()}
            type="line"
            layout={{
              visibility: renderLayer ? "visible" : "none",
            }}
            filter={["==", ["get", "region_id"], hoverInfo]}
            paint={{
              "line-color": "#ffffff",
              "line-width": 3,
            }}
          />
        )}
      </Source>
    );
  };

  /**
   * Renders all the layers
   * @returns {JSX.Element[]} - Array of rendered layers
   */
  const renderLayers = () => {
    return LAYER_CONFIG.map((layer, index) => {
      const layerColor = paintLegendConfig[index].color;
      const layerFilter = paintLegendConfig[index].filter;
      const { renderLayer, opacity } = layer;

      if (layer.type.includes("lcoh")) {
        return (
          <div key={index}>
            <Source {...vectorSource(layer.mapboxId, layer.sourceId)}>
              <Layer
                id={layer.layerId}
                source-layer={layer.sourceLayer}
                beforeId={getBeforeIdLayerId()}
                type="fill"
                layout={{
                  visibility: renderLayer ? "visible" : "none",
                }}
                paint={{
                  "fill-color": layerColor || "transparent",
                  "fill-opacity": opacity / 100,
                }}
              />
              {hoverInfo && (
                <Layer
                  id={`${layer.layerId}-highlighted`}
                  source-layer={layer.sourceLayer}
                  // beforeId={getBeforeIdLayerId()}
                  type="line"
                  layout={{
                    visibility: renderLayer ? "visible" : "none",
                  }}
                  filter={["==", ["get", "region_id"], hoverInfo]}
                  paint={{
                    "line-color": "#ffffff",
                    "line-width": 3,
                  }}
                />
              )}
            </Source>
          </div>
        );
      } else if (layer.type.includes("fill")) {
        return (
          <div key={index}>
            <Source {...vectorSource(layer.mapboxId, layer.sourceId)}>
              <Layer
                id={layer.layerId}
                source-layer={layer.sourceLayer}
                beforeId={getBeforeIdLayerId()}
                type="fill"
                layout={{
                  visibility: renderLayer ? "visible" : "none",
                }}
                paint={{
                  "fill-color": layerColor || "transparent",
                  "fill-opacity": opacity / 100,
                }}
              />
              {hoverInfo && (
                <Layer
                  id={`${layer.layerId}-highlighted`}
                  source-layer={layer.sourceLayer}
                  // beforeId={getBeforeIdLayerId()}
                  type="line"
                  layout={{
                    visibility: renderLayer ? "visible" : "none",
                  }}
                  filter={["==", ["get", "region_id"], hoverInfo]}
                  paint={{
                    "line-color": "#ffffff",
                    "line-width": 3,
                  }}
                />
              )}
            </Source>
            <Source {...vectorSource(layer.mapboxId, layer.sourceId)}>
              <Layer
                id={layer.layerId + "-" + "hidden"}
                source-layer={layer.sourceLayer}
                beforeId={getBeforeIdLayerId()}
                type="fill"
                layout={{
                  visibility: "visible",
                }}
                paint={{
                  "fill-opacity": 0,
                }}
              />
            </Source>
          </div>
        );
      } else if (layer.type.includes("icon")) {
        if (layer.layerId.includes("hydro")) {
          return (
            <div key={index}>
              <Source {...vectorSource(layer.mapboxId, layer.sourceId)}>
                <Layer
                  id={layer.layerId + "-" + "hidden"}
                  filter={layerFilter}
                  source-layer={layer.sourceLayer}
                  type="circle"
                  beforeId={getBeforeIdLayerId()}
                  layout={{
                    visibility: "visible",
                  }}
                  paint={{
                    "circle-opacity": 0,
                  }}
                  minzoom={0}
                  maxzoom={17}
                />
                <Layer
                  id={layer.layerId}
                  filter={layerFilter}
                  source-layer={layer.sourceLayer}
                  type="symbol"
                  beforeId={getBeforeIdLayerId()}
                  layout={{
                    visibility: renderLayer ? "visible" : "none",
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true,
                    "icon-ignore-placement": true,
                    "icon-image": layer.iconId,
                    "symbol-avoid-edges": true,
                    "icon-anchor": "bottom",
                    "icon-offset": {
                      stops: [
                        [0, [5, 5]],
                        [17, [20, 20]],
                      ],
                    },
                    "icon-size": {
                      base: 1.7,
                      stops: [
                        [0, 1],
                        [17, 5],
                      ],
                    },
                  }}
                  paint={{
                    "icon-color": layerColor || "transparent",
                  }}
                  minzoom={0}
                  maxzoom={17}
                />
              </Source>
            </div>
          );
        } else {
          return (
            <div key={index}>
              <Source {...vectorSource(layer.mapboxId, layer.sourceId)}>
                <Layer
                  id={layer.layerId + "-" + "hidden"}
                  source-layer={layer.sourceLayer}
                  type="circle"
                  beforeId={getBeforeIdLayerId()}
                  layout={{
                    visibility: "visible",
                  }}
                  paint={{
                    "circle-opacity": 0,
                  }}
                />
                <Layer
                  id={layer.layerId + "circle"}
                  source-layer={layer.sourceLayer}
                  type="circle"
                  beforeId={getBeforeIdLayerId()}
                  layout={{
                    visibility: renderLayer ? "visible" : "none",
                  }}
                  paint={{
                    "circle-opacity": opacity / 100,
                    "circle-color": layerColor || "transparent",
                    "circle-radius": [
                      "interpolate",
                      ["linear"],
                      ["zoom"],

                      3.5,
                      2.0,
                      3.9,
                      3.0,
                    ],
                  }}
                  minzoom={0}
                  maxzoom={10}
                />
                <Layer
                  id={layer.layerId}
                  source-layer={layer.sourceLayer}
                  type="symbol"
                  beforeId={getBeforeIdLayerId()}
                  layout={{
                    visibility: renderLayer ? "visible" : "none",
                    "icon-allow-overlap": true,
                    "icon-image": layer.iconId,
                    "symbol-avoid-edges": true,
                    "icon-anchor": "bottom",
                    "icon-offset": {
                      stops: [
                        [10, [5, 5]],
                        [22, [20, 20]],
                      ],
                    },
                    "icon-size": {
                      base: 1.7,
                      stops: [
                        [10, 1],
                        [17, 5],
                      ],
                    },
                  }}
                  paint={{
                    "icon-color": layerColor || "transparent",
                  }}
                  minzoom={10}
                  maxzoom={17}
                />
              </Source>
              {!layer.layerId.includes("hydro") && gidBaseLayer(layer)}
            </div>
          );
        }
      } else if (layer.layerId === "excOff") {
        return (
          <div key={index}>
            <Source {...emergencyRasterSource("33u6qa6i")}>
              <Layer
                id={layer.layerId}
                beforeId={getBeforeIdLayerId()}
                type="raster"
                layout={{
                  visibility: renderLayer ? "visible" : "none",
                }}
                paint={{
                  "raster-opacity": opacity / 100,
                  "raster-resampling": "linear",
                }}
                maxzoom={22}
              />
            </Source>
            <Source {...emergencyRasterSource("3mlm1azb")}>
              <Layer
                id={layer.layerId + "1"}
                beforeId={getBeforeIdLayerId()}
                type="raster"
                layout={{
                  visibility: renderLayer ? "visible" : "none",
                }}
                paint={{
                  "raster-opacity": opacity / 100,
                  "raster-resampling": "linear",
                }}
                maxzoom={22}
              />
            </Source>
          </div>
        );
      } else if (layer.type.includes("raster")) {
        return (
          <div key={index}>
            {gidBaseLayer(layer)}
            <Source {...rasterSource(layer.mapboxId)}>
              <Layer
                id={layer.layerId}
                beforeId={getBeforeIdLayerId()}
                type="raster"
                layout={{
                  visibility: renderLayer ? "visible" : "none",
                }}
                paint={{
                  "raster-opacity": opacity / 100,
                  "raster-resampling": "linear",
                }}
                maxzoom={22}
              />
            </Source>
          </div>
        );
      }
    });
  };

  if (!map) {
    return null;
  }
  // TODO: change the order of circle layers
  return renderLayers();
};

Layers.propTypes = {
  map: PropTypes.object,
  getBeforeIdLayerId: PropTypes.func,
  hoverInfo: PropTypes.string,
};

export default Layers;
