/**
 * This file contains the Material UI styling for the Geolocate component.
 * @name GeolocateMUIStyle
 * @category MUI
 *
 * @returns {Object} The Material UI styling.
 */
const style = () => ({
  geolocateWrapper: {
    position: "absolute",
    top: "1%",
    left: 0,
    zIndex: 1,
    outline: "none",
  },
});

export default style;
