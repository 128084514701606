import { WebMercatorViewport } from "react-map-gl";

export const getWebMercatorViewport = (
  viewport,
  southwest,
  northeast,
  width,
  height
) => {
  const mercartorViewport = new WebMercatorViewport({
    width,
    height,
    zoom: 2,
    // ...viewport,
  }).fitBounds(
    [
      southwest, // Southwest coordinates
      northeast, // Northeast coordinates
    ],
    {
      padding: 50, // Adjust the padding as needed
    }
  );
  return mercartorViewport;
};
