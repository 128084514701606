import { Box } from "@mui/material";
import PropTypes from "prop-types";

/**
 * A tab panel component that renders the children components inside a div element.
 * @component
 * @category Native
 * @subcategory NationalFactsSheet
 * @description A tab panel component that renders the children components inside a div element.
 * @returns {JSX.Element} - The JSX element representing the tab panel.
 */
const TabPanel = (props) => {
  /**
   * Destructure the props object to get the children and index properties.
   */
  const { children, index, ...other } = props;

  /**
   * Render the tab panel component.
   */
  return (
    <div
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box pt={1.3}>{children}</Box>
    </div>
  );
};

/**
 * Declare the propTypes for the TabPanel component.
 */
TabPanel.propTypes = {
  /**
   * The children components to be rendered inside the tab panel.
   */
  children: PropTypes.node,
  /**
   * The index of the tab panel.
   */
  index: PropTypes.any,
  /**
   * The value of the tab panel.
   */
  value: PropTypes.any,
};

export default TabPanel;
