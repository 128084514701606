import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

/**
 * A component that renders a toggle button group for a given layer property.
 * @component
 * @category Native
 * @subcategory Toggler
 * @description A component that renders a toggle button group for a given layer property.
 * @returns {JSX.Element} - The JSX element representing the toggle button group.
 */
const Toggler = ({ layerProperty, layer, toggleList, handleToggler }) => {
  const theme = useTheme();

  /**
   * Handles toggling of the toggle button group.
   * @param {Object} layer - The layer object.
   * @returns {Function} - The function to handle toggling of the toggle button group.
   */
  const handleToggle = (layer) => (event, newLayerProperty) => {
    console.log(
      "layerProperty",
      layerProperty,
      "newLayerProperty",
      newLayerProperty,
      "layer",
      layer
    );
    handleToggler(layer, newLayerProperty);
  };

  return (
    <ToggleButtonGroup
      value={layerProperty}
      exclusive
      onChange={handleToggle(layer)}
      sx={{
        backgroundColor: theme.palette.background.paper,
        margin: 1,
        alignSelf: "center",
        "& .MuiToggleButton-root.Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          color: "white",
        },
      }}
    >
      {toggleList.map((item, index) => (
        <ToggleButton
          color="primary"
          value={item.type}
          key={index}
          sx={{ fontSize: "0.6rem", color: "#000000", p: 0.5 }}
        >
          {item.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

Toggler.propTypes = {
  /**
   * The current value of the layer property.
   */
  layerProperty: PropTypes.string,
  /**
   * The layer object.
   */
  layer: PropTypes.object,
  /**
   * The list of toggle items to render.
   */
  toggleList: PropTypes.array,
  /**
   * The function to handle toggling of the toggle button group.
   */
  handleToggler: PropTypes.func,
};

export default Toggler;
