import React from "react";

import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import datasetCitation from "../../../lib/dataset_citation.json";
import style from "./MUIStyle";
import PropTypes from "prop-types";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(style);

/**
 * An About component that displays information about the GUI and its data sources.
 * @category Native
 * @subcategory About
 * @component
 * @returns {JSX.Element} - The About component UI.
 */
const About = ({ handleCloseAboutDialog, openAboutDialog }) => {
  const location = useLocation();
  const getGUI = location.pathname.slice(1).toUpperCase();
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Dialog
      data-testid="about-panel"
      className={classes.dialogBox}
      open={openAboutDialog}
      onClose={handleCloseAboutDialog}
      maxWidth="lg"
    >
      <Trans i18nKey="about.intro.title">
        {" "}
        <DialogTitle className={classes.dialogTitle}>About</DialogTitle>
      </Trans>

      <DialogContent>
        <Trans i18nKey="about.intro.body">
          {" "}
          <DialogContentText>
            <>
              This GUI was built by the {""}
              <Link
                href="https://www.fz-juelich.de/iek/iek-3/EN/Home/home_node.html"
                target="_blank"
              >
                Institute of Energy and Climate Research - Techno-Economic
                Systems Analysis (IEK-3)
              </Link>{" "}
              of the Forschungszentrum Jülich. <br /> <br />
              The data for the excluded areas, renewable energy sources and
              hydrogen layers were created by{" "}
              <Link
                href="https://www.fz-juelich.de/iek/iek-3/EN/Home/home_node.html"
                target="_blank"
              >
                IEK-3
              </Link>
              . <br />
              The data for the groundwater and social indicator layer were
              created by{" "}
              <Link
                href="https://www.fz-juelich.de/ibg/ibg-3/EN/Home/home_node.html"
                target="_blank"
              >
                IBG-3
              </Link>{" "}
              and{" "}
              <Link
                href="https://www.fz-juelich.de/iek/iek-ste/EN/Home/home_node.html"
                target="_blank"
              >
                IEK-STE
              </Link>{" "}
              respectively. <br /> <br />
            </>
          </DialogContentText>
          <div>
            <DialogContentText>
              <>
                If you find any bugs, have questions regarding the shown data or
                have feedback for improvements or extensions, please contact{" "}
                <Link
                  href="https://www.fz-juelich.de/SharedDocs/Kontaktdaten/Mitarbeiter/H/Heinrichs_h_heinrichs_fz_juelich_de.html?nn=557474"
                  target="_blank"
                >
                  Dr.-Ing. Heidi Ursula Heinrichs
                </Link>
              </>
            </DialogContentText>
          </div>
        </Trans>
        <br /> <br />
        <div>
          <Trans i18nKey="about.license.title">
            {" "}
            <Typography variant="h5">License</Typography>
          </Trans>
          <Trans i18nKey="about.license.body">
            {" "}
            <DialogContentText>
              <>
                {" "}
                © Copyright{" "}
                <Link
                  href="https://www.fz-juelich.de/iek/iek-3/EN/Home/home_node.html"
                  target="_blank"
                >
                  Institute of Energy and Climate Research - Techno-Economic
                  Systems Analysis (IEK-3)
                </Link>
                , Forschungszentrum Jülich. <br /> <br />
                {{ getGUI }} H2 Atlas is made available under the following
                Creative Commons License:{" "}
                <Link
                  href="https://creativecommons.org/licenses/by-nc-sa/3.0/"
                  target="_blank"
                >
                  Attribution-NonCommercial-ShareAlike 3.0 Unported (CC BY-NC-SA
                  3.0)
                </Link>
              </>
            </DialogContentText>
          </Trans>
        </div>{" "}
        <br />
        <br />
        <div>
          <Trans i18nKey="about.primaryDataSources.title">
            {" "}
            <Typography variant="h5">Primary Data Sources</Typography>
          </Trans>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {t("citationTable.dataset name")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {t("citationTable.version")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {t("citationTable.download URL")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {t("citationTable.full citation")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datasetCitation["Tabelle1"].map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row["Dataset name"]}</StyledTableCell>
                    <StyledTableCell>
                      {row["Version/release/retrieval date"]}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link href={row["Download URL"]} target="_blank">
                        Download
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>{row["Citation"]}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          {" "}
          <br />
          <br />
          <Trans i18nKey="about.primarySoftwareTools.title">
            {" "}
            <Typography variant="h5">Primary Software Tools</Typography>
          </Trans>
          <Trans i18nKey="about.primarySoftwareTools.dataGeneration.title">
            {" "}
            <Typography variant="h6">Data Generation</Typography>
          </Trans>
          <Trans i18nKey="about.primarySoftwareTools.dataGeneration.body">
            {" "}
            <DialogContentText>
              <>
                {" "}
                The code for all applied software tools can be downloaded from
                GitHub via the links below. Individual features within the tools
                may be in development stage yet and will appear in one of the
                coming version releases. The following tools were used in
                particular: <br />
                <Link
                  href="https://github.com/FZJ-IEK3-VSA/glaes"
                  target="_blank"
                >
                  Land eligibility analysis (GLAES)
                </Link>{" "}
                <br />
                <Link
                  href="https://github.com/FZJ-IEK3-VSA/RESKit"
                  target="_blank"
                >
                  {" "}
                  Energy yield/potential simulation (RESkit)
                </Link>{" "}
                <br />
                <Link
                  href="https://github.com/FZJ-IEK3-VSA/FINE"
                  target="_blank"
                >
                  System modeling framework (FINE)
                </Link>{" "}
                <br />
                <Link href="https://www.gurobi.com/" target="_blank">
                  Optimization solver (Gurobi)
                </Link>{" "}
                (We thank Gurobi for providing us an academic license for the
                different optimziations.)
                <br />
                <Link
                  href="https://www.cesm.ucar.edu/models/clm/"
                  target="_blank"
                >
                  {" "}
                  Community Land Model [version 5] software program (CLM5)
                </Link>{" "}
                <br />
                <Link href="https://www.r-project.org/" target="_blank">
                  R [version3.6.3]
                </Link>
              </>
            </DialogContentText>
          </Trans>{" "}
          <br /> <br />
          <Trans i18nKey="about.primarySoftwareTools.webtools.title">
            {" "}
            <Typography variant="h6">Webtools</Typography>
          </Trans>
          <Trans i18nKey="about.primarySoftwareTools.webtools.body">
            {" "}
            <DialogContentText>
              <>
                {" "}
                Apart from{" "}
                <Link href="https://www.mapbox.com/mapbox-gljs" target="_blank">
                  Mapbox GL-JS
                </Link>
                , a library that powers the map and location service, the
                graphical user interface, data pre-processing and
                post-processing is built with free software and open data.
                Mapbox has a paid subscription of map loads above 50,000 in a
                month. Mapbox Studio, an application in the Mapbox space is also
                used to store some vector tiles for serving. Other tools used
                include{" "}
                <Link href="https://reactjs.org/" target="_blank">
                  React
                </Link>{" "}
                (a popular Javascript library for the frontend),{" "}
                <Link href="http://geoserver.org/" target="_blank">
                  Geoserver
                </Link>
                (a Java based map server backend framework where map data is
                served),{" "}
                <Link href="https://postgis.net/" target="_blank">
                  POSTGIS
                </Link>{" "}
                (a relational database primarily for GIS operations),{" "}
                <Link href="https://www.nginx.com/" target="_blank">
                  NGINX
                </Link>{" "}
                (an open source library used for web serving, reverse proxying
                and load balancing), and{" "}
                <Link href="https://www.docker.com/" target="_blank">
                  Docker
                </Link>{" "}
                (a container software that zips all the aforementioned libraries
                for easy deployment on the web).
              </>
            </DialogContentText>
          </Trans>
        </div>
        <div>
          <br />
          <br />
          <Trans i18nKey="about.contributors.title">
            <Typography variant="h5">Contributors</Typography>{" "}
          </Trans>
          <Trans i18nKey="about.contributors.body">
            {" "}
            <DialogContentText component={Box}>
              <>
                {" "}
                <b>Project Management:</b> Heidi Heinrichs, Solomon Agbo <br />
                <b>Full-stack GUI development:</b> Nathan Ojieabu <br />
                <b>Model development</b>
                <List disablePadding>
                  <ListItem sx={{ py: 0 }}>
                    <span>
                      <em>Indicators: &nbsp; </em>
                    </span>{" "}
                    {"  "}
                    <span>Amin Lahnaoui</span>
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <span>
                      {" "}
                      <em>Land Eligibility:&nbsp;</em> Christoph Winkler
                    </span>
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <span>
                      {" "}
                      <em>Renewable Energy:&nbsp;</em>Shitab Ishmam, Edgar
                      Ubaldo Peña Sánchez
                    </span>
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <span>
                      {" "}
                      <em>Hydrogen:&nbsp;</em> Christoph Winkler
                    </span>
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <span>
                      {" "}
                      <em>Groundwater:&nbsp;</em> Bagher Bayat
                    </span>
                  </ListItem>
                </List>
                <b>Hardware adminstration:</b> Patrick Kuckertz, Hang Khuat{" "}
                <br />
                <b>Coordination and review:</b> Shruthi Patil, Leander Kotzur,
                Detlef Stolten{" "}
              </>
            </DialogContentText>
          </Trans>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="about-OK"
          variant="contained"
          onClick={handleCloseAboutDialog}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

About.propTypes = {
  handleCloseAboutDialog: PropTypes.func,
  openAboutDialog: PropTypes.bool,
};

export default About;
