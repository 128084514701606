import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

import h2AtlasLogo from "../../../assets/logo-h2atlas-africa.svg";
import style from "./MUIStyle";

const useStyles = makeStyles(style(h2AtlasLogo));

/**
 * A component that displays a dialog box with two buttons that redirect to different pages when clicked.
 * @component
 * @category Native
 * @subcategory Home
 *
 */

const Home = () => {
  const { t } = useTranslation();
  /**
   * A function that handles the click event of the buttons.
   * @param {string} pathname - The path to redirect to when a button is clicked.
   */
  const handleClickToGUI = (pathname) => {
    // createCookie("defaultGUI", pathname, 30);
    window.location.href = pathname;
  };

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Dialog className={classes.dialogBox} open>
        <DialogTitle className={classes.header}>
          {t("home.welcome")}
        </DialogTitle>
        <DialogContent>{t("home.select")}</DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Button
                className={classes.button}
                size="large"
                fullWidth
                variant="contained"
                onClick={() => handleClickToGUI("/ecowas")}
                color="primary"
              >
                {t("home.ecowas")}
              </Button>
            </Grid> */}
            {/* <Grid item xs={12}>
              <Button
                className={classes.button}
                size="large"
                fullWidth
                variant="contained"
                onClick={() => handleClickToGUI("/sadc")}
                color="primary"
              >
                {t("home.sadc")}
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Button
                className={classes.button}
                size="large"
                fullWidth
                variant="contained"
                onClick={() => handleClickToGUI("/east")}
                color="primary"
              >
                {t("home.east")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Home;
// TODO: Activate SADC
