/**
 * This file contains the style for the About component using Material-UI.
 * @name CookiesMUIStyle
 * @category MUI
 * @returns {Object} The style object.
 */
const style = (theme) => ({
  snackbar: {
    bottom: "10vh",
    [theme.breakpoints.down("md")]: {
      "& .MuiSnackbarContent-root": {
        fontSize: "0.575rem",
      },
    },
  },
  buttonRoot: {
    margin: theme.spacing(1),
  },
  buttonSize: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.5rem",
    },
  },
  cookieDialogBox: {
    [theme.breakpoints.down("md")]: {
      "& .MuiDialogContentText-root": {
        fontSize: "0.8rem",
      },
    },
  },
});

export default style;
