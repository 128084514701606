import jsPDF from "jspdf";
import { toPng, toCanvas } from "html-to-image";
import FZJLogo from "../assets/fzj_logo_pdf.png";
import wascalLogo from "../assets/wasscal_logo_original_pdf.png";
import sasscalLogo from "../assets/sasscal-logo_original_pdf.png";
import h2atlasLogo from "../assets/h2atlas_logo_pdf.jpg";
import BMBFLogo from "../assets/BMBF.JPG";

const savePdfToImage = (mapImage, layerName) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = mapImage;
  downloadLink.download = `${layerName}.png`;
  downloadLink.click();
};

export const printCanvas = async (
  mapCanvas,
  filteredRangeValues,
  layerName,
  layerId,
  layerUnit,
  region,
  pathLocation,
  formatOptions
) => {
  const mapImage = await toPng(mapCanvas);
  const legendCanvas = await toCanvas(
    document.getElementById(`legend-wrapper-${layerId}`)
  );

  const PDF_Width = 800; // Define the desired width of the PDF
  const PDF_Height = 600; // Define the desired height of the PDF
  const top_margin = 40;
  const image_height = PDF_Height * 0.45;
  const image_width = image_height * (mapCanvas.width / mapCanvas.height);
  const legend_width = image_width * 0.6;
  const legend_height = image_height * 0.5;

  const pdf = new jsPDF("l", "pt", [PDF_Width, PDF_Height]); // Set orientation to landscape

  pdf.setFontSize(25);
  pdf.text(layerName, PDF_Width / 2, top_margin, null, null, "center");

  if (filteredRangeValues && filteredRangeValues.length) {
    // Add null check
    pdf.setFontSize(15);
    pdf.text(
      `Filter Range: ${filteredRangeValues[0]} - ${filteredRangeValues[1]} ${layerUnit}`,
      PDF_Width / 2,
      top_margin + 20,
      null,
      null,
      "center"
    );
  }

  pdf.setFontSize(15);
  pdf.text(
    region
      ? `${region}`
      : `All ${pathLocation.substring(1).toUpperCase()} Countries`,
    PDF_Width / 2,
    top_margin + 50,
    null,
    null,
    "center"
  );

  const mapImageX = (PDF_Width - image_width) / 2;
  const mapImageY = top_margin + 70;

  pdf.addImage(
    mapImage,
    "PNG",
    mapImageX,
    mapImageY,
    image_width,
    image_height
  );

  const legendImageX = (PDF_Width - image_width) / 2;
  const legendImageY = mapImageY + image_height;

  pdf.addImage(
    legendCanvas,
    "PNG",
    legendImageX,
    legendImageY,
    legend_width,
    legend_height
  );

  pdf.setFont("times", "italic");
  pdf.setFontSize(12);
  pdf.setDrawColor(0, 0, 255);
  pdf.setTextColor(0, 0, 255);
  pdf.textWithLink(
    "Created by H2 Atlas Africa",
    PDF_Width - 150,
    PDF_Height - 20,
    {
      url: "https://africa.h2atlas.de",
    }
  );
  const textWidth = pdf.getTextWidth("Created by H2 Atlas Africa");
  pdf.line(
    PDF_Width - 150,
    PDF_Height - 18,
    PDF_Width - 150 + textWidth,
    PDF_Height - 18
  );
  pdf.addImage(h2atlasLogo, PDF_Width - 530, PDF_Height - 80);
  pdf.addImage(FZJLogo, PDF_Width - 430, PDF_Height - 80);
  pdf.addImage(BMBFLogo, PDF_Width - 250, PDF_Height - 80);
  pathLocation.includes("sadc")
    ? pdf.addImage(sasscalLogo, PDF_Width - 150, PDF_Height - 80)
    : pdf.addImage(wascalLogo, PDF_Width - 150, PDF_Height - 80);

  if (Object.values(formatOptions).every((value) => value === true)) {
    pdf.save(`${layerName}.pdf`, { returnPromise: true }).then(() => {
      savePdfToImage(mapImage, layerName);
    });
    // savePdfToImage(mapImage, layerName);
  } else if (formatOptions.pdf) {
    pdf.save(`${layerName}.pdf`, { returnPromise: true }).then(() => {});
  } else if (formatOptions.image) {
    savePdfToImage(mapImage, layerName);
  }
  return "done";
};
