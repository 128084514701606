import { useEffect } from "react";

/**
 * A custom React hook that listens for the "Escape" key press and calls the onDelete function if the featuresLength is greater than 0.
 * @category Hooks
 * @component
 */
const useDeleteKey = ({ onDelete, featuresLength }) => {
  /**
   * Handles the "Escape" key press and calls the onDelete function if the featuresLength is greater than 0.
   * @param {Object} event - The event object.
   */
  const handleDeleteKeyPress = (event) => {
    if (event.key === "Escape" && featuresLength?.length > 0) {
      onDelete();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleDeleteKeyPress);
    return () => {
      document.removeEventListener("keydown", handleDeleteKeyPress);
    };
  }, []);
};

export default useDeleteKey;
