/**
 * This file contains the style for the About component using Material-UI.
 * @name HomeMUIStyle
 * @category MUI
 * @returns {Object} The style object.
 */
const style = (h2AtlasLogo) => (theme) => ({
  button: {
    padding: theme.spacing(1),
  },
  header: {
    textAlign: "center",
    textTransform: "uppercase",
    "& .MuiTypography-h6": {
      fontWeight: 900,
    },
  },
  wrapper: {
    backgroundImage: `url(${h2AtlasLogo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center center",
    minHeight: "100vh",
    backgroundAttachment: "fixed",
  },
  dialogBox: {
    backgroundColor: theme.palette.background.paper,
    "& .MuiDialog-paperWidthSm": {
      [theme.breakpoints.down("md")]: {
        width: 200,
      },
    },
  },
});

export default style;
