/**
 * This module initializes the internationalization (i18n) configuration for the application using i18next.
 * It supports localization and translation features with features such as i18next-locize-backend, locize-lastused,
 * locize-editor, and more. - See {@tutorial translations} for more information.
 * @module i18nConfiguration
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import LastUsed from "locize-lastused";
import { locizePlugin } from "locize";
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";

// Environment variables for Locize project
const LOCIZE_PROJECT_ID = process.env.REACT_APP_LOCIZE_PROJECT_ID;
const LOCIZE_API_KEY = process.env.REACT_APP_LOCIZE_API_KEY;

/**
 * Configuration options for Locize integration.
 * @typedef {Object} LocizeOptions
 * @property {string} projectId - The Locize project ID.
 * @property {string} apiKey - The API key (only for development, do not expose in production).
 * @property {string} referenceLng - The reference language for translations.
 */
const locizeOptions = {
  projectId: LOCIZE_PROJECT_ID,
  apiKey: LOCIZE_API_KEY, // The API key should only be used in development, not in production. You should not expose your apps API key to production!!!
  referenceLng: "en",
};

/**
 * Configuration options for language detection.
 * @typedef {Object} LanguageDetectionOptions
 * @property {string[]} order - The order of language detection methods (e.g., ["navigator", "localStorage"]).
 */
const options = {
  order: ["navigator", "localStorage"],
};

// i18n
//   // i18next-locize-backend
//   // loads translations from your project, saves new keys to it (saveMissing: true)
//   // https://github.com/locize/i18next-locize-backend
//   .use(ChainedBackend)
//   // locize-lastused
//   // sets a timestamp of last access on every translation segment on locize
//   // -> safely remove the ones not being touched for weeks/months
//   // https://github.com/locize/locize-lastused
//   .use(LastUsed)
//     // locize-editor
//   // InContext Editor of locize
//   .use(locizePlugin)
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init({
//     debug: true,
//     fallbackLng: "en",
//     saveMissing: true, // you should not use saveMissing in production
//     // keySeparator: false,
//     detection: options,

//     interpolation: {
//       escapeValue: false, // not needed for react as it escapes by default
//     },
//     backend: {
//       backends: [
//         Backend,
//         resourcesToBackend((lng, ns) =>
//           import(`../public/locales/${lng}/${ns}.json`)
//         ),
//       ],
//       backendOptions: [locizeOptions],
//     },
//     locizeLastUsed: locizeOptions,
//   });

const isProduction = process.env.NODE_ENV === "production";

if (!isProduction) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18n.use(LastUsed);
}

i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(ChainedBackend)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProduction,
    fallbackLng: "en",
    detection: options,
    saveMissing: !isProduction, // you should not use saveMissing in production
    // keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      backends:
        process.env.REACT_APP_ENV == "development"
          ? [
              resourcesToBackend((lng, ns) =>
                import(`../public/locales/${lng}/${ns}.json`)
              ),
            ]
          : [
              Backend,
              resourcesToBackend((lng, ns) =>
                import(`../public/locales/${lng}/${ns}.json`)
              ),
            ],
      backendOptions: [locizeOptions],
    },
    locizeLastUsed: locizeOptions,
  });

export default i18n;
