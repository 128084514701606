import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

/**
 * A loader component that displays a circular progress indicator and optional children.
 * @component
 * @category Native
 * @subcategory LoadScreen
 */
const Loader = ({ size, thickness, displayHeight, children }) => {
  return (
    <Box
      height={displayHeight}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress
        size={size}
        thickness={thickness}
        disableShrink
        color="primary"
      />
      {children}
    </Box>
  );
};

Loader.propTypes = {
  /**
   * The size of the loader.
   */
  size: PropTypes.number,
  /**
   * The thickness of the loader.
   */
  thickness: PropTypes.number,
  /**
   * The height of the loader.
   */
  displayHeight: PropTypes.string,
  /**
   * The children of the loader.
   */
  children: PropTypes.node,
};

export default Loader;
