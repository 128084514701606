import { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { roundToDecimal } from "../../utils/roundToDecimal";
import { symbolsConverter } from "../../utils/symbolsConverter";
import {
  getHydrogenQuantities,
  getRasterQuantities,
  getRenewableEnergyQuantities,
} from "../../service/data";
import currencyAndUnitConverter from "../../utils/currencyAndUnitConverter";
import { useTranslation } from "react-i18next";

/**
 * Custom React hook that handles click events on non-LCOE layers and displays a popup with relevant information.
 *
 * @category Hooks
 * @component
 * @param {Array} LAYER_CONFIG - An array of layer configuration objects.
 * @returns {Object} An object containing state variables and functions for handling click events and displaying popups.
 */
const useClickToShow = (LAYER_CONFIG) => {
  const {
    toggleResultPanel,
    setToggleResultPanel,
    convertedLayerUnits,
    setCostFilterDisclaimerCount,
  } = useContext(AppContext);
  const { t } = useTranslation();

  const [resultPanelParams, setResultPanelParams] = useState(null);
  const [clickEventObject, setClickEventObject] = useState(null);
  const [clickPopupInfo, setClickPopupInfo] = useState();
  const [mapScroll, setMapScroll] = useState(true);
  const [latLng, setLatLng] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [popupLoading, setPopupLoading] = useState(false);

  /**
   * Handles click events on non-LCOE layers and displays a popup with relevant information.
   *
   * @param {Object} event - The click event object.
   * @returns {void}
   */
  const handleClickedNonLCOELayers = async (event) => {
    const selectedLayerId = event.features[0].layer.id;
    const { region_id, GID_0, NAME_0, NAME_1, NAME_2 } =
      event.features[0].properties;
    const {
      h2QuantityTranslationTag,
      sourceId,
      layerId,
      fieldAttribute,
      fieldAttribute_25,
      fieldAttribute_50,
      fieldAttribute_100,
      nationalFieldAttribute,
      nationalFieldAttribute_25,
      nationalFieldAttribute_50,
      nationalFieldAttribute_100,
      costPercentage,
      legendInfo,
      costYear,
      prodUnit,
      costValue,
      type,
    } = LAYER_CONFIG.find((layer) => layer.layerId === selectedLayerId);

    const regionalH2QuantityAttribute =
      costPercentage === "25%"
        ? fieldAttribute_25
        : costPercentage === "50%"
        ? fieldAttribute_50
        : costPercentage === "100%"
        ? fieldAttribute_100
        : fieldAttribute;

    const nationalH2QuantityAttribute =
      costPercentage === "25%"
        ? nationalFieldAttribute_25
        : costPercentage === "50%"
        ? nationalFieldAttribute_50
        : costPercentage === "100%"
        ? nationalFieldAttribute_100
        : nationalFieldAttribute;

    const unitConverted = convertedLayerUnits[layerId];
    const convertedCostValue = currencyAndUnitConverter.convert(
      unitConverted,
      legendInfo.unit,
      costValue
    );

    try {
      const { regional, national } = await getHydrogenQuantities({
        location: region_id,
        sourceId,
        regionalH2QuantityAttribute,
        nationalH2QuantityAttribute,
        inputValue: convertedCostValue,
        costYear,
        costPercentage,
        layerId,
      });

      const popupObject = {
        data: {
          category: "nonLCOE",
          title: "hydrogenRegions",
          costPercentage,
          costYear,
          layerId,
          type,
          defaultUnit: legendInfo.unit,
          queryParameters: {
            country: GID_0,
            region: region_id,
            sourceId,
            fieldAttribute,
            fieldAttribute_25,
            fieldAttribute_50,
            fieldAttribute_100,
            nationalFieldAttribute,
            nationalFieldAttribute_25,
            nationalFieldAttribute_50,
            nationalFieldAttribute_100,
            layerId,
            costPercentage,
            NAME_1,
            NAME_2,
            NAME_0,
            costYear,
            type,
            h2QuantityTranslationTag,
            prodUnit,
            defaultUnit: legendInfo.unit,
          },
          nonLCOEData: {
            region: {
              type: "regional",
              location: NAME_2 || NAME_1,
              h2QuantityLabel: h2QuantityTranslationTag,
              unrestricted: [
                {
                  label: h2QuantityTranslationTag,
                  value: regional.h2Quantity,
                },
                {
                  label: "costAtOnePerc",
                  value: regional.costAtOnePerc,
                },
                {
                  label: "hydrogenProduction",
                  value: roundToDecimal(regional.maxPotential, 2),
                  unit: symbolsConverter(prodUnit),
                },
              ],
              costFiltered: [
                {
                  label: "filteredCostLCOH",
                  value: roundToDecimal(costValue, 2),
                },
                {
                  label: "hydrogenProduction",
                  value: roundToDecimal(regional.desPotential, 2),
                  unit: symbolsConverter(prodUnit),
                },
              ],
            },
            country: {
              type: "national",
              location: NAME_0,
              h2QuantityLabel: h2QuantityTranslationTag,
              unrestricted: [
                {
                  label: h2QuantityTranslationTag,
                  value: national.h2Quantity,
                },
                {
                  label: "minCost",
                  value: roundToDecimal(national.minLCOH, 2),
                  unit: symbolsConverter(prodUnit),
                },
                {
                  label: "maxCost",
                  value: roundToDecimal(national.maxLCOH, 2),
                  unit: symbolsConverter(prodUnit),
                },
                {
                  label: "hydrogenProduction",
                  value: roundToDecimal(national.maxPotential, 2),
                  unit: symbolsConverter(prodUnit),
                },
              ],
              costFiltered: [
                {
                  label: "hydrogenProduction",
                  value: roundToDecimal(national.desPotential, 2),
                  unit: symbolsConverter(prodUnit),
                },
              ],
            },
          },
        },
      };
      setClickPopupInfo(popupObject);
      setPopupLoading(false);
      setErrorMessage("");
    } catch (error) {
      handlePopupError(error);
    }
  };

  const handleClickedLCOELayer = async (event) => {
    setPopupLoading(true);
    const selectedLayerId = event.features[0].layer.id.split("-")[0];
    const { region_id, GID_0, NAME_0, NAME_1, NAME_2, FID, name } =
      event.features[0].properties;
    const {
      prodUnit,
      capUnit,
      legendInfo,
      translationTag,
      year,
      sourceId,
      layerId,
      fieldAttribute,
      costValue,
      type,
    } = LAYER_CONFIG.find((layer) => layer.layerId === selectedLayerId);

    const unitConverted = convertedLayerUnits[layerId];
    const convertedCostValue = currencyAndUnitConverter.convert(
      unitConverted,
      "€/kWh",
      costValue
    );
    const fid = FID || sourceId.toLowerCase().includes("hydro") ? FID : "";
    try {
      const { plant, regional, national } = await getRenewableEnergyQuantities({
        location: region_id,
        sourceId,
        mainAttribute: "capacity",
        suppAttribute: "ENERGY",
        fieldAttribute,
        inputValue: convertedCostValue,
        fid,
      });

      const popupObject = {
        data: {
          title: translationTag,
          category: "LCOE",
          year: year || "",
          layerId,
          defaultUnit: legendInfo.unit,
          queryParameters: {
            country: GID_0,
            region: region_id,
            fid,
            name,
            sourceId,
            fieldAttribute,
            layerId,
            mainAttribute: "capacity",
            suppAttribute: "ENERGY",
            NAME_1,
            NAME_2,
            NAME_0,
            type,
            prodUnit,
            capUnit,
            defaultUnit: legendInfo.unit,
          },
          lcoeData: {
            region: {
              location: NAME_2 || NAME_1,
              type: "regional",
              unrestricted: [
                {
                  label: "count",
                  value: roundToDecimal(regional.normalCount, 2),
                },
                { label: "LCOECost", value: regional.avLCOE },
                { label: "minCost", value: regional.minLCOE },
                { label: "maxCost", value: regional.maxLCOE },
                {
                  label: "capacity",
                  value: roundToDecimal(regional.maxCapacity, 2),
                  unit: symbolsConverter(capUnit),
                },
                {
                  label: "electricityProduction",
                  value: roundToDecimal(regional.maxPotential, 2),
                  unit: symbolsConverter(prodUnit),
                },
              ],
              costFiltered: [
                {
                  label: "count",
                  value: roundToDecimal(regional.filteredCount, 2),
                },
                {
                  label: "filteredCostLCOE",
                  value: roundToDecimal(costValue, 2),
                },
                {
                  label: "capacity",
                  value: roundToDecimal(regional.desCapacity, 2),
                  unit: symbolsConverter(capUnit),
                },
                {
                  label: "electricityProduction",
                  value: roundToDecimal(regional.desPotential, 2),
                  unit: symbolsConverter(prodUnit),
                },
              ],
            },
            country: {
              location: NAME_0,
              type: "national",
              unrestricted: [
                {
                  label: "count",
                  value: roundToDecimal(national.normalCount, 2),
                },
                { label: "LCOECost", value: national.avLCOE },
                { label: "minCost", value: national.minLCOE },
                { label: "maxCost", value: national.maxLCOE },
                {
                  label: "capacity",
                  value: roundToDecimal(national.maxCapacity, 2),
                  unit: symbolsConverter(capUnit),
                },
                {
                  label: "electricityProduction",
                  value: roundToDecimal(national.maxPotential, 2),
                  unit: symbolsConverter(prodUnit),
                },
              ],
              costFiltered: [
                {
                  label: "count",
                  value: roundToDecimal(national.filteredCount, 2),
                },
                {
                  label: "capacity",
                  value: roundToDecimal(national.desCapacity, 2),
                  unit: symbolsConverter(capUnit),
                },
                {
                  label: "electricityProduction",
                  value: roundToDecimal(national.desPotential, 2),
                  unit: symbolsConverter(prodUnit),
                },
              ],
            },
          },
        },
      };
      const hydropowerPlantInfo = {
        location: name,
        type: "plant",
        locationLabel: "hydropowerPlant",
        unrestricted: [
          { label: "LCOECost", value: plant?.avLCOE },
          {
            label: "capacity",
            value: roundToDecimal(plant?.maxCapacity, 2),
            unit: symbolsConverter(capUnit),
          },
          {
            label: "electricityProduction",
            value: roundToDecimal(plant?.maxPotential, 2),
            unit: symbolsConverter(prodUnit),
          },
        ],
      };
      setClickPopupInfo(
        FID
          ? {
              ...popupObject,
              data: {
                ...popupObject.data,
                lcoeData: {
                  plant: hydropowerPlantInfo,
                  ...popupObject.data.lcoeData,
                },
              },
            }
          : { ...popupObject }
      );

      setPopupLoading(false);
      setErrorMessage("");
    } catch (error) {
      handlePopupError(error);
    }
  };

  const handleClickedToShowResultLayers = (event) => {
    const features = event.features.find((d) => d.layer.id === "fact");
    const regionId = features && features.properties.ISO3;
    const regionName = features && features.properties.ADM0_NAME;
    setResultPanelParams({
      regionId,
      regionName,
    });
    setToggleResultPanel({
      ...toggleResultPanel,
      resultPanelOpen: true,
      accordionOpen: true,
      panel: "nationalFactsheet",
    });
  };

  const handleClickedRasterLayers = async (event) => {
    console.log(event, "raster");
    const selectedLayerId = event.features[0].layer.id.split("-")[0];
    const { region_id, NAME_0, NAME_1, NAME_2 } = event.features[0].properties;
    const {
      name,
      sourceId,
      layerId,
      legendInfo,
      rasterQuantityTranslationTag,
    } = LAYER_CONFIG.find((layer) => layer.layerId === selectedLayerId);

    try {
      const { regional, national } = await getRasterQuantities({
        location: region_id,
        sourceId,
        layerId,
      });

      const popupObject = {
        data: {
          category: "rasters",
          title: "",
          name,
          layerId,
          defaultUnit: legendInfo.unit,
          location: NAME_2 || NAME_1,
          queryParameters: {},
          rasterData: {
            region: {
              location: NAME_2 || NAME_1,
              label: rasterQuantityTranslationTag,
              value: roundToDecimal(regional, 2),
              unit: symbolsConverter(legendInfo.unit),
            },
            country: {
              location: NAME_0,
              label: rasterQuantityTranslationTag,
              value: roundToDecimal(national, 2),
              unit: symbolsConverter(legendInfo.unit),
            },
          },
        },
      };
      console.log(popupObject, "popupObject");
      setClickPopupInfo(popupObject);
      setPopupLoading(false);
      setErrorMessage("");
    } catch (error) {
      handlePopupError(error);
    }
  };

  const handlePopupError = (error) => {
    console.log(error, "check");
    setPopupLoading(false);
    setErrorMessage(t("error.errorMessage"));
    setLatLng(null);
    setClickPopupInfo(null);
  };

  const handleClickEvent = (event) => {
    setClickEventObject(event);
    setClickPopupInfo(null);
    setCostFilterDisclaimerCount(0);
  };

  const closePopup = () => {
    setClickPopupInfo(null);
    setLatLng(null);
    setMapScroll(true);
    setClickEventObject(null);
  };

  const handleLatLng = (event) => {
    const {
      lngLat: [lng, lat],
    } = event;
    setLatLng({ lng, lat });
  };

  const handleMapScroll = (status) => setMapScroll(status);

  const states = {
    resultPanelParams,
    clickEventObject,
    clickPopupInfo,
    latLng,
    errorMessage,
    popupLoading,
    mapScroll,
  };

  const handlers = {
    handleClickedNonLCOELayers,
    handleClickedLCOELayer,
    handleClickedToShowResultLayers,
    handleClickedRasterLayers,
    handlePopupError,
    handleClickEvent,
    handleLatLng,
    closePopup,
    handleMapScroll,
  };

  return [states, handlers];
};

export default useClickToShow;
