import { useEffect, useState } from "react";
import { roundToDecimal } from "../../../utils/roundToDecimal";

/**
 * A custom React hook that returns groundwater assessment facts.
 * @category Hooks
 * @component
 * @param {Object} options - The options object.
 * @param {Object} options.remoteNationalData - The remote national data object.
 * @returns {Object} The groundwater assessment facts object.
 */
const useGroundwaterFacts = ({ remoteNationalData }) => {
  const [groundwaterFacts, setGroundwaterFacts] = useState(null);

  /**
   * A function that returns an object containing groundwater assessment facts.
   * @returns {Object} The groundwater assessment facts object.
   */
  const getGroundwaterFacts = () => ({
    heading: "Groundwater assessment",
    main: [
      {
        title: "Groundwater sustainable yield (Extreme)",
        value_2020: `${roundToDecimal(
          remoteNationalData.groundwater.extreme["2020"],
          2
        )}`,
        value_2050_optimistic: `${roundToDecimal(
          remoteNationalData.groundwater.extreme["2050"].optimistic,
          2
        )}`,
        value_2050_pessimistic: `${roundToDecimal(
          remoteNationalData.groundwater.extreme["2050"].pessimistic,
          2
        )}`,
        unit: "mm/yr",
        annotation: "",
      },
      {
        title: "Groundwater sustainable yield (Medium)",
        value_2020: `${roundToDecimal(
          remoteNationalData.groundwater.medium["2020"],
          2
        )}`,
        value_2050_optimistic: `${roundToDecimal(
          remoteNationalData.groundwater.medium["2050"].optimistic,
          2
        )}`,
        value_2050_pessimistic: `${roundToDecimal(
          remoteNationalData.groundwater.medium["2050"].pessimistic,
          2
        )}`,
        unit: "mm/yr",
        annotation: "",
      },

      {
        title: "Groundwater sustainable yield (Conservative)",
        value_2020: `${roundToDecimal(
          remoteNationalData.groundwater.conservative["2020"],
          2
        )}`,
        value_2050_optimistic: `${roundToDecimal(
          remoteNationalData.groundwater.conservative["2050"].optimistic,
          2
        )}`,
        value_2050_pessimistic: `${roundToDecimal(
          remoteNationalData.groundwater.conservative["2050"].pessimistic,
          2
        )}`,
        unit: "mm/yr",
        annotation: "",
      },
    ],
    footnotes: [
      { label: "gwFirst" },
      { label: "gwSecond" },
      { label: "gwThird" },
      { label: "gwFourth" },
      { label: "gwFifth" },
    ],
    references: [
      {
        label:
          "M. O. Cuthbert, R. G. Taylor, “Groundwater reserves in Africa may be more resilient to climate     change than first thought,” accessed 20 March 2023, https://theconversation.com/groundwater-reserves-in-africa-may-be-more-resilient-to-climate-change-than-first-thought-120948.",
        referenceAnnotation: 1,
      },
      {
        label:
          "R. C. Calow, A. M. MacDonald, A. L. Nicol, and N. S. Robins, “Ground water security and drought in Africa: linking availability, access, and demand,” Groundwater, vol. 48, no. 2, pp. 246–256, 2010.",
        referenceAnnotation: 2,
      },
      {
        label:
          "R. C. Calow et al., “Groundwater Management in Drought-prone Areas of Africa,” Int. J. Water Resour. Dev., vol. 13, no. 2, pp. 241–262, 1997.",
        referenceAnnotation: 3,
      },
      {
        label:
          "A. M. MacDonald, H. C. Bonsor, B. É. Ó. Dochartaigh, and R. G. Taylor, “Quantitative maps of groundwater resources in Africa,” Environ. Res. Lett., vol. 7, no. 2, p. 24009, 2012.",
        referenceAnnotation: 4,
      },
      {
        label:
          "D. M. Lawrence et al., “The Community Land Model version 5: Description of new features, benchmarking, and impact of forcing uncertainty,” J. Adv. Model. Earth Syst., vol. 11, no. 12, pp. 4245–4287, 2019.",
        referenceAnnotation: 5,
      },
      {
        label:
          "Z. Huang et al., “Reconstruction of global gridded monthly sectoral water withdrawals for 1971--2010 and analysis of their spatiotemporal patterns,” Hydrol. Earth Syst. Sci., vol. 22, no. 4, pp. 2117–2133, 2018.",
        referenceAnnotation: 6,
      },
      {
        label:
          "C. W. Fetter, “applied hydrogeology” text book 4th Ed.” Macmillan College, New York, 2001.",
        referenceAnnotation: 7,
      },
      {
        label:
          "C. W. Fetter, “The concept of safe groundwater yield in coastal aquifers,” JAWRA J. Am. Water Resour. Assoc., vol. 8, no. 6, pp. 1173–1176, 1972.",
        referenceAnnotation: 8,
      },
      {
        label:
          "R. A. Freeze, “Three-dimensional, transient, saturated-unsaturated flow in a groundwater basin,” Water Resour. Res., vol. 7, no. 2, pp. 347–366, 1971.",
        referenceAnnotation: 9,
      },
      {
        label:
          "F. Shi et al., “Identifying the sustainable groundwater yield in a Chinese semi-humid basin,” J. Hydrol., vol. 452–453, pp. 14–24, 2012.",
        referenceAnnotation: 10,
      },
      {
        label:
          "M. Sophocleous, “From safe yield to sustainable development of water resources—the Kansas experience,” J. Hydrol., vol. 235, no. 1–2, pp. 27–43, 2000.",
        referenceAnnotation: 11,
      },
      {
        label:
          "M. Sophocleous and S. P. Perkins, “Methodology and application of combined watershed and ground-water models in Kansas,” J. Hydrol., vol. 236, no. 3–4, pp. 185–201, 2000.",
        referenceAnnotation: 12,
      },
      {
        label:
          "W. M. Alley and S. A. Leake, “The journey from safe yield to sustainability,” Groundwater, vol. 42, no. 1, pp. 12–16, 2004.",
        referenceAnnotation: 13,
      },
      {
        label:
          "F. R. P. Kalf and D. R. Woolley, “Applicability and methodology of determining sustainable yield in groundwater systems,” Hydrogeol. J., vol. 13, no. 1, pp. 295–312, 2005.",
        referenceAnnotation: 14,
      },
    ],
  });

  useEffect(() => {
    if (!remoteNationalData) return;
    setGroundwaterFacts(getGroundwaterFacts());
  }, [remoteNationalData]);

  return { groundwaterFacts };
};

export default useGroundwaterFacts;
