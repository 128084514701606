import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import BMBFLogo from "../../../assets/BMBF.svg";
import sasscalLogo from "../../../assets/sasscal.svg";
import wascalLogo from "../../../assets/wascal.svg";
import h2Logo from "../../../assets/logo-h2atlas-africa.svg";
import { UIContext } from "../../../contexts/UIContext";
import Disclaimer from "../Disclaimer/Disclaimer";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import style from "./MUIStyle";
import { AppContext } from "../../../contexts/AppContext";

const versionInfo = process.env.REACT_APP_VERSION;

const useStyles = makeStyles(style);

const Footer = () => {
  const { t } = useTranslation();
  const { setOpenCookieDialog } = useContext(UIContext);
  const { pathLocation } = useContext(AppContext);
  const [openDisclaimerDialog, setOpenDisclaimerDialog] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const location = useLocation();
  const getGUILogo = () => {
    switch (location.pathname) {
      case "/ecowas":
        return (
          <Link href="https://wascal.org/" target="_blank">
            <img id="wascalLogo" src={wascalLogo} alt="wascal Logo" />
          </Link>
        );
      case "/sadc":
        return (
          <Link href="https://sasscal.org/" target="_blank">
            <img id="sasscalLogo" src={sasscalLogo} alt="sasscal Logo" />
          </Link>
        );
      case "/east":
        return (
          <Link
            href="placeholder_image"
            alt="east-africa-image"
            target="_blank"
          >
            <img id="sasscalLogo" src={sasscalLogo} alt="sasscal Logo" />
          </Link>
        );
    }
  };

  const classes = useStyles();

  // handle disclaimer Dialog

  const handleCloseDisclaimerDialog = () => {
    setOpenDisclaimerDialog(false);
  };

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false);
  };

  return (
    <AppBar
      id="header"
      className={classes.appBar}
      position="static"
      color="default"
    >
      <div className={classes.legalTerms}>
        <Typography variant="caption">
          <Link
            data-testid="disclaimer-button"
            onClick={(e) => {
              e.preventDefault();
              setOpenDisclaimerDialog(true);
            }}
            href="#"
            target="_blank"
          >
            {t("footer.disclaimer")}
          </Link>
        </Typography>
        <Disclaimer
          handleCloseDisclaimerDialog={handleCloseDisclaimerDialog}
          openDisclaimerDialog={openDisclaimerDialog}
        />
        <Typography variant="caption">
          <Link
            data-testid="privacy-policy-button"
            href="https://www.h2atlas.de/en/privacy-policy"
            target="_blank"
          >
            {t("footer.privacyPolicy")}
          </Link>
        </Typography>
        <Typography variant="caption">
          <Link
            data-testid="cookies-policy-button"
            onClick={(e) => {
              e.preventDefault();
              setOpenCookieDialog(true);
            }}
            href="#"
            target="_blank"
          >
            {t("footer.cookiesPolicy")}
          </Link>
        </Typography>
        <Typography variant="caption">
          <Link
            data-testid="legal-notice-button"
            href="https://www.h2atlas.de/en/legal-notice"
            target="_blank"
          >
            {t("footer.legalNotice")}
          </Link>
        </Typography>
        <Typography variant="caption">
          <Link
            data-testid="feedback-form-button"
            onClick={(e) => {
              e.preventDefault();
              setOpenFeedbackDialog(true);
            }}
            href="#"
            target="_blank"
          >
            Feedback Form
          </Link>
        </Typography>
        {process.env.REACT_APP_ENV === "development" && (
          <>
            <Typography variant="caption">
              <Link
                href={process.env.PUBLIC_URL + "/docs/index.html"}
                target="_blank"
              >
                Frontend Docs
              </Link>
            </Typography>
            <Typography variant="caption">
              <Link href="http://localhost:5555/docs" target="_blank">
                Backend Docs
              </Link>
            </Typography>
            <Typography variant="caption">
              <Link href={`${pathLocation}/modeller-interface`} target="_blank">
                Modeller-Interface
              </Link>
            </Typography>
          </>
        )}

        <FeedbackForm
          handleCloseFeedbackDialog={handleCloseFeedbackDialog}
          openFeedbackDialog={openFeedbackDialog}
        />
      </div>

      <div className={classes.iek3Wrapper}>
        <Typography variant="caption"> {t("footer.developedBy")}</Typography>
        <Typography className={classes.iek3Header}>
          {t("footer.institute")}
        </Typography>
        <Typography className={classes.iek3SubHeader}>
          <Link
            underline="none"
            href="https://www.fz-juelich.de/iek/iek-3/EN/Home/home_node.html;jsessionid=17422CFD2B8C493FB617F48906638B8C"
            target="_blank"
          >
            {t("footer.iek3")}
          </Link>
        </Typography>
        <Typography variant="caption">GUI version {versionInfo}</Typography>
      </div>

      <div className={classes.footerLogo}>
        <Link href="https://h2atlas.de/" target="_blank">
          <img id="h2Logo" src={h2Logo} alt="h2atlas logo" />
        </Link>
        {getGUILogo()}
        <Link href="https://bmbf.de/" target="_blank">
          <img id="BMBFLogo" src={BMBFLogo} alt="BMBF logo" />
        </Link>
      </div>
    </AppBar>
  );
};

export default Footer;
