const superscripts = {
  0: "⁰",
  1: "¹",
  2: "²",
  3: "³",
  4: "⁴",
  5: "⁵",
  6: "⁶",
  7: "⁷",
  8: "⁸",
  9: "⁹",
  "+": "⁺",
  "-": "⁻",
  "=": "⁼",
  "(": "⁽",
  ")": "⁾",
  n: "ⁿ",
  i: "ⁱ",
};

const subscripts = {
  0: "₀",
  1: "₁",
  2: "₂",
  3: "₃",
  4: "₄",
  5: "₅",
  6: "₆",
  7: "₇",
  8: "₈",
  9: "₉",
};
export const symbolsConverter = (string) => {
  return (
    string &&
    string
      .replace(/h2/gi, `H${subscripts[2]}`)
      .replace(/km2/gi, `km${superscripts[2]}`)
      .replace(/m2/gi, `m${superscripts[2]}`)
  );
};
