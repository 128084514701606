import React, { useContext } from "react";
import {
  Editor,
  DrawPolygonMode,
  EditingMode,
  DrawLineStringMode,
  DrawRectangleMode,
} from "react-map-gl-draw";
import { Popup } from "react-map-gl";
import { useTranslation } from "react-i18next";
import { getFeatureStyle, getEditHandleStyle } from "./DrawStyle";
import DrawMenu from "./DrawMenu";
import { AppContext } from "../../../contexts/AppContext";
import DisclaimerDialog from "../../UI/InfoDialog/DisclaimerDialog";
import useDraw from "../../../hooks/Draw/useDraw";

import PropTypes from "prop-types";

const DRAW_MODES = [
  {
    id: "drawLine",
    handler: DrawLineStringMode,
    highlight: false,
    iconClassName: "fas fa-grip-lines-vertical",
  },
  {
    id: "drawPolygon",
    handler: DrawPolygonMode,
    highlight: false,
    iconClassName: "fas fa-draw-polygon",
  },
  {
    id: "drawRectangle",
    handler: DrawRectangleMode,
    highlight: false,
    iconClassName: "fas fa-vector-square",
  },
  {
    id: "edit",
    handler: EditingMode,
    highlight: false,
    iconClassName: "fas fa-edit",
  },
];

/**
 * A component that renders a map editor with drawing capabilities.
 * @component
 * @category Native
 * @subcategory Draw
 * @description This component renders a map editor with drawing capabilities.
 */
const Draw = ({ map, getBeforeIdLayerId, setHoverInfo }) => {
  /**
   * The drawInfoDetails and featuresLength from AppContext.
   */
  const { drawInfoDetails, featuresLength } = useContext(AppContext);

  /**
   * The useDraw hook.
   */
  const [
    {
      toggleButtonMode,
      modeHandler,
      inactiveClickingDisclaimer,
      selectedFeatureIndex,
      deleteShapeDisclaimer,
      enableDrawDisclaimer,
    },
    { changeDrawMode, onSelect, onUpdate, onDelete },
    {
      setDrawRef,
      setToggleButtonMode,
      setInactiveClickingDisclaimer,
      setDeleteShapeDisclaimer,
      setEnableDrawDisclaimer,
    },
  ] = useDraw({ map, getBeforeIdLayerId, DRAW_MODES });

  /**
   * The i18n translation function.
   */
  const { t } = useTranslation();

  /**
   * The editorRef function.
   * @param {Object} node - The editor node.
   */
  const editorRef = (node) => {
    if (node !== null) {
      setDrawRef(node);
    }
  };

  return (
    <div>
      <Editor
        ref={editorRef}
        clickRadius={12}
        mode={modeHandler}
        onSelect={onSelect}
        onUpdate={onUpdate}
        editHandleShape={"circle"}
        featureStyle={({ feature, index, state }) => {
          // setDrawnFeatureState(state);
          return getFeatureStyle({ feature, index, state });
        }}
        editHandleStyle={getEditHandleStyle}
        // modeConfig={{ dragToDraw: true }}
      />
      {drawInfoDetails?.map((d, index) => (
        <Popup
          key={index}
          longitude={d.payload.coordinates[0]}
          latitude={d.payload.coordinates[1]}
          closeButton={false}
          anchor="top"
        >
          {`SHAPE ${index + 1}`}
        </Popup>
      ))}

      <DrawMenu
        changeDrawMode={changeDrawMode}
        onDelete={onDelete}
        modes={DRAW_MODES}
        toggleButtonMode={toggleButtonMode}
        setToggleButtonMode={setToggleButtonMode}
        selectedFeatureIndex={selectedFeatureIndex}
        setHoverInfo={setHoverInfo}
        featuresLength={featuresLength}
      />
      <DisclaimerDialog
        open={enableDrawDisclaimer}
        onClose={() => setEnableDrawDisclaimer(false)}
        onClick={() => setEnableDrawDisclaimer(false)}
        info={t(`layerPanelDisclaimer.drawDisclaimer`)}
      />
      <DisclaimerDialog
        open={deleteShapeDisclaimer}
        onClose={() => setDeleteShapeDisclaimer(false)}
        onClick={() => setDeleteShapeDisclaimer(false)}
        info={t(`layerPanelDisclaimer.deleteShapeDisclaimer`)}
      />
      <DisclaimerDialog
        open={inactiveClickingDisclaimer}
        onClose={() => setInactiveClickingDisclaimer(false)}
        onClick={() => setInactiveClickingDisclaimer(false)}
        info={t(`layerPanelDisclaimer.inactiveClickingDisclaimer`)}
      />
    </div>
  );
};

Draw.propTypes = {
  /**
   * The map object.
   */
  map: PropTypes.object,
  /**
   * A function that returns the layer id.
   */
  getBeforeIdLayerId: PropTypes.func,
  /**
   * A function that sets the hover info.
   */
  setHoverInfo: PropTypes.func,
};

export default Draw;
