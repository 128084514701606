import { createContext, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { history } from "../utils/history";
import useLayerConfig from "../hooks/Map/useLayerConfig";
import LayerGroups from "../config/initialLayerGroups";

/**
 * AppContext is a context object that provides data and functions related to the app.
 * @category Context
 */
const AppContext = createContext();

const pathLocation = history.location.pathname;
const layerGroups = pathLocation.includes("/sadc")
  ? LayerGroups("sadc")
  : pathLocation.includes("/ecowas")
  ? LayerGroups("ecowas")
  : pathLocation.includes("/east")
  ? LayerGroups("east")
  : [];
const layerArray = layerGroups.map((group) => group.items).flat(1);

/**
 * Returns a layer's default units of measurement to be used for conversion.
 * @private
 * @returns {Object} unitObject - Object with layerId as key and unit as value.
 */
const GETLAYERUNITS = () => {
  let unitObject = {};
  layerArray.forEach(
    (layer) => (unitObject[layer.layerId] = layer.legendInfo.unit)
  );
  return unitObject;
};

/**
 * AppProvider is a component that provides the AppContext to its children.
 * @component
 * @category Context
 */
const AppProvider = ({ children }) => {
  const [{ LAYER_GROUP_CONFIG, LAYER_CONFIG }, layerConfigHandlers] =
    useLayerConfig();
  const [checkedLayers, setCheckedLayers] = useState([]);
  const [toggleLayer, setToggleLayer] = useState(true);
  const [toggleResultPanel, setToggleResultPanel] = useState({
    resultPanelOpen: false,
    accordionOpen: false,
    panel: "",
  });
  const [stepIndex, setStepIndex] = useState(0);
  const [convertedLayerUnits, setConvertedLayerUnits] = useState(
    GETLAYERUNITS()
  );
  const [openCostFilterDisclaimer, setOpenCostFilterDisclaimer] =
    useState(false);
  const [costFilterDisclaimerCount, setCostFilterDisclaimerCount] = useState(0);
  const [drawInfoDetails, setDrawInfoDetails] = useState(null);
  const [featuresLength, setFeaturesLength] = useState(null);

  /**
   * The value object holds various state variables and functions related to the app.
   * @type {Object}
   * @property {Object} LAYER_GROUP_CONFIG - Configuration for layer groups.
   * @property {Object} LAYER_CONFIG - Configuration for layers.
   * @property {Object} layerConfigHandlers - Functions for handling layer configuration.
   * @property {Array} checkedLayers - List of checked layers.
   * @property {function} setCheckedLayers - Function to set the list of checked layers.
   * @property {boolean} toggleLayer - Determines whether a layer is toggled on or off.
   * @property {function} setToggleLayer - Function to set the state of layer toggle.
   * @property {Object} toggleResultPanel - Configuration for the result panel.
   * @property {function} setToggleResultPanel - Function to set the result panel configuration.
   * @property {number} stepIndex - The current step index.
   * @property {function} setStepIndex - Function to set the step index.
   * @property {string} pathLocation - The path location.
   * @property {Array} layerGroups - List of layer groups.
   * @property {Array} layerArray - List of layers.
   * @property {Object} convertedLayerUnits - Units of measurement for layers.
   * @property {function} setConvertedLayerUnits - Function to set the units of measurement.
   * @property {boolean} openCostFilterDisclaimer - Determines whether the cost filter disclaimer is open.
   * @property {function} setOpenCostFilterDisclaimer - Function to set the state of the cost filter disclaimer.
   * @property {number} costFilterDisclaimerCount - Count of cost filter disclaimer displays.
   * @property {function} setCostFilterDisclaimerCount - Function to set the count of cost filter disclaimer displays.
   * @property {Object} drawInfoDetails - Details of drawn information.
   * @property {function} setDrawInfoDetails - Function to set the drawn information details.
   * @property {number} featuresLength - The length of features.
   * @property {function} setFeaturesLength - Function to set the features' length.
   */

  const value = {
    LAYER_GROUP_CONFIG,
    LAYER_CONFIG,
    layerConfigHandlers,
    checkedLayers,
    setCheckedLayers,
    toggleLayer,
    setToggleLayer,
    toggleResultPanel,
    setToggleResultPanel,
    stepIndex,
    setStepIndex,
    pathLocation,
    layerGroups,
    layerArray,
    convertedLayerUnits,
    setConvertedLayerUnits,
    openCostFilterDisclaimer,
    setOpenCostFilterDisclaimer,
    costFilterDisclaimerCount,
    setCostFilterDisclaimerCount,
    drawInfoDetails,
    setDrawInfoDetails,
    featuresLength,
    setFeaturesLength,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Define PropTypes for AppProvider
AppProvider.propTypes = {
  children: PropTypes.node,
};

export { AppContext, AppProvider };
