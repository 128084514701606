import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { history } from "./utils/history";
import "./index.css";
import "./i18n";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";
import { StyledEngineProvider } from "@mui/material/styles";

ReactDOM.render(
  <Router history={history}>
    <StyledEngineProvider>
      <App />
    </StyledEngineProvider>
  </Router>,
  document.getElementById("root")
);
// registerServiceWorker();
unregister();
