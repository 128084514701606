import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

import PropTypes from "prop-types";

/**
 * A dialog component that displays information to the user.
 * @component
 * @category Native
 * @subcategory InfoDialog
 * @returns {JSX.Element} - Rendered component.
 */
const InfoDialog = ({ open, onClose, onClick, info }) => {
  /**
   * Function to handle the OK button click event.
   */
  const handleOnClick = () => {
    onClick();
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <DialogContentText component="div">
            <>{info}</>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleOnClick} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

InfoDialog.propTypes = {
  /**
   * Determines if the dialog is open or not.
   * @type {boolean}
   */
  open: PropTypes.bool,
  /**
   * Function to be called when the dialog is closed.
   * @type {function}
   */
  onClose: PropTypes.func,
  /**
   * Function to be called when the OK button is clicked.
   * @type {function}
   */
  onClick: PropTypes.func,
  /**
   * The information to be displayed in the dialog.
   * @type {string}
   */
  info: PropTypes.string,
};

export default InfoDialog;
