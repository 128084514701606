import { axiosInstance } from "../utils/axiosConfig";

export const fetchNewStyle = async (styleID, token) => {
  const response = axiosInstance.get(
    `https://api.mapbox.com/styles/v1/fzj/${styleID}?access_token=${token}`
  );
  return response;
};

// Make a request to the Mapbox Geocoding API
export const getCoordinatesFromGeocoder = async (countryName, isoCode) => {
  const response = await axiosInstance.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      countryName
    )}.json?country=${isoCode}&access_token=${
      process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
    }`
  );
  const features = response.data.features;
  if (features.length > 0 && features[0].bbox) {
    // Seychelles is the only country that does not have an accurate bbox, hence an estimate
    if (isoCode === "SC") {
      const southwest = [55.2, -5.5]; // [longitude, latitude];
      const northeast = [56.6, -3.5]; // [longitude, latitude]
      return { southwest: southwest, northeast: northeast };
    }
    const southwest = features[0].bbox.slice(0, 2); // [longitude, latitude];
    const northeast = features[0].bbox.slice(2, 4); // [longitude, latitude]

    // Use the southwest and northeast coordinates as needed
    return { southwest: southwest, northeast: northeast };
  }
};
