/**
 * This file contains the style for the About component using Material-UI.
 * @name AboutMUIStyle
 * @category MUI
 * @returns {Object} The style object.
 */
const style = () => ({
  dialogBox: {
    "& .MuiDialogContentText-root": {
      margin: 0,
    },
    "& .MuiTypography-colorTextSecondary": {
      color: "inherit",
    },
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: `calc(100% - 264px)`,
    },
  },

  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  table: {
    minWidth: 700,
  },
  tableContainer: {
    maxHeight: 400,
  },
  dialogTitle: {
    fontSize: "2.125rem",
    fontWeight: 400,
    lineHeight: 1.235,
  },
});
export default style;
