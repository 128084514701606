import { useEffect, useState } from "react";

/**
 * A hook that tracks the cursor position within a specified container element.
 * @category Hooks
 * @component
 */
const useCursorPosition = () => {
  /**
   * State hook that tracks whether the cursor is visible or not.
   * @type {[boolean, function]}
   */
  const [isVisible, setIsVisible] = useState(false);

  /**
   * State hook that tracks the cursor position.
   * @type {[{ top: number, left: number }, function]}
   */
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });

  /**
   * Updates the cursor position state based on the mouse event.
   * @param {MouseEvent} e - The mouse event.
   */
  const updateCursorPosition = (e) =>
    setCursorPosition({ top: e.clientY - 100, left: e.clientX });

  useEffect(() => {
    /**
     * Sets the cursor visibility state to true.
     */
    const handleMouseEnter = () => setIsVisible(true);

    /**
     * Sets the cursor visibility state to false.
     */
    const handleMouseLeave = () => setIsVisible(false);

    if (document.querySelector("#map-container")) {
      document
        .querySelector("#map-container")
        .addEventListener("mousemove", updateCursorPosition);
      document
        .querySelector("#map-container")
        .addEventListener("mouseenter", updateCursorPosition);
      document
        .querySelector("#map-container")
        .addEventListener("mouseenter", handleMouseEnter);
      document
        .querySelector("#map-container")
        .addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (document.querySelector("#map-container")) {
        document
          .querySelector("#map-container")
          .removeEventListener("mousemove", updateCursorPosition);
        document
          .querySelector("#map-container")
          .removeEventListener("mouseenter", updateCursorPosition);
        document
          .querySelector("#map-container")
          .addEventListener("mouseenter", handleMouseEnter);
        document
          .querySelector("#map-container")
          .addEventListener("mouseleave", handleMouseLeave);
      }
    };
  });

  /**
   * The state object returned by the hook.
   * @type {{ isVisible: boolean, cursorPosition: { top: number, left: number } }}
   */
  const state = { isVisible, cursorPosition };

  /**
   * An empty object that contains event handlers.
   * @type {Object}
   */
  const handlers = {};

  /**
   * An empty object that contains state setters.
   * @type {Object}
   */
  const setters = {};

  return [state, handlers, setters];
};

export default useCursorPosition;
