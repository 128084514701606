/**
 * This file contains the style for the About component using Material-UI.
 * @name FooterMUIStyle
 * @category MUI
 * @returns {Object} The style object.
 */
const style = (theme) => ({
  appBar: {
    zIndex: 1000,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "90px",
    boxSizing: "border-box",
    boxShadow: "0px 0px 3px #434343",
    backgroundColor: "rgba(255,255,255)",
  },
  legalTerms: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flexBasis: "13rem",
      "& span": {
        fontSize: "0.6rem",
      },
    },
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& span": {
      marginLeft: "1rem",
    },
    "& a": {
      color: "#023d6b",
      textDecorationColor: "#023d6b",
    },
  },

  iek3Wrapper: {
    [theme.breakpoints.down("md")]: {
      "& span": {
        fontSize: "0.4rem",
      },
    },
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  iek3Header: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.4rem",
    },
    color: theme.palette.primary.main,
    fontSize: "0.7rem",
  },
  iek3SubHeader: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.4rem",
    },
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "0.7rem",
  },
  footerLogo: {
    [theme.breakpoints.down("md")]: {
      flexBasis: "13rem",
    },
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "1em",
    width: "100%",
    "& img": {
      width: "100%",
    },
    "& a:first-child": {
      marginRight: "0.5rem",
    },
  },
});

export default style;
