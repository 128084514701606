import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

/**
 * A tooltip component that displays additional information when the user hovers over or clicks on an element.
 *
 * @component
 * @category Native
 * @subcategory Tooltip
 * @returns {JSX.Element}
 */
const MyTooltip = ({
  title,
  className,
  disableFocusListener = false,
  disableHoverListener = false,
  disableTouchListener = false,
  onClose,
  open = false,
  onClick,
}) => {
  /**
   * Callback fired when the tooltip is requesting to be closed.
   *
   * @param {object} event - The event source of the callback.
   * @param {string} reason - Can be: `"escapeKeyDown"`, `"clickaway"`, `"tabKeyDown"`.
   */
  const handleClose = (event, reason) => {
    if (onClose) {
      onClose(event, reason);
    }
  };

  /**
   * Callback fired when the tooltip is clicked.
   *
   * @param {object} event - The event source of the callback.
   */
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Tooltip
      className={className}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      onClose={handleClose}
      open={open}
      onClick={handleClick}
      title={<Typography variant="caption">{title}</Typography>}
    >
      <IconButton
        sx={{
          fontSize: 15,
          py: 0,
          pr: 0,
          color: "black",
        }}
      >
        <InfoIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

MyTooltip.propTypes = {
  /**
   * The tooltip title.
   */
  title: PropTypes.string,
  /**
   * The tooltip class name.
   */
  className: PropTypes.string,
  /**
   * If `true`, the focus event listener will be disabled.
   */
  disableFocusListener: PropTypes.bool,
  /**
   * If `true`, the hover event listener will be disabled.
   */
  disableHoverListener: PropTypes.bool,
  /**
   * If `true`, the touch event listener will be disabled.
   */
  disableTouchListener: PropTypes.bool,
  /**
   * Callback fired when the tooltip is requesting to be closed.
   */
  onClose: PropTypes.func,
  /**
   * If `true`, the tooltip is shown.
   */
  open: PropTypes.bool,
  /**
   * Callback fired when the tooltip is clicked.
   */
  onClick: PropTypes.func,
};

export default MyTooltip;
