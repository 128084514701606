import React, { Suspense } from "react";
import Loader from "./Loader";
import PropTypes from "prop-types";

/**
 * Renders a component with a loading screen fallback.
 * @category Native
 * @subcategory LoadScreen
 * @component
 * @returns {ReactNode} - The rendered component.
 */
const LoadScreen = ({ component }) => {
  return (
    <Suspense
      fallback={
        <Loader displayHeight="100vh" thickness={5} size={100}></Loader>
      }
    >
      {component}
    </Suspense>
  );
};

LoadScreen.propTypes = {
  /**
   * The component to be rendered.
   */
  component: PropTypes.node,
};

export default LoadScreen;
