import React, { useState } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

import "./Draw.css";
import MyTooltip from "../../UI/Tooltip/MyTooltip";
import DrawTip from "./DrawTip";
import InfoDialog from "../../UI/InfoDialog/InfoDialog";
import style from "./MUIStyle";
import useCursorPosition from "../../../hooks/Draw/useCursorPosition";
import useDeleteKey from "../../../hooks/Draw/useDeleteKey";

const useStyles = makeStyles(style);
const cursorMessage = {
  drawLine: "drawLine",
  drawPolygon: "drawPolygon",
  drawRectangle: "drawRectangle",
  edit: "edit",
};

import PropTypes from "prop-types";

/**
 * A component that renders a menu for drawing on a map.
 * @component
 * @category Native
 * @subcategory Draw
 * @param {Object} props - The component props.
 * @param {Function} props.changeDrawMode - A function that changes the draw mode.
 * @param {Function} props.onDelete - A function that deletes a feature.
 * @param {Array} props.modes - An array of objects that represent the draw modes.
 * @param {String} props.toggleButtonMode - The current toggle button mode.
 * @param {Function} props.setToggleButtonMode - A function that sets the toggle button mode.
 * @param {Number} props.featuresLength - The number of features.
 * @param {Function} props.setHoverInfo - A function that sets the hover info.
 * @returns {JSX.Element} - The component's markup.
 */
const DrawMenu = ({
  changeDrawMode,
  onDelete,
  modes,
  toggleButtonMode,
  setToggleButtonMode,
  featuresLength,
  setHoverInfo,
}) => {
  /**
   * A hook that returns the cursor position.
   */
  const [{ isVisible, cursorPosition }] = useCursorPosition();

  /**
   * A hook that listens for the delete key press.
   */
  useDeleteKey(onDelete, featuresLength);

  /**
   * A state that holds the draw tip.
   */
  const [drawTip, setDrawTip] = useState("none");

  /**
   * A hook that returns the translation function.
   */
  const { t } = useTranslation();

  /**
   * A state that holds the tooltip open status.
   */
  const [tooltipOpen, setTooltipOpen] = useState(false);

  /**
   * A function that handles the draw mode change.
   * @param {Object} event - The event object.
   * @param {String} newMode - The new mode.
   */
  const handleMode = (event, newMode) => {
    event.stopPropagation();
    if (!newMode) {
      setToggleButtonMode("");
      changeDrawMode(null);
      setDrawTip("none");
    }
    if (newMode !== null && newMode !== "erase") {
      changeDrawMode(event);
      setToggleButtonMode(newMode);
      setDrawTip("block");
    } else {
      // setToggleButtonMode("");
      // changeDrawMode(null);
      // setDrawTip("none");
    }
  };

  /**
   * The component's styles.
   */
  const classes = useStyles();

  return (
    <div>
      {toggleButtonMode && (
        <p
          style={{
            position: "absolute",
            display: drawTip,
            color: "white",
            opacity: isVisible && cursorPosition.left > 1 ? 1 : 0,
            background: "black",
            border: "1px solid",
            fontSize: "0.7rem",
            paddingBottom: "2px",
            paddingRight: "2px",
            paddingTop: "2px",
            paddingLeft: "12px",
            maxWidth: "7rem",
            ...cursorPosition,
          }}
        >
          {t(`drawMenu.cursorMessage.${cursorMessage[toggleButtonMode]}`)}
        </p>
      )}
      <Box className={classes.drawToolsWrapper}>
        <ToggleButtonGroup
          className={classes.drawToolsToggle}
          id="draw-tools-wrapper"
          exclusive
          value={toggleButtonMode}
          onChange={handleMode}
          onMouseEnter={() => setHoverInfo(null)}
        >
          {modes.map((icon) => (
            <ToggleButton
              key={icon.id}
              value={icon.id}
              title={t(`drawMenu.${icon.id}`)}
              className={classes.iconButton}
            >
              <Icon className={icon.iconClassName} />
            </ToggleButton>
          ))}
          <ToggleButton
            onClick={onDelete}
            value="erase"
            title={t(`drawMenu.erase`)}
            className={classes.iconButton}
          >
            <Icon className="fas fa-trash-alt" />
          </ToggleButton>
        </ToggleButtonGroup>
        <MyTooltip
          className={classes.drawTooltip}
          onClick={() => setTooltipOpen(true)}
          title={t("drawMenu.tooltipMessage")}
        />

        <InfoDialog
          open={tooltipOpen}
          onClose={() => setTooltipOpen(false)}
          onClick={() => setTooltipOpen(false)}
          info={<DrawTip />}
        />
      </Box>
    </div>
  );
};

DrawMenu.propTypes = {
  changeDrawMode: PropTypes.func,
  onDelete: PropTypes.func,
  modes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      iconClassName: PropTypes.string,
    })
  ),
  toggleButtonMode: PropTypes.string,
  setToggleButtonMode: PropTypes.func,
  featuresLength: PropTypes.number,
  setHoverInfo: PropTypes.func,
};

export default DrawMenu;
