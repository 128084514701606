import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import FZJLogo from "../../../assets/fzj_logo_pdf.png";
// import FZJLogo from "../../../assets/fzj_new_200.png";
import wascalLogo from "../../../assets/wasscal_logo_original_pdf.png";
// import wascalLogo from "../../../assets/wasscal_new_200.png";
import sasscalLogo from "../../../assets/sasscal-logo_original_pdf.png";
import h2atlasLogo from "../../../assets/h2atlas_logo_pdf.jpg";
// import h2atlasLogo from "../../../assets/h2atlas_new_200.png";
import BMBFLogo from "../../../assets/BMBF.JPG";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/**
 * Generates a PDF file containing information about a region.
 * @category Native
 * @subcategory Export
 * @component
 * @param {string} regionName - The name of the region.
 * @param {boolean} factsheetPanelLoading - A boolean indicating if the factsheet panel is loading.
 * @param {string} pathLocation - The path location of the file.
 * @returns {JSX.Element} - A JSX Element.
 */
const Export = ({ regionName, factsheetPanelLoading, pathLocation }) => {
  const { t } = useTranslation();

  /**
   * Creates a section in the PDF.
   * @param {object} doc - The jsPDF document object.
   * @param {string} html - The HTML content to be added to the section.
   * @param {number} startBuffer - The starting buffer of the section.
   * @param {object} margins - The margins of the section.
   * @param {function} translate - A function that translates text.
   * @param {string} image - The image to be added to the section.
   * @returns {Promise<void>} - A promise that resolves when the section is created.
   */
  const createSection = async (doc, html, startBuffer, margins, translate) => {
    let finalY = doc.lastAutoTable.finalY || 35;
    const pageWidth = doc.internal.pageSize.getWidth();
    const linkText = translate(
      "nationalFactsheet.titles.Created by H2 Atlas Africa"
    );

    autoTable(doc, {
      startY: finalY + startBuffer,
      html: html,
      includeHiddenHtml: true,
      useCss: true,
      margin: { bottom: margins.bottom, top: 20 },
      pageBreak: "auto",
      didDrawPage: async (data) => {
        // Header
        doc.setFont("helvetica");
        doc.setFontSize(8);
        doc.setDrawColor(0, 0, 255);
        doc.setTextColor("rgb(0,65,118)");
        doc.textWithLink(linkText, pageWidth - 55, 7, {
          url: "https://africa.h2atlas.de",
        });
        const textWidth = doc.getTextWidth(linkText);
        doc.line(pageWidth - 55, 8, pageWidth - 55 + textWidth, 8);

        // Footer
        const getDateTime = ` ${new Date().toLocaleString(undefined, {
          dateStyle: "medium",
          timeStyle: "short",
        })}`;
        const footerString =
          translate("nationalFactsheet.titles.generated on") + getDateTime;
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(footerString, data.settings.margin.right, pageHeight - 10);
      },
    });

    doc.setTextColor("rgb(0,65,118)");
  };

  /**
   * Exports the region factsheet to PDF.
   * @param {string} regionName - The name of the region.
   * @param {string} pathLocation - The path location of the file.
   * @param {function} translate - A function that translates text.
   * @param {string} image - The image to be added to the PDF.
   * @returns {Promise<void>} - A promise that resolves when the PDF is saved.
   */
  const exportToPDF = async (regionName, pathLocation, translate, image) => {
    const doc = new jsPDF();
    var totalPagesExp = "{total_pages_count_string}";
    const heading = translate("nationalFactsheet.titles.factsheet");
    const subHeadingPart1 = translate(
      "nationalFactsheet.titles.factsheetSubHeading.part1"
    );
    const subHeadingPart2 = translate(
      "nationalFactsheet.titles.factsheetSubHeading.part2"
    );

    doc.addImage(h2atlasLogo, 14, 15);
    doc.addImage(FZJLogo, 54, 15);
    doc.addImage(BMBFLogo, 124, 15);

    pathLocation.includes("sadc")
      ? doc.addImage(sasscalLogo, 160, 15)
      : pathLocation.includes("sadc")
      ? doc.addImage(wascalLogo, 160, 15)
      : null;

    doc.setTextColor("rgb(0,65,118)");
    doc.setFontSize(8);
    doc.setFont("helvetica", "italic");
    doc.text(subHeadingPart1, 14, 35);
    doc.text(subHeadingPart2, 14, 40);
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(`${heading}: ${regionName}`, 14, 55);
    doc.setFontSize(15);

    // Section 0
    createSection(
      doc,
      "#facts-sheet-table-0",
      25,
      {
        bottom: 20,
      },
      translate
    );
    createSection(
      doc,
      "#facts-sheet-footnote-0",
      5,
      {
        bottom: 0,
      },
      translate
    );
    createSection(
      doc,
      "#facts-sheet-reference-0",
      5,
      {
        bottom: 0,
      },
      translate
    );

    // Section 1
    createSection(
      doc,
      "#facts-sheet-table-1",
      10,
      {
        bottom: 20,
      },
      translate
    );
    createSection(
      doc,
      "#facts-sheet-footnote-1",
      5,
      {
        bottom: 0,
      },
      translate
    );
    createSection(
      doc,
      "#facts-sheet-reference-1",
      5,
      {
        bottom: 0,
      },
      translate
    );

    // Section 2
    createSection(
      doc,
      "#facts-sheet-table-2",
      20,
      {
        bottom: 20,
      },
      translate,
      image
    );
    createSection(
      doc,
      "#facts-sheet-footnote-2",
      5,
      {
        bottom: 0,
      },
      translate
    );

    // Section 3
    createSection(
      doc,
      "#facts-sheet-table-3",
      10,
      {
        bottom: 20,
      },
      translate
    );
    createSection(
      doc,
      "#facts-sheet-footnote-3",
      5,
      {
        bottom: 0,
      },
      translate
    );

    // Section 4
    createSection(
      doc,
      "#facts-sheet-table-4",
      10,
      {
        bottom: 20,
      },
      translate
    );
    createSection(
      doc,
      "#facts-sheet-footnote-4",
      5,
      {
        bottom: 0,
      },
      translate
    );

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(`${regionName}-factsheet.pdf`);
  };

  return (
    <div>
      <Button
        onClick={() => exportToPDF(regionName, pathLocation, t)}
        size="small"
        variant="contained"
        endIcon={<FileDownloadIcon />}
        disabled={factsheetPanelLoading}
      >
        {t("nationalFactsheet.titles.export to pdf")}
      </Button>
    </div>
  );
};

Export.propTypes = {
  regionName: PropTypes.string,
  factsheetPanelLoading: PropTypes.bool,
  pathLocation: PropTypes.string,
};

export default Export;
