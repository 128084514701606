import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { AppContext } from "../../../contexts/AppContext";
import { useState } from "react";
import { Container, Link } from "@mui/material";

const useStyles = makeStyles(() => ({
  gridWrapper: {
    minHeight: "100vh",
  },
  stepper: {
    maxWidth: "35rem",
  },
}));

const mapboxStudioCredentialsInput = {
  AWS_ACCESS_KEY_ID: "",
  AWS_SECRET_ACCESS_KEY: "",
  AWS_SESSION_TOKEN: "",
  BUCKET: "",
  KEY: "",
};

const steps = [
  {
    label: "Tippecanoe: Select region and layer to update to obtain command",
    id: "select layer",
    description: [
      `Selecting a layer populates the tippecanoe commands to run with the right parameters.`,
      `Take note of the projection.`,
    ],
    content: () => [],
  },

  {
    label: "Upload to Mapbox Studio: Retrieve temporary credentials",
    id: "retrieve temporary credentials",
    description: [
      "Before initializing upload, temporary credentials should be retrieved by typing into the command line",
    ],
    content: () => {
      const exampleResponseCredentialRetrieval = `
            {
              "bucket": "tilestream-tilesets-production",
              "key": "3c/_pending/mhv5wxsqnnj24pn3869bbunlc/fzj",
              "accessKeyId": "ASIATNLVGLR2EI4WDE4H",
              "secretAccessKey": "Qw2Ri0M0Xvi3/YKZk8PisU36z9wUvWsyeFVXpeoK",
              sessionToken:
                "FwoGZXIvYXdzEIb///wEaDD/KS9l4ALUZYcGFIyKKAjXb/NgDm7/+XL5zdMt8LvmtJ0m7NyldZ2xhPoyWNqnSe3XCkq2/sYGa2dlcMjRG5LuveCDcWZXndWy8oeeYh+sIGFQxCEYV/7c7onW7Kw9cAtkTR3HmNT5MinX58l7fgMPVTeJPX1u1IJNxWwoVEfCMRAQVrWUP3Pz13WP5RoqYCGxe4qOdatc+MMWEu8BQx1oVZeKbQlIkEDhzC8/zh5WMvIP02vljXbz9cAQ0J9Gp4S9hLlyzGlqJ3GXkEBhsL+f+MoEPT4Q/AUvNAxo2sdGuPrvd9RdU6iKetzNOAZdbBOSXVp2AZncfQC2E6fQVeINDfgbVchaTvK6HnlJQXC2DBx2FNXnEz8UoKLqBuqkGMinswwev+5Eykme7t78/PAS5JBMh/grn8NjS4d/3oxchK30rr1mceJQ98Q==",
              url: "https://tilestream-tilesets-production.s3.amazonaws.com/3c/_pending/mhv5wxsqnnj24pn3869bbunlc/fzj",
            }
            `;
      const command = [
        `curl -X POST --ssl-no-revoke "https://api.mapbox.com/uploads/v1/fzj/credentials?access_token=${process.env.REACT_APP_MAPBOX_SECRET_TOKEN}"`,
        exampleResponseCredentialRetrieval,
      ];
      // setCopyCommand = command;
      return { command };
    },
    additionalInformation: "",
  },
  {
    label: "Upload to Mapbox Studio: Set environment variables",
    id: "set environment variables",
    description: [
      <>
        Three environment variables have to be set to allow upload. Replace{" "}
        <code>set</code> with <code>export</code> for Ubuntu/Linux.
      </>,
    ],
    content: ({ mapboxStudioCredentials }) => {
      console.log(mapboxStudioCredentials, "mapboxStudioCredentials");
      const command = [
        <>
          export AWS_ACCESS_KEY_ID={mapboxStudioCredentials.AWS_ACCESS_KEY_ID}
        </>,
        <>
          export AWS_SECRET_ACCESS_KEY=
          {mapboxStudioCredentials.AWS_SECRET_ACCESS_KEY}
        </>,
        <>
          export AWS_SESSION_TOKEN={mapboxStudioCredentials.AWS_SESSION_TOKEN}
        </>,
      ];

      return { command };
    },
    additionalInformation: "",
  },
  {
    label: "Upload to Mapbox Studio: Stage dataset",
    description: [
      <>
        Stage the already converted <code>.mbtile</code> dataset on AWS by
        typing into the command line:
      </>,
      <>
        Replace <code>bucket</code> and <code>key</code> with the response
        information returned in the previous step, without the {} ofcourse
      </>,
    ],
    content: ({ mapboxStudioCredentials }) => {
      const command = [
        <>
          aws s3 cp path/to/data.mbtiles s3://{mapboxStudioCredentials.BUCKET}/
          {mapboxStudioCredentials.KEY} --region us-east-1
        </>,
      ];
      return { command };
    },
    additionalInformation: "",
  },
  {
    label: "Upload to Mapbox Studio: Begin upload",
    id: "begin upload",
    description: [
      "To begin upload to Mapbox studio, type into the command line:",
    ],
    content: ({ REGION, mapboxName, mapboxId, mapboxStudioCredentials }) => {
      const tilesetId = mapboxId.match(/_(.+)/)?.[1];
      console.log(tilesetId, "tilesetId");
      const command = [
        <>
          curl -X POST --ssl-no-revoke -H "Content-Type: application/json" -H
          "Cache-Control: no-cache" -d '
          {`{
          "url": "http://${mapboxStudioCredentials.BUCKET}.s3.amazonaws.com/${mapboxStudioCredentials.KEY}",
          "tileset": "fzj.${REGION}_${tilesetId}",
          "name": "${REGION}_${mapboxName}"
        }`}
          '
          "https://api.mapbox.com/uploads/v1/fzj?access_token=sk.eyJ1IjoiZnpqIiwiYSI6ImNrcXVzcHJwZTA3czcycG81bWZrOWtnYWYifQ.NHGi5WXxeKCGl6kQuf277Q"
        </>,
      ];

      return { command };
    },
    additionalInformation: "",
  },
  {
    label: "Upload to Mapbox Studio: Successful upload",
    description: [
      "On successful upload, you should get a response like the one below. A progress of 0 means 'incomplete', and 1 means complete",
      "A progress of 0 means 'incomplete', and 1 means 'complete'",
      <>
        You could go to the{" "}
        <a href="https://studio.mapbox.com/tilesets/" target="blank">
          Mapbox Studio tilesets
        </a>{" "}
        page and peep on the bottom right.
      </>,
    ],
    content: ({ REGION, mapboxName, mapboxId }) => {
      const tilesetId = mapboxId.match(/_(.+)/)?.[1];
      const command = [
        `{
            "complete": false,
            "tileset": "fzj.${tilesetId}",
            "error": null,
            "id": "{upload-id}",
            "name": "${REGION}_${mapboxName}",
            "modified": "{timestamp}",
            "created": "{timestamp}",
            "owner": "fzj",
            "progress": 0
          }

          `,
      ];
      return { command };
    },
    additionalInformation: "",
  },
  {
    label: "Update Postgres Databases for Development and Production",
    id: "update pg",
    description: [
      "Update the databases with the geojson format of your dataset for this particular layer, you had already created in previous steps of the tutorial. The pre-requisite to run the commands below is to install the [GDAL] program as stated in the readme",
    ],
    content: ({ sourceId }) => {
      const command = [
        <>
          ##Start an SSH Tunnel to the Production server
          <br />
          <br />
          ssh -L 2000:localhost:25432 sysadmin@134.94.130.208
        </>,

        <>
          ##In a seperate terminal window, update both development and
          production databases
          <br />
          <br />
          ogr2ogr -f "PostgreSQL" PG:"dbname=h2atlas host=localhost
          user=postgres port=25432 password=password" /path/to/geojson -nln{" "}
          {sourceId} -append && ogr2ogr -f "PostgreSQL" PG:"dbname=h2atlas
          host=localhost user=postgres port=2000 password=password"
          /path/to/geojson -nln {sourceId} -append
        </>,
      ];
      return { command };
    },
    additionalInformation: "",
  },
];
const DataUpload = () => {
  const { LAYER_CONFIG, pathLocation } = React.useContext(AppContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [layer, setLayer] = useState("");
  const [projection, setProjection] = useState("EPSG:3857");
  const [errorMessage, setErrorMessage] = useState("");
  const [mapboxStudioCredentials, setMapboxStudioCredentials] = useState(
    mapboxStudioCredentialsInput
  );
  const [copiedValue, setCopiedValue] = useState(null);

  const REGION = pathLocation.includes("ecowas")
    ? "ecowas"
    : pathLocation.includes("sadc")
    ? "sadc"
    : pathLocation.includes("east")
    ? "east"
    : "";

  // const { layer } = layerVariables;

  const getTippecanoeCommand = (layerId) => {
    console.log(layerId, "layerId");
    const getFileDetails = LAYER_CONFIG.find((layer) =>
      layer.layerId.includes(layerId)
    );
    let { uploadGroup, sourceLayer, mapboxName } = getFileDetails;
    return uploadGroup.includes("hydropower")
      ? `tippecanoe -o ${REGION}_${mapboxName}.mbtiles -f -Z0 -z10 -b0 -r1 -pk -pf -ai -l ${sourceLayer} -s ${projection} /path/to/file.geojson`
      : uploadGroup.includes("renewable")
      ? `tippecanoe -o ${REGION}_${mapboxName}.mbtiles -f --drop-densest-as-needed --extend-zooms-if-still-dropping -z10 -Z0 -no-duplication -ai -l ${sourceLayer} -s ${projection} /path/to/file.geojson`
      : uploadGroup.includes("hydrogen")
      ? `tippecanoe -o ${REGION}_${mapboxName}.mbtiles -f --extend-zooms-if-still-dropping -z10 -no-duplication -ai -l ${sourceLayer} -s ${projection} -y region_id -y GID_0 -y NAME_0 -y GID_1 -y NAME_1 -y GID_2 -y NAME_2 -y Aream2 -y 01LhEkg20 -y 25LhEkg20 -y 50LhEkg20 -y 00LhEkg20 -y 01LhEkg50 -y 25LhEkg50 -y 50LhEkg50 -y 00LhEkg50 -y H2TPAkWhm2 -y H2GTPperc -y H2SPAkWhm2 -y NATPTWha -y NASPTWha -y NAGTPperc -y NA01LTEkg2 -y NA01LTEkg5 -y NA25LTEkg2 -y NA25LTEkg5 -y NA50LTEkg2 -y NA50LTEkg5 -y 25TPGWha -y 50TPGWha -y 01TPGWha -y 00TPGWha /path/to/geojson_file`
      : uploadGroup.includes("raster")
      ? `Rasters do not need to be converted to mbtiles with tippecanoe. Proceed to the next step to upload directly to Mapbox Studio`
      : `tippecanoe -o ${REGION}_${mapboxName}.mbtiles -f --extend-zooms-if-still-dropping -z10 -no-duplication -ai -l ${sourceLayer} -s ${projection} /path/to/geojson_file`;
  };

  const handleCredentials = (event) => {
    setMapboxStudioCredentials({
      ...mapboxStudioCredentials,
      [event.target.name]: event.target.value,
    });
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    if (activeStep === 0) {
      if (layer) {
        setActiveStep(newActiveStep);
        setErrorMessage("");
      } else {
        setErrorMessage("Fill in the empty field(s)!");
      }
    } else if (activeStep === 1) {
      if (
        Object.values(mapboxStudioCredentials).filter((item) => item).length ===
        5
      ) {
        setActiveStep(newActiveStep);
        setErrorMessage("");
      } else {
        setErrorMessage("Fill in the empty field(s)!");
      }
    } else {
      setActiveStep(newActiveStep);
    }

    // setActiveStep(newActiveStep);
  };

  console.log(activeStep, "activeStep");
  // console.log(newActiveStep, "newActiveStep");

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleSkip = (stepId) => {
    if (stepId === "select layer") {
      setActiveStep(4);
    } else if (stepId === "begin upload") {
      setActiveStep(0);
    }
  };

  React.useEffect(() => {
    // exception for hydrogen layers' default projection
    if (layer.includes("h2")) {
      setProjection("EPSG:4326");
    }
  }, [layer]);

  const classes = useStyles();

  const copyTextRef = React.useCallback(
    (node) => {
      if (!node) return;
      setCopiedValue({ ...copiedValue, [node.id + "code"]: node.textContent });
    },
    [copiedValue && copiedValue[0]]
  );

  return (
    <Container id="data-upload" maxWidth="md">
      <Grid
        container
        spacing={2}
        // direction="column"
        alignItems="center"
        justifyContent="center"
        // className={classes.gridWrapper}
      >
        <Grid item sm={12}>
          <Typography gutterBottom variant="h5">
            Upload Procedure
          </Typography>
          <Typography gutterBottom variant="h6">
            Video Tutorials
          </Typography>
          <Typography gutterBottom>
            You can follow the video tutorials below to learn how to upload data
            to Mapbox Studio and update the database accordingly for viewing on
            the GUI.
          </Typography>
          <Typography gutterBottom>Part 1:</Typography>
          <video
            controls
            width={500}
            src="https://fz-juelich.sciebo.de/s/aYZ1ZlwKTFNtfzq/download"
            poster="https://fz-juelich.sciebo.de/s/nYAgQeyQLvGE8pM/download"
          >
            Sorry, your browser doesn't support embedded videos, but don't
            worry, you can
            <a href="https://fz-juelich.sciebo.de/s/aYZ1ZlwKTFNtfzq/download">
              download it
            </a>
            and watch it with your favorite video player!
          </video>
          <Typography gutterBottom>Part 2</Typography>
          <video
            width={500}
            controls
            src="https://fz-juelich.sciebo.de/s/FkpKSAwE2q5jn1A/download"
            poster="https://fz-juelich.sciebo.de/s/nYAgQeyQLvGE8pM/download"
          >
            Sorry, your browser doesn't support embedded videos, but don't
            worry, you can
            <a href="https://fz-juelich.sciebo.de/s/FkpKSAwE2q5jn1A/download">
              download it
            </a>
            and watch it with your favorite video player!
          </video>
          <Typography gutterBottom variant="h6">
            Stepper Tutorials
          </Typography>
          <Typography gutterBottom variant="body1">
            The stepper below is a guide on how to upload datasets to Mapbox
            Studio, and update the database accordingly for viewing on the GUI.
            <br></br>
            <b>
              Please make sure to go through the previous sections in the{" "}
              <Link
                href="https://jugit.fz-juelich.de/iek-3/projects/h2atlasafrica/WP8_GUI_and_H2Atlas/-/blob/develop/Readme.md?ref_type=heads"
                target="_blank"
                rel="noreferrer"
              >
                README
              </Link>{" "}
              or in the{" "}
              <Link href="/docs/index.html" target="_blank" rel="noreferrer">
                DOCS
              </Link>{" "}
              before continuing
            </b>
          </Typography>
          <Stepper
            style={{ padding: 24 }}
            activeStep={activeStep}
            orientation="vertical"
          >
            {steps.map((step, index) => (
              <Step className={classes.stepper} key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  <Box maxWidth="fit-content" sx={{ mb: 2 }}>
                    {step.description.map((item, index) => (
                      <Typography sx={{ mb: 2 }} key={index}>
                        {item}
                      </Typography>
                    ))}
                  </Box>
                  {step.id === "select layer" ? (
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          size="small"
                          name="region"
                          label="Region"
                          value={REGION}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          select
                          name="layer"
                          size="small"
                          label="Layer"
                          value={layer}
                          onChange={(e) => setLayer(e.target.value)}
                        >
                          {LAYER_CONFIG.map((layer, index) => {
                            return (
                              <MenuItem key={index} value={layer.layerId}>
                                {layer.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          select
                          name="projection"
                          size="small"
                          label="Projection"
                          value={projection}
                          onChange={(e) => setProjection(e.target.value)}
                        >
                          {["EPSG:3857", "EPSG:4326"].map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      {layer && (
                        <code
                          style={{
                            display: "block",
                            maxWidth: "fit-content",
                            border: "1px solid",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            wordBreak: "break-word",
                          }}
                        >
                          <span>
                            ## Run this command in the Ubuntu Linux environment
                            directory <br /> <br />
                          </span>
                          {getTippecanoeCommand(layer)}
                        </code>
                      )}
                      <Grid item xs={12}>
                        <FormHelperText error>{errorMessage}</FormHelperText>
                      </Grid>
                    </Grid>
                  ) : step.id === "retrieve temporary credentials" ? (
                    <Grid container spacing={2}>
                      {step.content().command.map((code, index) => (
                        <Grid item xs={12} key={index}>
                          {index === 1 && (
                            <Typography>Example Response</Typography>
                          )}
                          <code
                            ref={copyTextRef}
                            id={`${index}code`}
                            style={{
                              display: "block",
                              maxWidth: "fit-content",
                              border: "1px solid",
                              padding: "0.5rem",
                              margin: "0.5rem",
                              wordBreak: "break-word",
                            }}
                          >
                            {code}
                          </code>
                        </Grid>
                      ))}

                      <Grid item xs={12}>
                        <Typography>
                          Copy and paste the response from your command line in
                          the boxes below according to the keys. <br /> See the
                          example response above for guidance:
                        </Typography>
                      </Grid>

                      {Object.entries(mapboxStudioCredentials).map(
                        ([key, value], index) => (
                          <Grid key={index} item xs={4}>
                            <TextField
                              name={key}
                              value={value}
                              size="small"
                              label={key}
                              onChange={handleCredentials}
                            />
                          </Grid>
                        )
                      )}
                      <Grid item xs={12}>
                        <FormHelperText error>{errorMessage}</FormHelperText>
                      </Grid>
                    </Grid>
                  ) : (
                    LAYER_CONFIG?.filter((data) => data.layerId === layer).map(
                      (data, index) => {
                        const {
                          mapboxName,
                          sourceLayer,
                          groupName,
                          mapboxId,
                          sourceId,
                        } = data;

                        return (
                          <Box
                            key={index}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            {step
                              .content({
                                REGION,
                                mapboxName,
                                groupName,
                                mapboxId,
                                sourceId,
                                sourceLayer,
                                mapboxStudioCredentials,
                              })
                              .command.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <code
                                      key={index}
                                      ref={copyTextRef}
                                      id={index}
                                      style={{
                                        display: "block",
                                        maxWidth: "fit-content",
                                        border: "1px solid",
                                        padding: "0.5rem",
                                        margin: "0.5rem",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {item}
                                    </code>
                                  </div>
                                );
                              })}
                            {step.additionalInformation && (
                              <Box>
                                <Typography>
                                  {step.additionalInformation}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        );
                      }
                    )
                  )}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? "Finish" : "Continue"}
                      </Button>
                      {((step.id === "select layer" &&
                        layer &&
                        mapboxStudioCredentials.BUCKET) ||
                        step.id === "begin upload") && (
                        <Button
                          variant="contained"
                          onClick={() => handleSkip(step.id)}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {step.id === "select layer"
                            ? "Skip to Upload"
                            : "Skip to Layer Select"}
                        </Button>
                      )}

                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
                {step.id === "select layer" && (
                  <Typography>
                    <b>Selected Layer:</b>{" "}
                    {LAYER_CONFIG.find((item) => item.layerId === layer)?.name}
                  </Typography>
                )}
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default DataUpload;
