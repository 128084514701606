/**
 * This file contains the style for the About component using Material-UI.
 * @name NavbarMUIStyle
 * @category MUI
 * @returns {Object} The style object.
 */
const style = (theme) => ({
  appBar: {
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "90px",
    boxSizing: "border-box",
    boxShadow: "0px 0px 3px #434343",
    backgroundColor: "rgba(255,255,255)",
    position: "relative",
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  toolbarCollapse: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    display: "flex",
    flexDirection: "column",
  },
  fzjLogo: {
    display: "flex",
    alignItems: "center",
    "& img": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "1rem",
        marginRight: "1rem",
      },
      display: "block",
      marginLeft: "3rem",
      marginRight: "3rem",
    },
  },
  title: {
    textTransform: "uppercase",
  },
  list: {
    "& li": {
      padding: 0,
    },
  },
  dialogBox: {
    "& .MuiDialogContentText-root": {
      margin: 0,
    },
    "& .MuiTypography-colorTextSecondary": {
      color: "inherit",
    },
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: `calc(100% - 264px)`,
    },
  },
  buttonToolbar: {
    paddingTop: "0",
    paddingBottom: "0",
    "&:hover": {
      backgroundColor: "#023d6b",
      color: "#ffffff",
    },
    textTransform: "uppercase",
  },
  menuItem: {
    textTransform: "uppercase",

    "&:hover": {
      backgroundColor: "#adbde3",
    },
  },
  translationBar: {
    margin: "0.3rem 1rem",
    "& .MuiIconButton-sizeSmall": {
      fontSize: "0.9rem",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },
  },
  buttonTranslationBar: {
    padding: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "#023d6b",
      color: "#ffffff",
    },
    textTransform: "uppercase",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
});

export default style;
