/**
 * This file contains the MUI style for the draw components
 * @name DrawMUIStyle
 * @param {Object} theme - The material UI theme object.
 * @category MUI
 */
const style = (theme) => ({
  iconButton: {
    padding: "5px",
    "& .fas": {
      fontSize: "0.8rem",
      color: "black",
    },
    "&:hover": {
      backgroundColor: theme.palette.atlas.main,
    },
    borderRadius: "unset",
  },
  drawToolsToggle: {
    backgroundColor: `rgba(255,255,255,0.8)`,
    height: "1.8rem",
    display: "flex",
    boxSizing: "border-box",
    boxShadow: "0px 0px 3px #434343",
    borderRadius: "5px",
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.atlas.main,
    },
  },
  drawToolsWrapper: {
    [theme.breakpoints.down("md")]: {
      top: "50px",
    },
    position: "absolute",
    top: "40px",
    right: 0,
    zIndex: 100,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  drawTooltip: {
    backgroundColor: `rgba(255,255,255,0.8)`,
    borderRadius: "50%",
    padding: "2px",
    width: "1.5rem",
    height: "1.5rem",
    fontSize: "18px",
  },
});

export default style;
