import React, { useState, useEffect } from "react";
import { Button, Snackbar } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

/**
 * A component that displays a notification to refresh the page after a certain number of days have passed since the last refresh.
 * @category Native
 * @subcategory Refresh
 * @component
 * @returns {JSX.Element} The Refresh component.
 */
const Refresh = () => {
  const [showNotification, setShowNotification] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const targetDays = 3; // show every 3 days
    const lastRefreshDate = localStorage.getItem("lastRefreshDate");
    if (lastRefreshDate) {
      const diffInDays = Math.floor(
        (new Date() - new Date(lastRefreshDate)) / (1000 * 60 * 60 * 24)
      );
      if (diffInDays >= targetDays) {
        setShowNotification(true);
        localStorage.setItem("lastRefreshDate", new Date());
      }
    } else {
      localStorage.setItem("lastRefreshDate", new Date());
    }
    // setShowNotification(true);
  });

  /**
   * Reloads the page when the Refresh button is clicked.
   */
  const handleRefresh = () => {
    window.location.reload();
  };

  /**
   * Closes the notification when the Close button is clicked.
   * @param {object} event - The event object.
   * @param {string} reason - The reason for closing the notification.
   */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowNotification(false);
  };

  const action = (
    <Stack direction="row" gap="10px">
      <Button
        sx={{ backgroundColor: "green" }}
        size="small"
        variant="contained"
        onClick={handleRefresh}
      >
        REFRESH
      </Button>
      <Button
        size="small"
        variant="contained"
        aria-label="close"
        color="error"
        onClick={handleClose}
      >
        {" "}
        CLOSE
      </Button>
    </Stack>
  );

  return (
    <Snackbar
      open={showNotification}
      // autoHideDuration={6000}
      onClose={handleClose}
      sx={{ width: "100%" }}
      message={t("refreshBar.content")}
      action={action}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    />
  );
};

export default Refresh;
