import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import style from "./MUIStyle";

const useStyles = makeStyles(style);

import PropTypes from "prop-types";

/**
 * A dialog box that displays a disclaimer message.
 * @component
 * @category Native
 * @subcategory Disclaimer
 */
const Disclaimer = ({ handleCloseDisclaimerDialog, openDisclaimerDialog }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      data-testid="disclaimer-panel"
      className={classes.disclaimerDialogBox}
      open={openDisclaimerDialog}
      onClose={handleCloseDisclaimerDialog}
    >
      <DialogTitle>{t("disclaimer.part1")}</DialogTitle>
      <DialogContent>
        <div>
          <Typography>{t("disclaimer.part2")}</Typography>
          <DialogContentText>
            {" "}
            <>{t("disclaimer.part3")}</>{" "}
          </DialogContentText>
        </div>{" "}
        <br />
        <div>
          <Typography>{t("disclaimer.part4")}</Typography>
          <ul>
            <li>
              <DialogContentText>
                {" "}
                <> {t("disclaimer.part5")}</>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                <>{t("disclaimer.part6")}</>{" "}
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                <>{t("disclaimer.part7")}</>{" "}
              </DialogContentText>
            </li>
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="disclaimer-OK"
          variant="contained"
          onClick={handleCloseDisclaimerDialog}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Disclaimer.propTypes = {
  /**
   * A function that handles the closing of the disclaimer dialog box.
   */
  handleCloseDisclaimerDialog: PropTypes.func,
  /**
   * A boolean that determines if the disclaimer dialog box is open or not.
   */
  openDisclaimerDialog: PropTypes.bool,
};

export default Disclaimer;
