import React, { useContext } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { symbolsConverter } from "../../../utils/symbolsConverter";
import { AppContext } from "../../../contexts/AppContext";
import currencyAndUnitConverter from "../../../utils/currencyAndUnitConverter";

const shapeProperties = {
  LineString: {
    type: "Distance",
    unit: "km",
  },
  Polygon: {
    type: "Area",
    unit: "km2",
  },
};

/**
 * Renders the details of a shape drawn on the map, including its type, value, and associated layer information.
 * @category Native
 * @subcategory Draw
 * @component
 * @returns {JSX.Element} The ShapeDetails component.
 */
const ShapeDetails = () => {
  const { convertedLayerUnits, drawInfoDetails } = useContext(AppContext);
  const { t } = useTranslation();
  const theme = useTheme();

  /**
   * Converts the default unit to a new unit and rounds up the value.
   * @param {string} defaultUnit - The default unit to be converted.
   * @param {string} newUnit - The new unit to convert to.
   * @param {number} value - The value to be converted.
   * @returns {number} The converted and rounded up value.
   */
  const getConvertedandRoundedUpValue = (defaultUnit, newUnit, value) => {
    return currencyAndUnitConverter.convert(defaultUnit, newUnit, value);
  };

  return (
    <Box mt={-3.5}>
      {[...drawInfoDetails]?.reverse().map((d, index, arr) => {
        const unitConverted =
          d?.payload?.feature && convertedLayerUnits[d.payload.feature.layerId];
        return (
          <Box mt={1} key={index}>
            <Box pt={1}>
              <DialogTitle component="div" sx={{ m: 0, p: 0 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Stack direction="column">
                    <Typography
                      display="inline-block"
                      textTransform={"uppercase"}
                      fontWeight={900}
                    >
                      {`${t("drawMenu.shape")} ${arr.length - index}`}
                    </Typography>
                  </Stack>
                </Stack>
              </DialogTitle>
              <DialogContent
                component="div"
                sx={{
                  pr: 1,
                  py: 1,
                  pl: 0,
                  maxHeight: 250,
                  overflowY: "auto",
                }}
                dividers
              >
                <Stack
                  mb={0.5}
                  gap={1}
                  fontSize={theme.typography.body2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Chip
                    label={
                      d && t(`drawMenu.${shapeProperties[d.payload.type].type}`)
                    }
                    size="small"
                    color="primary"
                    sx={{ fontSize: theme.typography.caption }}
                  />
                  <Typography variant="subtitle2">
                    {d &&
                      symbolsConverter(
                        `${d.payload.value} ${
                          shapeProperties[d.payload.type].unit
                        }`
                      )}
                  </Typography>
                </Stack>
              </DialogContent>
            </Box>

            {d.payload.feature && (
              <>
                <DialogContent
                  component="div"
                  sx={{
                    pr: 1,
                    py: 0,
                    pl: 0,
                    maxHeight: 250,
                    overflowY: "auto",
                  }}
                  dividers
                >
                  <DialogContentText
                    component={DialogContent}
                    textAlign="center"
                    key={index}
                    sx={{ p: 0 }}
                    dividers
                  >
                    <Typography
                      variant="subtitle"
                      fontSize="0.8rem"
                      sx={{ fontWeight: 900 }}
                    >
                      {d.payload.feature.layerName}
                    </Typography>
                    <Stack
                      mb={0.5}
                      gap={1}
                      fontSize={theme.typography.body2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Chip
                        label={
                          d.payload.feature.layerType.includes("lcoe")
                            ? t(`drawMenu.Number of Plants`)
                            : t(`drawMenu.Number of Regions`)
                        }
                        size="small"
                        color="primary"
                        sx={{ fontSize: theme.typography.caption }}
                      />
                      <Typography variant="body2">
                        {d.payload.feature.numberOfFeatures}
                      </Typography>
                    </Stack>
                    <Stack
                      mb={0.5}
                      gap={1}
                      fontSize={theme.typography.body2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Chip
                        label={t(`drawMenu.Average`)}
                        size="small"
                        color="primary"
                        sx={{ fontSize: theme.typography.caption }}
                      />
                      <Typography variant="body2">
                        ~{" "}
                        {getConvertedandRoundedUpValue(
                          d.payload.feature.unit,
                          unitConverted,
                          d.payload.feature.averageQuantity
                        )}{" "}
                        {symbolsConverter(unitConverted)}
                      </Typography>
                    </Stack>
                    <Stack
                      mb={0.5}
                      gap={1}
                      fontSize={theme.typography.body2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Chip
                        label={t(`drawMenu.Minimum`)}
                        size="small"
                        color="primary"
                        sx={{ fontSize: theme.typography.caption }}
                      />
                      <Typography variant="body2">
                        ~{" "}
                        {getConvertedandRoundedUpValue(
                          d.payload.feature.unit,
                          unitConverted,
                          d.payload.feature.minQuantity
                        )}{" "}
                        {symbolsConverter(unitConverted)}
                      </Typography>
                    </Stack>
                    <Stack
                      mb={0.5}
                      gap={1}
                      fontSize={theme.typography.body2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Chip
                        label={t(`drawMenu.Maximum`)}
                        size="small"
                        color="primary"
                        sx={{ fontSize: theme.typography.caption }}
                      />
                      <Typography variant="body2">
                        ~{" "}
                        {getConvertedandRoundedUpValue(
                          d.payload.feature.unit,
                          unitConverted,
                          d.payload.feature.maxQuantity
                        )}{" "}
                        {symbolsConverter(unitConverted)}
                      </Typography>
                    </Stack>
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default React.memo(ShapeDetails);
