/**
 * REGIONS is a constant object that holds region data.
 * @category Data
 * @private
 */
const REGIONS = {
  ecowas: [
    {
      region_id: "BEN.1.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.1_1",
      NAME_1: "Alibori",
      NL_NAME_1: "None",
      GID_2: "BEN.1.1_1",
      NAME_2: "Banikoara",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.1.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.1_1",
      NAME_1: "Alibori",
      NL_NAME_1: "None",
      GID_2: "BEN.1.2_1",
      NAME_2: "Gogounou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.1.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.1_1",
      NAME_1: "Alibori",
      NL_NAME_1: "None",
      GID_2: "BEN.1.3_1",
      NAME_2: "Kandi",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.1.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.1_1",
      NAME_1: "Alibori",
      NL_NAME_1: "None",
      GID_2: "BEN.1.4_1",
      NAME_2: "Karimama",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.1.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.1_1",
      NAME_1: "Alibori",
      NL_NAME_1: "None",
      GID_2: "BEN.1.5_1",
      NAME_2: "Malanville",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.1.6_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.1_1",
      NAME_1: "Alibori",
      NL_NAME_1: "None",
      GID_2: "BEN.1.6_1",
      NAME_2: "Segbana",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.1_1",
      NAME_2: "Boukoumbé",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.2_1",
      NAME_2: "Cobly",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.3_1",
      NAME_2: "Kérou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.4_1",
      NAME_2: "Kouandé",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.5_1",
      NAME_2: "Matéri",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.6_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.6_1",
      NAME_2: "Natitingou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.7_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.7_1",
      NAME_2: "Péhunco",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.8_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.8_1",
      NAME_2: "Tanguiéta",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.2.9_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.2_1",
      NAME_1: "Atakora",
      NL_NAME_1: "None",
      GID_2: "BEN.2.9_1",
      NAME_2: "Toucountouna",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.3.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.3_1",
      NAME_1: "Atlantique",
      NL_NAME_1: "None",
      GID_2: "BEN.3.1_1",
      NAME_2: "Abomey-Calavi",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.3.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.3_1",
      NAME_1: "Atlantique",
      NL_NAME_1: "None",
      GID_2: "BEN.3.2_1",
      NAME_2: "Allada",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.3.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.3_1",
      NAME_1: "Atlantique",
      NL_NAME_1: "None",
      GID_2: "BEN.3.3_1",
      NAME_2: "Kpomassè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.3.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.3_1",
      NAME_1: "Atlantique",
      NL_NAME_1: "None",
      GID_2: "BEN.3.4_1",
      NAME_2: "Ouidah",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.3.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.3_1",
      NAME_1: "Atlantique",
      NL_NAME_1: "None",
      GID_2: "BEN.3.5_1",
      NAME_2: "Sô-Ava",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.3.6_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.3_1",
      NAME_1: "Atlantique",
      NL_NAME_1: "None",
      GID_2: "BEN.3.6_1",
      NAME_2: "Toffo",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.3.7_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.3_1",
      NAME_1: "Atlantique",
      NL_NAME_1: "None",
      GID_2: "BEN.3.7_1",
      NAME_2: "Tori-Bossito",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.3.8_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.3_1",
      NAME_1: "Atlantique",
      NL_NAME_1: "None",
      GID_2: "BEN.3.8_1",
      NAME_2: "Zè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.4.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.4_1",
      NAME_1: "Borgou",
      NL_NAME_1: "None",
      GID_2: "BEN.4.1_1",
      NAME_2: "Bembéréké",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.4.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.4_1",
      NAME_1: "Borgou",
      NL_NAME_1: "None",
      GID_2: "BEN.4.2_1",
      NAME_2: "Kalalé",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.4.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.4_1",
      NAME_1: "Borgou",
      NL_NAME_1: "None",
      GID_2: "BEN.4.3_1",
      NAME_2: "N'Dali",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.4.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.4_1",
      NAME_1: "Borgou",
      NL_NAME_1: "None",
      GID_2: "BEN.4.4_1",
      NAME_2: "Nikki",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.4.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.4_1",
      NAME_1: "Borgou",
      NL_NAME_1: "None",
      GID_2: "BEN.4.5_1",
      NAME_2: "Parakou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.4.6_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.4_1",
      NAME_1: "Borgou",
      NL_NAME_1: "None",
      GID_2: "BEN.4.6_1",
      NAME_2: "Pèrèrè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.4.7_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.4_1",
      NAME_1: "Borgou",
      NL_NAME_1: "None",
      GID_2: "BEN.4.7_1",
      NAME_2: "Sinendé",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.4.8_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.4_1",
      NAME_1: "Borgou",
      NL_NAME_1: "None",
      GID_2: "BEN.4.8_1",
      NAME_2: "Tchaourou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.5.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.5_1",
      NAME_1: "Collines",
      NL_NAME_1: "None",
      GID_2: "BEN.5.1_1",
      NAME_2: "Bantè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.5.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.5_1",
      NAME_1: "Collines",
      NL_NAME_1: "None",
      GID_2: "BEN.5.2_1",
      NAME_2: "Dassa-Zoumè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.5.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.5_1",
      NAME_1: "Collines",
      NL_NAME_1: "None",
      GID_2: "BEN.5.3_1",
      NAME_2: "Glazoué",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.5.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.5_1",
      NAME_1: "Collines",
      NL_NAME_1: "None",
      GID_2: "BEN.5.4_1",
      NAME_2: "Ouèssè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.5.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.5_1",
      NAME_1: "Collines",
      NL_NAME_1: "None",
      GID_2: "BEN.5.5_1",
      NAME_2: "Savalou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.5.6_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.5_1",
      NAME_1: "Collines",
      NL_NAME_1: "None",
      GID_2: "BEN.5.6_1",
      NAME_2: "Savè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.6.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.6_1",
      NAME_1: "Donga",
      NL_NAME_1: "None",
      GID_2: "BEN.6.1_1",
      NAME_2: "Bassila",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.6.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.6_1",
      NAME_1: "Donga",
      NL_NAME_1: "None",
      GID_2: "BEN.6.2_1",
      NAME_2: "Copargo",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.6.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.6_1",
      NAME_1: "Donga",
      NL_NAME_1: "None",
      GID_2: "BEN.6.3_1",
      NAME_2: "Djougou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.6.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.6_1",
      NAME_1: "Donga",
      NL_NAME_1: "None",
      GID_2: "BEN.6.4_1",
      NAME_2: "Ouaké",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.7.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.7_1",
      NAME_1: "Kouffo",
      NL_NAME_1: "None",
      GID_2: "BEN.7.1_1",
      NAME_2: "Aplahoué",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.7.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.7_1",
      NAME_1: "Kouffo",
      NL_NAME_1: "None",
      GID_2: "BEN.7.2_1",
      NAME_2: "Djakotomey",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.7.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.7_1",
      NAME_1: "Kouffo",
      NL_NAME_1: "None",
      GID_2: "BEN.7.3_1",
      NAME_2: "Dogbo",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.7.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.7_1",
      NAME_1: "Kouffo",
      NL_NAME_1: "None",
      GID_2: "BEN.7.4_1",
      NAME_2: "Klouékanmè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.7.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.7_1",
      NAME_1: "Kouffo",
      NL_NAME_1: "None",
      GID_2: "BEN.7.5_1",
      NAME_2: "Lalo",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.7.6_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.7_1",
      NAME_1: "Kouffo",
      NL_NAME_1: "None",
      GID_2: "BEN.7.6_1",
      NAME_2: "Toviklin",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.8.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.8_1",
      NAME_1: "Littoral",
      NL_NAME_1: "None",
      GID_2: "BEN.8.1_1",
      NAME_2: "Cotonou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.9.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.9_1",
      NAME_1: "Mono",
      NL_NAME_1: "None",
      GID_2: "BEN.9.1_1",
      NAME_2: "Athiémé",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.9.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.9_1",
      NAME_1: "Mono",
      NL_NAME_1: "None",
      GID_2: "BEN.9.2_1",
      NAME_2: "Bopa",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.9.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.9_1",
      NAME_1: "Mono",
      NL_NAME_1: "None",
      GID_2: "BEN.9.3_1",
      NAME_2: "Comè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.9.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.9_1",
      NAME_1: "Mono",
      NL_NAME_1: "None",
      GID_2: "BEN.9.4_1",
      NAME_2: "Grand-Popo",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.9.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.9_1",
      NAME_1: "Mono",
      NL_NAME_1: "None",
      GID_2: "BEN.9.5_1",
      NAME_2: "Houéyogbé",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.1_1",
      NAME_2: "Adjarra",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.2_1",
      NAME_2: "Adjohoun",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.3_1",
      NAME_2: "Aguégués",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.4_1",
      NAME_2: "Akpro-Missérété",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.5_1",
      NAME_2: "Avrankou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.6_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.6_1",
      NAME_2: "Bonou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.7_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.7_1",
      NAME_2: "Dangbo",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.8_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.8_1",
      NAME_2: "Porto-Novo",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.10.9_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.10_1",
      NAME_1: "Ouémé",
      NL_NAME_1: "None",
      GID_2: "BEN.10.9_1",
      NAME_2: "Sèmè-Kpodji",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.11.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.11_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "BEN.11.1_1",
      NAME_2: "Adja-Ouèrè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.11.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.11_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "BEN.11.2_1",
      NAME_2: "Ifangni",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.11.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.11_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "BEN.11.3_1",
      NAME_2: "Kétou",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.11.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.11_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "BEN.11.4_1",
      NAME_2: "Pobè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.11.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.11_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "BEN.11.5_1",
      NAME_2: "Sakété",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.1_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.1_1",
      NAME_2: "Abomey",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.2_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.2_1",
      NAME_2: "Agbangnizoun",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.3_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.3_1",
      NAME_2: "Bohicon",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.4_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.4_1",
      NAME_2: "Covè",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.5_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.5_1",
      NAME_2: "Djidja",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.6_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.6_1",
      NAME_2: "Ouinhi",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.7_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.7_1",
      NAME_2: "Za-Kpota",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.8_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.8_1",
      NAME_2: "Zagnanado",
      isoCode: "BJ",
    },
    {
      region_id: "BEN.12.9_1",
      GID_0: "BEN",
      NAME_0: "Benin",
      GID_1: "BEN.12_1",
      NAME_1: "Zou",
      NL_NAME_1: "None",
      GID_2: "BEN.12.9_1",
      NAME_2: "Zogbodomey",
      isoCode: "BJ",
    },
    {
      region_id: "BFA.1.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.1_1",
      NAME_1: "Boucle du Mouhoun",
      NL_NAME_1: "None",
      GID_2: "BFA.1.1_1",
      NAME_2: "Balé",
      isoCode: "BF",
    },
    {
      region_id: "BFA.1.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.1_1",
      NAME_1: "Boucle du Mouhoun",
      NL_NAME_1: "None",
      GID_2: "BFA.1.2_1",
      NAME_2: "Banwa",
      isoCode: "BF",
    },
    {
      region_id: "BFA.1.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.1_1",
      NAME_1: "Boucle du Mouhoun",
      NL_NAME_1: "None",
      GID_2: "BFA.1.3_1",
      NAME_2: "Kossi",
      isoCode: "BF",
    },
    {
      region_id: "BFA.1.4_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.1_1",
      NAME_1: "Boucle du Mouhoun",
      NL_NAME_1: "None",
      GID_2: "BFA.1.4_1",
      NAME_2: "Mouhoun",
      isoCode: "BF",
    },
    {
      region_id: "BFA.1.5_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.1_1",
      NAME_1: "Boucle du Mouhoun",
      NL_NAME_1: "None",
      GID_2: "BFA.1.5_1",
      NAME_2: "Nayala",
      isoCode: "BF",
    },
    {
      region_id: "BFA.1.6_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.1_1",
      NAME_1: "Boucle du Mouhoun",
      NL_NAME_1: "None",
      GID_2: "BFA.1.6_1",
      NAME_2: "Sourou",
      isoCode: "BF",
    },
    {
      region_id: "BFA.2.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.2_1",
      NAME_1: "Cascades",
      NL_NAME_1: "None",
      GID_2: "BFA.2.1_1",
      NAME_2: "Comoé",
      isoCode: "BF",
    },
    {
      region_id: "BFA.2.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.2_1",
      NAME_1: "Cascades",
      NL_NAME_1: "None",
      GID_2: "BFA.2.2_1",
      NAME_2: "Léraba",
      isoCode: "BF",
    },
    {
      region_id: "BFA.3.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.3_1",
      NAME_1: "Centre-Est",
      NL_NAME_1: "None",
      GID_2: "BFA.3.1_1",
      NAME_2: "Boulgou",
      isoCode: "BF",
    },
    {
      region_id: "BFA.3.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.3_1",
      NAME_1: "Centre-Est",
      NL_NAME_1: "None",
      GID_2: "BFA.3.2_1",
      NAME_2: "Koulpélogo",
      isoCode: "BF",
    },
    {
      region_id: "BFA.3.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.3_1",
      NAME_1: "Centre-Est",
      NL_NAME_1: "None",
      GID_2: "BFA.3.3_1",
      NAME_2: "Kouritenga",
      isoCode: "BF",
    },
    {
      region_id: "BFA.4.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.4_1",
      NAME_1: "Centre-Nord",
      NL_NAME_1: "None",
      GID_2: "BFA.4.1_1",
      NAME_2: "Bam",
      isoCode: "BF",
    },
    {
      region_id: "BFA.4.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.4_1",
      NAME_1: "Centre-Nord",
      NL_NAME_1: "None",
      GID_2: "BFA.4.2_1",
      NAME_2: "Namentenga",
      isoCode: "BF",
    },
    {
      region_id: "BFA.4.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.4_1",
      NAME_1: "Centre-Nord",
      NL_NAME_1: "None",
      GID_2: "BFA.4.3_1",
      NAME_2: "Sanmatenga",
      isoCode: "BF",
    },
    {
      region_id: "BFA.5.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.5_1",
      NAME_1: "Centre-Ouest",
      NL_NAME_1: "None",
      GID_2: "BFA.5.1_1",
      NAME_2: "Boulkiemdé",
      isoCode: "BF",
    },
    {
      region_id: "BFA.5.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.5_1",
      NAME_1: "Centre-Ouest",
      NL_NAME_1: "None",
      GID_2: "BFA.5.2_1",
      NAME_2: "Sanguié",
      isoCode: "BF",
    },
    {
      region_id: "BFA.5.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.5_1",
      NAME_1: "Centre-Ouest",
      NL_NAME_1: "None",
      GID_2: "BFA.5.3_1",
      NAME_2: "Sissili",
      isoCode: "BF",
    },
    {
      region_id: "BFA.5.4_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.5_1",
      NAME_1: "Centre-Ouest",
      NL_NAME_1: "None",
      GID_2: "BFA.5.4_1",
      NAME_2: "Ziro",
      isoCode: "BF",
    },
    {
      region_id: "BFA.6.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.6_1",
      NAME_1: "Centre-Sud",
      NL_NAME_1: "None",
      GID_2: "BFA.6.1_1",
      NAME_2: "Bazèga",
      isoCode: "BF",
    },
    {
      region_id: "BFA.6.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.6_1",
      NAME_1: "Centre-Sud",
      NL_NAME_1: "None",
      GID_2: "BFA.6.2_1",
      NAME_2: "Nahouri",
      isoCode: "BF",
    },
    {
      region_id: "BFA.6.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.6_1",
      NAME_1: "Centre-Sud",
      NL_NAME_1: "None",
      GID_2: "BFA.6.3_1",
      NAME_2: "Zoundwéogo",
      isoCode: "BF",
    },
    {
      region_id: "BFA.7.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.7_1",
      NAME_1: "Centre",
      NL_NAME_1: "None",
      GID_2: "BFA.7.1_1",
      NAME_2: "Kadiogo",
      isoCode: "BF",
    },
    {
      region_id: "BFA.8.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.8_1",
      NAME_1: "Est",
      NL_NAME_1: "None",
      GID_2: "BFA.8.1_1",
      NAME_2: "Gnagna",
      isoCode: "BF",
    },
    {
      region_id: "BFA.8.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.8_1",
      NAME_1: "Est",
      NL_NAME_1: "None",
      GID_2: "BFA.8.2_1",
      NAME_2: "Gourma",
      isoCode: "BF",
    },
    {
      region_id: "BFA.8.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.8_1",
      NAME_1: "Est",
      NL_NAME_1: "None",
      GID_2: "BFA.8.3_1",
      NAME_2: "Komandjoari",
      isoCode: "BF",
    },
    {
      region_id: "BFA.8.4_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.8_1",
      NAME_1: "Est",
      NL_NAME_1: "None",
      GID_2: "BFA.8.4_1",
      NAME_2: "Kompienga",
      isoCode: "BF",
    },
    {
      region_id: "BFA.8.5_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.8_1",
      NAME_1: "Est",
      NL_NAME_1: "None",
      GID_2: "BFA.8.5_1",
      NAME_2: "Tapoa",
      isoCode: "BF",
    },
    {
      region_id: "BFA.9.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.9_1",
      NAME_1: "Haut-Bassins",
      NL_NAME_1: "None",
      GID_2: "BFA.9.1_1",
      NAME_2: "Houet",
      isoCode: "BF",
    },
    {
      region_id: "BFA.9.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.9_1",
      NAME_1: "Haut-Bassins",
      NL_NAME_1: "None",
      GID_2: "BFA.9.2_1",
      NAME_2: "Kénédougou",
      isoCode: "BF",
    },
    {
      region_id: "BFA.9.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.9_1",
      NAME_1: "Haut-Bassins",
      NL_NAME_1: "None",
      GID_2: "BFA.9.3_1",
      NAME_2: "Tuy",
      isoCode: "BF",
    },
    {
      region_id: "BFA.10.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.10_1",
      NAME_1: "Nord",
      NL_NAME_1: "None",
      GID_2: "BFA.10.1_1",
      NAME_2: "Loroum",
      isoCode: "BF",
    },
    {
      region_id: "BFA.10.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.10_1",
      NAME_1: "Nord",
      NL_NAME_1: "None",
      GID_2: "BFA.10.2_1",
      NAME_2: "Passoré",
      isoCode: "BF",
    },
    {
      region_id: "BFA.10.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.10_1",
      NAME_1: "Nord",
      NL_NAME_1: "None",
      GID_2: "BFA.10.3_1",
      NAME_2: "Yatenga",
      isoCode: "BF",
    },
    {
      region_id: "BFA.10.4_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.10_1",
      NAME_1: "Nord",
      NL_NAME_1: "None",
      GID_2: "BFA.10.4_1",
      NAME_2: "Zondoma",
      isoCode: "BF",
    },
    {
      region_id: "BFA.11.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.11_1",
      NAME_1: "Plateau-Central",
      NL_NAME_1: "None",
      GID_2: "BFA.11.1_1",
      NAME_2: "Ganzourgou",
      isoCode: "BF",
    },
    {
      region_id: "BFA.11.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.11_1",
      NAME_1: "Plateau-Central",
      NL_NAME_1: "None",
      GID_2: "BFA.11.2_1",
      NAME_2: "Kourwéogo",
      isoCode: "BF",
    },
    {
      region_id: "BFA.11.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.11_1",
      NAME_1: "Plateau-Central",
      NL_NAME_1: "None",
      GID_2: "BFA.11.3_1",
      NAME_2: "Oubritenga",
      isoCode: "BF",
    },
    {
      region_id: "BFA.12.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.12_1",
      NAME_1: "Sahel",
      NL_NAME_1: "None",
      GID_2: "BFA.12.1_1",
      NAME_2: "Oudalan",
      isoCode: "BF",
    },
    {
      region_id: "BFA.12.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.12_1",
      NAME_1: "Sahel",
      NL_NAME_1: "None",
      GID_2: "BFA.12.2_1",
      NAME_2: "Séno",
      isoCode: "BF",
    },
    {
      region_id: "BFA.12.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.12_1",
      NAME_1: "Sahel",
      NL_NAME_1: "None",
      GID_2: "BFA.12.3_1",
      NAME_2: "Soum",
      isoCode: "BF",
    },
    {
      region_id: "BFA.12.4_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.12_1",
      NAME_1: "Sahel",
      NL_NAME_1: "None",
      GID_2: "BFA.12.4_1",
      NAME_2: "Yagha",
      isoCode: "BF",
    },
    {
      region_id: "BFA.13.1_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.13_1",
      NAME_1: "Sud-Ouest",
      NL_NAME_1: "None",
      GID_2: "BFA.13.1_1",
      NAME_2: "Bougouriba",
      isoCode: "BF",
    },
    {
      region_id: "BFA.13.2_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.13_1",
      NAME_1: "Sud-Ouest",
      NL_NAME_1: "None",
      GID_2: "BFA.13.2_1",
      NAME_2: "Ioba",
      isoCode: "BF",
    },
    {
      region_id: "BFA.13.3_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.13_1",
      NAME_1: "Sud-Ouest",
      NL_NAME_1: "None",
      GID_2: "BFA.13.3_1",
      NAME_2: "Noumbiel",
      isoCode: "BF",
    },
    {
      region_id: "BFA.13.4_1",
      GID_0: "BFA",
      NAME_0: "Burkina Faso",
      GID_1: "BFA.13_1",
      NAME_1: "Sud-Ouest",
      NL_NAME_1: "None",
      GID_2: "BFA.13.4_1",
      NAME_2: "Poni",
      isoCode: "BF",
    },
    {
      region_id: "CPV.1.1_1",
      GID_0: "CPV",
      NAME_0: "Cape Verde",
      GID_1: "CPV.1_1",
      NAME_1: "Barlavento Islands",
      NL_NAME_1: "None",
      GID_2: " CPV.1.1_1",
      NAME_2: "None",
      isoCode: "CV",
    },
    {
      region_id: "CIV.1.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.1_1",
      NAME_1: "Abidjan",
      NL_NAME_1: "None",
      GID_2: "CIV.1.1_1",
      NAME_2: "Abidjan",
      isoCode: "CI",
    },
    {
      region_id: "CIV.2.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.2_1",
      NAME_1: "Bas-Sassandra",
      NL_NAME_1: "None",
      GID_2: "CIV.2.1_1",
      NAME_2: "Gbôkle",
      isoCode: "CI",
    },
    {
      region_id: "CIV.2.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.2_1",
      NAME_1: "Bas-Sassandra",
      NL_NAME_1: "None",
      GID_2: "CIV.2.2_1",
      NAME_2: "Nawa",
      isoCode: "CI",
    },
    {
      region_id: "CIV.2.3_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.2_1",
      NAME_1: "Bas-Sassandra",
      NL_NAME_1: "None",
      GID_2: "CIV.2.3_1",
      NAME_2: "San-Pédro",
      isoCode: "CI",
    },
    {
      region_id: "CIV.3.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.3_1",
      NAME_1: "Comoé",
      NL_NAME_1: "None",
      GID_2: "CIV.3.1_1",
      NAME_2: "Indénié-Djuablin",
      isoCode: "CI",
    },
    {
      region_id: "CIV.3.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.3_1",
      NAME_1: "Comoé",
      NL_NAME_1: "None",
      GID_2: "CIV.3.2_1",
      NAME_2: "Sud Comoé",
      isoCode: "CI",
    },
    {
      region_id: "CIV.4.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.4_1",
      NAME_1: "Denguélé",
      NL_NAME_1: "None",
      GID_2: "CIV.4.1_1",
      NAME_2: "Folon",
      isoCode: "CI",
    },
    {
      region_id: "CIV.4.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.4_1",
      NAME_1: "Denguélé",
      NL_NAME_1: "None",
      GID_2: "CIV.4.2_1",
      NAME_2: "Kabadougou",
      isoCode: "CI",
    },
    {
      region_id: "CIV.5.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.5_1",
      NAME_1: "Gôh-Djiboua",
      NL_NAME_1: "None",
      GID_2: "CIV.5.1_1",
      NAME_2: "Gôh",
      isoCode: "CI",
    },
    {
      region_id: "CIV.5.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.5_1",
      NAME_1: "Gôh-Djiboua",
      NL_NAME_1: "None",
      GID_2: "CIV.5.2_1",
      NAME_2: "Lôh-Djiboua",
      isoCode: "CI",
    },
    {
      region_id: "CIV.6.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.6_1",
      NAME_1: "Lacs",
      NL_NAME_1: "None",
      GID_2: "CIV.6.1_1",
      NAME_2: "Bélier",
      isoCode: "CI",
    },
    {
      region_id: "CIV.6.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.6_1",
      NAME_1: "Lacs",
      NL_NAME_1: "None",
      GID_2: "CIV.6.2_1",
      NAME_2: "Iffou",
      isoCode: "CI",
    },
    {
      region_id: "CIV.6.3_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.6_1",
      NAME_1: "Lacs",
      NL_NAME_1: "None",
      GID_2: "CIV.6.3_1",
      NAME_2: "Moronou",
      isoCode: "CI",
    },
    {
      region_id: "CIV.6.4_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.6_1",
      NAME_1: "Lacs",
      NL_NAME_1: "None",
      GID_2: "CIV.6.4_1",
      NAME_2: "N'zi",
      isoCode: "CI",
    },
    {
      region_id: "CIV.7.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.7_1",
      NAME_1: "Lagunes",
      NL_NAME_1: "None",
      GID_2: "CIV.7.1_1",
      NAME_2: "Agnéby-Tiassa",
      isoCode: "CI",
    },
    {
      region_id: "CIV.7.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.7_1",
      NAME_1: "Lagunes",
      NL_NAME_1: "None",
      GID_2: "CIV.7.2_1",
      NAME_2: "Grands Ponts",
      isoCode: "CI",
    },
    {
      region_id: "CIV.7.3_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.7_1",
      NAME_1: "Lagunes",
      NL_NAME_1: "None",
      GID_2: "CIV.7.3_1",
      NAME_2: "La Mé",
      isoCode: "CI",
    },
    {
      region_id: "CIV.8.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.8_1",
      NAME_1: "Montagnes",
      NL_NAME_1: "None",
      GID_2: "CIV.8.1_1",
      NAME_2: "Cavally",
      isoCode: "CI",
    },
    {
      region_id: "CIV.8.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.8_1",
      NAME_1: "Montagnes",
      NL_NAME_1: "None",
      GID_2: "CIV.8.2_1",
      NAME_2: "Guémon",
      isoCode: "CI",
    },
    {
      region_id: "CIV.8.3_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.8_1",
      NAME_1: "Montagnes",
      NL_NAME_1: "None",
      GID_2: "CIV.8.3_1",
      NAME_2: "Tonkpi",
      isoCode: "CI",
    },
    {
      region_id: "CIV.9.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.9_1",
      NAME_1: "Sassandra-Marahoué",
      NL_NAME_1: "None",
      GID_2: "CIV.9.1_1",
      NAME_2: "Haut-Sassandra",
      isoCode: "CI",
    },
    {
      region_id: "CIV.9.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.9_1",
      NAME_1: "Sassandra-Marahoué",
      NL_NAME_1: "None",
      GID_2: "CIV.9.2_1",
      NAME_2: "Marahoué",
      isoCode: "CI",
    },
    {
      region_id: "CIV.10.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.10_1",
      NAME_1: "Savanes",
      NL_NAME_1: "None",
      GID_2: "CIV.10.1_1",
      NAME_2: "Bagoué",
      isoCode: "CI",
    },
    {
      region_id: "CIV.10.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.10_1",
      NAME_1: "Savanes",
      NL_NAME_1: "None",
      GID_2: "CIV.10.2_1",
      NAME_2: "Poro",
      isoCode: "CI",
    },
    {
      region_id: "CIV.10.3_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.10_1",
      NAME_1: "Savanes",
      NL_NAME_1: "None",
      GID_2: "CIV.10.3_1",
      NAME_2: "Tchologo",
      isoCode: "CI",
    },
    {
      region_id: "CIV.11.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.11_1",
      NAME_1: "Vallée du Bandama",
      NL_NAME_1: "None",
      GID_2: "CIV.11.1_1",
      NAME_2: "Gbeke",
      isoCode: "CI",
    },
    {
      region_id: "CIV.11.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.11_1",
      NAME_1: "Vallée du Bandama",
      NL_NAME_1: "None",
      GID_2: "CIV.11.2_1",
      NAME_2: "Hambol",
      isoCode: "CI",
    },
    {
      region_id: "CIV.12.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.12_1",
      NAME_1: "Woroba",
      NL_NAME_1: "None",
      GID_2: "CIV.12.1_1",
      NAME_2: "Bafing",
      isoCode: "CI",
    },
    {
      region_id: "CIV.12.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.12_1",
      NAME_1: "Woroba",
      NL_NAME_1: "None",
      GID_2: "CIV.12.2_1",
      NAME_2: "Béré",
      isoCode: "CI",
    },
    {
      region_id: "CIV.12.3_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.12_1",
      NAME_1: "Woroba",
      NL_NAME_1: "None",
      GID_2: "CIV.12.3_1",
      NAME_2: "Worodougou",
      isoCode: "CI",
    },
    {
      region_id: "CIV.13.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.13_1",
      NAME_1: "Yamoussoukro",
      NL_NAME_1: "None",
      GID_2: "CIV.13.1_1",
      NAME_2: "Yamoussoukro",
      isoCode: "CI",
    },
    {
      region_id: "CIV.14.1_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.14_1",
      NAME_1: "Zanzan",
      NL_NAME_1: "None",
      GID_2: "CIV.14.1_1",
      NAME_2: "Bounkani",
      isoCode: "CI",
    },
    {
      region_id: "CIV.14.2_1",
      GID_0: "CIV",
      NAME_0: "Côte d'Ivoire",
      GID_1: "CIV.14_1",
      NAME_1: "Zanzan",
      NL_NAME_1: "None",
      GID_2: "CIV.14.2_1",
      NAME_2: "Gontougo",
      isoCode: "CI",
    },
    {
      region_id: "GHA.1.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.1_1",
      NAME_2: "Adansi North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.2_1",
      NAME_2: "Adansi South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.3_1",
      NAME_2: "Afigya Sekyere",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.4_1",
      NAME_2: "Ahafo Ano North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.5_1",
      NAME_2: "Ahafo Ano South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.6_1",
      NAME_2: "Amansie Central",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.7_1",
      NAME_2: "Amansie East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.8_1",
      NAME_2: "Amansie West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.9_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.9_1",
      NAME_2: "Asante Akim North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.10_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.10_1",
      NAME_2: "Asante Akim South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.11_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.11_1",
      NAME_2: "Atwima",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.12_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.12_1",
      NAME_2: "Atwima Mponua",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.13_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.13_1",
      NAME_2: "Bosomtwe-Kwanwoma",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.14_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.14_1",
      NAME_2: "Ejisu-Juabeng",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.15_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.15_1",
      NAME_2: "Ejura Sekyedumase",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.16_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.16_1",
      NAME_2: "Kumasi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.17_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.17_1",
      NAME_2: "Kwabre",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.18_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.18_1",
      NAME_2: "Obuasi Municipal",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.19_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.19_1",
      NAME_2: "Offinso",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.20_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.20_1",
      NAME_2: "Sekyere East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.1.21_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.1_1",
      NAME_1: "Ashanti",
      NL_NAME_1: "None",
      GID_2: "GHA.1.21_1",
      NAME_2: "Sekyere West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.1_1",
      NAME_2: "Asunafo North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.2_1",
      NAME_2: "Asunafo South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.3_1",
      NAME_2: "Asutifi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.4_1",
      NAME_2: "Atebubu-Amantin",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.5_1",
      NAME_2: "Berekum",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.6_1",
      NAME_2: "Dormaa",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.7_1",
      NAME_2: "Jaman North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.8_1",
      NAME_2: "Jaman South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.9_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.9_1",
      NAME_2: "Kintampo North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.10_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.10_1",
      NAME_2: "Kintampo South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.11_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.11_1",
      NAME_2: "Nkoranza",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.12_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.12_1",
      NAME_2: "Pru",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.13_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.13_1",
      NAME_2: "Sene",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.14_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.14_1",
      NAME_2: "Sunyani",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.15_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.15_1",
      NAME_2: "Tain",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.16_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.16_1",
      NAME_2: "Tano North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.17_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.17_1",
      NAME_2: "Tano South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.2.18_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.2_1",
      NAME_1: "Brong Ahafo",
      NL_NAME_1: "None",
      GID_2: "GHA.2.18_1",
      NAME_2: "Techiman",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.1_1",
      NAME_2: "Abura-Asebu-Kwamankese",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.2_1",
      NAME_2: "Agona",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.3_1",
      NAME_2: "Ajumako-Enyan-Esiam",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.4_1",
      NAME_2: "Asikuma Odoben Brakwa",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.5_1",
      NAME_2: "Assin North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.6_1",
      NAME_2: "Assin South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.7_1",
      NAME_2: "Awutu Efutu Senya",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.8_1",
      NAME_2: "Cape Coast",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.9_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.9_1",
      NAME_2: "Gomoa",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.10_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.10_1",
      NAME_2: "Komenda-Edina-Eguafo-Abirem",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.11_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.11_1",
      NAME_2: "Lower Denkyira",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.12_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.12_1",
      NAME_2: "Mfantsiman",
      isoCode: "GH",
    },
    {
      region_id: "GHA.3.13_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.3_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "GHA.3.13_1",
      NAME_2: "Upper Denkyira",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.1_1",
      NAME_2: "Afram Plains",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.2_1",
      NAME_2: "Akwapim North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.3_1",
      NAME_2: "Akwapim South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.4_1",
      NAME_2: "Asuogyaman",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.5_1",
      NAME_2: "Atiwa",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.6_1",
      NAME_2: "Birim North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.7_1",
      NAME_2: "Birim South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.8_1",
      NAME_2: "East Akim",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.9_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.9_1",
      NAME_2: "Fanteakwa",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.10_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.10_1",
      NAME_2: "Kwabibirem",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.11_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.11_1",
      NAME_2: "Kwahu South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.12_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.12_1",
      NAME_2: "Kwahu West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.13_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.13_1",
      NAME_2: "Manya Krobo",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.14_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.14_1",
      NAME_2: "New Juaben",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.15_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.15_1",
      NAME_2: "Suhum Kraboa Coaltar",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.16_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.16_1",
      NAME_2: "West Akim",
      isoCode: "GH",
    },
    {
      region_id: "GHA.4.17_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.4_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "GHA.4.17_1",
      NAME_2: "Yilo Krobo",
      isoCode: "GH",
    },
    {
      region_id: "GHA.5.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.5_1",
      NAME_1: "Greater Accra",
      NL_NAME_1: "None",
      GID_2: "GHA.5.1_1",
      NAME_2: "Accra",
      isoCode: "GH",
    },
    {
      region_id: "GHA.5.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.5_1",
      NAME_1: "Greater Accra",
      NL_NAME_1: "None",
      GID_2: "GHA.5.2_1",
      NAME_2: "Dangbe East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.5.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.5_1",
      NAME_1: "Greater Accra",
      NL_NAME_1: "None",
      GID_2: "GHA.5.3_1",
      NAME_2: "Dangbe West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.5.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.5_1",
      NAME_1: "Greater Accra",
      NL_NAME_1: "None",
      GID_2: "GHA.5.4_1",
      NAME_2: "Ga East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.5.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.5_1",
      NAME_1: "Greater Accra",
      NL_NAME_1: "None",
      GID_2: "GHA.5.5_1",
      NAME_2: "Ga West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.5.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.5_1",
      NAME_1: "Greater Accra",
      NL_NAME_1: "None",
      GID_2: "GHA.5.6_1",
      NAME_2: "Tema",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.1_1",
      NAME_2: "Bole",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.2_1",
      NAME_2: "Bunkpurugu Yunyoo",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.3_1",
      NAME_2: "Central Gonja",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.4_1",
      NAME_2: "East Gonja",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.5_1",
      NAME_2: "East Mamprusi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.6_1",
      NAME_2: "Gushiegu",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.7_1",
      NAME_2: "Karaga",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.8_1",
      NAME_2: "Nanumba North",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.9_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.9_1",
      NAME_2: "Nanumba South",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.10_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.10_1",
      NAME_2: "Saboba Chereponi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.11_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.11_1",
      NAME_2: "Savelugu Nanton",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.12_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.12_1",
      NAME_2: "Sawa-Tuna-Kalba",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.13_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.13_1",
      NAME_2: "Tamale",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.14_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.14_1",
      NAME_2: "Tolon-Kumbungu",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.15_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.15_1",
      NAME_2: "West Gonja",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.16_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.16_1",
      NAME_2: "West Mamprusi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.17_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.17_1",
      NAME_2: "Yendi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.6.18_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.6_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "GHA.6.18_1",
      NAME_2: "Zabzugu Tatale",
      isoCode: "GH",
    },
    {
      region_id: "GHA.7.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.7_1",
      NAME_1: "Upper East",
      NL_NAME_1: "None",
      GID_2: "GHA.7.1_1",
      NAME_2: "Bawku Municipal",
      isoCode: "GH",
    },
    {
      region_id: "GHA.7.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.7_1",
      NAME_1: "Upper East",
      NL_NAME_1: "None",
      GID_2: "GHA.7.2_1",
      NAME_2: "Bawku West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.7.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.7_1",
      NAME_1: "Upper East",
      NL_NAME_1: "None",
      GID_2: "GHA.7.3_1",
      NAME_2: "Bolgatanga",
      isoCode: "GH",
    },
    {
      region_id: "GHA.7.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.7_1",
      NAME_1: "Upper East",
      NL_NAME_1: "None",
      GID_2: "GHA.7.4_1",
      NAME_2: "Bongo",
      isoCode: "GH",
    },
    {
      region_id: "GHA.7.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.7_1",
      NAME_1: "Upper East",
      NL_NAME_1: "None",
      GID_2: "GHA.7.5_1",
      NAME_2: "Builsa",
      isoCode: "GH",
    },
    {
      region_id: "GHA.7.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.7_1",
      NAME_1: "Upper East",
      NL_NAME_1: "None",
      GID_2: "GHA.7.6_1",
      NAME_2: "Garu Tempane",
      isoCode: "GH",
    },
    {
      region_id: "GHA.7.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.7_1",
      NAME_1: "Upper East",
      NL_NAME_1: "None",
      GID_2: "GHA.7.7_1",
      NAME_2: "Kassena Nankana",
      isoCode: "GH",
    },
    {
      region_id: "GHA.7.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.7_1",
      NAME_1: "Upper East",
      NL_NAME_1: "None",
      GID_2: "GHA.7.8_1",
      NAME_2: "Talensi Nabdam",
      isoCode: "GH",
    },
    {
      region_id: "GHA.8.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.8_1",
      NAME_1: "Upper West",
      NL_NAME_1: "None",
      GID_2: "GHA.8.1_1",
      NAME_2: "Jirapa Lambussie",
      isoCode: "GH",
    },
    {
      region_id: "GHA.8.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.8_1",
      NAME_1: "Upper West",
      NL_NAME_1: "None",
      GID_2: "GHA.8.2_1",
      NAME_2: "Lawra",
      isoCode: "GH",
    },
    {
      region_id: "GHA.8.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.8_1",
      NAME_1: "Upper West",
      NL_NAME_1: "None",
      GID_2: "GHA.8.3_1",
      NAME_2: "Nadowli",
      isoCode: "GH",
    },
    {
      region_id: "GHA.8.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.8_1",
      NAME_1: "Upper West",
      NL_NAME_1: "None",
      GID_2: "GHA.8.4_1",
      NAME_2: "Sissala East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.8.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.8_1",
      NAME_1: "Upper West",
      NL_NAME_1: "None",
      GID_2: "GHA.8.5_1",
      NAME_2: "Sissala West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.8.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.8_1",
      NAME_1: "Upper West",
      NL_NAME_1: "None",
      GID_2: "GHA.8.6_1",
      NAME_2: "Wa",
      isoCode: "GH",
    },
    {
      region_id: "GHA.8.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.8_1",
      NAME_1: "Upper West",
      NL_NAME_1: "None",
      GID_2: "GHA.8.7_1",
      NAME_2: "Wa East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.8.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.8_1",
      NAME_1: "Upper West",
      NL_NAME_1: "None",
      GID_2: "GHA.8.8_1",
      NAME_2: "Wa West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.1_1",
      NAME_2: "Adaklu Anyigbe",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.2_1",
      NAME_2: "Akatsi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.3_1",
      NAME_2: "Ho",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.4_1",
      NAME_2: "Hohoe",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.5_1",
      NAME_2: "Jasikan",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.6_1",
      NAME_2: "Kadjebi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.7_1",
      NAME_2: "Keta",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.8_1",
      NAME_2: "Ketu",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.9_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.9_1",
      NAME_2: "Kpandu",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.10_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.10_1",
      NAME_2: "Krachi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.11_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.11_1",
      NAME_2: "Krachi East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.12_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.12_1",
      NAME_2: "Nkwanta",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.13_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.13_1",
      NAME_2: "North Tongu",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.14_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.14_1",
      NAME_2: "South Dayi",
      isoCode: "GH",
    },
    {
      region_id: "GHA.9.15_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.9_1",
      NAME_1: "Volta",
      NL_NAME_1: "None",
      GID_2: "GHA.9.15_1",
      NAME_2: "South Tongu",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.1_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.1_1",
      NAME_2: "Ahanta West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.2_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.2_1",
      NAME_2: "Aowin-Suaman",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.3_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.3_1",
      NAME_2: "Bia",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.4_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.4_1",
      NAME_2: "Bibiani Anhwiaso Bekwai",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.5_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.5_1",
      NAME_2: "Jomoro",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.6_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.6_1",
      NAME_2: "Juabeso",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.7_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.7_1",
      NAME_2: "Mpohor Wassa East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.8_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.8_1",
      NAME_2: "Nzema East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.9_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.9_1",
      NAME_2: "Sefwi Wiawso",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.10_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.10_1",
      NAME_2: "Shama Ahanta East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.11_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.11_1",
      NAME_2: "Wasa Amenfi East",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.12_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.12_1",
      NAME_2: "Wasa Amenfi West",
      isoCode: "GH",
    },
    {
      region_id: "GHA.10.13_1",
      GID_0: "GHA",
      NAME_0: "Ghana",
      GID_1: "GHA.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GHA.10.13_1",
      NAME_2: "Wassa West",
      isoCode: "GH",
    },
    {
      region_id: "GIN.1.1_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.1_1",
      NAME_1: "Boké",
      NL_NAME_1: "None",
      GID_2: "GIN.1.1_1",
      NAME_2: "Boffa",
      isoCode: "GN",
    },
    {
      region_id: "GIN.1.2_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.1_1",
      NAME_1: "Boké",
      NL_NAME_1: "None",
      GID_2: "GIN.1.2_1",
      NAME_2: "Boké",
      isoCode: "GN",
    },
    {
      region_id: "GIN.1.3_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.1_1",
      NAME_1: "Boké",
      NL_NAME_1: "None",
      GID_2: "GIN.1.3_1",
      NAME_2: "Fria",
      isoCode: "GN",
    },
    {
      region_id: "GIN.1.4_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.1_1",
      NAME_1: "Boké",
      NL_NAME_1: "None",
      GID_2: "GIN.1.4_1",
      NAME_2: "Gaoual",
      isoCode: "GN",
    },
    {
      region_id: "GIN.1.5_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.1_1",
      NAME_1: "Boké",
      NL_NAME_1: "None",
      GID_2: "GIN.1.5_1",
      NAME_2: "Koundara",
      isoCode: "GN",
    },
    {
      region_id: "GIN.2.1_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.2_1",
      NAME_1: "Conakry",
      NL_NAME_1: "None",
      GID_2: "GIN.2.1_1",
      NAME_2: "Conakry",
      isoCode: "GN",
    },
    {
      region_id: "GIN.3.1_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.3_1",
      NAME_1: "Faranah",
      NL_NAME_1: "None",
      GID_2: "GIN.3.1_1",
      NAME_2: "Dabola",
      isoCode: "GN",
    },
    {
      region_id: "GIN.3.2_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.3_1",
      NAME_1: "Faranah",
      NL_NAME_1: "None",
      GID_2: "GIN.3.2_1",
      NAME_2: "Dinguiraye",
      isoCode: "GN",
    },
    {
      region_id: "GIN.3.3_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.3_1",
      NAME_1: "Faranah",
      NL_NAME_1: "None",
      GID_2: "GIN.3.3_1",
      NAME_2: "Faranah",
      isoCode: "GN",
    },
    {
      region_id: "GIN.3.4_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.3_1",
      NAME_1: "Faranah",
      NL_NAME_1: "None",
      GID_2: "GIN.3.4_1",
      NAME_2: "Kissidougou",
      isoCode: "GN",
    },
    {
      region_id: "GIN.4.1_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.4_1",
      NAME_1: "Kankan",
      NL_NAME_1: "None",
      GID_2: "GIN.4.1_1",
      NAME_2: "Kankan",
      isoCode: "GN",
    },
    {
      region_id: "GIN.4.2_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.4_1",
      NAME_1: "Kankan",
      NL_NAME_1: "None",
      GID_2: "GIN.4.2_1",
      NAME_2: "Kérouané",
      isoCode: "GN",
    },
    {
      region_id: "GIN.4.3_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.4_1",
      NAME_1: "Kankan",
      NL_NAME_1: "None",
      GID_2: "GIN.4.3_1",
      NAME_2: "Kouroussa",
      isoCode: "GN",
    },
    {
      region_id: "GIN.4.4_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.4_1",
      NAME_1: "Kankan",
      NL_NAME_1: "None",
      GID_2: "GIN.4.4_1",
      NAME_2: "Mandiana",
      isoCode: "GN",
    },
    {
      region_id: "GIN.4.5_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.4_1",
      NAME_1: "Kankan",
      NL_NAME_1: "None",
      GID_2: "GIN.4.5_1",
      NAME_2: "Siguiri",
      isoCode: "GN",
    },
    {
      region_id: "GIN.5.1_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.5_1",
      NAME_1: "Kindia",
      NL_NAME_1: "None",
      GID_2: "GIN.5.1_1",
      NAME_2: "Coyah",
      isoCode: "GN",
    },
    {
      region_id: "GIN.5.2_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.5_1",
      NAME_1: "Kindia",
      NL_NAME_1: "None",
      GID_2: "GIN.5.2_1",
      NAME_2: "Dubréka",
      isoCode: "GN",
    },
    {
      region_id: "GIN.5.3_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.5_1",
      NAME_1: "Kindia",
      NL_NAME_1: "None",
      GID_2: "GIN.5.3_1",
      NAME_2: "Forécariah",
      isoCode: "GN",
    },
    {
      region_id: "GIN.5.4_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.5_1",
      NAME_1: "Kindia",
      NL_NAME_1: "None",
      GID_2: "GIN.5.4_1",
      NAME_2: "Kindia",
      isoCode: "GN",
    },
    {
      region_id: "GIN.5.5_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.5_1",
      NAME_1: "Kindia",
      NL_NAME_1: "None",
      GID_2: "GIN.5.5_1",
      NAME_2: "Télimélé",
      isoCode: "GN",
    },
    {
      region_id: "GIN.6.1_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.6_1",
      NAME_1: "Labé",
      NL_NAME_1: "None",
      GID_2: "GIN.6.1_1",
      NAME_2: "Koubia",
      isoCode: "GN",
    },
    {
      region_id: "GIN.6.2_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.6_1",
      NAME_1: "Labé",
      NL_NAME_1: "None",
      GID_2: "GIN.6.2_1",
      NAME_2: "Labé",
      isoCode: "GN",
    },
    {
      region_id: "GIN.6.3_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.6_1",
      NAME_1: "Labé",
      NL_NAME_1: "None",
      GID_2: "GIN.6.3_1",
      NAME_2: "Lélouma",
      isoCode: "GN",
    },
    {
      region_id: "GIN.6.4_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.6_1",
      NAME_1: "Labé",
      NL_NAME_1: "None",
      GID_2: "GIN.6.4_1",
      NAME_2: "Mali",
      isoCode: "GN",
    },
    {
      region_id: "GIN.6.5_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.6_1",
      NAME_1: "Labé",
      NL_NAME_1: "None",
      GID_2: "GIN.6.5_1",
      NAME_2: "Tougué",
      isoCode: "GN",
    },
    {
      region_id: "GIN.7.1_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.7_1",
      NAME_1: "Mamou",
      NL_NAME_1: "None",
      GID_2: "GIN.7.1_1",
      NAME_2: "Dalaba",
      isoCode: "GN",
    },
    {
      region_id: "GIN.7.2_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.7_1",
      NAME_1: "Mamou",
      NL_NAME_1: "None",
      GID_2: "GIN.7.2_1",
      NAME_2: "Mamou",
      isoCode: "GN",
    },
    {
      region_id: "GIN.7.3_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.7_1",
      NAME_1: "Mamou",
      NL_NAME_1: "None",
      GID_2: "GIN.7.3_1",
      NAME_2: "Pita",
      isoCode: "GN",
    },
    {
      region_id: "GIN.8.1_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.8_1",
      NAME_1: "Nzérékoré",
      NL_NAME_1: "None",
      GID_2: "GIN.8.1_1",
      NAME_2: "Beyla",
      isoCode: "GN",
    },
    {
      region_id: "GIN.8.2_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.8_1",
      NAME_1: "Nzérékoré",
      NL_NAME_1: "None",
      GID_2: "GIN.8.2_1",
      NAME_2: "Guéckédou",
      isoCode: "GN",
    },
    {
      region_id: "GIN.8.3_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.8_1",
      NAME_1: "Nzérékoré",
      NL_NAME_1: "None",
      GID_2: "GIN.8.3_1",
      NAME_2: "Lola",
      isoCode: "GN",
    },
    {
      region_id: "GIN.8.4_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.8_1",
      NAME_1: "Nzérékoré",
      NL_NAME_1: "None",
      GID_2: "GIN.8.4_1",
      NAME_2: "Macenta",
      isoCode: "GN",
    },
    {
      region_id: "GIN.8.5_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.8_1",
      NAME_1: "Nzérékoré",
      NL_NAME_1: "None",
      GID_2: "GIN.8.5_1",
      NAME_2: "Nzérékoré",
      isoCode: "GN",
    },
    {
      region_id: "GIN.8.6_1",
      GID_0: "GIN",
      NAME_0: "Guinea",
      GID_1: "GIN.8_1",
      NAME_1: "Nzérékoré",
      NL_NAME_1: "None",
      GID_2: "GIN.8.6_1",
      NAME_2: "Yamou",
      isoCode: "GN",
    },
    {
      region_id: "GMB.1.1_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.1_1",
      NAME_1: "Banjul",
      NL_NAME_1: "None",
      GID_2: "GMB.1.1_1",
      NAME_2: "Banjul",
      isoCode: "GM",
    },
    {
      region_id: "GMB.1.2_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.1_1",
      NAME_1: "Banjul",
      NL_NAME_1: "None",
      GID_2: "GMB.1.2_1",
      NAME_2: "Kanifing",
      isoCode: "GM",
    },
    {
      region_id: "GMB.2.1_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.2_1",
      NAME_1: "Lower River",
      NL_NAME_1: "None",
      GID_2: "GMB.2.1_1",
      NAME_2: "Jarra Central",
      isoCode: "GM",
    },
    {
      region_id: "GMB.2.2_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.2_1",
      NAME_1: "Lower River",
      NL_NAME_1: "None",
      GID_2: "GMB.2.2_1",
      NAME_2: "Jarra East",
      isoCode: "GM",
    },
    {
      region_id: "GMB.2.3_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.2_1",
      NAME_1: "Lower River",
      NL_NAME_1: "None",
      GID_2: "GMB.2.3_1",
      NAME_2: "Jarra West",
      isoCode: "GM",
    },
    {
      region_id: "GMB.2.4_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.2_1",
      NAME_1: "Lower River",
      NL_NAME_1: "None",
      GID_2: "GMB.2.4_1",
      NAME_2: "Kiang Central",
      isoCode: "GM",
    },
    {
      region_id: "GMB.2.5_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.2_1",
      NAME_1: "Lower River",
      NL_NAME_1: "None",
      GID_2: "GMB.2.5_1",
      NAME_2: "Kiang East",
      isoCode: "GM",
    },
    {
      region_id: "GMB.2.6_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.2_1",
      NAME_1: "Lower River",
      NL_NAME_1: "None",
      GID_2: "GMB.2.6_1",
      NAME_2: "Kiang West",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.1_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.1_1",
      NAME_2: "Fulladu West",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.2_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.2_1",
      NAME_2: "Janjanbureh",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.3_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.3_1",
      NAME_2: "Lower Saloum",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.4_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.4_1",
      NAME_2: "Niamina Dankunku",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.5_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.5_1",
      NAME_2: "Niamina East",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.6_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.6_1",
      NAME_2: "Niamina West",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.7_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.7_1",
      NAME_2: "Niani",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.8_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.8_1",
      NAME_2: "Nianija",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.9_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.9_1",
      NAME_2: "Sami",
      isoCode: "GM",
    },
    {
      region_id: "GMB.3.10_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.3_1",
      NAME_1: "Maccarthy Island",
      NL_NAME_1: "None",
      GID_2: "GMB.3.10_1",
      NAME_2: "Upper Saloum",
      isoCode: "GM",
    },
    {
      region_id: "GMB.4.1_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.4_1",
      NAME_1: "North Bank",
      NL_NAME_1: "None",
      GID_2: "GMB.4.1_1",
      NAME_2: "Central Baddibu",
      isoCode: "GM",
    },
    {
      region_id: "GMB.4.2_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.4_1",
      NAME_1: "North Bank",
      NL_NAME_1: "None",
      GID_2: "GMB.4.2_1",
      NAME_2: "Jokadu",
      isoCode: "GM",
    },
    {
      region_id: "GMB.4.3_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.4_1",
      NAME_1: "North Bank",
      NL_NAME_1: "None",
      GID_2: "GMB.4.3_1",
      NAME_2: "Lower Baddibu",
      isoCode: "GM",
    },
    {
      region_id: "GMB.4.4_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.4_1",
      NAME_1: "North Bank",
      NL_NAME_1: "None",
      GID_2: "GMB.4.4_1",
      NAME_2: "Lower Nuimi",
      isoCode: "GM",
    },
    {
      region_id: "GMB.4.5_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.4_1",
      NAME_1: "North Bank",
      NL_NAME_1: "None",
      GID_2: "GMB.4.5_1",
      NAME_2: "Upper Baddibu",
      isoCode: "GM",
    },
    {
      region_id: "GMB.4.6_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.4_1",
      NAME_1: "North Bank",
      NL_NAME_1: "None",
      GID_2: "GMB.4.6_1",
      NAME_2: "Upper Nuimi",
      isoCode: "GM",
    },
    {
      region_id: "GMB.5.1_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.5_1",
      NAME_1: "Upper River",
      NL_NAME_1: "None",
      GID_2: "GMB.5.1_1",
      NAME_2: "Fulladu East",
      isoCode: "GM",
    },
    {
      region_id: "GMB.5.2_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.5_1",
      NAME_1: "Upper River",
      NL_NAME_1: "None",
      GID_2: "GMB.5.2_1",
      NAME_2: "Kantora",
      isoCode: "GM",
    },
    {
      region_id: "GMB.5.3_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.5_1",
      NAME_1: "Upper River",
      NL_NAME_1: "None",
      GID_2: "GMB.5.3_1",
      NAME_2: "Sandu",
      isoCode: "GM",
    },
    {
      region_id: "GMB.5.4_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.5_1",
      NAME_1: "Upper River",
      NL_NAME_1: "None",
      GID_2: "GMB.5.4_1",
      NAME_2: "Wuli",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.1_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.1_1",
      NAME_2: "Foni Bintang Karanai",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.2_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.2_1",
      NAME_2: "Foni Bondali",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.3_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.3_1",
      NAME_2: "Foni Brefet",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.4_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.4_1",
      NAME_2: "Foni Jarrol",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.5_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.5_1",
      NAME_2: "Foni Kansala",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.6_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.6_1",
      NAME_2: "Kombo Central",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.7_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.7_1",
      NAME_2: "Kombo East",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.8_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.8_1",
      NAME_2: "Kombo Saint Mary",
      isoCode: "GM",
    },
    {
      region_id: "GMB.6.9_1",
      GID_0: "GMB",
      NAME_0: "Gambia",
      GID_1: "GMB.6_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "GMB.6.9_1",
      NAME_2: "Kombo South",
      isoCode: "GM",
    },
    {
      region_id: "GNB.1.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.1_1",
      NAME_1: "Bafatá",
      NL_NAME_1: "None",
      GID_2: "GNB.1.1_1",
      NAME_2: "Bafata",
      isoCode: "GW",
    },
    {
      region_id: "GNB.1.2_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.1_1",
      NAME_1: "Bafatá",
      NL_NAME_1: "None",
      GID_2: "GNB.1.2_1",
      NAME_2: "Bambadinca",
      isoCode: "GW",
    },
    {
      region_id: "GNB.1.3_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.1_1",
      NAME_1: "Bafatá",
      NL_NAME_1: "None",
      GID_2: "GNB.1.3_1",
      NAME_2: "Contuboel",
      isoCode: "GW",
    },
    {
      region_id: "GNB.1.4_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.1_1",
      NAME_1: "Bafatá",
      NL_NAME_1: "None",
      GID_2: "GNB.1.4_1",
      NAME_2: "Galomaro",
      isoCode: "GW",
    },
    {
      region_id: "GNB.1.5_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.1_1",
      NAME_1: "Bafatá",
      NL_NAME_1: "None",
      GID_2: "GNB.1.5_1",
      NAME_2: "Gamamundo",
      isoCode: "GW",
    },
    {
      region_id: "GNB.1.6_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.1_1",
      NAME_1: "Bafatá",
      NL_NAME_1: "None",
      GID_2: "GNB.1.6_1",
      NAME_2: "Xitole",
      isoCode: "GW",
    },
    {
      region_id: "GNB.2.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.2_1",
      NAME_1: "Biombo",
      NL_NAME_1: "None",
      GID_2: "GNB.2.1_1",
      NAME_2: "Prabis",
      isoCode: "GW",
    },
    {
      region_id: "GNB.2.2_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.2_1",
      NAME_1: "Biombo",
      NL_NAME_1: "None",
      GID_2: "GNB.2.2_1",
      NAME_2: "Quinhamel",
      isoCode: "GW",
    },
    {
      region_id: "GNB.2.3_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.2_1",
      NAME_1: "Biombo",
      NL_NAME_1: "None",
      GID_2: "GNB.2.3_1",
      NAME_2: "Safim",
      isoCode: "GW",
    },
    {
      region_id: "GNB.3.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.3_1",
      NAME_1: "Bissau",
      NL_NAME_1: "None",
      GID_2: "GNB.3.1_1",
      NAME_2: "Bissau",
      isoCode: "GW",
    },
    {
      region_id: "GNB.4.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.4_1",
      NAME_1: "Bolama",
      NL_NAME_1: "None",
      GID_2: "GNB.4.1_1",
      NAME_2: "Bolama",
      isoCode: "GW",
    },
    {
      region_id: "GNB.4.2_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.4_1",
      NAME_1: "Bolama",
      NL_NAME_1: "None",
      GID_2: "GNB.4.2_1",
      NAME_2: "Bubaque",
      isoCode: "GW",
    },
    {
      region_id: "GNB.4.3_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.4_1",
      NAME_1: "Bolama",
      NL_NAME_1: "None",
      GID_2: "GNB.4.3_1",
      NAME_2: "Caravela",
      isoCode: "GW",
    },
    {
      region_id: "GNB.5.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.5_1",
      NAME_1: "Cacheu",
      NL_NAME_1: "None",
      GID_2: "GNB.5.1_1",
      NAME_2: "Bigene",
      isoCode: "GW",
    },
    {
      region_id: "GNB.5.2_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.5_1",
      NAME_1: "Cacheu",
      NL_NAME_1: "None",
      GID_2: "GNB.5.2_1",
      NAME_2: "Bula",
      isoCode: "GW",
    },
    {
      region_id: "GNB.5.3_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.5_1",
      NAME_1: "Cacheu",
      NL_NAME_1: "None",
      GID_2: "GNB.5.3_1",
      NAME_2: "Cacheu",
      isoCode: "GW",
    },
    {
      region_id: "GNB.5.4_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.5_1",
      NAME_1: "Cacheu",
      NL_NAME_1: "None",
      GID_2: "GNB.5.4_1",
      NAME_2: "Caio",
      isoCode: "GW",
    },
    {
      region_id: "GNB.5.5_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.5_1",
      NAME_1: "Cacheu",
      NL_NAME_1: "None",
      GID_2: "GNB.5.5_1",
      NAME_2: "Canghungo",
      isoCode: "GW",
    },
    {
      region_id: "GNB.5.6_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.5_1",
      NAME_1: "Cacheu",
      NL_NAME_1: "None",
      GID_2: "GNB.5.6_1",
      NAME_2: "Sao Domingos",
      isoCode: "GW",
    },
    {
      region_id: "GNB.6.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.6_1",
      NAME_1: "Gabú",
      NL_NAME_1: "None",
      GID_2: "GNB.6.1_1",
      NAME_2: "Boe",
      isoCode: "GW",
    },
    {
      region_id: "GNB.6.2_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.6_1",
      NAME_1: "Gabú",
      NL_NAME_1: "None",
      GID_2: "GNB.6.2_1",
      NAME_2: "Gabu",
      isoCode: "GW",
    },
    {
      region_id: "GNB.6.3_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.6_1",
      NAME_1: "Gabú",
      NL_NAME_1: "None",
      GID_2: "GNB.6.3_1",
      NAME_2: "Piche",
      isoCode: "GW",
    },
    {
      region_id: "GNB.6.4_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.6_1",
      NAME_1: "Gabú",
      NL_NAME_1: "None",
      GID_2: "GNB.6.4_1",
      NAME_2: "Pirada",
      isoCode: "GW",
    },
    {
      region_id: "GNB.6.5_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.6_1",
      NAME_1: "Gabú",
      NL_NAME_1: "None",
      GID_2: "GNB.6.5_1",
      NAME_2: "Sonaco",
      isoCode: "GW",
    },
    {
      region_id: "GNB.7.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.7_1",
      NAME_1: "Oio",
      NL_NAME_1: "None",
      GID_2: "GNB.7.1_1",
      NAME_2: "Bissora",
      isoCode: "GW",
    },
    {
      region_id: "GNB.7.2_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.7_1",
      NAME_1: "Oio",
      NL_NAME_1: "None",
      GID_2: "GNB.7.2_1",
      NAME_2: "Farim",
      isoCode: "GW",
    },
    {
      region_id: "GNB.7.3_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.7_1",
      NAME_1: "Oio",
      NL_NAME_1: "None",
      GID_2: "GNB.7.3_1",
      NAME_2: "Mansaba",
      isoCode: "GW",
    },
    {
      region_id: "GNB.7.4_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.7_1",
      NAME_1: "Oio",
      NL_NAME_1: "None",
      GID_2: "GNB.7.4_1",
      NAME_2: "Mansoa",
      isoCode: "GW",
    },
    {
      region_id: "GNB.7.5_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.7_1",
      NAME_1: "Oio",
      NL_NAME_1: "None",
      GID_2: "GNB.7.5_1",
      NAME_2: "Nhacra",
      isoCode: "GW",
    },
    {
      region_id: "GNB.8.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.8_1",
      NAME_1: "Quinara",
      NL_NAME_1: "None",
      GID_2: "GNB.8.1_1",
      NAME_2: "Buba",
      isoCode: "GW",
    },
    {
      region_id: "GNB.8.2_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.8_1",
      NAME_1: "Quinara",
      NL_NAME_1: "None",
      GID_2: "GNB.8.2_1",
      NAME_2: "Empada",
      isoCode: "GW",
    },
    {
      region_id: "GNB.8.3_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.8_1",
      NAME_1: "Quinara",
      NL_NAME_1: "None",
      GID_2: "GNB.8.3_1",
      NAME_2: "Fulacunda",
      isoCode: "GW",
    },
    {
      region_id: "GNB.8.4_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.8_1",
      NAME_1: "Quinara",
      NL_NAME_1: "None",
      GID_2: "GNB.8.4_1",
      NAME_2: "Tite",
      isoCode: "GW",
    },
    {
      region_id: "GNB.9.1_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.9_1",
      NAME_1: "Tombali",
      NL_NAME_1: "None",
      GID_2: "GNB.9.1_1",
      NAME_2: "Bedanda",
      isoCode: "GW",
    },
    {
      region_id: "GNB.9.2_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.9_1",
      NAME_1: "Tombali",
      NL_NAME_1: "None",
      GID_2: "GNB.9.2_1",
      NAME_2: "Cacine",
      isoCode: "GW",
    },
    {
      region_id: "GNB.9.3_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.9_1",
      NAME_1: "Tombali",
      NL_NAME_1: "None",
      GID_2: "GNB.9.3_1",
      NAME_2: "Catio",
      isoCode: "GW",
    },
    {
      region_id: "GNB.9.4_1",
      GID_0: "GNB",
      NAME_0: "Guinea-Bissau",
      GID_1: "GNB.9_1",
      NAME_1: "Tombali",
      NL_NAME_1: "None",
      GID_2: "GNB.9.4_1",
      NAME_2: "Quebo",
      isoCode: "GW",
    },
    {
      region_id: "LBR.1.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.1_1",
      NAME_1: "Bomi",
      NL_NAME_1: "None",
      GID_2: "LBR.1.1_1",
      NAME_2: "Klay",
      isoCode: "LR",
    },
    {
      region_id: "LBR.1.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.1_1",
      NAME_1: "Bomi",
      NL_NAME_1: "None",
      GID_2: "LBR.1.2_1",
      NAME_2: "Mecca",
      isoCode: "LR",
    },
    {
      region_id: "LBR.2.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.2_1",
      NAME_1: "Bong",
      NL_NAME_1: "None",
      GID_2: "LBR.2.1_1",
      NAME_2: "Fuamah",
      isoCode: "LR",
    },
    {
      region_id: "LBR.2.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.2_1",
      NAME_1: "Bong",
      NL_NAME_1: "None",
      GID_2: "LBR.2.2_1",
      NAME_2: "Jorquelleh",
      isoCode: "LR",
    },
    {
      region_id: "LBR.2.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.2_1",
      NAME_1: "Bong",
      NL_NAME_1: "None",
      GID_2: "LBR.2.3_1",
      NAME_2: "Kokoyah",
      isoCode: "LR",
    },
    {
      region_id: "LBR.2.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.2_1",
      NAME_1: "Bong",
      NL_NAME_1: "None",
      GID_2: "LBR.2.4_1",
      NAME_2: "Panta-Kpa",
      isoCode: "LR",
    },
    {
      region_id: "LBR.2.5_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.2_1",
      NAME_1: "Bong",
      NL_NAME_1: "None",
      GID_2: "LBR.2.5_1",
      NAME_2: "Salala",
      isoCode: "LR",
    },
    {
      region_id: "LBR.2.6_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.2_1",
      NAME_1: "Bong",
      NL_NAME_1: "None",
      GID_2: "LBR.2.6_1",
      NAME_2: "Sanayea",
      isoCode: "LR",
    },
    {
      region_id: "LBR.2.7_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.2_1",
      NAME_1: "Bong",
      NL_NAME_1: "None",
      GID_2: "LBR.2.7_1",
      NAME_2: "Suakoko",
      isoCode: "LR",
    },
    {
      region_id: "LBR.2.8_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.2_1",
      NAME_1: "Bong",
      NL_NAME_1: "None",
      GID_2: "LBR.2.8_1",
      NAME_2: "Zota",
      isoCode: "LR",
    },
    {
      region_id: "LBR.3.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.3_1",
      NAME_1: "Gbapolu",
      NL_NAME_1: "None",
      GID_2: "LBR.3.1_1",
      NAME_2: "Belleh",
      isoCode: "LR",
    },
    {
      region_id: "LBR.3.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.3_1",
      NAME_1: "Gbapolu",
      NL_NAME_1: "None",
      GID_2: "LBR.3.2_1",
      NAME_2: "Bokomu",
      isoCode: "LR",
    },
    {
      region_id: "LBR.3.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.3_1",
      NAME_1: "Gbapolu",
      NL_NAME_1: "None",
      GID_2: "LBR.3.3_1",
      NAME_2: "Bopolu",
      isoCode: "LR",
    },
    {
      region_id: "LBR.3.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.3_1",
      NAME_1: "Gbapolu",
      NL_NAME_1: "None",
      GID_2: "LBR.3.4_1",
      NAME_2: "Gbarma",
      isoCode: "LR",
    },
    {
      region_id: "LBR.3.5_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.3_1",
      NAME_1: "Gbapolu",
      NL_NAME_1: "None",
      GID_2: "LBR.3.5_1",
      NAME_2: "Kongba",
      isoCode: "LR",
    },
    {
      region_id: "LBR.4.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.4_1",
      NAME_1: "Grand Cape Mount",
      NL_NAME_1: "None",
      GID_2: "LBR.4.1_1",
      NAME_2: "Commnwealth",
      isoCode: "LR",
    },
    {
      region_id: "LBR.4.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.4_1",
      NAME_1: "Grand Cape Mount",
      NL_NAME_1: "None",
      GID_2: "LBR.4.2_1",
      NAME_2: "Garwula",
      isoCode: "LR",
    },
    {
      region_id: "LBR.4.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.4_1",
      NAME_1: "Grand Cape Mount",
      NL_NAME_1: "None",
      GID_2: "LBR.4.3_1",
      NAME_2: "Golakonneh",
      isoCode: "LR",
    },
    {
      region_id: "LBR.4.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.4_1",
      NAME_1: "Grand Cape Mount",
      NL_NAME_1: "None",
      GID_2: "LBR.4.4_1",
      NAME_2: "Porkpa",
      isoCode: "LR",
    },
    {
      region_id: "LBR.4.5_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.4_1",
      NAME_1: "Grand Cape Mount",
      NL_NAME_1: "None",
      GID_2: "LBR.4.5_1",
      NAME_2: "Tewor",
      isoCode: "LR",
    },
    {
      region_id: "LBR.5.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.5_1",
      NAME_1: "GrandBassa",
      NL_NAME_1: "None",
      GID_2: "LBR.5.1_1",
      NAME_2: "District # 1",
      isoCode: "LR",
    },
    {
      region_id: "LBR.5.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.5_1",
      NAME_1: "GrandBassa",
      NL_NAME_1: "None",
      GID_2: "LBR.5.2_1",
      NAME_2: "District # 2",
      isoCode: "LR",
    },
    {
      region_id: "LBR.5.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.5_1",
      NAME_1: "GrandBassa",
      NL_NAME_1: "None",
      GID_2: "LBR.5.3_1",
      NAME_2: "District # 3",
      isoCode: "LR",
    },
    {
      region_id: "LBR.5.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.5_1",
      NAME_1: "GrandBassa",
      NL_NAME_1: "None",
      GID_2: "LBR.5.4_1",
      NAME_2: "District # 4",
      isoCode: "LR",
    },
    {
      region_id: "LBR.5.5_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.5_1",
      NAME_1: "GrandBassa",
      NL_NAME_1: "None",
      GID_2: "LBR.5.5_1",
      NAME_2: "Owensgrove",
      isoCode: "LR",
    },
    {
      region_id: "LBR.5.6_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.5_1",
      NAME_1: "GrandBassa",
      NL_NAME_1: "None",
      GID_2: "LBR.5.6_1",
      NAME_2: "Stjohnriver",
      isoCode: "LR",
    },
    {
      region_id: "LBR.6.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.6_1",
      NAME_1: "GrandGedeh",
      NL_NAME_1: "None",
      GID_2: "LBR.6.1_1",
      NAME_2: "Gbarzon",
      isoCode: "LR",
    },
    {
      region_id: "LBR.6.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.6_1",
      NAME_1: "GrandGedeh",
      NL_NAME_1: "None",
      GID_2: "LBR.6.2_1",
      NAME_2: "Konobo",
      isoCode: "LR",
    },
    {
      region_id: "LBR.6.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.6_1",
      NAME_1: "GrandGedeh",
      NL_NAME_1: "None",
      GID_2: "LBR.6.3_1",
      NAME_2: "Tchien",
      isoCode: "LR",
    },
    {
      region_id: "LBR.7.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.7_1",
      NAME_1: "GrandKru",
      NL_NAME_1: "None",
      GID_2: "LBR.7.1_1",
      NAME_2: "Buah",
      isoCode: "LR",
    },
    {
      region_id: "LBR.7.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.7_1",
      NAME_1: "GrandKru",
      NL_NAME_1: "None",
      GID_2: "LBR.7.2_1",
      NAME_2: "Lower Kru Coast",
      isoCode: "LR",
    },
    {
      region_id: "LBR.7.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.7_1",
      NAME_1: "GrandKru",
      NL_NAME_1: "None",
      GID_2: "LBR.7.3_1",
      NAME_2: "Sasstown 180606",
      isoCode: "LR",
    },
    {
      region_id: "LBR.7.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.7_1",
      NAME_1: "GrandKru",
      NL_NAME_1: "None",
      GID_2: "LBR.7.4_1",
      NAME_2: "Upperkrucoast",
      isoCode: "LR",
    },
    {
      region_id: "LBR.8.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.8_1",
      NAME_1: "Lofa",
      NL_NAME_1: "None",
      GID_2: "LBR.8.1_1",
      NAME_2: "Foya",
      isoCode: "LR",
    },
    {
      region_id: "LBR.8.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.8_1",
      NAME_1: "Lofa",
      NL_NAME_1: "None",
      GID_2: "LBR.8.2_1",
      NAME_2: "Kolahun",
      isoCode: "LR",
    },
    {
      region_id: "LBR.8.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.8_1",
      NAME_1: "Lofa",
      NL_NAME_1: "None",
      GID_2: "LBR.8.3_1",
      NAME_2: "Lower Kru Coast",
      isoCode: "LR",
    },
    {
      region_id: "LBR.8.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.8_1",
      NAME_1: "Lofa",
      NL_NAME_1: "None",
      GID_2: "LBR.8.4_1",
      NAME_2: "Salayea",
      isoCode: "LR",
    },
    {
      region_id: "LBR.8.5_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.8_1",
      NAME_1: "Lofa",
      NL_NAME_1: "None",
      GID_2: "LBR.8.5_1",
      NAME_2: "Voinjama",
      isoCode: "LR",
    },
    {
      region_id: "LBR.8.6_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.8_1",
      NAME_1: "Lofa",
      NL_NAME_1: "None",
      GID_2: "LBR.8.6_1",
      NAME_2: "Zorzor",
      isoCode: "LR",
    },
    {
      region_id: "LBR.9.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.9_1",
      NAME_1: "Margibi",
      NL_NAME_1: "None",
      GID_2: "LBR.9.1_1",
      NAME_2: "Firestone",
      isoCode: "LR",
    },
    {
      region_id: "LBR.9.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.9_1",
      NAME_1: "Margibi",
      NL_NAME_1: "None",
      GID_2: "LBR.9.2_1",
      NAME_2: "Gibi",
      isoCode: "LR",
    },
    {
      region_id: "LBR.9.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.9_1",
      NAME_1: "Margibi",
      NL_NAME_1: "None",
      GID_2: "LBR.9.3_1",
      NAME_2: "Kakata",
      isoCode: "LR",
    },
    {
      region_id: "LBR.9.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.9_1",
      NAME_1: "Margibi",
      NL_NAME_1: "None",
      GID_2: "LBR.9.4_1",
      NAME_2: "Mambah-Kaba",
      isoCode: "LR",
    },
    {
      region_id: "LBR.10.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.10_1",
      NAME_1: "Maryland",
      NL_NAME_1: "None",
      GID_2: "LBR.10.1_1",
      NAME_2: "Barrobo",
      isoCode: "LR",
    },
    {
      region_id: "LBR.10.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.10_1",
      NAME_1: "Maryland",
      NL_NAME_1: "None",
      GID_2: "LBR.10.2_1",
      NAME_2: "Pleebo/Sodeken",
      isoCode: "LR",
    },
    {
      region_id: "LBR.11.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.11_1",
      NAME_1: "Montserrado",
      NL_NAME_1: "None",
      GID_2: "LBR.11.1_1",
      NAME_2: "Careysburg",
      isoCode: "LR",
    },
    {
      region_id: "LBR.11.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.11_1",
      NAME_1: "Montserrado",
      NL_NAME_1: "None",
      GID_2: "LBR.11.2_1",
      NAME_2: "Greater Monrovia",
      isoCode: "LR",
    },
    {
      region_id: "LBR.11.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.11_1",
      NAME_1: "Montserrado",
      NL_NAME_1: "None",
      GID_2: "LBR.11.3_1",
      NAME_2: "St Paul River",
      isoCode: "LR",
    },
    {
      region_id: "LBR.11.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.11_1",
      NAME_1: "Montserrado",
      NL_NAME_1: "None",
      GID_2: "LBR.11.4_1",
      NAME_2: "Todee",
      isoCode: "LR",
    },
    {
      region_id: "LBR.12.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.12_1",
      NAME_1: "Nimba",
      NL_NAME_1: "None",
      GID_2: "LBR.12.1_1",
      NAME_2: "Gbehlageh",
      isoCode: "LR",
    },
    {
      region_id: "LBR.12.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.12_1",
      NAME_1: "Nimba",
      NL_NAME_1: "None",
      GID_2: "LBR.12.2_1",
      NAME_2: "Saclepea",
      isoCode: "LR",
    },
    {
      region_id: "LBR.12.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.12_1",
      NAME_1: "Nimba",
      NL_NAME_1: "None",
      GID_2: "LBR.12.3_1",
      NAME_2: "Sanniquelleh-Mahn",
      isoCode: "LR",
    },
    {
      region_id: "LBR.12.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.12_1",
      NAME_1: "Nimba",
      NL_NAME_1: "None",
      GID_2: "LBR.12.4_1",
      NAME_2: "Tappita",
      isoCode: "LR",
    },
    {
      region_id: "LBR.12.5_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.12_1",
      NAME_1: "Nimba",
      NL_NAME_1: "None",
      GID_2: "LBR.12.5_1",
      NAME_2: "Yarwein-Mehnsohnne",
      isoCode: "LR",
    },
    {
      region_id: "LBR.12.6_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.12_1",
      NAME_1: "Nimba",
      NL_NAME_1: "None",
      GID_2: "LBR.12.6_1",
      NAME_2: "Zoegeh",
      isoCode: "LR",
    },
    {
      region_id: "LBR.13.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.13_1",
      NAME_1: "River Cess",
      NL_NAME_1: "None",
      GID_2: "LBR.13.1_1",
      NAME_2: "Morweh",
      isoCode: "LR",
    },
    {
      region_id: "LBR.13.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.13_1",
      NAME_1: "River Cess",
      NL_NAME_1: "None",
      GID_2: "LBR.13.2_1",
      NAME_2: "Timbo",
      isoCode: "LR",
    },
    {
      region_id: "LBR.14.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.14_1",
      NAME_1: "River Gee",
      NL_NAME_1: "None",
      GID_2: "LBR.14.1_1",
      NAME_2: "Gbeapo",
      isoCode: "LR",
    },
    {
      region_id: "LBR.14.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.14_1",
      NAME_1: "River Gee",
      NL_NAME_1: "None",
      GID_2: "LBR.14.2_1",
      NAME_2: "Webbo",
      isoCode: "LR",
    },
    {
      region_id: "LBR.15.1_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.15_1",
      NAME_1: "Sinoe",
      NL_NAME_1: "None",
      GID_2: "LBR.15.1_1",
      NAME_2: "Butaw",
      isoCode: "LR",
    },
    {
      region_id: "LBR.15.2_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.15_1",
      NAME_1: "Sinoe",
      NL_NAME_1: "None",
      GID_2: "LBR.15.2_1",
      NAME_2: "Dugbe River",
      isoCode: "LR",
    },
    {
      region_id: "LBR.15.3_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.15_1",
      NAME_1: "Sinoe",
      NL_NAME_1: "None",
      GID_2: "LBR.15.3_1",
      NAME_2: "Greenville",
      isoCode: "LR",
    },
    {
      region_id: "LBR.15.4_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.15_1",
      NAME_1: "Sinoe",
      NL_NAME_1: "None",
      GID_2: "LBR.15.4_1",
      NAME_2: "Jaedae Jaedepo",
      isoCode: "LR",
    },
    {
      region_id: "LBR.15.5_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.15_1",
      NAME_1: "Sinoe",
      NL_NAME_1: "None",
      GID_2: "LBR.15.5_1",
      NAME_2: "Juarzon",
      isoCode: "LR",
    },
    {
      region_id: "LBR.15.6_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.15_1",
      NAME_1: "Sinoe",
      NL_NAME_1: "None",
      GID_2: "LBR.15.6_1",
      NAME_2: "Kpayan",
      isoCode: "LR",
    },
    {
      region_id: "LBR.15.7_1",
      GID_0: "LBR",
      NAME_0: "Liberia",
      GID_1: "LBR.15_1",
      NAME_1: "Sinoe",
      NL_NAME_1: "None",
      GID_2: "LBR.15.7_1",
      NAME_2: "Pyneston",
      isoCode: "LR",
    },
    {
      region_id: "MLI.1.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.1_1",
      NAME_1: "Bamako",
      NL_NAME_1: "None",
      GID_2: "MLI.1.1_1",
      NAME_2: "Bamako",
      isoCode: "ML",
    },
    {
      region_id: "MLI.2.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.2_1",
      NAME_1: "Gao",
      NL_NAME_1: "None",
      GID_2: "MLI.2.1_1",
      NAME_2: "Ansongo",
      isoCode: "ML",
    },
    {
      region_id: "MLI.2.2_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.2_1",
      NAME_1: "Gao",
      NL_NAME_1: "None",
      GID_2: "MLI.2.2_1",
      NAME_2: "Bourem",
      isoCode: "ML",
    },
    {
      region_id: "MLI.2.3_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.2_1",
      NAME_1: "Gao",
      NL_NAME_1: "None",
      GID_2: "MLI.2.3_1",
      NAME_2: "Gao",
      isoCode: "ML",
    },
    {
      region_id: "MLI.2.4_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.2_1",
      NAME_1: "Gao",
      NL_NAME_1: "None",
      GID_2: "MLI.2.4_1",
      NAME_2: "Ménaka",
      isoCode: "ML",
    },
    {
      region_id: "MLI.3.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.3_1",
      NAME_1: "Kayes",
      NL_NAME_1: "None",
      GID_2: "MLI.3.1_1",
      NAME_2: "Bafoulabé",
      isoCode: "ML",
    },
    {
      region_id: "MLI.3.2_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.3_1",
      NAME_1: "Kayes",
      NL_NAME_1: "None",
      GID_2: "MLI.3.2_1",
      NAME_2: "Diéma",
      isoCode: "ML",
    },
    {
      region_id: "MLI.3.3_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.3_1",
      NAME_1: "Kayes",
      NL_NAME_1: "None",
      GID_2: "MLI.3.3_1",
      NAME_2: "Kayes",
      isoCode: "ML",
    },
    {
      region_id: "MLI.3.4_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.3_1",
      NAME_1: "Kayes",
      NL_NAME_1: "None",
      GID_2: "MLI.3.4_1",
      NAME_2: "Kéniéba",
      isoCode: "ML",
    },
    {
      region_id: "MLI.3.5_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.3_1",
      NAME_1: "Kayes",
      NL_NAME_1: "None",
      GID_2: "MLI.3.5_1",
      NAME_2: "Kita",
      isoCode: "ML",
    },
    {
      region_id: "MLI.3.6_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.3_1",
      NAME_1: "Kayes",
      NL_NAME_1: "None",
      GID_2: "MLI.3.6_1",
      NAME_2: "Nioro",
      isoCode: "ML",
    },
    {
      region_id: "MLI.3.7_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.3_1",
      NAME_1: "Kayes",
      NL_NAME_1: "None",
      GID_2: "MLI.3.7_1",
      NAME_2: "Yélimané",
      isoCode: "ML",
    },
    {
      region_id: "MLI.4.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.4_1",
      NAME_1: "Kidal",
      NL_NAME_1: "None",
      GID_2: "MLI.4.1_1",
      NAME_2: "Abeïbara",
      isoCode: "ML",
    },
    {
      region_id: "MLI.4.2_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.4_1",
      NAME_1: "Kidal",
      NL_NAME_1: "None",
      GID_2: "MLI.4.2_1",
      NAME_2: "Kidal",
      isoCode: "ML",
    },
    {
      region_id: "MLI.4.3_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.4_1",
      NAME_1: "Kidal",
      NL_NAME_1: "None",
      GID_2: "MLI.4.3_1",
      NAME_2: "Tessalit",
      isoCode: "ML",
    },
    {
      region_id: "MLI.4.4_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.4_1",
      NAME_1: "Kidal",
      NL_NAME_1: "None",
      GID_2: "MLI.4.4_1",
      NAME_2: "Tin-Essako",
      isoCode: "ML",
    },
    {
      region_id: "MLI.5.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.5_1",
      NAME_1: "Koulikoro",
      NL_NAME_1: "None",
      GID_2: "MLI.5.1_1",
      NAME_2: "Banamba",
      isoCode: "ML",
    },
    {
      region_id: "MLI.5.2_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.5_1",
      NAME_1: "Koulikoro",
      NL_NAME_1: "None",
      GID_2: "MLI.5.2_1",
      NAME_2: "Dioïla",
      isoCode: "ML",
    },
    {
      region_id: "MLI.5.3_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.5_1",
      NAME_1: "Koulikoro",
      NL_NAME_1: "None",
      GID_2: "MLI.5.3_1",
      NAME_2: "Kangaba",
      isoCode: "ML",
    },
    {
      region_id: "MLI.5.4_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.5_1",
      NAME_1: "Koulikoro",
      NL_NAME_1: "None",
      GID_2: "MLI.5.4_1",
      NAME_2: "Kati",
      isoCode: "ML",
    },
    {
      region_id: "MLI.5.5_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.5_1",
      NAME_1: "Koulikoro",
      NL_NAME_1: "None",
      GID_2: "MLI.5.5_1",
      NAME_2: "Kolokani",
      isoCode: "ML",
    },
    {
      region_id: "MLI.5.6_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.5_1",
      NAME_1: "Koulikoro",
      NL_NAME_1: "None",
      GID_2: "MLI.5.6_1",
      NAME_2: "Koulikoro",
      isoCode: "ML",
    },
    {
      region_id: "MLI.5.7_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.5_1",
      NAME_1: "Koulikoro",
      NL_NAME_1: "None",
      GID_2: "MLI.5.7_1",
      NAME_2: "Nara",
      isoCode: "ML",
    },
    {
      region_id: "MLI.6.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.6_1",
      NAME_1: "Mopti",
      NL_NAME_1: "None",
      GID_2: "MLI.6.1_1",
      NAME_2: "Bandiagara",
      isoCode: "ML",
    },
    {
      region_id: "MLI.6.2_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.6_1",
      NAME_1: "Mopti",
      NL_NAME_1: "None",
      GID_2: "MLI.6.2_1",
      NAME_2: "Bankass",
      isoCode: "ML",
    },
    {
      region_id: "MLI.6.3_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.6_1",
      NAME_1: "Mopti",
      NL_NAME_1: "None",
      GID_2: "MLI.6.3_1",
      NAME_2: "Djenné",
      isoCode: "ML",
    },
    {
      region_id: "MLI.6.4_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.6_1",
      NAME_1: "Mopti",
      NL_NAME_1: "None",
      GID_2: "MLI.6.4_1",
      NAME_2: "Douentza",
      isoCode: "ML",
    },
    {
      region_id: "MLI.6.5_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.6_1",
      NAME_1: "Mopti",
      NL_NAME_1: "None",
      GID_2: "MLI.6.5_1",
      NAME_2: "Koro",
      isoCode: "ML",
    },
    {
      region_id: "MLI.6.6_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.6_1",
      NAME_1: "Mopti",
      NL_NAME_1: "None",
      GID_2: "MLI.6.6_1",
      NAME_2: "Mopti",
      isoCode: "ML",
    },
    {
      region_id: "MLI.6.7_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.6_1",
      NAME_1: "Mopti",
      NL_NAME_1: "None",
      GID_2: "MLI.6.7_1",
      NAME_2: "Ténenkou",
      isoCode: "ML",
    },
    {
      region_id: "MLI.6.8_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.6_1",
      NAME_1: "Mopti",
      NL_NAME_1: "None",
      GID_2: "MLI.6.8_1",
      NAME_2: "Youwarou",
      isoCode: "ML",
    },
    {
      region_id: "MLI.7.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.7_1",
      NAME_1: "Ségou",
      NL_NAME_1: "None",
      GID_2: "MLI.7.1_1",
      NAME_2: "Barouéli",
      isoCode: "ML",
    },
    {
      region_id: "MLI.7.2_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.7_1",
      NAME_1: "Ségou",
      NL_NAME_1: "None",
      GID_2: "MLI.7.2_1",
      NAME_2: "Bla",
      isoCode: "ML",
    },
    {
      region_id: "MLI.7.3_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.7_1",
      NAME_1: "Ségou",
      NL_NAME_1: "None",
      GID_2: "MLI.7.3_1",
      NAME_2: "Macina",
      isoCode: "ML",
    },
    {
      region_id: "MLI.7.4_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.7_1",
      NAME_1: "Ségou",
      NL_NAME_1: "None",
      GID_2: "MLI.7.4_1",
      NAME_2: "Niono",
      isoCode: "ML",
    },
    {
      region_id: "MLI.7.5_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.7_1",
      NAME_1: "Ségou",
      NL_NAME_1: "None",
      GID_2: "MLI.7.5_1",
      NAME_2: "San",
      isoCode: "ML",
    },
    {
      region_id: "MLI.7.6_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.7_1",
      NAME_1: "Ségou",
      NL_NAME_1: "None",
      GID_2: "MLI.7.6_1",
      NAME_2: "Ségou",
      isoCode: "ML",
    },
    {
      region_id: "MLI.7.7_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.7_1",
      NAME_1: "Ségou",
      NL_NAME_1: "None",
      GID_2: "MLI.7.7_1",
      NAME_2: "Tominian",
      isoCode: "ML",
    },
    {
      region_id: "MLI.8.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.8_1",
      NAME_1: "Sikasso",
      NL_NAME_1: "None",
      GID_2: "MLI.8.1_1",
      NAME_2: "Bougouni",
      isoCode: "ML",
    },
    {
      region_id: "MLI.8.2_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.8_1",
      NAME_1: "Sikasso",
      NL_NAME_1: "None",
      GID_2: "MLI.8.2_1",
      NAME_2: "Kadiolo",
      isoCode: "ML",
    },
    {
      region_id: "MLI.8.3_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.8_1",
      NAME_1: "Sikasso",
      NL_NAME_1: "None",
      GID_2: "MLI.8.3_1",
      NAME_2: "Kolondiéba",
      isoCode: "ML",
    },
    {
      region_id: "MLI.8.4_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.8_1",
      NAME_1: "Sikasso",
      NL_NAME_1: "None",
      GID_2: "MLI.8.4_1",
      NAME_2: "Koutiala",
      isoCode: "ML",
    },
    {
      region_id: "MLI.8.5_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.8_1",
      NAME_1: "Sikasso",
      NL_NAME_1: "None",
      GID_2: "MLI.8.5_1",
      NAME_2: "Sikasso",
      isoCode: "ML",
    },
    {
      region_id: "MLI.8.6_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.8_1",
      NAME_1: "Sikasso",
      NL_NAME_1: "None",
      GID_2: "MLI.8.6_1",
      NAME_2: "Yanfolila",
      isoCode: "ML",
    },
    {
      region_id: "MLI.8.7_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.8_1",
      NAME_1: "Sikasso",
      NL_NAME_1: "None",
      GID_2: "MLI.8.7_1",
      NAME_2: "Yorosso",
      isoCode: "ML",
    },
    {
      region_id: "MLI.9.1_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.9_1",
      NAME_1: "Timbuktu",
      NL_NAME_1: "None",
      GID_2: "MLI.9.1_1",
      NAME_2: "Diré",
      isoCode: "ML",
    },
    {
      region_id: "MLI.9.2_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.9_1",
      NAME_1: "Timbuktu",
      NL_NAME_1: "None",
      GID_2: "MLI.9.2_1",
      NAME_2: "Goundam",
      isoCode: "ML",
    },
    {
      region_id: "MLI.9.3_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.9_1",
      NAME_1: "Timbuktu",
      NL_NAME_1: "None",
      GID_2: "MLI.9.3_1",
      NAME_2: "Gourma-Rharous",
      isoCode: "ML",
    },
    {
      region_id: "MLI.9.4_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.9_1",
      NAME_1: "Timbuktu",
      NL_NAME_1: "None",
      GID_2: "MLI.9.4_1",
      NAME_2: "Niafunké",
      isoCode: "ML",
    },
    {
      region_id: "MLI.9.5_1",
      GID_0: "MLI",
      NAME_0: "Mali",
      GID_1: "MLI.9_1",
      NAME_1: "Timbuktu",
      NL_NAME_1: "None",
      GID_2: "MLI.9.5_1",
      NAME_2: "Tombouctou",
      isoCode: "ML",
    },
    {
      region_id: "NER.1.1_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.1_1",
      NAME_1: "Agadez",
      NL_NAME_1: "None",
      GID_2: "NER.1.1_1",
      NAME_2: "Arlit",
      isoCode: "NE",
    },
    {
      region_id: "NER.1.2_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.1_1",
      NAME_1: "Agadez",
      NL_NAME_1: "None",
      GID_2: "NER.1.2_1",
      NAME_2: "Bilma",
      isoCode: "NE",
    },
    {
      region_id: "NER.1.3_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.1_1",
      NAME_1: "Agadez",
      NL_NAME_1: "None",
      GID_2: "NER.1.3_1",
      NAME_2: "Tchighozerine",
      isoCode: "NE",
    },
    {
      region_id: "NER.2.1_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.2_1",
      NAME_1: "Diffa",
      NL_NAME_1: "None",
      GID_2: "NER.2.1_1",
      NAME_2: "Diffa",
      isoCode: "NE",
    },
    {
      region_id: "NER.2.2_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.2_1",
      NAME_1: "Diffa",
      NL_NAME_1: "None",
      GID_2: "NER.2.2_1",
      NAME_2: "Maïné-Soroa",
      isoCode: "NE",
    },
    {
      region_id: "NER.2.3_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.2_1",
      NAME_1: "Diffa",
      NL_NAME_1: "None",
      GID_2: "NER.2.3_1",
      NAME_2: "N'Guigmi",
      isoCode: "NE",
    },
    {
      region_id: "NER.3.1_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.3_1",
      NAME_1: "Dosso",
      NL_NAME_1: "None",
      GID_2: "NER.3.1_1",
      NAME_2: "Boboye",
      isoCode: "NE",
    },
    {
      region_id: "NER.3.2_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.3_1",
      NAME_1: "Dosso",
      NL_NAME_1: "None",
      GID_2: "NER.3.2_1",
      NAME_2: "Dogon-Doutchi",
      isoCode: "NE",
    },
    {
      region_id: "NER.3.3_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.3_1",
      NAME_1: "Dosso",
      NL_NAME_1: "None",
      GID_2: "NER.3.3_1",
      NAME_2: "Dosso",
      isoCode: "NE",
    },
    {
      region_id: "NER.3.4_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.3_1",
      NAME_1: "Dosso",
      NL_NAME_1: "None",
      GID_2: "NER.3.4_1",
      NAME_2: "Gaya",
      isoCode: "NE",
    },
    {
      region_id: "NER.3.5_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.3_1",
      NAME_1: "Dosso",
      NL_NAME_1: "None",
      GID_2: "NER.3.5_1",
      NAME_2: "Loga",
      isoCode: "NE",
    },
    {
      region_id: "NER.4.1_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.4_1",
      NAME_1: "Maradi",
      NL_NAME_1: "None",
      GID_2: "NER.4.1_1",
      NAME_2: "Aguié",
      isoCode: "NE",
    },
    {
      region_id: "NER.4.2_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.4_1",
      NAME_1: "Maradi",
      NL_NAME_1: "None",
      GID_2: "NER.4.2_1",
      NAME_2: "Dakoro",
      isoCode: "NE",
    },
    {
      region_id: "NER.4.3_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.4_1",
      NAME_1: "Maradi",
      NL_NAME_1: "None",
      GID_2: "NER.4.3_1",
      NAME_2: "Groumdji",
      isoCode: "NE",
    },
    {
      region_id: "NER.4.4_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.4_1",
      NAME_1: "Maradi",
      NL_NAME_1: "None",
      GID_2: "NER.4.4_1",
      NAME_2: "Madarounfa",
      isoCode: "NE",
    },
    {
      region_id: "NER.4.5_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.4_1",
      NAME_1: "Maradi",
      NL_NAME_1: "None",
      GID_2: "NER.4.5_1",
      NAME_2: "Mayahi",
      isoCode: "NE",
    },
    {
      region_id: "NER.4.6_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.4_1",
      NAME_1: "Maradi",
      NL_NAME_1: "None",
      GID_2: "NER.4.6_1",
      NAME_2: "Tessaoua",
      isoCode: "NE",
    },
    {
      region_id: "NER.5.1_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.5_1",
      NAME_1: "Niamey",
      NL_NAME_1: "None",
      GID_2: "NER.5.1_1",
      NAME_2: "Niamey",
      isoCode: "NE",
    },
    {
      region_id: "NER.6.1_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.6_1",
      NAME_1: "Tahoua",
      NL_NAME_1: "None",
      GID_2: "NER.6.1_1",
      NAME_2: "Bkonni",
      isoCode: "NE",
    },
    {
      region_id: "NER.6.2_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.6_1",
      NAME_1: "Tahoua",
      NL_NAME_1: "None",
      GID_2: "NER.6.2_1",
      NAME_2: "Bouza",
      isoCode: "NE",
    },
    {
      region_id: "NER.6.3_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.6_1",
      NAME_1: "Tahoua",
      NL_NAME_1: "None",
      GID_2: "NER.6.3_1",
      NAME_2: "Illéla",
      isoCode: "NE",
    },
    {
      region_id: "NER.6.4_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.6_1",
      NAME_1: "Tahoua",
      NL_NAME_1: "None",
      GID_2: "NER.6.4_1",
      NAME_2: "Keita",
      isoCode: "NE",
    },
    {
      region_id: "NER.6.5_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.6_1",
      NAME_1: "Tahoua",
      NL_NAME_1: "None",
      GID_2: "NER.6.5_1",
      NAME_2: "Madaoua",
      isoCode: "NE",
    },
    {
      region_id: "NER.6.6_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.6_1",
      NAME_1: "Tahoua",
      NL_NAME_1: "None",
      GID_2: "NER.6.6_1",
      NAME_2: "Tahoua",
      isoCode: "NE",
    },
    {
      region_id: "NER.6.7_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.6_1",
      NAME_1: "Tahoua",
      NL_NAME_1: "None",
      GID_2: "NER.6.7_1",
      NAME_2: "Tchin-Tabarade",
      isoCode: "NE",
    },
    {
      region_id: "NER.7.1_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.7_1",
      NAME_1: "Tillabéry",
      NL_NAME_1: "None",
      GID_2: "NER.7.1_1",
      NAME_2: "Filingué",
      isoCode: "NE",
    },
    {
      region_id: "NER.7.2_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.7_1",
      NAME_1: "Tillabéry",
      NL_NAME_1: "None",
      GID_2: "NER.7.2_1",
      NAME_2: "Kollo",
      isoCode: "NE",
    },
    {
      region_id: "NER.7.3_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.7_1",
      NAME_1: "Tillabéry",
      NL_NAME_1: "None",
      GID_2: "NER.7.3_1",
      NAME_2: "Ouallam",
      isoCode: "NE",
    },
    {
      region_id: "NER.7.4_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.7_1",
      NAME_1: "Tillabéry",
      NL_NAME_1: "None",
      GID_2: "NER.7.4_1",
      NAME_2: "Say",
      isoCode: "NE",
    },
    {
      region_id: "NER.7.5_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.7_1",
      NAME_1: "Tillabéry",
      NL_NAME_1: "None",
      GID_2: "NER.7.5_1",
      NAME_2: "Téra",
      isoCode: "NE",
    },
    {
      region_id: "NER.7.6_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.7_1",
      NAME_1: "Tillabéry",
      NL_NAME_1: "None",
      GID_2: "NER.7.6_1",
      NAME_2: "Tillabéry",
      isoCode: "NE",
    },
    {
      region_id: "NER.8.1_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.8_1",
      NAME_1: "Zinder",
      NL_NAME_1: "None",
      GID_2: "NER.8.1_1",
      NAME_2: "Gouré",
      isoCode: "NE",
    },
    {
      region_id: "NER.8.2_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.8_1",
      NAME_1: "Zinder",
      NL_NAME_1: "None",
      GID_2: "NER.8.2_1",
      NAME_2: "Magaria",
      isoCode: "NE",
    },
    {
      region_id: "NER.8.3_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.8_1",
      NAME_1: "Zinder",
      NL_NAME_1: "None",
      GID_2: "NER.8.3_1",
      NAME_2: "Matameye",
      isoCode: "NE",
    },
    {
      region_id: "NER.8.4_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.8_1",
      NAME_1: "Zinder",
      NL_NAME_1: "None",
      GID_2: "NER.8.4_1",
      NAME_2: "Mirriah",
      isoCode: "NE",
    },
    {
      region_id: "NER.8.5_1",
      GID_0: "NER",
      NAME_0: "Niger",
      GID_1: "NER.8_1",
      NAME_1: "Zinder",
      NL_NAME_1: "None",
      GID_2: "NER.8.5_1",
      NAME_2: "Tanout",
      isoCode: "NE",
    },
    {
      region_id: "NGA.1.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.1_1",
      NAME_2: "Aba North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.2_1",
      NAME_2: "Aba South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.3_1",
      NAME_2: "Arochukw",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.4_1",
      NAME_2: "Bende",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.5_1",
      NAME_2: "Ikwuano",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.6_1",
      NAME_2: "Isiala Ngwa North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.7_1",
      NAME_2: "Isiala Ngwa South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.8_1",
      NAME_2: "Isuikwua",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.9_1",
      NAME_2: "Oboma Ngwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.10_1",
      NAME_2: "Ohafia Abia",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.11_1",
      NAME_2: "Osisioma Ngwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.12_1",
      NAME_2: "Ugwunagbo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.13_1",
      NAME_2: "Ukwa East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.14_1",
      NAME_2: "Ukwa West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.15_1",
      NAME_2: "Umu-Nneochi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.16_1",
      NAME_2: "Umuahia North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.1.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.1_1",
      NAME_1: "Abia",
      NL_NAME_1: "None",
      GID_2: "NGA.1.17_1",
      NAME_2: "Umuahia South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.1_1",
      NAME_2: "Demsa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.2_1",
      NAME_2: "Fufore",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.3_1",
      NAME_2: "Ganye",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.4_1",
      NAME_2: "Girie",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.5_1",
      NAME_2: "Gombi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.6_1",
      NAME_2: "Guyuk",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.7_1",
      NAME_2: "Hong",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.8_1",
      NAME_2: "Jada",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.9_1",
      NAME_2: "Lamurde",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.10_1",
      NAME_2: "Madagali",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.11_1",
      NAME_2: "Maiha",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.12_1",
      NAME_2: "Mayo-Bel",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.13_1",
      NAME_2: "Michika",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.14_1",
      NAME_2: "Mubi North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.15_1",
      NAME_2: "Mubi South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.16_1",
      NAME_2: "Numan",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.17_1",
      NAME_2: "Shelleng",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.18_1",
      NAME_2: "Song",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.19_1",
      NAME_2: "Teungo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.20_1",
      NAME_2: "Yola North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.2.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.2_1",
      NAME_1: "Adamawa",
      NL_NAME_1: "None",
      GID_2: "NGA.2.21_1",
      NAME_2: "Yola South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.1_1",
      NAME_2: "Abak",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.2_1",
      NAME_2: "Eastern Obolo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.3_1",
      NAME_2: "Eket",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.4_1",
      NAME_2: "Esit Eket",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.5_1",
      NAME_2: "Essien-U",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.6_1",
      NAME_2: "EtimEkpo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.7_1",
      NAME_2: "Etinan",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.8_1",
      NAME_2: "Ibeno",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.9_1",
      NAME_2: "Ibesikpo Asutan",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.10_1",
      NAME_2: "Ibiono Ibom",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.11_1",
      NAME_2: "Ika",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.12_1",
      NAME_2: "Ikono",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.13_1",
      NAME_2: "Ikot-Aba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.14_1",
      NAME_2: "Ikot-Ekp",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.15_1",
      NAME_2: "Ini",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.16_1",
      NAME_2: "Itu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.17_1",
      NAME_2: "Mbo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.18_1",
      NAME_2: "Mkpat Enin",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.19_1",
      NAME_2: "Nsit Atai",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.20_1",
      NAME_2: "Nsit Ibom",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.21_1",
      NAME_2: "Nsit Ubium",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.22_1",
      NAME_2: "Obot Akara",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.23_1",
      NAME_2: "Okobo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.24_1",
      NAME_2: "Onna",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.25_1",
      NAME_2: "Oron",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.26_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.26_1",
      NAME_2: "Oruk-Ana",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.27_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.27_1",
      NAME_2: "Udung Uko",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.28_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.28_1",
      NAME_2: "Ukanafun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.29_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.29_1",
      NAME_2: "Uruan",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.30_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.30_1",
      NAME_2: "UrueOffo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.3.31_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.3_1",
      NAME_1: "Akwa Ibom",
      NL_NAME_1: "None",
      GID_2: "NGA.3.31_1",
      NAME_2: "Uyo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.1_1",
      NAME_2: "Aguata",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.2_1",
      NAME_2: "Anambra East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.3_1",
      NAME_2: "Anambra West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.4_1",
      NAME_2: "Anaocha",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.5_1",
      NAME_2: "AwkaNort",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.6_1",
      NAME_2: "AwkaSout",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.7_1",
      NAME_2: "Ayamelum",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.8_1",
      NAME_2: "Dunukofia",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.9_1",
      NAME_2: "Ekwusigo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.10_1",
      NAME_2: "Idemili North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.11_1",
      NAME_2: "Idemili South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.12_1",
      NAME_2: "Ihiala",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.13_1",
      NAME_2: "Njikoka",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.14_1",
      NAME_2: "NnewiNort",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.15_1",
      NAME_2: "NnewiSou",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.16_1",
      NAME_2: "Ogbaru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.17_1",
      NAME_2: "Onitsha North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.18_1",
      NAME_2: "Onitsha South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.19_1",
      NAME_2: "OrumbaNo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.20_1",
      NAME_2: "OrumbaSo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.4.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.4_1",
      NAME_1: "Anambra",
      NL_NAME_1: "None",
      GID_2: "NGA.4.21_1",
      NAME_2: "Oyi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.1_1",
      NAME_2: "Alkaleri",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.2_1",
      NAME_2: "Bauchi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.3_1",
      NAME_2: "Bogoro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.4_1",
      NAME_2: "Damban",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.5_1",
      NAME_2: "Darazo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.6_1",
      NAME_2: "Dass",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.7_1",
      NAME_2: "Gamawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.8_1",
      NAME_2: "Gamjuwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.9_1",
      NAME_2: "Giade",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.10_1",
      NAME_2: "Itas/Gad",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.11_1",
      NAME_2: "Jama'are",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.12_1",
      NAME_2: "Katagum",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.13_1",
      NAME_2: "Kirfi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.14_1",
      NAME_2: "Misau",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.15_1",
      NAME_2: "Ningi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.16_1",
      NAME_2: "Shira",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.17_1",
      NAME_2: "Tafawa-B",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.18_1",
      NAME_2: "Toro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.19_1",
      NAME_2: "Warji",
      isoCode: "NG",
    },
    {
      region_id: "NGA.5.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.5_1",
      NAME_1: "Bauchi",
      NL_NAME_1: "None",
      GID_2: "NGA.5.20_1",
      NAME_2: "Zaki",
      isoCode: "NG",
    },
    {
      region_id: "NGA.6.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.6_1",
      NAME_1: "Bayelsa",
      NL_NAME_1: "None",
      GID_2: "NGA.6.1_1",
      NAME_2: "Brass",
      isoCode: "NG",
    },
    {
      region_id: "NGA.6.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.6_1",
      NAME_1: "Bayelsa",
      NL_NAME_1: "None",
      GID_2: "NGA.6.2_1",
      NAME_2: "Ekeremor",
      isoCode: "NG",
    },
    {
      region_id: "NGA.6.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.6_1",
      NAME_1: "Bayelsa",
      NL_NAME_1: "None",
      GID_2: "NGA.6.3_1",
      NAME_2: "Kolokuma/Opokuma",
      isoCode: "NG",
    },
    {
      region_id: "NGA.6.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.6_1",
      NAME_1: "Bayelsa",
      NL_NAME_1: "None",
      GID_2: "NGA.6.4_1",
      NAME_2: "Nembe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.6.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.6_1",
      NAME_1: "Bayelsa",
      NL_NAME_1: "None",
      GID_2: "NGA.6.5_1",
      NAME_2: "Ogbia",
      isoCode: "NG",
    },
    {
      region_id: "NGA.6.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.6_1",
      NAME_1: "Bayelsa",
      NL_NAME_1: "None",
      GID_2: "NGA.6.6_1",
      NAME_2: "Sagbama",
      isoCode: "NG",
    },
    {
      region_id: "NGA.6.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.6_1",
      NAME_1: "Bayelsa",
      NL_NAME_1: "None",
      GID_2: "NGA.6.7_1",
      NAME_2: "Southern Ijaw",
      isoCode: "NG",
    },
    {
      region_id: "NGA.6.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.6_1",
      NAME_1: "Bayelsa",
      NL_NAME_1: "None",
      GID_2: "NGA.6.8_1",
      NAME_2: "Yenegoa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.1_1",
      NAME_2: "Ado",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.2_1",
      NAME_2: "Agatu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.3_1",
      NAME_2: "Apa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.4_1",
      NAME_2: "Buruku",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.5_1",
      NAME_2: "Gboko",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.6_1",
      NAME_2: "Guma",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.7_1",
      NAME_2: "Gwer East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.8_1",
      NAME_2: "GwerWest",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.9_1",
      NAME_2: "Katsina (Benue)",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.10_1",
      NAME_2: "Konshish",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.11_1",
      NAME_2: "Kwande",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.12_1",
      NAME_2: "Logo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.13_1",
      NAME_2: "Makurdi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.14_1",
      NAME_2: "Obi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.15_1",
      NAME_2: "Ogbadibo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.16_1",
      NAME_2: "Ohimini",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.17_1",
      NAME_2: "Oju",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.18_1",
      NAME_2: "Okpokwu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.19_1",
      NAME_2: "Oturkpo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.20_1",
      NAME_2: "Tarka",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.21_1",
      NAME_2: "Ukum",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.22_1",
      NAME_2: "Ushongo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.7.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.7_1",
      NAME_1: "Benue",
      NL_NAME_1: "None",
      GID_2: "NGA.7.23_1",
      NAME_2: "Vandeiky",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.1_1",
      NAME_2: "Abadam",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.2_1",
      NAME_2: "Askira/U",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.3_1",
      NAME_2: "Bama",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.4_1",
      NAME_2: "Bayo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.5_1",
      NAME_2: "Biu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.6_1",
      NAME_2: "Chibok",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.7_1",
      NAME_2: "Damboa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.8_1",
      NAME_2: "Dikwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.9_1",
      NAME_2: "Gubio",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.10_1",
      NAME_2: "Guzamala",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.11_1",
      NAME_2: "Gwoza",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.12_1",
      NAME_2: "Hawul",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.13_1",
      NAME_2: "Jere",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.14_1",
      NAME_2: "Kaga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.15_1",
      NAME_2: "Kala/Balge",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.16_1",
      NAME_2: "Konduga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.17_1",
      NAME_2: "Kukawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.18_1",
      NAME_2: "Kwaya Kusar",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.19_1",
      NAME_2: "Lake Chad",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.20_1",
      NAME_2: "Mafa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.21_1",
      NAME_2: "Magumeri",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.22_1",
      NAME_2: "Maidugur",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.23_1",
      NAME_2: "Marte",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.24_1",
      NAME_2: "Mobbar",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.25_1",
      NAME_2: "Monguno",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.26_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.26_1",
      NAME_2: "Ngala",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.27_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.27_1",
      NAME_2: "Nganzai",
      isoCode: "NG",
    },
    {
      region_id: "NGA.8.28_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.8_1",
      NAME_1: "Borno",
      NL_NAME_1: "None",
      GID_2: "NGA.8.28_1",
      NAME_2: "Shani",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.1_1",
      NAME_2: "Abi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.2_1",
      NAME_2: "Akamkpa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.3_1",
      NAME_2: "Akpabuyo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.4_1",
      NAME_2: "Bakassi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.5_1",
      NAME_2: "Bekwarra",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.6_1",
      NAME_2: "Biase",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.7_1",
      NAME_2: "Boki",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.8_1",
      NAME_2: "Calabar",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.9_1",
      NAME_2: "Calabar South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.10_1",
      NAME_2: "Etung",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.11_1",
      NAME_2: "Ikom",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.12_1",
      NAME_2: "Obanliku",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.13_1",
      NAME_2: "Obubra",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.14_1",
      NAME_2: "Obudu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.15_1",
      NAME_2: "Odukpani",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.16_1",
      NAME_2: "Ogoja",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.17_1",
      NAME_2: "Yakurr",
      isoCode: "NG",
    },
    {
      region_id: "NGA.9.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.9_1",
      NAME_1: "Cross River",
      NL_NAME_1: "None",
      GID_2: "NGA.9.18_1",
      NAME_2: "Yala Cross",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.1_1",
      NAME_2: "AniochaN",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.2_1",
      NAME_2: "AniochaS",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.3_1",
      NAME_2: "Bomadi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.4_1",
      NAME_2: "Burutu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.5_1",
      NAME_2: "Ethiope West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.6_1",
      NAME_2: "EthiopeE",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.7_1",
      NAME_2: "IkaNorth",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.8_1",
      NAME_2: "IkaSouth",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.9_1",
      NAME_2: "IsokoNor",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.10_1",
      NAME_2: "IsokoSou",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.11_1",
      NAME_2: "Ndokwa East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.12_1",
      NAME_2: "Ndokwa West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.13_1",
      NAME_2: "Okpe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.14_1",
      NAME_2: "Oshimili North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.15_1",
      NAME_2: "Oshimili South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.16_1",
      NAME_2: "Patani",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.17_1",
      NAME_2: "Sapele",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.18_1",
      NAME_2: "Udu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.19_1",
      NAME_2: "Ughelli North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.20_1",
      NAME_2: "Ughelli South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.21_1",
      NAME_2: "Ukwuani",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.22_1",
      NAME_2: "Uvwie",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.23_1",
      NAME_2: "Warri North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.24_1",
      NAME_2: "Warri South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.10.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.10_1",
      NAME_1: "Delta",
      NL_NAME_1: "None",
      GID_2: "NGA.10.25_1",
      NAME_2: "Warri South-West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.1_1",
      NAME_2: "Abakalik",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.2_1",
      NAME_2: "Afikpo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.3_1",
      NAME_2: "AfikpoSo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.4_1",
      NAME_2: "Ebonyi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.5_1",
      NAME_2: "Ezza North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.6_1",
      NAME_2: "Ezza South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.7_1",
      NAME_2: "Ikwo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.8_1",
      NAME_2: "Ishielu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.9_1",
      NAME_2: "Ivo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.10_1",
      NAME_2: "Izzi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.11_1",
      NAME_2: "Ohaozara",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.12_1",
      NAME_2: "Ohaukwu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.11.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.11_1",
      NAME_1: "Ebonyi",
      NL_NAME_1: "None",
      GID_2: "NGA.11.13_1",
      NAME_2: "Onicha",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.1_1",
      NAME_2: "Akoko-Ed",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.2_1",
      NAME_2: "Egor",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.3_1",
      NAME_2: "EsanCent",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.4_1",
      NAME_2: "EsanNort",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.5_1",
      NAME_2: "EsanSout",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.6_1",
      NAME_2: "EsanWest",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.7_1",
      NAME_2: "Etsako Central",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.8_1",
      NAME_2: "EtsakoEa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.9_1",
      NAME_2: "EtsakoWe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.10_1",
      NAME_2: "Igueben",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.11_1",
      NAME_2: "Ikpoba-Okha",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.12_1",
      NAME_2: "Oredo Edo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.13_1",
      NAME_2: "Orhionmw",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.14_1",
      NAME_2: "OviaNort",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.15_1",
      NAME_2: "OviaSouth-West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.16_1",
      NAME_2: "Owan East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.17_1",
      NAME_2: "OwanWest",
      isoCode: "NG",
    },
    {
      region_id: "NGA.12.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.12_1",
      NAME_1: "Edo",
      NL_NAME_1: "None",
      GID_2: "NGA.12.18_1",
      NAME_2: "Uhunmwonde",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.1_1",
      NAME_2: "Ado-Ekiti",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.2_1",
      NAME_2: "Efon",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.3_1",
      NAME_2: "EkitiEas",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.4_1",
      NAME_2: "EkitiSouth-West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.5_1",
      NAME_2: "EkitiWest",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.6_1",
      NAME_2: "Emure/Ise/Orun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.7_1",
      NAME_2: "Gboyin",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.8_1",
      NAME_2: "Ido/Osi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.9_1",
      NAME_2: "Ijero",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.10_1",
      NAME_2: "Ikere",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.11_1",
      NAME_2: "Ikole",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.12_1",
      NAME_2: "Ilejemeje",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.13_1",
      NAME_2: "Irepodun/Ifelodun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.14_1",
      NAME_2: "Ise/Orun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.15_1",
      NAME_2: "Moba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.13.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.13_1",
      NAME_1: "Ekiti",
      NL_NAME_1: "None",
      GID_2: "NGA.13.16_1",
      NAME_2: "Oye",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.1_1",
      NAME_2: "Aninri",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.2_1",
      NAME_2: "Awgu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.3_1",
      NAME_2: "Enugu East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.4_1",
      NAME_2: "Enugu North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.5_1",
      NAME_2: "EnuguSou",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.6_1",
      NAME_2: "Ezeagu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.7_1",
      NAME_2: "Igbo-Eti",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.8_1",
      NAME_2: "Igbo-eze North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.9_1",
      NAME_2: "Igbo-eze South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.10_1",
      NAME_2: "Isi-Uzo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.11_1",
      NAME_2: "Nkanu East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.12_1",
      NAME_2: "Nkanu West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.13_1",
      NAME_2: "Nsukka",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.14_1",
      NAME_2: "Oji-River",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.15_1",
      NAME_2: "Udenu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.16_1",
      NAME_2: "Udi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.14.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.14_1",
      NAME_1: "Enugu",
      NL_NAME_1: "None",
      GID_2: "NGA.14.17_1",
      NAME_2: "Uzo-Uwani",
      isoCode: "NG",
    },
    {
      region_id: "NGA.15.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.15_1",
      NAME_1: "Federal Capital Territory",
      NL_NAME_1: "None",
      GID_2: "NGA.15.1_1",
      NAME_2: "Abaji",
      isoCode: "NG",
    },
    {
      region_id: "NGA.15.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.15_1",
      NAME_1: "Federal Capital Territory",
      NL_NAME_1: "None",
      GID_2: "NGA.15.2_1",
      NAME_2: "AbujaMun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.15.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.15_1",
      NAME_1: "Federal Capital Territory",
      NL_NAME_1: "None",
      GID_2: "NGA.15.3_1",
      NAME_2: "Bwari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.15.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.15_1",
      NAME_1: "Federal Capital Territory",
      NL_NAME_1: "None",
      GID_2: "NGA.15.4_1",
      NAME_2: "Gwagwala",
      isoCode: "NG",
    },
    {
      region_id: "NGA.15.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.15_1",
      NAME_1: "Federal Capital Territory",
      NL_NAME_1: "None",
      GID_2: "NGA.15.5_1",
      NAME_2: "Kuje",
      isoCode: "NG",
    },
    {
      region_id: "NGA.15.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.15_1",
      NAME_1: "Federal Capital Territory",
      NL_NAME_1: "None",
      GID_2: "NGA.15.6_1",
      NAME_2: "Kwali",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.1_1",
      NAME_2: "Akko",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.2_1",
      NAME_2: "Balanga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.3_1",
      NAME_2: "Billiri",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.4_1",
      NAME_2: "Dukku",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.5_1",
      NAME_2: "Funakaye",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.6_1",
      NAME_2: "Gombe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.7_1",
      NAME_2: "Kaltungo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.8_1",
      NAME_2: "Kwami",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.9_1",
      NAME_2: "Nafada",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.10_1",
      NAME_2: "Shomgom",
      isoCode: "NG",
    },
    {
      region_id: "NGA.16.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.16_1",
      NAME_1: "Gombe",
      NL_NAME_1: "None",
      GID_2: "NGA.16.11_1",
      NAME_2: "Yamaltu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.1_1",
      NAME_2: "Aboh-Mba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.2_1",
      NAME_2: "Ahizu-Mb",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.3_1",
      NAME_2: "Ehime-Mb",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.4_1",
      NAME_2: "Ezinihit",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.5_1",
      NAME_2: "Ideato South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.6_1",
      NAME_2: "IdeatoNo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.7_1",
      NAME_2: "Ihitte/U",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.8_1",
      NAME_2: "Ikeduru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.9_1",
      NAME_2: "IsialaMb",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.10_1",
      NAME_2: "Isu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.11_1",
      NAME_2: "Mbaitoli",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.12_1",
      NAME_2: "Ngor-Okp",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.13_1",
      NAME_2: "Njaba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.14_1",
      NAME_2: "Nkwerre",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.15_1",
      NAME_2: "Nwangele",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.16_1",
      NAME_2: "Obowo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.17_1",
      NAME_2: "Oguta",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.18_1",
      NAME_2: "Ohaji/Eg",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.19_1",
      NAME_2: "Okigwe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.20_1",
      NAME_2: "Orlu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.21_1",
      NAME_2: "Orsu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.22_1",
      NAME_2: "Oru East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.23_1",
      NAME_2: "Oru West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.24_1",
      NAME_2: "Owerri Municipal",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.25_1",
      NAME_2: "Owerri North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.26_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.26_1",
      NAME_2: "Owerri West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.17.27_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.17_1",
      NAME_1: "Imo",
      NL_NAME_1: "None",
      GID_2: "NGA.17.27_1",
      NAME_2: "Unuimo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.1_1",
      NAME_2: "Auyo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.2_1",
      NAME_2: "Babura",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.3_1",
      NAME_2: "Biriniwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.4_1",
      NAME_2: "BirninKu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.5_1",
      NAME_2: "Buji",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.6_1",
      NAME_2: "Dutse",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.7_1",
      NAME_2: "Gagarawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.8_1",
      NAME_2: "Garki",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.9_1",
      NAME_2: "Gumel",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.10_1",
      NAME_2: "Guri",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.11_1",
      NAME_2: "Gwaram",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.12_1",
      NAME_2: "Gwiwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.13_1",
      NAME_2: "Hadejia",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.14_1",
      NAME_2: "Jahun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.15_1",
      NAME_2: "KafinHau",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.16_1",
      NAME_2: "Kaugama",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.17_1",
      NAME_2: "Kazaure",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.18_1",
      NAME_2: "KiriKasa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.19_1",
      NAME_2: "Kiyawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.20_1",
      NAME_2: "Maigatari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.21_1",
      NAME_2: "MalamMad",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.22_1",
      NAME_2: "Miga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.23_1",
      NAME_2: "Ringim",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.24_1",
      NAME_2: "Roni",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.25_1",
      NAME_2: "Sule-Tan",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.26_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.26_1",
      NAME_2: "Taura",
      isoCode: "NG",
    },
    {
      region_id: "NGA.18.27_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.18_1",
      NAME_1: "Jigawa",
      NL_NAME_1: "None",
      GID_2: "NGA.18.27_1",
      NAME_2: "Yankwashi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.1_1",
      NAME_2: "Birnin-G",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.2_1",
      NAME_2: "Chikun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.3_1",
      NAME_2: "Giwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.4_1",
      NAME_2: "Igabi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.5_1",
      NAME_2: "Ikara",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.6_1",
      NAME_2: "Jaba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.7_1",
      NAME_2: "Jema'a",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.8_1",
      NAME_2: "Kachia",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.9_1",
      NAME_2: "Kaduna North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.10_1",
      NAME_2: "Kaduna South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.11_1",
      NAME_2: "Kagarko",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.12_1",
      NAME_2: "Kajuru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.13_1",
      NAME_2: "Kaura",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.14_1",
      NAME_2: "Kauru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.15_1",
      NAME_2: "Kubau",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.16_1",
      NAME_2: "Kudan",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.17_1",
      NAME_2: "Lere",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.18_1",
      NAME_2: "Makarfi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.19_1",
      NAME_2: "Sabon-Ga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.20_1",
      NAME_2: "Sanga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.21_1",
      NAME_2: "Soba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.22_1",
      NAME_2: "ZangonKa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.19.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.19_1",
      NAME_1: "Kaduna",
      NL_NAME_1: "None",
      GID_2: "NGA.19.23_1",
      NAME_2: "Zaria",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.1_1",
      NAME_2: "Ajingi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.2_1",
      NAME_2: "Albasu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.3_1",
      NAME_2: "Bagwai",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.4_1",
      NAME_2: "Bebeji",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.5_1",
      NAME_2: "Bichi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.6_1",
      NAME_2: "Bunkure",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.7_1",
      NAME_2: "Dala",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.8_1",
      NAME_2: "Dambatta",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.9_1",
      NAME_2: "DawakinK",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.10_1",
      NAME_2: "DawakinT",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.11_1",
      NAME_2: "Doguwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.12_1",
      NAME_2: "Fagge",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.13_1",
      NAME_2: "Gabasawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.14_1",
      NAME_2: "Garko",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.15_1",
      NAME_2: "Garum Mallam",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.16_1",
      NAME_2: "Gaya",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.17_1",
      NAME_2: "Gezawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.18_1",
      NAME_2: "Gwale",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.19_1",
      NAME_2: "Gwarzo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.20_1",
      NAME_2: "Kabo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.21_1",
      NAME_2: "Kano",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.22_1",
      NAME_2: "Karaye",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.23_1",
      NAME_2: "Kibiya",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.24_1",
      NAME_2: "Kiru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.25_1",
      NAME_2: "Kumbotso",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.26_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.26_1",
      NAME_2: "Kunchi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.27_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.27_1",
      NAME_2: "Kura",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.28_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.28_1",
      NAME_2: "Madobi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.29_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.29_1",
      NAME_2: "Makoda",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.30_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.30_1",
      NAME_2: "Minjibir",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.31_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.31_1",
      NAME_2: "Nassaraw",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.32_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.32_1",
      NAME_2: "Rano",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.33_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.33_1",
      NAME_2: "RiminGad",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.34_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.34_1",
      NAME_2: "Rogo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.35_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.35_1",
      NAME_2: "Shanono",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.36_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.36_1",
      NAME_2: "Sumaila",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.37_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.37_1",
      NAME_2: "Takai",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.38_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.38_1",
      NAME_2: "Tarauni",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.39_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.39_1",
      NAME_2: "Tofa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.40_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.40_1",
      NAME_2: "Tsanyawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.41_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.41_1",
      NAME_2: "Tundun Wada",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.42_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.42_1",
      NAME_2: "Ungogo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.43_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.43_1",
      NAME_2: "Warawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.20.44_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.20_1",
      NAME_1: "Kano",
      NL_NAME_1: "None",
      GID_2: "NGA.20.44_1",
      NAME_2: "Wudil",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.1_1",
      NAME_2: "Bakori",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.2_1",
      NAME_2: "Batagarawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.3_1",
      NAME_2: "Batsari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.4_1",
      NAME_2: "Baure",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.5_1",
      NAME_2: "Bindawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.6_1",
      NAME_2: "Charanchi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.7_1",
      NAME_2: "Dandume",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.8_1",
      NAME_2: "Danja",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.9_1",
      NAME_2: "Danmusa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.10_1",
      NAME_2: "Daura",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.11_1",
      NAME_2: "Dutsi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.12_1",
      NAME_2: "Dutsin-M",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.13_1",
      NAME_2: "Faskari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.14_1",
      NAME_2: "Funtua",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.15_1",
      NAME_2: "Ingawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.16_1",
      NAME_2: "Jibia",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.17_1",
      NAME_2: "Kafur",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.18_1",
      NAME_2: "Kaita",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.19_1",
      NAME_2: "Kankara",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.20_1",
      NAME_2: "Kankiya",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.21_1",
      NAME_2: "Katsina (K)",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.22_1",
      NAME_2: "Kurfi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.23_1",
      NAME_2: "Kusada",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.24_1",
      NAME_2: "Mai'Adua",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.25_1",
      NAME_2: "Malumfashi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.26_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.26_1",
      NAME_2: "Mani",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.27_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.27_1",
      NAME_2: "Mashi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.28_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.28_1",
      NAME_2: "Matazu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.29_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.29_1",
      NAME_2: "Musawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.30_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.30_1",
      NAME_2: "Rimi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.31_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.31_1",
      NAME_2: "Sabuwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.32_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.32_1",
      NAME_2: "Safana",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.33_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.33_1",
      NAME_2: "Sandamu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.21.34_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.21_1",
      NAME_1: "Katsina",
      NL_NAME_1: "None",
      GID_2: "NGA.21.34_1",
      NAME_2: "Zango",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.1_1",
      NAME_2: "Aleiro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.2_1",
      NAME_2: "Arewa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.3_1",
      NAME_2: "Argungu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.4_1",
      NAME_2: "Augie",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.5_1",
      NAME_2: "Bagudo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.6_1",
      NAME_2: "BirninKe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.7_1",
      NAME_2: "Bunza",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.8_1",
      NAME_2: "Dandi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.9_1",
      NAME_2: "Danko Wasagu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.10_1",
      NAME_2: "Fakai",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.11_1",
      NAME_2: "Gwandu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.12_1",
      NAME_2: "Jega",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.13_1",
      NAME_2: "Kalgo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.14_1",
      NAME_2: "Koko/Bes",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.15_1",
      NAME_2: "Maiyama",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.16_1",
      NAME_2: "Ngaski",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.17_1",
      NAME_2: "Sakaba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.18_1",
      NAME_2: "Shanga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.19_1",
      NAME_2: "Suru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.20_1",
      NAME_2: "Yauri",
      isoCode: "NG",
    },
    {
      region_id: "NGA.22.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.22_1",
      NAME_1: "Kebbi",
      NL_NAME_1: "None",
      GID_2: "NGA.22.21_1",
      NAME_2: "Zuru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.1_1",
      NAME_2: "Adavi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.2_1",
      NAME_2: "Ajaokuta",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.3_1",
      NAME_2: "Ankpa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.4_1",
      NAME_2: "Bassa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.5_1",
      NAME_2: "Dekina",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.6_1",
      NAME_2: "Ibaji",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.7_1",
      NAME_2: "Idah",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.8_1",
      NAME_2: "Igalamela-Odolu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.9_1",
      NAME_2: "Ijumu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.10_1",
      NAME_2: "Kabba/Bu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.11_1",
      NAME_2: "Kotonkar",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.12_1",
      NAME_2: "Lokoja",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.13_1",
      NAME_2: "Mopa-Muro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.14_1",
      NAME_2: "Ofu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.15_1",
      NAME_2: "Ogori/Magongo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.16_1",
      NAME_2: "Okehi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.17_1",
      NAME_2: "Okene",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.18_1",
      NAME_2: "Olamabor",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.19_1",
      NAME_2: "Omala",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.20_1",
      NAME_2: "Yagba East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.23.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.23_1",
      NAME_1: "Kogi",
      NL_NAME_1: "None",
      GID_2: "NGA.23.21_1",
      NAME_2: "Yagba West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.1_1",
      NAME_2: "Asa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.2_1",
      NAME_2: "Baruten",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.3_1",
      NAME_2: "Edu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.4_1",
      NAME_2: "Ekiti",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.5_1",
      NAME_2: "Ifelodun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.6_1",
      NAME_2: "Ilorin East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.7_1",
      NAME_2: "Ilorin South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.8_1",
      NAME_2: "IlorinWe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.9_1",
      NAME_2: "Irepodun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.10_1",
      NAME_2: "Isin",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.11_1",
      NAME_2: "Kaiama",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.12_1",
      NAME_2: "Moro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.13_1",
      NAME_2: "Offa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.14_1",
      NAME_2: "Oke-Ero",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.15_1",
      NAME_2: "Oyun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.24.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.24_1",
      NAME_1: "Kwara",
      NL_NAME_1: "None",
      GID_2: "NGA.24.16_1",
      NAME_2: "Pategi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.1_1",
      NAME_2: "Agege",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.2_1",
      NAME_2: "Ajeromi/Ifelodun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.3_1",
      NAME_2: "Alimosho",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.4_1",
      NAME_2: "Amuwo Odofin",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.5_1",
      NAME_2: "Apapa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.6_1",
      NAME_2: "Badagary",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.7_1",
      NAME_2: "Epe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.8_1",
      NAME_2: "Eti-Osa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.9_1",
      NAME_2: "Ibeju/Lekki",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.10_1",
      NAME_2: "Ifako/Ijaye",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.11_1",
      NAME_2: "Ikeja",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.12_1",
      NAME_2: "Ikorodu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.13_1",
      NAME_2: "Kosofe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.14_1",
      NAME_2: "LagosIsland",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.15_1",
      NAME_2: "Mainland",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.16_1",
      NAME_2: "Mushin",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.17_1",
      NAME_2: "Ojo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.18_1",
      NAME_2: "Oshodi/Isolo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.19_1",
      NAME_2: "Shomolu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.25.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.25_1",
      NAME_1: "Lagos",
      NL_NAME_1: "None",
      GID_2: "NGA.25.20_1",
      NAME_2: "Surulere",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.1_1",
      NAME_2: "Akwanga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.2_1",
      NAME_2: "Awe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.3_1",
      NAME_2: "Doma",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.4_1",
      NAME_2: "Karu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.5_1",
      NAME_2: "Keana",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.6_1",
      NAME_2: "Keffi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.7_1",
      NAME_2: "Kokona",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.8_1",
      NAME_2: "Lafia",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.9_1",
      NAME_2: "Nasarawa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.10_1",
      NAME_2: "Nassarawa Egon",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.11_1",
      NAME_2: "Obi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.12_1",
      NAME_2: "Toto",
      isoCode: "NG",
    },
    {
      region_id: "NGA.26.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.26_1",
      NAME_1: "Nassarawa",
      NL_NAME_1: "None",
      GID_2: "NGA.26.13_1",
      NAME_2: "Wamba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.1_1",
      NAME_2: "Agaie",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.2_1",
      NAME_2: "Agwara",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.3_1",
      NAME_2: "Bida",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.4_1",
      NAME_2: "Borgu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.5_1",
      NAME_2: "Bosso",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.6_1",
      NAME_2: "Chanchaga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.7_1",
      NAME_2: "Edati",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.8_1",
      NAME_2: "Gbako",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.9_1",
      NAME_2: "Gurara",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.10_1",
      NAME_2: "Katcha",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.11_1",
      NAME_2: "Kontogur",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.12_1",
      NAME_2: "Lapai",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.13_1",
      NAME_2: "Lavun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.14_1",
      NAME_2: "Magama",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.15_1",
      NAME_2: "Mariga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.16_1",
      NAME_2: "Mashegu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.17_1",
      NAME_2: "Mokwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.18_1",
      NAME_2: "Muya",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.19_1",
      NAME_2: "Paikoro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.20_1",
      NAME_2: "Rafi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.21_1",
      NAME_2: "Rijau",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.22_1",
      NAME_2: "Shiroro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.23_1",
      NAME_2: "Suleja",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.24_1",
      NAME_2: "Tafa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.27.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.27_1",
      NAME_1: "Niger",
      NL_NAME_1: "None",
      GID_2: "NGA.27.25_1",
      NAME_2: "Wushishi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.1_1",
      NAME_2: "Abeokuta South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.2_1",
      NAME_2: "AbeokutaNorth",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.3_1",
      NAME_2: "AdoOdo/Ota",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.4_1",
      NAME_2: "EgbadoNorth",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.5_1",
      NAME_2: "EgbadoSouth",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.6_1",
      NAME_2: "Ewekoro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.7_1",
      NAME_2: "Ifo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.8_1",
      NAME_2: "Ijebu North-East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.9_1",
      NAME_2: "IjebuEast",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.10_1",
      NAME_2: "IjebuNorth",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.11_1",
      NAME_2: "IjebuOde",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.12_1",
      NAME_2: "Ikenne",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.13_1",
      NAME_2: "Imeko-Afon",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.14_1",
      NAME_2: "Ipokia",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.15_1",
      NAME_2: "Obafemi-Owode",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.16_1",
      NAME_2: "Odeda",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.17_1",
      NAME_2: "Odogbolu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.18_1",
      NAME_2: "OgunWaterside",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.19_1",
      NAME_2: "Remo-North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.28.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.28_1",
      NAME_1: "Ogun",
      NL_NAME_1: "None",
      GID_2: "NGA.28.20_1",
      NAME_2: "Shagamu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.1_1",
      NAME_2: "Akoko North-East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.2_1",
      NAME_2: "Akoko South-East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.3_1",
      NAME_2: "Akoko South-West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.4_1",
      NAME_2: "AkokoNorthWest",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.5_1",
      NAME_2: "Akure North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.6_1",
      NAME_2: "Akure South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.7_1",
      NAME_2: "Ese-Odo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.8_1",
      NAME_2: "Idanre",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.9_1",
      NAME_2: "Ifedore",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.10_1",
      NAME_2: "IlajeEseodo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.11_1",
      NAME_2: "IleOluji/Okeigbo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.12_1",
      NAME_2: "Irele",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.13_1",
      NAME_2: "Odigbo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.14_1",
      NAME_2: "Okitipupa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.15_1",
      NAME_2: "Ondo East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.16_1",
      NAME_2: "Ondo West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.17_1",
      NAME_2: "Ose",
      isoCode: "NG",
    },
    {
      region_id: "NGA.29.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.29_1",
      NAME_1: "Ondo",
      NL_NAME_1: "None",
      GID_2: "NGA.29.18_1",
      NAME_2: "Owo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.1_1",
      NAME_2: "Atakumosa East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.2_1",
      NAME_2: "Atakumosa West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.3_1",
      NAME_2: "Ayedaade",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.4_1",
      NAME_2: "Ayedire",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.5_1",
      NAME_2: "Boluwaduro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.6_1",
      NAME_2: "Boripe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.7_1",
      NAME_2: "Ede North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.8_1",
      NAME_2: "Ede South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.9_1",
      NAME_2: "Egbedore",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.10_1",
      NAME_2: "Ejigbo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.11_1",
      NAME_2: "Ife East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.12_1",
      NAME_2: "Ife North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.13_1",
      NAME_2: "Ife South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.14_1",
      NAME_2: "IfeCentral",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.15_1",
      NAME_2: "Ifedayo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.16_1",
      NAME_2: "Ifelodun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.17_1",
      NAME_2: "Ila",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.18_1",
      NAME_2: "Ilesha East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.19_1",
      NAME_2: "Ilesha West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.20_1",
      NAME_2: "Irepodun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.21_1",
      NAME_2: "Irewole",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.22_1",
      NAME_2: "Isokan",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.23_1",
      NAME_2: "Iwo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.24_1",
      NAME_2: "Obokun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.25_1",
      NAME_2: "Odo0tin",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.26_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.26_1",
      NAME_2: "Ola-Oluwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.27_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.27_1",
      NAME_2: "Olorunda",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.28_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.28_1",
      NAME_2: "Oriade",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.29_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.29_1",
      NAME_2: "Orolu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.30.30_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.30_1",
      NAME_1: "Osun",
      NL_NAME_1: "None",
      GID_2: "NGA.30.30_1",
      NAME_2: "Osogbo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.1_1",
      NAME_2: "Afijio",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.2_1",
      NAME_2: "Akinyele",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.3_1",
      NAME_2: "Atiba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.4_1",
      NAME_2: "Atisbo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.5_1",
      NAME_2: "Egbeda",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.6_1",
      NAME_2: "IbadanNorth",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.7_1",
      NAME_2: "IbadanNorth-East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.8_1",
      NAME_2: "IbadanNorth-West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.9_1",
      NAME_2: "IbadanSouth-East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.10_1",
      NAME_2: "IbadanSouth-West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.11_1",
      NAME_2: "Ibarapa Central",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.12_1",
      NAME_2: "Ibarapa East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.13_1",
      NAME_2: "Ibarapa North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.14_1",
      NAME_2: "Ido",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.15_1",
      NAME_2: "Irepo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.16_1",
      NAME_2: "Iseyin",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.17_1",
      NAME_2: "Itesiwaju",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.18_1",
      NAME_2: "Iwajowa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.19_1",
      NAME_2: "Kajola",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.20_1",
      NAME_2: "Lagelu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.21_1",
      NAME_2: "Ogbomosho North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.22_1",
      NAME_2: "Ogbomosho South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.23_1",
      NAME_2: "Ogo-Oluw",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.24_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.24_1",
      NAME_2: "Olorunsogo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.25_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.25_1",
      NAME_2: "Oluyole",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.26_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.26_1",
      NAME_2: "Ona-Ara",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.27_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.27_1",
      NAME_2: "Orelope",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.28_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.28_1",
      NAME_2: "Ori-Ire",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.29_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.29_1",
      NAME_2: "Oyo East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.30_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.30_1",
      NAME_2: "Oyo West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.31_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.31_1",
      NAME_2: "Saki East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.32_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.32_1",
      NAME_2: "Saki West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.31.33_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.31_1",
      NAME_1: "Oyo",
      NL_NAME_1: "None",
      GID_2: "NGA.31.33_1",
      NAME_2: "Surulere",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.1_1",
      NAME_2: "Barkin Ladi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.2_1",
      NAME_2: "Bassa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.3_1",
      NAME_2: "Bokkos",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.4_1",
      NAME_2: "Jos East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.5_1",
      NAME_2: "Jos North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.6_1",
      NAME_2: "Jos South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.7_1",
      NAME_2: "Kanam",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.8_1",
      NAME_2: "Kanke",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.9_1",
      NAME_2: "Langtang North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.10_1",
      NAME_2: "Langtang South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.11_1",
      NAME_2: "Mangu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.12_1",
      NAME_2: "Mikang",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.13_1",
      NAME_2: "Pankshin",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.14_1",
      NAME_2: "Qua'anpa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.15_1",
      NAME_2: "Riyom",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.16_1",
      NAME_2: "Shendam",
      isoCode: "NG",
    },
    {
      region_id: "NGA.32.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.32_1",
      NAME_1: "Plateau",
      NL_NAME_1: "None",
      GID_2: "NGA.32.17_1",
      NAME_2: "Wase",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.1_1",
      NAME_2: "Abua/Odu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.2_1",
      NAME_2: "Ahoada East",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.3_1",
      NAME_2: "Ahoada West",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.4_1",
      NAME_2: "Akukutor",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.5_1",
      NAME_2: "Andoni/O",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.6_1",
      NAME_2: "Asari-To",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.7_1",
      NAME_2: "Bonny",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.8_1",
      NAME_2: "Degema",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.9_1",
      NAME_2: "Eleme",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.10_1",
      NAME_2: "Emuoha",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.11_1",
      NAME_2: "Etche",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.12_1",
      NAME_2: "Gokana",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.13_1",
      NAME_2: "Ikwerre",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.14_1",
      NAME_2: "Khana",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.15_1",
      NAME_2: "Obio/Akp",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.16_1",
      NAME_2: "Ogba/Egbe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.17_1",
      NAME_2: "Ogu/Bolo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.18_1",
      NAME_2: "Okrika",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.19_1",
      NAME_2: "Omumma",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.20_1",
      NAME_2: "Opobo/Nkoro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.21_1",
      NAME_2: "Oyigbo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.22_1",
      NAME_2: "Port Harcourt",
      isoCode: "NG",
    },
    {
      region_id: "NGA.33.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.33_1",
      NAME_1: "Rivers",
      NL_NAME_1: "None",
      GID_2: "NGA.33.23_1",
      NAME_2: "Tai",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.1_1",
      NAME_2: "Binji",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.2_1",
      NAME_2: "Bodinga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.3_1",
      NAME_2: "Dange-Shuni",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.4_1",
      NAME_2: "Gada",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.5_1",
      NAME_2: "Goronyo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.6_1",
      NAME_2: "Gudu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.7_1",
      NAME_2: "Gwadabaw",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.8_1",
      NAME_2: "Illela",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.9_1",
      NAME_2: "Isa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.10_1",
      NAME_2: "Kebbe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.11_1",
      NAME_2: "Kware",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.12_1",
      NAME_2: "Rabah",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.13_1",
      NAME_2: "Sabon Birni",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.14_1",
      NAME_2: "Shagari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.15_1",
      NAME_2: "Silame",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.16_1",
      NAME_2: "Sokoto North",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.17_1",
      NAME_2: "Sokoto South",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.18_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.18_1",
      NAME_2: "Tambawal",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.19_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.19_1",
      NAME_2: "Tangazar",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.20_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.20_1",
      NAME_2: "Tureta",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.21_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.21_1",
      NAME_2: "Wamakko",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.22_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.22_1",
      NAME_2: "Wurno",
      isoCode: "NG",
    },
    {
      region_id: "NGA.34.23_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.34_1",
      NAME_1: "Sokoto",
      NL_NAME_1: "None",
      GID_2: "NGA.34.23_1",
      NAME_2: "Yabo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.1_1",
      NAME_2: "Ardo-Kola",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.2_1",
      NAME_2: "Bali",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.3_1",
      NAME_2: "Donga",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.4_1",
      NAME_2: "Gashaka",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.5_1",
      NAME_2: "Gassol",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.6_1",
      NAME_2: "Ibi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.7_1",
      NAME_2: "Jalingo",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.8_1",
      NAME_2: "Karim-La",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.9_1",
      NAME_2: "Kurmi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.10_1",
      NAME_2: "Lau",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.11_1",
      NAME_2: "Sardauna",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.12_1",
      NAME_2: "Takum",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.13_1",
      NAME_2: "Ussa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.14_1",
      NAME_2: "Wukari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.15_1",
      NAME_2: "Yorro",
      isoCode: "NG",
    },
    {
      region_id: "NGA.35.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.35_1",
      NAME_1: "Taraba",
      NL_NAME_1: "None",
      GID_2: "NGA.35.16_1",
      NAME_2: "Zing",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.1_1",
      NAME_2: "Bade",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.2_1",
      NAME_2: "Borsari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.3_1",
      NAME_2: "Damaturu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.4_1",
      NAME_2: "Fika",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.5_1",
      NAME_2: "Fune",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.6_1",
      NAME_2: "Geidam",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.7_1",
      NAME_2: "Gujba",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.8_1",
      NAME_2: "Gulani",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.9_1",
      NAME_2: "Jakusko",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.10_1",
      NAME_2: "Karasuwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.11_1",
      NAME_2: "Machina",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.12_1",
      NAME_2: "Nangere",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.13_1",
      NAME_2: "Nguru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.14_1",
      NAME_2: "Potiskum",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.15_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.15_1",
      NAME_2: "Tarmuwa",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.16_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.16_1",
      NAME_2: "Yunusari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.36.17_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.36_1",
      NAME_1: "Yobe",
      NL_NAME_1: "None",
      GID_2: "NGA.36.17_1",
      NAME_2: "Yusufari",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.1_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.1_1",
      NAME_2: "Anka",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.2_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.2_1",
      NAME_2: "Bakura",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.3_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.3_1",
      NAME_2: "Birnin-Magaji/Kiyaw",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.4_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.4_1",
      NAME_2: "Bukkuyum",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.5_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.5_1",
      NAME_2: "Bungudu",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.6_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.6_1",
      NAME_2: "Gummi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.7_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.7_1",
      NAME_2: "Gusau",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.8_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.8_1",
      NAME_2: "Kaura-Na",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.9_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.9_1",
      NAME_2: "Maradun",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.10_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.10_1",
      NAME_2: "Maru",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.11_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.11_1",
      NAME_2: "Shinkafi",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.12_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.12_1",
      NAME_2: "Talata-Mafara",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.13_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.13_1",
      NAME_2: "Tsafe",
      isoCode: "NG",
    },
    {
      region_id: "NGA.37.14_1",
      GID_0: "NGA",
      NAME_0: "Nigeria",
      GID_1: "NGA.37_1",
      NAME_1: "Zamfara",
      NL_NAME_1: "None",
      GID_2: "NGA.37.14_1",
      NAME_2: "Zurmi",
      isoCode: "NG",
    },
    {
      region_id: "SEN.1.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.1_1",
      NAME_1: "Dakar",
      NL_NAME_1: "None",
      GID_2: "SEN.1.1_1",
      NAME_2: "Dakar",
      isoCode: "SN",
    },
    {
      region_id: "SEN.1.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.1_1",
      NAME_1: "Dakar",
      NL_NAME_1: "None",
      GID_2: "SEN.1.2_1",
      NAME_2: "Guédiawaye",
      isoCode: "SN",
    },
    {
      region_id: "SEN.1.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.1_1",
      NAME_1: "Dakar",
      NL_NAME_1: "None",
      GID_2: "SEN.1.3_1",
      NAME_2: "Pikine",
      isoCode: "SN",
    },
    {
      region_id: "SEN.1.4_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.1_1",
      NAME_1: "Dakar",
      NL_NAME_1: "None",
      GID_2: "SEN.1.4_1",
      NAME_2: "Rufisque",
      isoCode: "SN",
    },
    {
      region_id: "SEN.2.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.2_1",
      NAME_1: "Diourbel",
      NL_NAME_1: "None",
      GID_2: "SEN.2.1_1",
      NAME_2: "Bambey",
      isoCode: "SN",
    },
    {
      region_id: "SEN.2.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.2_1",
      NAME_1: "Diourbel",
      NL_NAME_1: "None",
      GID_2: "SEN.2.2_1",
      NAME_2: "Diourbel",
      isoCode: "SN",
    },
    {
      region_id: "SEN.2.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.2_1",
      NAME_1: "Diourbel",
      NL_NAME_1: "None",
      GID_2: "SEN.2.3_1",
      NAME_2: "Mbacké",
      isoCode: "SN",
    },
    {
      region_id: "SEN.3.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.3_1",
      NAME_1: "Fatick",
      NL_NAME_1: "None",
      GID_2: "SEN.3.1_1",
      NAME_2: "Fatick",
      isoCode: "SN",
    },
    {
      region_id: "SEN.3.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.3_1",
      NAME_1: "Fatick",
      NL_NAME_1: "None",
      GID_2: "SEN.3.2_1",
      NAME_2: "Foundiougne",
      isoCode: "SN",
    },
    {
      region_id: "SEN.3.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.3_1",
      NAME_1: "Fatick",
      NL_NAME_1: "None",
      GID_2: "SEN.3.3_1",
      NAME_2: "Gossas",
      isoCode: "SN",
    },
    {
      region_id: "SEN.4.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.4_1",
      NAME_1: "Kaffrine",
      NL_NAME_1: "None",
      GID_2: "SEN.4.1_1",
      NAME_2: "Birkilane",
      isoCode: "SN",
    },
    {
      region_id: "SEN.4.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.4_1",
      NAME_1: "Kaffrine",
      NL_NAME_1: "None",
      GID_2: "SEN.4.2_1",
      NAME_2: "Kaffrine",
      isoCode: "SN",
    },
    {
      region_id: "SEN.4.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.4_1",
      NAME_1: "Kaffrine",
      NL_NAME_1: "None",
      GID_2: "SEN.4.3_1",
      NAME_2: "Koungheul",
      isoCode: "SN",
    },
    {
      region_id: "SEN.4.4_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.4_1",
      NAME_1: "Kaffrine",
      NL_NAME_1: "None",
      GID_2: "SEN.4.4_1",
      NAME_2: "Malème Hodar",
      isoCode: "SN",
    },
    {
      region_id: "SEN.5.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.5_1",
      NAME_1: "Kaolack",
      NL_NAME_1: "None",
      GID_2: "SEN.5.1_1",
      NAME_2: "Guinguinéo",
      isoCode: "SN",
    },
    {
      region_id: "SEN.5.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.5_1",
      NAME_1: "Kaolack",
      NL_NAME_1: "None",
      GID_2: "SEN.5.2_1",
      NAME_2: "Kaolack",
      isoCode: "SN",
    },
    {
      region_id: "SEN.5.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.5_1",
      NAME_1: "Kaolack",
      NL_NAME_1: "None",
      GID_2: "SEN.5.3_1",
      NAME_2: "Nioro du Rip",
      isoCode: "SN",
    },
    {
      region_id: "SEN.6.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.6_1",
      NAME_1: "Kédougou",
      NL_NAME_1: "None",
      GID_2: "SEN.6.1_1",
      NAME_2: "Kédougou",
      isoCode: "SN",
    },
    {
      region_id: "SEN.6.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.6_1",
      NAME_1: "Kédougou",
      NL_NAME_1: "None",
      GID_2: "SEN.6.2_1",
      NAME_2: "Salémata",
      isoCode: "SN",
    },
    {
      region_id: "SEN.6.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.6_1",
      NAME_1: "Kédougou",
      NL_NAME_1: "None",
      GID_2: "SEN.6.3_1",
      NAME_2: "Saraya",
      isoCode: "SN",
    },
    {
      region_id: "SEN.7.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.7_1",
      NAME_1: "Kolda",
      NL_NAME_1: "None",
      GID_2: "SEN.7.1_1",
      NAME_2: "Kolda",
      isoCode: "SN",
    },
    {
      region_id: "SEN.7.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.7_1",
      NAME_1: "Kolda",
      NL_NAME_1: "None",
      GID_2: "SEN.7.2_1",
      NAME_2: "Médina Yoro Foula",
      isoCode: "SN",
    },
    {
      region_id: "SEN.7.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.7_1",
      NAME_1: "Kolda",
      NL_NAME_1: "None",
      GID_2: "SEN.7.3_1",
      NAME_2: "Vélingara",
      isoCode: "SN",
    },
    {
      region_id: "SEN.8.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.8_1",
      NAME_1: "Louga",
      NL_NAME_1: "None",
      GID_2: "SEN.8.1_1",
      NAME_2: "Kébémer",
      isoCode: "SN",
    },
    {
      region_id: "SEN.8.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.8_1",
      NAME_1: "Louga",
      NL_NAME_1: "None",
      GID_2: "SEN.8.2_1",
      NAME_2: "Linguère",
      isoCode: "SN",
    },
    {
      region_id: "SEN.8.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.8_1",
      NAME_1: "Louga",
      NL_NAME_1: "None",
      GID_2: "SEN.8.3_1",
      NAME_2: "Louga",
      isoCode: "SN",
    },
    {
      region_id: "SEN.9.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.9_1",
      NAME_1: "Matam",
      NL_NAME_1: "None",
      GID_2: "SEN.9.1_1",
      NAME_2: "Kanel",
      isoCode: "SN",
    },
    {
      region_id: "SEN.9.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.9_1",
      NAME_1: "Matam",
      NL_NAME_1: "None",
      GID_2: "SEN.9.2_1",
      NAME_2: "Matam",
      isoCode: "SN",
    },
    {
      region_id: "SEN.9.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.9_1",
      NAME_1: "Matam",
      NL_NAME_1: "None",
      GID_2: "SEN.9.3_1",
      NAME_2: "Ranérou Ferlo",
      isoCode: "SN",
    },
    {
      region_id: "SEN.10.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.10_1",
      NAME_1: "Saint-Louis",
      NL_NAME_1: "None",
      GID_2: "SEN.10.1_1",
      NAME_2: "Dagana",
      isoCode: "SN",
    },
    {
      region_id: "SEN.10.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.10_1",
      NAME_1: "Saint-Louis",
      NL_NAME_1: "None",
      GID_2: "SEN.10.2_1",
      NAME_2: "Podor",
      isoCode: "SN",
    },
    {
      region_id: "SEN.10.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.10_1",
      NAME_1: "Saint-Louis",
      NL_NAME_1: "None",
      GID_2: "SEN.10.3_1",
      NAME_2: "Saint-Louis",
      isoCode: "SN",
    },
    {
      region_id: "SEN.11.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.11_1",
      NAME_1: "Sédhiou",
      NL_NAME_1: "None",
      GID_2: "SEN.11.1_1",
      NAME_2: "Bounkiling",
      isoCode: "SN",
    },
    {
      region_id: "SEN.11.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.11_1",
      NAME_1: "Sédhiou",
      NL_NAME_1: "None",
      GID_2: "SEN.11.2_1",
      NAME_2: "Goudomp",
      isoCode: "SN",
    },
    {
      region_id: "SEN.11.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.11_1",
      NAME_1: "Sédhiou",
      NL_NAME_1: "None",
      GID_2: "SEN.11.3_1",
      NAME_2: "Sédhiou",
      isoCode: "SN",
    },
    {
      region_id: "SEN.12.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.12_1",
      NAME_1: "Tambacounda",
      NL_NAME_1: "None",
      GID_2: "SEN.12.1_1",
      NAME_2: "Bakel",
      isoCode: "SN",
    },
    {
      region_id: "SEN.12.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.12_1",
      NAME_1: "Tambacounda",
      NL_NAME_1: "None",
      GID_2: "SEN.12.2_1",
      NAME_2: "Goudiry",
      isoCode: "SN",
    },
    {
      region_id: "SEN.12.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.12_1",
      NAME_1: "Tambacounda",
      NL_NAME_1: "None",
      GID_2: "SEN.12.3_1",
      NAME_2: "Koupentoum",
      isoCode: "SN",
    },
    {
      region_id: "SEN.12.4_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.12_1",
      NAME_1: "Tambacounda",
      NL_NAME_1: "None",
      GID_2: "SEN.12.4_1",
      NAME_2: "Tambacounda",
      isoCode: "SN",
    },
    {
      region_id: "SEN.13.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.13_1",
      NAME_1: "Thiès",
      NL_NAME_1: "None",
      GID_2: "SEN.13.1_1",
      NAME_2: "Mbour",
      isoCode: "SN",
    },
    {
      region_id: "SEN.13.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.13_1",
      NAME_1: "Thiès",
      NL_NAME_1: "None",
      GID_2: "SEN.13.2_1",
      NAME_2: "Thiès",
      isoCode: "SN",
    },
    {
      region_id: "SEN.13.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.13_1",
      NAME_1: "Thiès",
      NL_NAME_1: "None",
      GID_2: "SEN.13.3_1",
      NAME_2: "Tivaouane",
      isoCode: "SN",
    },
    {
      region_id: "SEN.14.1_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.14_1",
      NAME_1: "Ziguinchor",
      NL_NAME_1: "None",
      GID_2: "SEN.14.1_1",
      NAME_2: "Bignona",
      isoCode: "SN",
    },
    {
      region_id: "SEN.14.2_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.14_1",
      NAME_1: "Ziguinchor",
      NL_NAME_1: "None",
      GID_2: "SEN.14.2_1",
      NAME_2: "Oussouye",
      isoCode: "SN",
    },
    {
      region_id: "SEN.14.3_1",
      GID_0: "SEN",
      NAME_0: "Senegal",
      GID_1: "SEN.14_1",
      NAME_1: "Ziguinchor",
      NL_NAME_1: "None",
      GID_2: "SEN.14.3_1",
      NAME_2: "Ziguinchor",
      isoCode: "SN",
    },
    {
      region_id: "SLE.1.1_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.1_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "SLE.1.1_1",
      NAME_2: "Kailahun",
      isoCode: "SL",
    },
    {
      region_id: "SLE.1.2_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.1_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "SLE.1.2_1",
      NAME_2: "Kenema",
      isoCode: "SL",
    },
    {
      region_id: "SLE.1.3_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.1_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "SLE.1.3_1",
      NAME_2: "Kono",
      isoCode: "SL",
    },
    {
      region_id: "SLE.2.1_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.2_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "SLE.2.1_1",
      NAME_2: "Bombali",
      isoCode: "SL",
    },
    {
      region_id: "SLE.2.2_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.2_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "SLE.2.2_1",
      NAME_2: "Kambia",
      isoCode: "SL",
    },
    {
      region_id: "SLE.2.3_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.2_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "SLE.2.3_1",
      NAME_2: "Koinadugu",
      isoCode: "SL",
    },
    {
      region_id: "SLE.2.4_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.2_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "SLE.2.4_1",
      NAME_2: "Port Loko",
      isoCode: "SL",
    },
    {
      region_id: "SLE.2.5_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.2_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "SLE.2.5_1",
      NAME_2: "Tonkolili",
      isoCode: "SL",
    },
    {
      region_id: "SLE.3.1_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.3_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "SLE.3.1_1",
      NAME_2: "Bo",
      isoCode: "SL",
    },
    {
      region_id: "SLE.3.2_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.3_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "SLE.3.2_1",
      NAME_2: "Bonthe",
      isoCode: "SL",
    },
    {
      region_id: "SLE.3.3_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.3_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "SLE.3.3_1",
      NAME_2: "Moyamba",
      isoCode: "SL",
    },
    {
      region_id: "SLE.3.4_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.3_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "SLE.3.4_1",
      NAME_2: "Pujehun",
      isoCode: "SL",
    },
    {
      region_id: "SLE.4.1_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.4_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "SLE.4.1_1",
      NAME_2: "Western Rural",
      isoCode: "SL",
    },
    {
      region_id: "SLE.4.2_1",
      GID_0: "SLE",
      NAME_0: "Sierra Leone",
      GID_1: "SLE.4_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "SLE.4.2_1",
      NAME_2: "Western Urban",
      isoCode: "SL",
    },
    {
      region_id: "TGO.1.1_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.1_1",
      NAME_1: "Centre",
      NL_NAME_1: "None",
      GID_2: "TGO.1.1_1",
      NAME_2: "Sotouboua",
      isoCode: "TG",
    },
    {
      region_id: "TGO.1.2_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.1_1",
      NAME_1: "Centre",
      NL_NAME_1: "None",
      GID_2: "TGO.1.2_1",
      NAME_2: "Tchamba (Nyala)",
      isoCode: "TG",
    },
    {
      region_id: "TGO.1.3_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.1_1",
      NAME_1: "Centre",
      NL_NAME_1: "None",
      GID_2: "TGO.1.3_1",
      NAME_2: "Tchaudjo",
      isoCode: "TG",
    },
    {
      region_id: "TGO.2.1_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.2_1",
      NAME_1: "Kara",
      NL_NAME_1: "None",
      GID_2: "TGO.2.1_1",
      NAME_2: "Assoli",
      isoCode: "TG",
    },
    {
      region_id: "TGO.2.2_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.2_1",
      NAME_1: "Kara",
      NL_NAME_1: "None",
      GID_2: "TGO.2.2_1",
      NAME_2: "Bassar",
      isoCode: "TG",
    },
    {
      region_id: "TGO.2.3_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.2_1",
      NAME_1: "Kara",
      NL_NAME_1: "None",
      GID_2: "TGO.2.3_1",
      NAME_2: "Bimah",
      isoCode: "TG",
    },
    {
      region_id: "TGO.2.4_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.2_1",
      NAME_1: "Kara",
      NL_NAME_1: "None",
      GID_2: "TGO.2.4_1",
      NAME_2: "Doufelgou",
      isoCode: "TG",
    },
    {
      region_id: "TGO.2.5_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.2_1",
      NAME_1: "Kara",
      NL_NAME_1: "None",
      GID_2: "TGO.2.5_1",
      NAME_2: "Kéran",
      isoCode: "TG",
    },
    {
      region_id: "TGO.2.6_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.2_1",
      NAME_1: "Kara",
      NL_NAME_1: "None",
      GID_2: "TGO.2.6_1",
      NAME_2: "Kozah",
      isoCode: "TG",
    },
    {
      region_id: "TGO.3.1_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.3_1",
      NAME_1: "Maritime",
      NL_NAME_1: "None",
      GID_2: "TGO.3.1_1",
      NAME_2: "Golfe (incl Lomé)",
      isoCode: "TG",
    },
    {
      region_id: "TGO.3.2_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.3_1",
      NAME_1: "Maritime",
      NL_NAME_1: "None",
      GID_2: "TGO.3.2_1",
      NAME_2: "Lacs",
      isoCode: "TG",
    },
    {
      region_id: "TGO.3.3_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.3_1",
      NAME_1: "Maritime",
      NL_NAME_1: "None",
      GID_2: "TGO.3.3_1",
      NAME_2: "Vo",
      isoCode: "TG",
    },
    {
      region_id: "TGO.3.4_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.3_1",
      NAME_1: "Maritime",
      NL_NAME_1: "None",
      GID_2: "TGO.3.4_1",
      NAME_2: "Yoto",
      isoCode: "TG",
    },
    {
      region_id: "TGO.3.5_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.3_1",
      NAME_1: "Maritime",
      NL_NAME_1: "None",
      GID_2: "TGO.3.5_1",
      NAME_2: "Zio",
      isoCode: "TG",
    },
    {
      region_id: "TGO.4.1_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.4_1",
      NAME_1: "Plateaux",
      NL_NAME_1: "None",
      GID_2: "TGO.4.1_1",
      NAME_2: "Amou",
      isoCode: "TG",
    },
    {
      region_id: "TGO.4.2_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.4_1",
      NAME_1: "Plateaux",
      NL_NAME_1: "None",
      GID_2: "TGO.4.2_1",
      NAME_2: "Haho",
      isoCode: "TG",
    },
    {
      region_id: "TGO.4.3_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.4_1",
      NAME_1: "Plateaux",
      NL_NAME_1: "None",
      GID_2: "TGO.4.3_1",
      NAME_2: "Kloto",
      isoCode: "TG",
    },
    {
      region_id: "TGO.4.4_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.4_1",
      NAME_1: "Plateaux",
      NL_NAME_1: "None",
      GID_2: "TGO.4.4_1",
      NAME_2: "Ogou",
      isoCode: "TG",
    },
    {
      region_id: "TGO.4.5_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.4_1",
      NAME_1: "Plateaux",
      NL_NAME_1: "None",
      GID_2: "TGO.4.5_1",
      NAME_2: "Wawa",
      isoCode: "TG",
    },
    {
      region_id: "TGO.5.1_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.5_1",
      NAME_1: "Savanes",
      NL_NAME_1: "None",
      GID_2: "TGO.5.1_1",
      NAME_2: "Oti",
      isoCode: "TG",
    },
    {
      region_id: "TGO.5.2_1",
      GID_0: "TGO",
      NAME_0: "Togo",
      GID_1: "TGO.5_1",
      NAME_1: "Savanes",
      NL_NAME_1: "None",
      GID_2: "TGO.5.2_1",
      NAME_2: "Tône",
      isoCode: "TG",
    },
  ],
  sadc: [
    {
      region_id: "AGO.1.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.1_1",
      NAME_1: "Bengo",
      NL_NAME_1: "None",
      GID_2: "AGO.1.1_1",
      NAME_2: "Ambriz",
      isoCode: "AO",
    },
    {
      region_id: "AGO.1.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.1_1",
      NAME_1: "Bengo",
      NL_NAME_1: "None",
      GID_2: "AGO.1.2_1",
      NAME_2: "Dande",
      isoCode: "AO",
    },
    {
      region_id: "AGO.1.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.1_1",
      NAME_1: "Bengo",
      NL_NAME_1: "None",
      GID_2: "AGO.1.3_1",
      NAME_2: "Icolo e Bengo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.1.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.1_1",
      NAME_1: "Bengo",
      NL_NAME_1: "None",
      GID_2: "AGO.1.4_1",
      NAME_2: "Muxima",
      isoCode: "AO",
    },
    {
      region_id: "AGO.1.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.1_1",
      NAME_1: "Bengo",
      NL_NAME_1: "None",
      GID_2: "AGO.1.5_1",
      NAME_2: "Nambuangongo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.1_1",
      NAME_2: "Baía Farta",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.2_1",
      NAME_2: "Balombo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.3_1",
      NAME_2: "Benguela",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.4_1",
      NAME_2: "Bocoio",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.5_1",
      NAME_2: "Caiambambo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.6_1",
      NAME_2: "Chongoroi",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.7_1",
      NAME_2: "Cubal",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.8_1",
      NAME_2: "Ganda",
      isoCode: "AO",
    },
    {
      region_id: "AGO.2.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.2_1",
      NAME_1: "Benguela",
      NL_NAME_1: "None",
      GID_2: "AGO.2.9_1",
      NAME_2: "Lobito",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.1_1",
      NAME_2: "Andulo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.2_1",
      NAME_2: "Camacupa",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.3_1",
      NAME_2: "Catabola",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.4_1",
      NAME_2: "Chinguar",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.5_1",
      NAME_2: "Chitembo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.6_1",
      NAME_2: "Cuemba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.7_1",
      NAME_2: "Cunhinga",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.8_1",
      NAME_2: "Kuito",
      isoCode: "AO",
    },
    {
      region_id: "AGO.3.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.3_1",
      NAME_1: "Bié",
      NL_NAME_1: "None",
      GID_2: "AGO.3.9_1",
      NAME_2: "Nharea",
      isoCode: "AO",
    },
    {
      region_id: "AGO.4.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.4_1",
      NAME_1: "Cabinda",
      NL_NAME_1: "None",
      GID_2: "AGO.4.1_1",
      NAME_2: "Belize",
      isoCode: "AO",
    },
    {
      region_id: "AGO.4.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.4_1",
      NAME_1: "Cabinda",
      NL_NAME_1: "None",
      GID_2: "AGO.4.2_1",
      NAME_2: "Buco Zau",
      isoCode: "AO",
    },
    {
      region_id: "AGO.4.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.4_1",
      NAME_1: "Cabinda",
      NL_NAME_1: "None",
      GID_2: "AGO.4.3_1",
      NAME_2: "Cabinda",
      isoCode: "AO",
    },
    {
      region_id: "AGO.4.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.4_1",
      NAME_1: "Cabinda",
      NL_NAME_1: "None",
      GID_2: "AGO.4.4_1",
      NAME_2: "Landana",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.1_1",
      NAME_2: "Calai",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.2_1",
      NAME_2: "Cuangar",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.3_1",
      NAME_2: "Cuchi",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.4_1",
      NAME_2: "Cuito Cuanavale",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.5_1",
      NAME_2: "Dirico",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.6_1",
      NAME_2: "Mavinga",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.7_1",
      NAME_2: "Menongue",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.8_1",
      NAME_2: "Nancova",
      isoCode: "AO",
    },
    {
      region_id: "AGO.5.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.5_1",
      NAME_1: "Cuando Cubango",
      NL_NAME_1: "None",
      GID_2: "AGO.5.9_1",
      NAME_2: "Rivungo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.1_1",
      NAME_2: "Ambaca",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.2_1",
      NAME_2: "Banga",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.3_1",
      NAME_2: "Bolongongo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.4_1",
      NAME_2: "Bula Atumba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.5_1",
      NAME_2: "Cambambe",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.6_1",
      NAME_2: "Cazengo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.7_1",
      NAME_2: "Dembos",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.8_1",
      NAME_2: "Golungo Alto",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.9_1",
      NAME_2: "Lucala",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.10_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.10_1",
      NAME_2: "Ngonguembo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.11_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.11_1",
      NAME_2: "Pango Aluquém",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.12_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.12_1",
      NAME_2: "Quiculungo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.6.13_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.6_1",
      NAME_1: "Cuanza Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.6.13_1",
      NAME_2: "Samba Cajú",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.1_1",
      NAME_2: "Amboim",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.2_1",
      NAME_2: "Cassongue",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.3_1",
      NAME_2: "Conda",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.4_1",
      NAME_2: "Ebo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.5_1",
      NAME_2: "Libolo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.6_1",
      NAME_2: "Mussende",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.7_1",
      NAME_2: "Porto Amboim",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.8_1",
      NAME_2: "Quibala",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.9_1",
      NAME_2: "Quilenda",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.10_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.10_1",
      NAME_2: "Seles",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.11_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.11_1",
      NAME_2: "Sumbe",
      isoCode: "AO",
    },
    {
      region_id: "AGO.7.12_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.7_1",
      NAME_1: "Cuanza Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.7.12_1",
      NAME_2: "Waku Kungo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.8.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.8_1",
      NAME_1: "Cunene",
      NL_NAME_1: "None",
      GID_2: "AGO.8.1_1",
      NAME_2: "Cahama",
      isoCode: "AO",
    },
    {
      region_id: "AGO.8.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.8_1",
      NAME_1: "Cunene",
      NL_NAME_1: "None",
      GID_2: "AGO.8.2_1",
      NAME_2: "Cuanhama",
      isoCode: "AO",
    },
    {
      region_id: "AGO.8.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.8_1",
      NAME_1: "Cunene",
      NL_NAME_1: "None",
      GID_2: "AGO.8.3_1",
      NAME_2: "Curoca",
      isoCode: "AO",
    },
    {
      region_id: "AGO.8.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.8_1",
      NAME_1: "Cunene",
      NL_NAME_1: "None",
      GID_2: "AGO.8.4_1",
      NAME_2: "Cuvelai",
      isoCode: "AO",
    },
    {
      region_id: "AGO.8.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.8_1",
      NAME_1: "Cunene",
      NL_NAME_1: "None",
      GID_2: "AGO.8.5_1",
      NAME_2: "Namakunde",
      isoCode: "AO",
    },
    {
      region_id: "AGO.8.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.8_1",
      NAME_1: "Cunene",
      NL_NAME_1: "None",
      GID_2: "AGO.8.6_1",
      NAME_2: "Ombadja",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.1_1",
      NAME_2: "Bailundo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.2_1",
      NAME_2: "Caála",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.3_1",
      NAME_2: "Catchiungo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.4_1",
      NAME_2: "Ekunha",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.5_1",
      NAME_2: "Huambo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.6_1",
      NAME_2: "Londuimbale",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.7_1",
      NAME_2: "Longonjo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.8_1",
      NAME_2: "Mungo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.9_1",
      NAME_2: "Tchicala-Tcholoanga",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.10_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.10_1",
      NAME_2: "Tchindjenje",
      isoCode: "AO",
    },
    {
      region_id: "AGO.9.11_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.9_1",
      NAME_1: "Huambo",
      NL_NAME_1: "None",
      GID_2: "AGO.9.11_1",
      NAME_2: "Ukuma",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.1_1",
      NAME_2: "Caconda",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.2_1",
      NAME_2: "Caluquembe",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.3_1",
      NAME_2: "Chibia",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.4_1",
      NAME_2: "Chicomba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.5_1",
      NAME_2: "Chipindo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.6_1",
      NAME_2: "Gambos",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.7_1",
      NAME_2: "Humpata",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.8_1",
      NAME_2: "Jamba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.9_1",
      NAME_2: "Kuvango",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.10_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.10_1",
      NAME_2: "Lubango",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.11_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.11_1",
      NAME_2: "Matala",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.12_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.12_1",
      NAME_2: "Quilengues",
      isoCode: "AO",
    },
    {
      region_id: "AGO.10.13_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.10_1",
      NAME_1: "Huíla",
      NL_NAME_1: "None",
      GID_2: "AGO.10.13_1",
      NAME_2: "Tchipungo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.1_1",
      NAME_2: "Cacuaco",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.2_1",
      NAME_2: "Cazenga",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.3_1",
      NAME_2: "Ingombota",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.4_1",
      NAME_2: "Kilamba Kiaxi",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.5_1",
      NAME_2: "Maianga",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.6_1",
      NAME_2: "Rangel",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.7_1",
      NAME_2: "Samba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.8_1",
      NAME_2: "Sambizanga",
      isoCode: "AO",
    },
    {
      region_id: "AGO.11.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.11_1",
      NAME_1: "Luanda",
      NL_NAME_1: "None",
      GID_2: "AGO.11.9_1",
      NAME_2: "Viana",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.1_1",
      NAME_2: "Cambulo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.2_1",
      NAME_2: "Capenda",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.3_1",
      NAME_2: "Caungula",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.4_1",
      NAME_2: "Chitato",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.5_1",
      NAME_2: "Cuango",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.6_1",
      NAME_2: "Cuilo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.7_1",
      NAME_2: "Lubalo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.8_1",
      NAME_2: "Lucapa",
      isoCode: "AO",
    },
    {
      region_id: "AGO.12.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.12_1",
      NAME_1: "Lunda Norte",
      NL_NAME_1: "None",
      GID_2: "AGO.12.9_1",
      NAME_2: "Xá Muteba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.13.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.13_1",
      NAME_1: "Lunda Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.13.1_1",
      NAME_2: "Cacolo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.13.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.13_1",
      NAME_1: "Lunda Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.13.2_1",
      NAME_2: "Dala",
      isoCode: "AO",
    },
    {
      region_id: "AGO.13.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.13_1",
      NAME_1: "Lunda Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.13.3_1",
      NAME_2: "Muconda",
      isoCode: "AO",
    },
    {
      region_id: "AGO.13.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.13_1",
      NAME_1: "Lunda Sul",
      NL_NAME_1: "None",
      GID_2: "AGO.13.4_1",
      NAME_2: "Saurimo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.1_1",
      NAME_2: "Cacuzo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.2_1",
      NAME_2: "Calandula",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.3_1",
      NAME_2: "Cambundi-Catembo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.4_1",
      NAME_2: "Cangandala",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.5_1",
      NAME_2: "Caombo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.6_1",
      NAME_2: "Cuaba Nzogo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.7_1",
      NAME_2: "Cunda-dia-Baza",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.8_1",
      NAME_2: "Luquembo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.9_1",
      NAME_2: "Malanje",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.10_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.10_1",
      NAME_2: "Marimba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.11_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.11_1",
      NAME_2: "Massango",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.12_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.12_1",
      NAME_2: "Mucari",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.13_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.13_1",
      NAME_2: "Quela",
      isoCode: "AO",
    },
    {
      region_id: "AGO.14.14_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.14_1",
      NAME_1: "Malanje",
      NL_NAME_1: "None",
      GID_2: "AGO.14.14_1",
      NAME_2: "Quirima",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.1_1",
      NAME_2: "Alto Zambeze",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.2_1",
      NAME_2: "Camanongue",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.3_1",
      NAME_2: "Cameia",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.4_1",
      NAME_2: "Léua",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.5_1",
      NAME_2: "Luau",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.6_1",
      NAME_2: "Lucano",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.7_1",
      NAME_2: "Luchazes",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.8_1",
      NAME_2: "Lumbala-Nguimbo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.15.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.15_1",
      NAME_1: "Moxico",
      NL_NAME_1: "None",
      GID_2: "AGO.15.9_1",
      NAME_2: "Moxico",
      isoCode: "AO",
    },
    {
      region_id: "AGO.16.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.16_1",
      NAME_1: "Namibe",
      NL_NAME_1: "None",
      GID_2: "AGO.16.1_1",
      NAME_2: "Bibala",
      isoCode: "AO",
    },
    {
      region_id: "AGO.16.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.16_1",
      NAME_1: "Namibe",
      NL_NAME_1: "None",
      GID_2: "AGO.16.2_1",
      NAME_2: "Camacuio",
      isoCode: "AO",
    },
    {
      region_id: "AGO.16.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.16_1",
      NAME_1: "Namibe",
      NL_NAME_1: "None",
      GID_2: "AGO.16.3_1",
      NAME_2: "Namibe",
      isoCode: "AO",
    },
    {
      region_id: "AGO.16.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.16_1",
      NAME_1: "Namibe",
      NL_NAME_1: "None",
      GID_2: "AGO.16.4_1",
      NAME_2: "Tombwa",
      isoCode: "AO",
    },
    {
      region_id: "AGO.16.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.16_1",
      NAME_1: "Namibe",
      NL_NAME_1: "None",
      GID_2: "AGO.16.5_1",
      NAME_2: "Virei",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.1_1",
      NAME_2: "Alto Cauale",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.2_1",
      NAME_2: "Ambuila",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.3_1",
      NAME_2: "Bembe",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.4_1",
      NAME_2: "Buengas",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.5_1",
      NAME_2: "Bungo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.6_1",
      NAME_2: "Damba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.7_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.7_1",
      NAME_2: "Maquela do Zombo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.8_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.8_1",
      NAME_2: "Milunga",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.9_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.9_1",
      NAME_2: "Mucaba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.10_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.10_1",
      NAME_2: "Negage",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.11_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.11_1",
      NAME_2: "Puri",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.12_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.12_1",
      NAME_2: "Quimbele",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.13_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.13_1",
      NAME_2: "Quitexe",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.14_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.14_1",
      NAME_2: "Sanza Pombo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.15_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.15_1",
      NAME_2: "Songo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.17.16_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.17_1",
      NAME_1: "Uíge",
      NL_NAME_1: "None",
      GID_2: "AGO.17.16_1",
      NAME_2: "Uíge",
      isoCode: "AO",
    },
    {
      region_id: "AGO.18.1_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.18_1",
      NAME_1: "Zaire",
      NL_NAME_1: "None",
      GID_2: "AGO.18.1_1",
      NAME_2: "Cuimba",
      isoCode: "AO",
    },
    {
      region_id: "AGO.18.2_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.18_1",
      NAME_1: "Zaire",
      NL_NAME_1: "None",
      GID_2: "AGO.18.2_1",
      NAME_2: "M'Banza Congo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.18.3_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.18_1",
      NAME_1: "Zaire",
      NL_NAME_1: "None",
      GID_2: "AGO.18.3_1",
      NAME_2: "N'Zeto",
      isoCode: "AO",
    },
    {
      region_id: "AGO.18.4_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.18_1",
      NAME_1: "Zaire",
      NL_NAME_1: "None",
      GID_2: "AGO.18.4_1",
      NAME_2: "Noqui",
      isoCode: "AO",
    },
    {
      region_id: "AGO.18.5_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.18_1",
      NAME_1: "Zaire",
      NL_NAME_1: "None",
      GID_2: "AGO.18.5_1",
      NAME_2: "Soyo",
      isoCode: "AO",
    },
    {
      region_id: "AGO.18.6_1",
      GID_0: "AGO",
      NAME_0: "Angola",
      GID_1: "AGO.18_1",
      NAME_1: "Zaire",
      NL_NAME_1: "None",
      GID_2: "AGO.18.6_1",
      NAME_2: "Tomboco",
      isoCode: "AO",
    },
    {
      region_id: "BWA.1.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "BWA.1.1_1",
      NAME_2: "Bobonong",
      isoCode: "BW",
    },
    {
      region_id: "BWA.1.2_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "BWA.1.2_1",
      NAME_2: "Lethlakane",
      isoCode: "BW",
    },
    {
      region_id: "BWA.1.3_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "BWA.1.3_1",
      NAME_2: "Machaneng",
      isoCode: "BW",
    },
    {
      region_id: "BWA.1.4_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "BWA.1.4_1",
      NAME_2: "Mahalapye",
      isoCode: "BW",
    },
    {
      region_id: "BWA.1.5_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "BWA.1.5_1",
      NAME_2: "Palapye",
      isoCode: "BW",
    },
    {
      region_id: "BWA.1.6_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "BWA.1.6_1",
      NAME_2: "Serowe",
      isoCode: "BW",
    },
    {
      region_id: "BWA.1.7_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "BWA.1.7_1",
      NAME_2: "Tuli",
      isoCode: "BW",
    },
    {
      region_id: "BWA.1.8_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "BWA.1.8_1",
      NAME_2: "Tutume",
      isoCode: "BW",
    },
    {
      region_id: "BWA.2.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.2_1",
      NAME_1: "Chobe",
      NL_NAME_1: "None",
      GID_2: "BWA.2.1_1",
      NAME_2: "Chobe",
      isoCode: "BW",
    },
    {
      region_id: "BWA.3.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.3_1",
      NAME_1: "Francistown",
      NL_NAME_1: "None",
      GID_2: "BWA.3.1_1",
      NAME_2: "Francistown",
      isoCode: "BW",
    },
    {
      region_id: "BWA.4.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.4_1",
      NAME_1: "Gaborone",
      NL_NAME_1: "None",
      GID_2: "BWA.4.1_1",
      NAME_2: "Gaborone",
      isoCode: "BW",
    },
    {
      region_id: "BWA.5.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.5_1",
      NAME_1: "Ghanzi",
      NL_NAME_1: "None",
      GID_2: "BWA.5.1_1",
      NAME_2: "Ghanzi",
      isoCode: "BW",
    },
    {
      region_id: "BWA.6.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.6_1",
      NAME_1: "Jwaneng",
      NL_NAME_1: "None",
      GID_2: "BWA.6.1_1",
      NAME_2: "Jwaneng",
      isoCode: "BW",
    },
    {
      region_id: "BWA.7.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.7_1",
      NAME_1: "Kgalagadi",
      NL_NAME_1: "None",
      GID_2: "BWA.7.1_1",
      NAME_2: "Gemsbok",
      isoCode: "BW",
    },
    {
      region_id: "BWA.7.2_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.7_1",
      NAME_1: "Kgalagadi",
      NL_NAME_1: "None",
      GID_2: "BWA.7.2_1",
      NAME_2: "Hukunsti",
      isoCode: "BW",
    },
    {
      region_id: "BWA.7.3_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.7_1",
      NAME_1: "Kgalagadi",
      NL_NAME_1: "None",
      GID_2: "BWA.7.3_1",
      NAME_2: "Tshabong",
      isoCode: "BW",
    },
    {
      region_id: "BWA.8.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.8_1",
      NAME_1: "Kgatleng",
      NL_NAME_1: "None",
      GID_2: "BWA.8.1_1",
      NAME_2: "Kgatleng",
      isoCode: "BW",
    },
    {
      region_id: "BWA.9.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.9_1",
      NAME_1: "Kweneng",
      NL_NAME_1: "None",
      GID_2: "BWA.9.1_1",
      NAME_2: "Kweneng North",
      isoCode: "BW",
    },
    {
      region_id: "BWA.9.2_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.9_1",
      NAME_1: "Kweneng",
      NL_NAME_1: "None",
      GID_2: "BWA.9.2_1",
      NAME_2: "Kweneng South",
      isoCode: "BW",
    },
    {
      region_id: "BWA.10.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.10_1",
      NAME_1: "Lobatse",
      NL_NAME_1: "None",
      GID_2: "BWA.10.1_1",
      NAME_2: "Lobatse",
      isoCode: "BW",
    },
    {
      region_id: "BWA.11.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.11_1",
      NAME_1: "North-East",
      NL_NAME_1: "None",
      GID_2: "BWA.11.1_1",
      NAME_2: "Masungu",
      isoCode: "BW",
    },
    {
      region_id: "BWA.12.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.12_1",
      NAME_1: "North-West",
      NL_NAME_1: "None",
      GID_2: "BWA.12.1_1",
      NAME_2: "Ngamiland East",
      isoCode: "BW",
    },
    {
      region_id: "BWA.12.2_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.12_1",
      NAME_1: "North-West",
      NL_NAME_1: "None",
      GID_2: "BWA.12.2_1",
      NAME_2: "Ngamiland West",
      isoCode: "BW",
    },
    {
      region_id: "BWA.13.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.13_1",
      NAME_1: "Selibe Phikwe",
      NL_NAME_1: "None",
      GID_2: "BWA.13.1_1",
      NAME_2: "Selibe Phikwe",
      isoCode: "BW",
    },
    {
      region_id: "BWA.14.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.14_1",
      NAME_1: "South-East",
      NL_NAME_1: "None",
      GID_2: "BWA.14.1_1",
      NAME_2: "South East",
      isoCode: "BW",
    },
    {
      region_id: "BWA.15.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.15_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "BWA.15.1_1",
      NAME_2: "Barolong",
      isoCode: "BW",
    },
    {
      region_id: "BWA.15.2_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.15_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "BWA.15.2_1",
      NAME_2: "Ngwaketse Central",
      isoCode: "BW",
    },
    {
      region_id: "BWA.15.3_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.15_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "BWA.15.3_1",
      NAME_2: "Ngwaketse North",
      isoCode: "BW",
    },
    {
      region_id: "BWA.15.4_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.15_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "BWA.15.4_1",
      NAME_2: "Ngwaketse South",
      isoCode: "BW",
    },
    {
      region_id: "BWA.16.1_1",
      GID_0: "BWA",
      NAME_0: "Botswana",
      GID_1: "BWA.16_1",
      NAME_1: "Sowa",
      NL_NAME_1: "None",
      GID_2: "BWA.16.1_1",
      NAME_2: "Sowa",
      isoCode: "BW",
    },
    {
      region_id: "COD.1.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.1_1",
      NAME_2: "Aketi",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.2_1",
      NAME_2: "Aketi (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.3_1",
      NAME_2: "Ango",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.4_1",
      NAME_2: "Bambesa",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.5_1",
      NAME_2: "Bondo",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.6_1",
      NAME_2: "Bondo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.7_1",
      NAME_2: "Buta",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.8_1",
      NAME_2: "Buta (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.9_1",
      NAME_2: "Dingila",
      isoCode: "CD",
    },
    {
      region_id: "COD.1.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.1_1",
      NAME_1: "Bas-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.1.10_1",
      NAME_2: "Poko",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.1_1",
      NAME_2: "Basankusu",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.2_1",
      NAME_2: "Basankusu (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.3_1",
      NAME_2: "Bikoro",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.4_1",
      NAME_2: "Bolomba",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.5_1",
      NAME_2: "Bomongo",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.6_1",
      NAME_2: "Ingende",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.7_1",
      NAME_2: "Lukolela",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.8_1",
      NAME_2: "Makanza",
      isoCode: "CD",
    },
    {
      region_id: "COD.2.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.2_1",
      NAME_1: "Équateur",
      NL_NAME_1: "None",
      GID_2: "COD.2.9_1",
      NAME_2: "Mbandaka",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.1_1",
      NAME_2: "Kambove",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.2_1",
      NAME_2: "Kasenga",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.3_1",
      NAME_2: "Kipushi",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.4_1",
      NAME_2: "Kipushi (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.5_1",
      NAME_2: "Likasi",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.6_1",
      NAME_2: "Lubumbashi",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.7_1",
      NAME_2: "Mitwaba",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.8_1",
      NAME_2: "Pweto",
      isoCode: "CD",
    },
    {
      region_id: "COD.3.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.3_1",
      NAME_1: "Haut-Katanga",
      NL_NAME_1: "None",
      GID_2: "COD.3.9_1",
      NAME_2: "Sakania",
      isoCode: "CD",
    },
    {
      region_id: "COD.4.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.4_1",
      NAME_1: "Haut-Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.4.1_1",
      NAME_2: "Bukama",
      isoCode: "CD",
    },
    {
      region_id: "COD.4.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.4_1",
      NAME_1: "Haut-Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.4.2_1",
      NAME_2: "Kabongo",
      isoCode: "CD",
    },
    {
      region_id: "COD.4.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.4_1",
      NAME_1: "Haut-Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.4.3_1",
      NAME_2: "Kamina",
      isoCode: "CD",
    },
    {
      region_id: "COD.4.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.4_1",
      NAME_1: "Haut-Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.4.4_1",
      NAME_2: "Kamina (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.4.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.4_1",
      NAME_1: "Haut-Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.4.5_1",
      NAME_2: "Kaniama",
      isoCode: "CD",
    },
    {
      region_id: "COD.4.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.4_1",
      NAME_1: "Haut-Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.4.6_1",
      NAME_2: "Malemba-Nkulu",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.1_1",
      NAME_2: "Aba",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.2_1",
      NAME_2: "Dungu",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.3_1",
      NAME_2: "Dungu (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.4_1",
      NAME_2: "Faradje",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.5_1",
      NAME_2: "Isiro",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.6_1",
      NAME_2: "Niangara",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.7_1",
      NAME_2: "Rungu",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.8_1",
      NAME_2: "Wamba",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.9_1",
      NAME_2: "Wamba (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.10_1",
      NAME_2: "Watsa",
      isoCode: "CD",
    },
    {
      region_id: "COD.5.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.5_1",
      NAME_1: "Haut-Uélé",
      NL_NAME_1: "None",
      GID_2: "COD.5.11_1",
      NAME_2: "Watsa (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.1_1",
      NAME_2: "Ariwara",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.2_1",
      NAME_2: "Aru",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.3_1",
      NAME_2: "Aru (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.4_1",
      NAME_2: "Bunia",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.5_1",
      NAME_2: "Djugu",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.6_1",
      NAME_2: "Ingbokolo",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.7_1",
      NAME_2: "Irumu",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.8_1",
      NAME_2: "Mahagi",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.9_1",
      NAME_2: "Mahagi (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.10_1",
      NAME_2: "Mambasa",
      isoCode: "CD",
    },
    {
      region_id: "COD.6.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.6_1",
      NAME_1: "Ituri",
      NL_NAME_1: "None",
      GID_2: "COD.6.11_1",
      NAME_2: "Mongwalu",
      isoCode: "CD",
    },
    {
      region_id: "COD.7.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.7_1",
      NAME_1: "Kasaï-Central",
      NL_NAME_1: "None",
      GID_2: "COD.7.1_1",
      NAME_2: "Demba",
      isoCode: "CD",
    },
    {
      region_id: "COD.7.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.7_1",
      NAME_1: "Kasaï-Central",
      NL_NAME_1: "None",
      GID_2: "COD.7.2_1",
      NAME_2: "Dibaya",
      isoCode: "CD",
    },
    {
      region_id: "COD.7.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.7_1",
      NAME_1: "Kasaï-Central",
      NL_NAME_1: "None",
      GID_2: "COD.7.3_1",
      NAME_2: "Dimbelenge",
      isoCode: "CD",
    },
    {
      region_id: "COD.7.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.7_1",
      NAME_1: "Kasaï-Central",
      NL_NAME_1: "None",
      GID_2: "COD.7.4_1",
      NAME_2: "Kananga",
      isoCode: "CD",
    },
    {
      region_id: "COD.7.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.7_1",
      NAME_1: "Kasaï-Central",
      NL_NAME_1: "None",
      GID_2: "COD.7.5_1",
      NAME_2: "Kazumba",
      isoCode: "CD",
    },
    {
      region_id: "COD.7.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.7_1",
      NAME_1: "Kasaï-Central",
      NL_NAME_1: "None",
      GID_2: "COD.7.6_1",
      NAME_2: "Luiza",
      isoCode: "CD",
    },
    {
      region_id: "COD.7.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.7_1",
      NAME_1: "Kasaï-Central",
      NL_NAME_1: "None",
      GID_2: "COD.7.7_1",
      NAME_2: "Tshimbulu",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.1_1",
      NAME_2: "Kabeya-Kamwanga",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.2_1",
      NAME_2: "Katanda",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.3_1",
      NAME_2: "Lukalaba",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.4_1",
      NAME_2: "Lupatapata",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.5_1",
      NAME_2: "Mbuji-Mayi",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.6_1",
      NAME_2: "Miabi",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.7_1",
      NAME_2: "Miabi (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.8_1",
      NAME_2: "Tshilenge",
      isoCode: "CD",
    },
    {
      region_id: "COD.8.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.8_1",
      NAME_1: "Kasaï-Oriental",
      NL_NAME_1: "None",
      GID_2: "COD.8.9_1",
      NAME_2: "Tshilenge (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.9.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.9_1",
      NAME_1: "Kasaï",
      NL_NAME_1: "None",
      GID_2: "COD.9.1_1",
      NAME_2: "Dekese",
      isoCode: "CD",
    },
    {
      region_id: "COD.9.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.9_1",
      NAME_1: "Kasaï",
      NL_NAME_1: "None",
      GID_2: "COD.9.2_1",
      NAME_2: "Ilebo",
      isoCode: "CD",
    },
    {
      region_id: "COD.9.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.9_1",
      NAME_1: "Kasaï",
      NL_NAME_1: "None",
      GID_2: "COD.9.3_1",
      NAME_2: "Ilebo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.9.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.9_1",
      NAME_1: "Kasaï",
      NL_NAME_1: "None",
      GID_2: "COD.9.4_1",
      NAME_2: "Kamonia",
      isoCode: "CD",
    },
    {
      region_id: "COD.9.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.9_1",
      NAME_1: "Kasaï",
      NL_NAME_1: "None",
      GID_2: "COD.9.5_1",
      NAME_2: "Luebo",
      isoCode: "CD",
    },
    {
      region_id: "COD.9.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.9_1",
      NAME_1: "Kasaï",
      NL_NAME_1: "None",
      GID_2: "COD.9.6_1",
      NAME_2: "Luebo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.9.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.9_1",
      NAME_1: "Kasaï",
      NL_NAME_1: "None",
      GID_2: "COD.9.7_1",
      NAME_2: "Mweka",
      isoCode: "CD",
    },
    {
      region_id: "COD.9.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.9_1",
      NAME_1: "Kasaï",
      NL_NAME_1: "None",
      GID_2: "COD.9.8_1",
      NAME_2: "Tshikapa",
      isoCode: "CD",
    },
    {
      region_id: "COD.10.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.10_1",
      NAME_1: "Kinshasa",
      NL_NAME_1: "None",
      GID_2: "COD.10.1_1",
      NAME_2: "Kinshasa",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.1_1",
      NAME_2: "Bangu",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.2_1",
      NAME_2: "Boma",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.3_1",
      NAME_2: "Inkisi",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.4_1",
      NAME_2: "Kasangulu",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.5_1",
      NAME_2: "Kasangulu (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.6_1",
      NAME_2: "Kimvula",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.7_1",
      NAME_2: "Lukula",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.8_1",
      NAME_2: "Lukula (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.9_1",
      NAME_2: "Luozi",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.10_1",
      NAME_2: "Madimba",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.11_1",
      NAME_2: "Matadi",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.12_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.12_1",
      NAME_2: "Mbanza-Ngungu",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.13_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.13_1",
      NAME_2: "Mbanza-Ngungu (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.14_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.14_1",
      NAME_2: "Moanda",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.15_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.15_1",
      NAME_2: "Moanda (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.16_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.16_1",
      NAME_2: "Seke-Banza",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.17_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.17_1",
      NAME_2: "Songololo",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.18_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.18_1",
      NAME_2: "Tshela",
      isoCode: "CD",
    },
    {
      region_id: "COD.11.19_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.11_1",
      NAME_1: "Kongo-Central",
      NL_NAME_1: "None",
      GID_2: "COD.11.19_1",
      NAME_2: "Tshela (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.12.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.12_1",
      NAME_1: "Kwango",
      NL_NAME_1: "None",
      GID_2: "COD.12.1_1",
      NAME_2: "Feshi",
      isoCode: "CD",
    },
    {
      region_id: "COD.12.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.12_1",
      NAME_1: "Kwango",
      NL_NAME_1: "None",
      GID_2: "COD.12.2_1",
      NAME_2: "Kahemba",
      isoCode: "CD",
    },
    {
      region_id: "COD.12.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.12_1",
      NAME_1: "Kwango",
      NL_NAME_1: "None",
      GID_2: "COD.12.3_1",
      NAME_2: "Kahemba (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.12.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.12_1",
      NAME_1: "Kwango",
      NL_NAME_1: "None",
      GID_2: "COD.12.4_1",
      NAME_2: "Kasongo-Lunda",
      isoCode: "CD",
    },
    {
      region_id: "COD.12.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.12_1",
      NAME_1: "Kwango",
      NL_NAME_1: "None",
      GID_2: "COD.12.5_1",
      NAME_2: "Kasongo-Lunda (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.12.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.12_1",
      NAME_1: "Kwango",
      NL_NAME_1: "None",
      GID_2: "COD.12.6_1",
      NAME_2: "Kenge",
      isoCode: "CD",
    },
    {
      region_id: "COD.12.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.12_1",
      NAME_1: "Kwango",
      NL_NAME_1: "None",
      GID_2: "COD.12.7_1",
      NAME_2: "Kenge (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.12.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.12_1",
      NAME_1: "Kwango",
      NL_NAME_1: "None",
      GID_2: "COD.12.8_1",
      NAME_2: "Popokabaka",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.1_1",
      NAME_2: "Bagata",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.2_1",
      NAME_2: "Bandundu",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.3_1",
      NAME_2: "Bulungu",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.4_1",
      NAME_2: "Bulungu (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.5_1",
      NAME_2: "Dibaya-Lubwe",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.6_1",
      NAME_2: "Gungu",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.7_1",
      NAME_2: "Gungu (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.8_1",
      NAME_2: "Idiofa",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.9_1",
      NAME_2: "Idiofa (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.10_1",
      NAME_2: "Kikwit",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.11_1",
      NAME_2: "Mangai",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.12_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.12_1",
      NAME_2: "Masi-Manimba",
      isoCode: "CD",
    },
    {
      region_id: "COD.13.13_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.13_1",
      NAME_1: "Kwilu",
      NL_NAME_1: "None",
      GID_2: "COD.13.13_1",
      NAME_2: "Masi-Manimba (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.1_1",
      NAME_2: "Kabinda",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.2_1",
      NAME_2: "Kabinda (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.3_1",
      NAME_2: "Kamiji",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.4_1",
      NAME_2: "Lubao",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.5_1",
      NAME_2: "Lubao (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.6_1",
      NAME_2: "Luilu",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.7_1",
      NAME_2: "Mwene-Ditu",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.8_1",
      NAME_2: "Ngandajika",
      isoCode: "CD",
    },
    {
      region_id: "COD.14.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.14_1",
      NAME_1: "Lomami",
      NL_NAME_1: "None",
      GID_2: "COD.14.9_1",
      NAME_2: "Ngandajika (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.15.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.15_1",
      NAME_1: "Lualaba",
      NL_NAME_1: "None",
      GID_2: "COD.15.1_1",
      NAME_2: "Dilolo",
      isoCode: "CD",
    },
    {
      region_id: "COD.15.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.15_1",
      NAME_1: "Lualaba",
      NL_NAME_1: "None",
      GID_2: "COD.15.2_1",
      NAME_2: "Kapanga",
      isoCode: "CD",
    },
    {
      region_id: "COD.15.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.15_1",
      NAME_1: "Lualaba",
      NL_NAME_1: "None",
      GID_2: "COD.15.3_1",
      NAME_2: "Kasaji",
      isoCode: "CD",
    },
    {
      region_id: "COD.15.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.15_1",
      NAME_1: "Lualaba",
      NL_NAME_1: "None",
      GID_2: "COD.15.4_1",
      NAME_2: "Kolwezi",
      isoCode: "CD",
    },
    {
      region_id: "COD.15.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.15_1",
      NAME_1: "Lualaba",
      NL_NAME_1: "None",
      GID_2: "COD.15.5_1",
      NAME_2: "Lubudi",
      isoCode: "CD",
    },
    {
      region_id: "COD.15.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.15_1",
      NAME_1: "Lualaba",
      NL_NAME_1: "None",
      GID_2: "COD.15.6_1",
      NAME_2: "Mutshatsha",
      isoCode: "CD",
    },
    {
      region_id: "COD.15.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.15_1",
      NAME_1: "Lualaba",
      NL_NAME_1: "None",
      GID_2: "COD.15.7_1",
      NAME_2: "Sandoa",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.1_1",
      NAME_2: "Bolobo",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.2_1",
      NAME_2: "Bolobo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.3_1",
      NAME_2: "Inongo",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.4_1",
      NAME_2: "Inongo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.5_1",
      NAME_2: "Kiri",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.6_1",
      NAME_2: "Kutu",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.7_1",
      NAME_2: "Kwamouth",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.8_1",
      NAME_2: "Mushie",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.9_1",
      NAME_2: "Nioki",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.10_1",
      NAME_2: "Oshwe",
      isoCode: "CD",
    },
    {
      region_id: "COD.16.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.16_1",
      NAME_1: "Maï-Ndombe",
      NL_NAME_1: "None",
      GID_2: "COD.16.11_1",
      NAME_2: "Yumbi",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.1_1",
      NAME_2: "Kabambare",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.2_1",
      NAME_2: "Kailo",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.3_1",
      NAME_2: "Kalima",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.4_1",
      NAME_2: "Kasongo",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.5_1",
      NAME_2: "Kasongo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.6_1",
      NAME_2: "Kibombo",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.7_1",
      NAME_2: "Kindu",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.8_1",
      NAME_2: "Lubutu",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.9_1",
      NAME_2: "Lubutu (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.10_1",
      NAME_2: "Namoya",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.11_1",
      NAME_2: "Pangi",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.12_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.12_1",
      NAME_2: "Punia",
      isoCode: "CD",
    },
    {
      region_id: "COD.17.13_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.17_1",
      NAME_1: "Maniema",
      NL_NAME_1: "None",
      GID_2: "COD.17.13_1",
      NAME_2: "Punia (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.18.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.18_1",
      NAME_1: "Mongala",
      NL_NAME_1: "None",
      GID_2: "COD.18.1_1",
      NAME_2: "Bongandanga",
      isoCode: "CD",
    },
    {
      region_id: "COD.18.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.18_1",
      NAME_1: "Mongala",
      NL_NAME_1: "None",
      GID_2: "COD.18.2_1",
      NAME_2: "Bumba",
      isoCode: "CD",
    },
    {
      region_id: "COD.18.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.18_1",
      NAME_1: "Mongala",
      NL_NAME_1: "None",
      GID_2: "COD.18.3_1",
      NAME_2: "Bumba (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.18.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.18_1",
      NAME_1: "Mongala",
      NL_NAME_1: "None",
      GID_2: "COD.18.4_1",
      NAME_2: "Lisala",
      isoCode: "CD",
    },
    {
      region_id: "COD.18.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.18_1",
      NAME_1: "Mongala",
      NL_NAME_1: "None",
      GID_2: "COD.18.5_1",
      NAME_2: "Lisala (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.1_1",
      NAME_2: "Beni",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.2_1",
      NAME_2: "Butembo",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.3_1",
      NAME_2: "Goma",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.4_1",
      NAME_2: "Lubero",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.5_1",
      NAME_2: "Masisi",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.6_1",
      NAME_2: "Nyiragongo",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.7_1",
      NAME_2: "Oicha",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.8_1",
      NAME_2: "Oïcha (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.9_1",
      NAME_2: "Rutshuru",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.10_1",
      NAME_2: "Rutshuru (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.19.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.19_1",
      NAME_1: "Nord-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.19.11_1",
      NAME_2: "Walikale",
      isoCode: "CD",
    },
    {
      region_id: "COD.20.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.20_1",
      NAME_1: "Nord-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.20.1_1",
      NAME_2: "Bosobolo",
      isoCode: "CD",
    },
    {
      region_id: "COD.20.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.20_1",
      NAME_1: "Nord-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.20.2_1",
      NAME_2: "Businga",
      isoCode: "CD",
    },
    {
      region_id: "COD.20.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.20_1",
      NAME_1: "Nord-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.20.3_1",
      NAME_2: "Gbadolite",
      isoCode: "CD",
    },
    {
      region_id: "COD.20.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.20_1",
      NAME_1: "Nord-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.20.4_1",
      NAME_2: "Mobayi-Mbongo",
      isoCode: "CD",
    },
    {
      region_id: "COD.20.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.20_1",
      NAME_1: "Nord-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.20.5_1",
      NAME_2: "Mobayi-Mbongo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.20.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.20_1",
      NAME_1: "Nord-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.20.6_1",
      NAME_2: "Yakoma",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.1_1",
      NAME_2: "Bena-Dibele",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.2_1",
      NAME_2: "Katako-Kombe",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.3_1",
      NAME_2: "Kole",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.4_1",
      NAME_2: "Lodja",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.5_1",
      NAME_2: "Lodja (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.6_1",
      NAME_2: "Lomela",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.7_1",
      NAME_2: "Lubefu",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.8_1",
      NAME_2: "Lusambo",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.9_1",
      NAME_2: "Lusambo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.21.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.21_1",
      NAME_1: "Sankuru",
      NL_NAME_1: "None",
      GID_2: "COD.21.10_1",
      NAME_2: "Tshumbe",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.1_1",
      NAME_2: "Baraka",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.2_1",
      NAME_2: "Bukavu",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.3_1",
      NAME_2: "Fizi",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.4_1",
      NAME_2: "Idjwi",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.5_1",
      NAME_2: "Kabare",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.6_1",
      NAME_2: "Kalehe",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.7_1",
      NAME_2: "Kamituga",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.8_1",
      NAME_2: "Mwenga",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.9_1",
      NAME_2: "Shabunda",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.10_1",
      NAME_2: "Shabunda (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.11_1",
      NAME_2: "Uvira",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.12_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.12_1",
      NAME_2: "Uvira (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.22.13_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.22_1",
      NAME_1: "Sud-Kivu",
      NL_NAME_1: "None",
      GID_2: "COD.22.13_1",
      NAME_2: "Walungu",
      isoCode: "CD",
    },
    {
      region_id: "COD.23.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.23_1",
      NAME_1: "Sud-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.23.1_1",
      NAME_2: "Budjala",
      isoCode: "CD",
    },
    {
      region_id: "COD.23.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.23_1",
      NAME_1: "Sud-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.23.2_1",
      NAME_2: "Gemena",
      isoCode: "CD",
    },
    {
      region_id: "COD.23.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.23_1",
      NAME_1: "Sud-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.23.3_1",
      NAME_2: "Gemena (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.23.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.23_1",
      NAME_1: "Sud-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.23.4_1",
      NAME_2: "Kungu",
      isoCode: "CD",
    },
    {
      region_id: "COD.23.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.23_1",
      NAME_1: "Sud-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.23.5_1",
      NAME_2: "Libenge",
      isoCode: "CD",
    },
    {
      region_id: "COD.23.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.23_1",
      NAME_1: "Sud-Ubangi",
      NL_NAME_1: "None",
      GID_2: "COD.23.6_1",
      NAME_2: "Zongo",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.1_1",
      NAME_2: "Kabalo",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.2_1",
      NAME_2: "Kalemie",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.3_1",
      NAME_2: "Kalemie (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.4_1",
      NAME_2: "Kaoze",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.5_1",
      NAME_2: "Kongolo",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.6_1",
      NAME_2: "Kongolo (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.7_1",
      NAME_2: "Manono",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.8_1",
      NAME_2: "Manono (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.9_1",
      NAME_2: "Moba",
      isoCode: "CD",
    },
    {
      region_id: "COD.24.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.24_1",
      NAME_1: "Tanganyika",
      NL_NAME_1: "None",
      GID_2: "COD.24.10_1",
      NAME_2: "Nyunzu",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.1_1",
      NAME_2: "Bafwasende",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.2_1",
      NAME_2: "Banalia",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.3_1",
      NAME_2: "Basoko",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.4_1",
      NAME_2: "Basoko (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.5_1",
      NAME_2: "Isangi",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.6_1",
      NAME_2: "Isangi (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.7_1",
      NAME_2: "Kisangani",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.8_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.8_1",
      NAME_2: "Opala",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.9_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.9_1",
      NAME_2: "Ubundu",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.10_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.10_1",
      NAME_2: "Yahuma",
      isoCode: "CD",
    },
    {
      region_id: "COD.25.11_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.25_1",
      NAME_1: "Tshopo",
      NL_NAME_1: "None",
      GID_2: "COD.25.11_1",
      NAME_2: "Yangambi",
      isoCode: "CD",
    },
    {
      region_id: "COD.26.1_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.26_1",
      NAME_1: "Tshuapa",
      NL_NAME_1: "None",
      GID_2: "COD.26.1_1",
      NAME_2: "Befale",
      isoCode: "CD",
    },
    {
      region_id: "COD.26.2_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.26_1",
      NAME_1: "Tshuapa",
      NL_NAME_1: "None",
      GID_2: "COD.26.2_1",
      NAME_2: "Boende",
      isoCode: "CD",
    },
    {
      region_id: "COD.26.3_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.26_1",
      NAME_1: "Tshuapa",
      NL_NAME_1: "None",
      GID_2: "COD.26.3_1",
      NAME_2: "Boende (ville)",
      isoCode: "CD",
    },
    {
      region_id: "COD.26.4_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.26_1",
      NAME_1: "Tshuapa",
      NL_NAME_1: "None",
      GID_2: "COD.26.4_1",
      NAME_2: "Bokungu",
      isoCode: "CD",
    },
    {
      region_id: "COD.26.5_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.26_1",
      NAME_1: "Tshuapa",
      NL_NAME_1: "None",
      GID_2: "COD.26.5_1",
      NAME_2: "Djolu",
      isoCode: "CD",
    },
    {
      region_id: "COD.26.6_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.26_1",
      NAME_1: "Tshuapa",
      NL_NAME_1: "None",
      GID_2: "COD.26.6_1",
      NAME_2: "Ikela",
      isoCode: "CD",
    },
    {
      region_id: "COD.26.7_1",
      GID_0: "COD",
      NAME_0: "Democratic Republic of the Congo",
      GID_1: "COD.26_1",
      NAME_1: "Tshuapa",
      NL_NAME_1: "None",
      GID_2: "COD.26.7_1",
      NAME_2: "Monkoto",
      isoCode: "CD",
    },
    {
      region_id: "COM.1_1",
      GID_0: "COM",
      NAME_0: "Comoros",
      GID_1: "COM.1_1",
      NAME_1: "Mwali",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "KM",
    },
    {
      region_id: "COM.2_1",
      GID_0: "COM",
      NAME_0: "Comoros",
      GID_1: "COM.2_1",
      NAME_1: "Njazídja",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "KM",
    },
    {
      region_id: "COM.3_1",
      GID_0: "COM",
      NAME_0: "Comoros",
      GID_1: "COM.3_1",
      NAME_1: "Nzwani",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "KM",
    },
    {
      region_id: "LSO.1_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.1_1",
      NAME_1: "Berea",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.2_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.2_1",
      NAME_1: "Butha-Buthe",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.3_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.3_1",
      NAME_1: "Leribe",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.4_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.4_1",
      NAME_1: "Mafeteng",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.5_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.5_1",
      NAME_1: "Maseru",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.6_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.6_1",
      NAME_1: "Mohale's Hoek",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.7_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.7_1",
      NAME_1: "Mokhotlong",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.8_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.8_1",
      NAME_1: "Qacha's Nek",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.9_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.9_1",
      NAME_1: "Quthing",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "LSO.10_1",
      GID_0: "LSO",
      NAME_0: "Lesotho",
      GID_1: "LSO.10_1",
      NAME_1: "Thaba-Tseka",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "LS",
    },
    {
      region_id: "MDG.1.1_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.1_1",
      NAME_1: "Antananarivo",
      NL_NAME_1: "None",
      GID_2: "MDG.1.1_1",
      NAME_2: "Analamanga",
      isoCode: "MG",
    },
    {
      region_id: "MDG.1.2_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.1_1",
      NAME_1: "Antananarivo",
      NL_NAME_1: "None",
      GID_2: "MDG.1.2_1",
      NAME_2: "Bongolava",
      isoCode: "MG",
    },
    {
      region_id: "MDG.1.3_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.1_1",
      NAME_1: "Antananarivo",
      NL_NAME_1: "None",
      GID_2: "MDG.1.3_1",
      NAME_2: "Itasy",
      isoCode: "MG",
    },
    {
      region_id: "MDG.1.4_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.1_1",
      NAME_1: "Antananarivo",
      NL_NAME_1: "None",
      GID_2: "MDG.1.4_1",
      NAME_2: "Vakinankaratra",
      isoCode: "MG",
    },
    {
      region_id: "MDG.2.1_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.2_1",
      NAME_1: "Antsiranana",
      NL_NAME_1: "None",
      GID_2: "MDG.2.1_1",
      NAME_2: "Diana",
      isoCode: "MG",
    },
    {
      region_id: "MDG.2.2_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.2_1",
      NAME_1: "Antsiranana",
      NL_NAME_1: "None",
      GID_2: "MDG.2.2_1",
      NAME_2: "Sava",
      isoCode: "MG",
    },
    {
      region_id: "MDG.3.1_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.3_1",
      NAME_1: "Fianarantsoa",
      NL_NAME_1: "None",
      GID_2: "MDG.3.1_1",
      NAME_2: "Amoron'i mania",
      isoCode: "MG",
    },
    {
      region_id: "MDG.3.2_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.3_1",
      NAME_1: "Fianarantsoa",
      NL_NAME_1: "None",
      GID_2: "MDG.3.2_1",
      NAME_2: "Atsimo-Atsinana",
      isoCode: "MG",
    },
    {
      region_id: "MDG.3.3_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.3_1",
      NAME_1: "Fianarantsoa",
      NL_NAME_1: "None",
      GID_2: "MDG.3.3_1",
      NAME_2: "Haute matsiatra",
      isoCode: "MG",
    },
    {
      region_id: "MDG.3.4_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.3_1",
      NAME_1: "Fianarantsoa",
      NL_NAME_1: "None",
      GID_2: "MDG.3.4_1",
      NAME_2: "Ihorombe",
      isoCode: "MG",
    },
    {
      region_id: "MDG.3.5_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.3_1",
      NAME_1: "Fianarantsoa",
      NL_NAME_1: "None",
      GID_2: "MDG.3.5_1",
      NAME_2: "Vatovavy Fitovinany",
      isoCode: "MG",
    },
    {
      region_id: "MDG.4.1_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.4_1",
      NAME_1: "Mahajanga",
      NL_NAME_1: "None",
      GID_2: "MDG.4.1_1",
      NAME_2: "Betsiboka",
      isoCode: "MG",
    },
    {
      region_id: "MDG.4.2_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.4_1",
      NAME_1: "Mahajanga",
      NL_NAME_1: "None",
      GID_2: "MDG.4.2_1",
      NAME_2: "Boeny",
      isoCode: "MG",
    },
    {
      region_id: "MDG.4.3_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.4_1",
      NAME_1: "Mahajanga",
      NL_NAME_1: "None",
      GID_2: "MDG.4.3_1",
      NAME_2: "Melaky",
      isoCode: "MG",
    },
    {
      region_id: "MDG.4.4_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.4_1",
      NAME_1: "Mahajanga",
      NL_NAME_1: "None",
      GID_2: "MDG.4.4_1",
      NAME_2: "Sofia",
      isoCode: "MG",
    },
    {
      region_id: "MDG.5.1_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.5_1",
      NAME_1: "Toamasina",
      NL_NAME_1: "None",
      GID_2: "MDG.5.1_1",
      NAME_2: "Alaotra-Mangoro",
      isoCode: "MG",
    },
    {
      region_id: "MDG.5.2_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.5_1",
      NAME_1: "Toamasina",
      NL_NAME_1: "None",
      GID_2: "MDG.5.2_1",
      NAME_2: "Analanjirofo",
      isoCode: "MG",
    },
    {
      region_id: "MDG.5.3_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.5_1",
      NAME_1: "Toamasina",
      NL_NAME_1: "None",
      GID_2: "MDG.5.3_1",
      NAME_2: "Atsinanana",
      isoCode: "MG",
    },
    {
      region_id: "MDG.6.1_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.6_1",
      NAME_1: "Toliary",
      NL_NAME_1: "None",
      GID_2: "MDG.6.1_1",
      NAME_2: "Androy",
      isoCode: "MG",
    },
    {
      region_id: "MDG.6.2_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.6_1",
      NAME_1: "Toliary",
      NL_NAME_1: "None",
      GID_2: "MDG.6.2_1",
      NAME_2: "Anosy",
      isoCode: "MG",
    },
    {
      region_id: "MDG.6.3_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.6_1",
      NAME_1: "Toliary",
      NL_NAME_1: "None",
      GID_2: "MDG.6.3_1",
      NAME_2: "Atsimo-Andrefana",
      isoCode: "MG",
    },
    {
      region_id: "MDG.6.4_1",
      GID_0: "MDG",
      NAME_0: "Madagascar",
      GID_1: "MDG.6_1",
      NAME_1: "Toliary",
      NL_NAME_1: "None",
      GID_2: "MDG.6.4_1",
      NAME_2: "Menabe",
      isoCode: "MG",
    },
    {
      region_id: "MOZ.1.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.1_1",
      NAME_2: "Ancuabe",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.2_1",
      NAME_2: "Balama",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.3_1",
      NAME_2: "Chiúre",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.4_1",
      NAME_2: "Macomia",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.5_1",
      NAME_2: "Mecufi",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.6_1",
      NAME_2: "Meluco",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.7_1",
      NAME_2: "Mocimboa da Praia",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.8_1",
      NAME_2: "Montepuez",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.9_1",
      NAME_2: "Mueda",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.10_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.10_1",
      NAME_2: "Muidumbe",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.11_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.11_1",
      NAME_2: "Namuno",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.12_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.12_1",
      NAME_2: "Nangade",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.13_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.13_1",
      NAME_2: "Palma",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.14_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.14_1",
      NAME_2: "Pemba",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.1.15_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.1_1",
      NAME_1: "Cabo Delgado",
      NL_NAME_1: "None",
      GID_2: "MOZ.1.15_1",
      NAME_2: "Quissanga",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.1_1",
      NAME_2: "Bilene",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.2_1",
      NAME_2: "Chibuto",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.3_1",
      NAME_2: "Chicualacuala",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.4_1",
      NAME_2: "Chigubo",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.5_1",
      NAME_2: "Chókwè",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.6_1",
      NAME_2: "Guijá",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.7_1",
      NAME_2: "Mabalane",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.8_1",
      NAME_2: "Mandlakazi",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.9_1",
      NAME_2: "Massangena",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.10_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.10_1",
      NAME_2: "Massingir",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.2.11_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.2_1",
      NAME_1: "Gaza",
      NL_NAME_1: "None",
      GID_2: "MOZ.2.11_1",
      NAME_2: "Xai-Xai",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.1_1",
      NAME_2: "Funhalouro",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.2_1",
      NAME_2: "Govuro",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.3_1",
      NAME_2: "Homoine",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.4_1",
      NAME_2: "Inharrime",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.5_1",
      NAME_2: "Inhassoro",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.6_1",
      NAME_2: "Jangamo",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.7_1",
      NAME_2: "Mabote",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.8_1",
      NAME_2: "Massinga",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.9_1",
      NAME_2: "Morrumbene",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.10_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.10_1",
      NAME_2: "Panda",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.11_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.11_1",
      NAME_2: "Vilanculos",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.3.12_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.3_1",
      NAME_1: "Inhambane",
      NL_NAME_1: "None",
      GID_2: "MOZ.3.12_1",
      NAME_2: "Zavala",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.1_1",
      NAME_2: "Barue",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.2_1",
      NAME_2: "Gondola",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.3_1",
      NAME_2: "Guro",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.4_1",
      NAME_2: "Machaze",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.5_1",
      NAME_2: "Macossa",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.6_1",
      NAME_2: "Manica",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.7_1",
      NAME_2: "Mossurize",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.8_1",
      NAME_2: "Sussundenga",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.4.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.4_1",
      NAME_1: "Manica",
      NL_NAME_1: "None",
      GID_2: "MOZ.4.9_1",
      NAME_2: "Tambara",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.5.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.5_1",
      NAME_1: "Maputo City",
      NL_NAME_1: "None",
      GID_2: "MOZ.5.1_1",
      NAME_2: "Boane",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.5.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.5_1",
      NAME_1: "Maputo City",
      NL_NAME_1: "None",
      GID_2: "MOZ.5.2_1",
      NAME_2: "Maputo",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.6.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.6_1",
      NAME_1: "Maputo",
      NL_NAME_1: "None",
      GID_2: "MOZ.6.1_1",
      NAME_2: "Boane",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.6.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.6_1",
      NAME_1: "Maputo",
      NL_NAME_1: "None",
      GID_2: "MOZ.6.2_1",
      NAME_2: "Magude",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.6.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.6_1",
      NAME_1: "Maputo",
      NL_NAME_1: "None",
      GID_2: "MOZ.6.3_1",
      NAME_2: "Manhiça",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.6.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.6_1",
      NAME_1: "Maputo",
      NL_NAME_1: "None",
      GID_2: "MOZ.6.4_1",
      NAME_2: "Marracuene",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.6.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.6_1",
      NAME_1: "Maputo",
      NL_NAME_1: "None",
      GID_2: "MOZ.6.5_1",
      NAME_2: "Matutuíne",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.6.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.6_1",
      NAME_1: "Maputo",
      NL_NAME_1: "None",
      GID_2: "MOZ.6.6_1",
      NAME_2: "Moamba",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.6.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.6_1",
      NAME_1: "Maputo",
      NL_NAME_1: "None",
      GID_2: "MOZ.6.7_1",
      NAME_2: "Namaacha",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.1_1",
      NAME_2: "Angoche",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.2_1",
      NAME_2: "Erati",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.3_1",
      NAME_2: "Lalaua",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.4_1",
      NAME_2: "Malema",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.5_1",
      NAME_2: "Meconta",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.6_1",
      NAME_2: "Mecuburi",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.7_1",
      NAME_2: "Memba",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.8_1",
      NAME_2: "Mogovolas",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.9_1",
      NAME_2: "Moma",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.10_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.10_1",
      NAME_2: "Monapo",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.11_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.11_1",
      NAME_2: "Mongincual",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.12_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.12_1",
      NAME_2: "Mossuril",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.13_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.13_1",
      NAME_2: "Muecate",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.14_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.14_1",
      NAME_2: "Murrupula",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.15_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.15_1",
      NAME_2: "Nacala Velha",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.16_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.16_1",
      NAME_2: "Namapa",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.17_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.17_1",
      NAME_2: "Nampula",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.7.18_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.7_1",
      NAME_1: "Nampula",
      NL_NAME_1: "None",
      GID_2: "MOZ.7.18_1",
      NAME_2: "Ribaue",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.1_1",
      NAME_2: "Cuamba",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.2_1",
      NAME_2: "Lago",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.3_1",
      NAME_2: "Lichinga",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.4_1",
      NAME_2: "Majune",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.5_1",
      NAME_2: "Mandimba",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.6_1",
      NAME_2: "Marrupa",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.7_1",
      NAME_2: "Maúa",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.8_1",
      NAME_2: "Mavago",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.9_1",
      NAME_2: "Mecanhelas",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.10_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.10_1",
      NAME_2: "Mecula",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.11_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.11_1",
      NAME_2: "Metarica",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.12_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.12_1",
      NAME_2: "Muembe",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.13_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.13_1",
      NAME_2: "N'gauma",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.14_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.14_1",
      NAME_2: "Nipepe",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.8.15_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.8_1",
      NAME_1: "Nassa",
      NL_NAME_1: "None",
      GID_2: "MOZ.8.15_1",
      NAME_2: "Sanga",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.1_1",
      NAME_2: "Buzi",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.2_1",
      NAME_2: "Caia",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.3_1",
      NAME_2: "Chemba",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.4_1",
      NAME_2: "Cheringoma",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.5_1",
      NAME_2: "Chibabava",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.6_1",
      NAME_2: "Dondo",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.7_1",
      NAME_2: "Gorongosa",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.8_1",
      NAME_2: "Machanga",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.9_1",
      NAME_2: "Maringue",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.10_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.10_1",
      NAME_2: "Marromeu",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.11_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.11_1",
      NAME_2: "Muanza",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.9.12_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.9_1",
      NAME_1: "Sofala",
      NL_NAME_1: "None",
      GID_2: "MOZ.9.12_1",
      NAME_2: "Nhamatanda",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.1_1",
      NAME_2: "Angónia",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.2_1",
      NAME_2: "Cahora Bassa",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.3_1",
      NAME_2: "Changara",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.4_1",
      NAME_2: "Chifunde",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.5_1",
      NAME_2: "Chiuta",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.6_1",
      NAME_2: "Macanga",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.7_1",
      NAME_2: "Magoe",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.8_1",
      NAME_2: "Maravia",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.9_1",
      NAME_2: "Moatize",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.10_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.10_1",
      NAME_2: "Mutarara",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.11_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.11_1",
      NAME_2: "Tsangano",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.10.12_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.10_1",
      NAME_1: "Tete",
      NL_NAME_1: "None",
      GID_2: "MOZ.10.12_1",
      NAME_2: "Zumbu",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.1_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.1_1",
      NAME_2: "Alto Molocue",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.2_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.2_1",
      NAME_2: "Chinde",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.3_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.3_1",
      NAME_2: "Gile",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.4_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.4_1",
      NAME_2: "Gurue",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.5_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.5_1",
      NAME_2: "Ile",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.6_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.6_1",
      NAME_2: "Inhassunge",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.7_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.7_1",
      NAME_2: "Lugela",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.8_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.8_1",
      NAME_2: "Maganja da Costa",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.9_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.9_1",
      NAME_2: "Milange",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.10_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.10_1",
      NAME_2: "Mocuba",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.11_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.11_1",
      NAME_2: "Mopeia",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.12_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.12_1",
      NAME_2: "Morrumbala",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.13_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.13_1",
      NAME_2: "Namacurra",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.14_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.14_1",
      NAME_2: "Namarroi",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.15_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.15_1",
      NAME_2: "Nicoadala",
      isoCode: "MZ",
    },
    {
      region_id: "MOZ.11.16_1",
      GID_0: "MOZ",
      NAME_0: "Mozambique",
      GID_1: "MOZ.11_1",
      NAME_1: "Zambezia",
      NL_NAME_1: "None",
      GID_2: "MOZ.11.16_1",
      NAME_2: "Pebane",
      isoCode: "MZ",
    },
    {
      region_id: "MUS.1_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.1_1",
      NAME_1: "Agalega Islands",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.2_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.2_1",
      NAME_1: "Black River",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.3_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.3_1",
      NAME_1: "Flacq",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.4_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.4_1",
      NAME_1: "Grand Port",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.5_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.5_1",
      NAME_1: "Moka",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.6_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.6_1",
      NAME_1: "Pamplemousses",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.7_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.7_1",
      NAME_1: "Plaines Wilhems",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.8_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.8_1",
      NAME_1: "Port Louis",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.9_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.9_1",
      NAME_1: "Rivière du Rempart",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.10_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.10_1",
      NAME_1: "Rodriguez",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.11_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.11_1",
      NAME_1: "Saint Brandon",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MUS.12_1",
      GID_0: "MUS",
      NAME_0: "Mauritius",
      GID_1: "MUS.12_1",
      NAME_1: "Savanne",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "MU",
    },
    {
      region_id: "MWI.1.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.1_1",
      NAME_1: "Balaka",
      NL_NAME_1: "None",
      GID_2: "MWI.1.1_1",
      NAME_2: "Balaka Town",
      isoCode: "MW",
    },
    {
      region_id: "MWI.1.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.1_1",
      NAME_1: "Balaka",
      NL_NAME_1: "None",
      GID_2: "MWI.1.2_1",
      NAME_2: "TA Kalembo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.1.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.1_1",
      NAME_1: "Balaka",
      NL_NAME_1: "None",
      GID_2: "MWI.1.3_1",
      NAME_2: "TA Nsamala",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.1_1",
      NAME_2: "Blantyre City",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.2_1",
      NAME_2: "TA Chigaru",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.3_1",
      NAME_2: "TA Kapeni",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.4_1",
      NAME_2: "TA Kuntaja",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.5_1",
      NAME_2: "TA Kunthembwe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.6_1",
      NAME_2: "TA Lundu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.7_1",
      NAME_2: "TA Machinjili",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.8_1",
      NAME_2: "TA Makata",
      isoCode: "MW",
    },
    {
      region_id: "MWI.2.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.2_1",
      NAME_1: "Blantyre",
      NL_NAME_1: "None",
      GID_2: "MWI.2.9_1",
      NAME_2: "TA Somba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.1_1",
      NAME_2: "Chikwawa Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.2_1",
      NAME_2: "Lengwe National Park",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.3_1",
      NAME_2: "Majete Game Reserve - Chikwawa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.4_1",
      NAME_2: "Ngabu Urban",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.5_1",
      NAME_2: "TA Chapananga",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.6_1",
      NAME_2: "TA Kasisi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.7_1",
      NAME_2: "TA Katunga",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.8_1",
      NAME_2: "TA Lundu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.9_1",
      NAME_2: "TA Makhwira",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.10_1",
      NAME_2: "TA Maseya",
      isoCode: "MW",
    },
    {
      region_id: "MWI.3.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.3_1",
      NAME_1: "Chikwawa",
      NL_NAME_1: "None",
      GID_2: "MWI.3.11_1",
      NAME_2: "TA Ngabu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.4.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.4_1",
      NAME_1: "Chiradzulu",
      NL_NAME_1: "None",
      GID_2: "MWI.4.1_1",
      NAME_2: "Chiradzulu Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.4.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.4_1",
      NAME_1: "Chiradzulu",
      NL_NAME_1: "None",
      GID_2: "MWI.4.2_1",
      NAME_2: "TA Chitera",
      isoCode: "MW",
    },
    {
      region_id: "MWI.4.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.4_1",
      NAME_1: "Chiradzulu",
      NL_NAME_1: "None",
      GID_2: "MWI.4.3_1",
      NAME_2: "TA Kadewere",
      isoCode: "MW",
    },
    {
      region_id: "MWI.4.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.4_1",
      NAME_1: "Chiradzulu",
      NL_NAME_1: "None",
      GID_2: "MWI.4.4_1",
      NAME_2: "TA Likoswe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.4.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.4_1",
      NAME_1: "Chiradzulu",
      NL_NAME_1: "None",
      GID_2: "MWI.4.5_1",
      NAME_2: "TA Mpama",
      isoCode: "MW",
    },
    {
      region_id: "MWI.4.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.4_1",
      NAME_1: "Chiradzulu",
      NL_NAME_1: "None",
      GID_2: "MWI.4.6_1",
      NAME_2: "TA Nchema",
      isoCode: "MW",
    },
    {
      region_id: "MWI.4.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.4_1",
      NAME_1: "Chiradzulu",
      NL_NAME_1: "None",
      GID_2: "MWI.4.7_1",
      NAME_2: "TA Nkalo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.5.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.5_1",
      NAME_1: "Chitipa",
      NL_NAME_1: "None",
      GID_2: "MWI.5.1_1",
      NAME_2: "Chitipa Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.5.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.5_1",
      NAME_1: "Chitipa",
      NL_NAME_1: "None",
      GID_2: "MWI.5.2_1",
      NAME_2: "Nyika N.P. - Chitipa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.5.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.5_1",
      NAME_1: "Chitipa",
      NL_NAME_1: "None",
      GID_2: "MWI.5.3_1",
      NAME_2: "TA Kameme",
      isoCode: "MW",
    },
    {
      region_id: "MWI.5.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.5_1",
      NAME_1: "Chitipa",
      NL_NAME_1: "None",
      GID_2: "MWI.5.4_1",
      NAME_2: "TA Mwabulambya",
      isoCode: "MW",
    },
    {
      region_id: "MWI.5.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.5_1",
      NAME_1: "Chitipa",
      NL_NAME_1: "None",
      GID_2: "MWI.5.5_1",
      NAME_2: "TA Mwenemisuku",
      isoCode: "MW",
    },
    {
      region_id: "MWI.5.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.5_1",
      NAME_1: "Chitipa",
      NL_NAME_1: "None",
      GID_2: "MWI.5.6_1",
      NAME_2: "TA Mwenewenya",
      isoCode: "MW",
    },
    {
      region_id: "MWI.5.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.5_1",
      NAME_1: "Chitipa",
      NL_NAME_1: "None",
      GID_2: "MWI.5.7_1",
      NAME_2: "TA Nthalire",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.1_1",
      NAME_2: "Dedza Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.2_1",
      NAME_2: "Lake Malawi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.3_1",
      NAME_2: "SC Chauma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.4_1",
      NAME_2: "SC Chilikumwendo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.5_1",
      NAME_2: "SC Kamenya Gwaza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.6_1",
      NAME_2: "TA Kachindamoto",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.7_1",
      NAME_2: "TA Kaphuka",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.8_1",
      NAME_2: "TA Kasumbu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.9_1",
      NAME_2: "TA Pemba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.6.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.6_1",
      NAME_1: "Dedza",
      NL_NAME_1: "None",
      GID_2: "MWI.6.10_1",
      NAME_2: "TA Tambala",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.1_1",
      NAME_2: "Dowa Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.2_1",
      NAME_2: "Mponela Urban",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.3_1",
      NAME_2: "SC Chakhaza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.4_1",
      NAME_2: "SC Kayembe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.5_1",
      NAME_2: "SC Mkukula",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.6_1",
      NAME_2: "SC Mponela",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.7_1",
      NAME_2: "TA Chiwere",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.8_1",
      NAME_2: "TA Dzoole",
      isoCode: "MW",
    },
    {
      region_id: "MWI.7.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.7_1",
      NAME_1: "Dowa",
      NL_NAME_1: "None",
      GID_2: "MWI.7.9_1",
      NAME_2: "TA Msakambewa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.8.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.8_1",
      NAME_1: "Karonga",
      NL_NAME_1: "None",
      GID_2: "MWI.8.1_1",
      NAME_2: "Karonga Town",
      isoCode: "MW",
    },
    {
      region_id: "MWI.8.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.8_1",
      NAME_1: "Karonga",
      NL_NAME_1: "None",
      GID_2: "MWI.8.2_1",
      NAME_2: "Lake Malawi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.8.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.8_1",
      NAME_1: "Karonga",
      NL_NAME_1: "None",
      GID_2: "MWI.8.3_1",
      NAME_2: "Nyipa N. P. - Karonga",
      isoCode: "MW",
    },
    {
      region_id: "MWI.8.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.8_1",
      NAME_1: "Karonga",
      NL_NAME_1: "None",
      GID_2: "MWI.8.4_1",
      NAME_2: "SC Mwakaboko",
      isoCode: "MW",
    },
    {
      region_id: "MWI.8.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.8_1",
      NAME_1: "Karonga",
      NL_NAME_1: "None",
      GID_2: "MWI.8.5_1",
      NAME_2: "SC Mwirang'ombe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.8.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.8_1",
      NAME_1: "Karonga",
      NL_NAME_1: "None",
      GID_2: "MWI.8.6_1",
      NAME_2: "TA Kilupula",
      isoCode: "MW",
    },
    {
      region_id: "MWI.8.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.8_1",
      NAME_1: "Karonga",
      NL_NAME_1: "None",
      GID_2: "MWI.8.7_1",
      NAME_2: "TA Kyungu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.8.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.8_1",
      NAME_1: "Karonga",
      NL_NAME_1: "None",
      GID_2: "MWI.8.8_1",
      NAME_2: "TA Wasambo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.1_1",
      NAME_2: "Kasungu Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.2_1",
      NAME_2: "Kasungu National Park",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.3_1",
      NAME_2: "SC Chilowamatambe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.4_1",
      NAME_2: "SC Chisikwa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.5_1",
      NAME_2: "SC Kawamba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.6_1",
      NAME_2: "SC Lukwa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.7_1",
      NAME_2: "SC M'nyanja",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.8_1",
      NAME_2: "SC Njombwa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.9_1",
      NAME_2: "SC Simlemba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.10_1",
      NAME_2: "TA Chulu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.11_1",
      NAME_2: "TA Kaluluma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.12_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.12_1",
      NAME_2: "TA Kaomba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.13_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.13_1",
      NAME_2: "TA Kapelula",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.14_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.14_1",
      NAME_2: "TA Mwase",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.15_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.15_1",
      NAME_2: "TA Santhe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.9.16_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.9_1",
      NAME_1: "Kasungu",
      NL_NAME_1: "None",
      GID_2: "MWI.9.16_1",
      NAME_2: "TA Wimbe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.10.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.10_1",
      NAME_1: "Likoma",
      NL_NAME_1: "None",
      GID_2: "MWI.10.1_1",
      NAME_2: "Lake Malawi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.1_1",
      NAME_2: "Lilongwe City",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.2_1",
      NAME_2: "SC Chitekwele",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.3_1",
      NAME_2: "SC Mtema",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.4_1",
      NAME_2: "SC Njewa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.5_1",
      NAME_2: "SC Tsabango",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.6_1",
      NAME_2: "TA Chadza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.7_1",
      NAME_2: "TA Chimutu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.8_1",
      NAME_2: "TA Chiseka",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.9_1",
      NAME_2: "TA Chitukula",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.10_1",
      NAME_2: "TA Kabudula",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.11_1",
      NAME_2: "TA Kalolo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.12_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.12_1",
      NAME_2: "TA Kalumba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.13_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.13_1",
      NAME_2: "TA Kalumbu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.14_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.14_1",
      NAME_2: "TA Khongoni",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.15_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.15_1",
      NAME_2: "TA Malili",
      isoCode: "MW",
    },
    {
      region_id: "MWI.11.16_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.11_1",
      NAME_1: "Lilongwe",
      NL_NAME_1: "None",
      GID_2: "MWI.11.16_1",
      NAME_2: "TA Mazengera",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.1_1",
      NAME_2: "Lake Chilwa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.2_1",
      NAME_2: "Lake Chiuta",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.3_1",
      NAME_2: "Liwonda National Park",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.4_1",
      NAME_2: "Liwonde Town",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.5_1",
      NAME_2: "SC Chamba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.6_1",
      NAME_2: "SC Chikweo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.7_1",
      NAME_2: "SC Chiwalo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.8_1",
      NAME_2: "SC Mlomba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.9_1",
      NAME_2: "SC Mposa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.10_1",
      NAME_2: "SC Ngokwe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.11_1",
      NAME_2: "SC Sitola",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.12_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.12_1",
      NAME_2: "TA Kawinga",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.13_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.13_1",
      NAME_2: "TA Liwonde",
      isoCode: "MW",
    },
    {
      region_id: "MWI.12.14_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.12_1",
      NAME_1: "Machinga",
      NL_NAME_1: "None",
      GID_2: "MWI.12.14_1",
      NAME_2: "TA Nyambi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.1_1",
      NAME_2: "Lake Malawi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.2_1",
      NAME_2: "Lake Malombe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.3_1",
      NAME_2: "Mangochi Town",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.4_1",
      NAME_2: "Monkey Bay Urban",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.5_1",
      NAME_2: "SC Chowe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.6_1",
      NAME_2: "SC Mbwana Nyambi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.7_1",
      NAME_2: "SC Namabvi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.8_1",
      NAME_2: "TA Chimwala",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.9_1",
      NAME_2: "TA Jalasi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.10_1",
      NAME_2: "TA Katuli",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.11_1",
      NAME_2: "TA Makanjila",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.12_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.12_1",
      NAME_2: "TA Mponda",
      isoCode: "MW",
    },
    {
      region_id: "MWI.13.13_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.13_1",
      NAME_1: "Mangochi",
      NL_NAME_1: "None",
      GID_2: "MWI.13.13_1",
      NAME_2: "TA Nankumba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.14.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.14_1",
      NAME_1: "Mchinji",
      NL_NAME_1: "None",
      GID_2: "MWI.14.1_1",
      NAME_2: "Mchinji Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.14.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.14_1",
      NAME_1: "Mchinji",
      NL_NAME_1: "None",
      GID_2: "MWI.14.2_1",
      NAME_2: "SC Dambe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.14.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.14_1",
      NAME_1: "Mchinji",
      NL_NAME_1: "None",
      GID_2: "MWI.14.3_1",
      NAME_2: "SC Mavwere",
      isoCode: "MW",
    },
    {
      region_id: "MWI.14.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.14_1",
      NAME_1: "Mchinji",
      NL_NAME_1: "None",
      GID_2: "MWI.14.4_1",
      NAME_2: "SC Mduwa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.14.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.14_1",
      NAME_1: "Mchinji",
      NL_NAME_1: "None",
      GID_2: "MWI.14.5_1",
      NAME_2: "TA Mkanda",
      isoCode: "MW",
    },
    {
      region_id: "MWI.14.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.14_1",
      NAME_1: "Mchinji",
      NL_NAME_1: "None",
      GID_2: "MWI.14.6_1",
      NAME_2: "TA Mlonyeni",
      isoCode: "MW",
    },
    {
      region_id: "MWI.14.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.14_1",
      NAME_1: "Mchinji",
      NL_NAME_1: "None",
      GID_2: "MWI.14.7_1",
      NAME_2: "TA Zulu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.15.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.15_1",
      NAME_1: "Mulanje",
      NL_NAME_1: "None",
      GID_2: "MWI.15.1_1",
      NAME_2: "Mulanje Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.15.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.15_1",
      NAME_1: "Mulanje",
      NL_NAME_1: "None",
      GID_2: "MWI.15.2_1",
      NAME_2: "Mulanje Mountain Forest Reserve",
      isoCode: "MW",
    },
    {
      region_id: "MWI.15.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.15_1",
      NAME_1: "Mulanje",
      NL_NAME_1: "None",
      GID_2: "MWI.15.3_1",
      NAME_2: "SC Juma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.15.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.15_1",
      NAME_1: "Mulanje",
      NL_NAME_1: "None",
      GID_2: "MWI.15.4_1",
      NAME_2: "SC Laston Njema",
      isoCode: "MW",
    },
    {
      region_id: "MWI.15.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.15_1",
      NAME_1: "Mulanje",
      NL_NAME_1: "None",
      GID_2: "MWI.15.5_1",
      NAME_2: "TA Chikumbu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.15.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.15_1",
      NAME_1: "Mulanje",
      NL_NAME_1: "None",
      GID_2: "MWI.15.6_1",
      NAME_2: "TA Mabuka",
      isoCode: "MW",
    },
    {
      region_id: "MWI.15.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.15_1",
      NAME_1: "Mulanje",
      NL_NAME_1: "None",
      GID_2: "MWI.15.7_1",
      NAME_2: "TA Nkanda",
      isoCode: "MW",
    },
    {
      region_id: "MWI.15.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.15_1",
      NAME_1: "Mulanje",
      NL_NAME_1: "None",
      GID_2: "MWI.15.8_1",
      NAME_2: "TA Nthiramanja",
      isoCode: "MW",
    },
    {
      region_id: "MWI.16.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "MWI.16.1_1",
      NAME_2: "Majete Game Reserve - Mwanza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.16.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "MWI.16.2_1",
      NAME_2: "Mwanza Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.16.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "MWI.16.3_1",
      NAME_2: "TA Kanduku",
      isoCode: "MW",
    },
    {
      region_id: "MWI.16.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "MWI.16.4_1",
      NAME_2: "TA Nthache",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.1_1",
      NAME_2: "Mzimba Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.2_1",
      NAME_2: "Mzuzu City",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.3_1",
      NAME_2: "SC Jaravikuba Munthali",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.4_1",
      NAME_2: "SC Kampingo Sibande",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.5_1",
      NAME_2: "SC Khosolo Gwaza Jere",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.6_1",
      NAME_2: "TA Chindi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.7_1",
      NAME_2: "TA M'Mbelwa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.8_1",
      NAME_2: "TA Mabulabo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.9_1",
      NAME_2: "TA Mpherembe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.10_1",
      NAME_2: "TA Mtwalo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.11_1",
      NAME_2: "TA Mzikubola",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.12_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.12_1",
      NAME_2: "TA Mzukuzuku",
      isoCode: "MW",
    },
    {
      region_id: "MWI.17.13_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.17_1",
      NAME_1: "Mzimba",
      NL_NAME_1: "None",
      GID_2: "MWI.17.13_1",
      NAME_2: "Vwaza Marsh Game Reserve - Mzimba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.18.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.18_1",
      NAME_1: "Neno",
      NL_NAME_1: "None",
      GID_2: "MWI.18.1_1",
      NAME_2: "Majete Game Reserve - Neno",
      isoCode: "MW",
    },
    {
      region_id: "MWI.18.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.18_1",
      NAME_1: "Neno",
      NL_NAME_1: "None",
      GID_2: "MWI.18.2_1",
      NAME_2: "TA Dambe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.18.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.18_1",
      NAME_1: "Neno",
      NL_NAME_1: "None",
      GID_2: "MWI.18.3_1",
      NAME_2: "TA Mlauli",
      isoCode: "MW",
    },
    {
      region_id: "MWI.18.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.18_1",
      NAME_1: "Neno",
      NL_NAME_1: "None",
      GID_2: "MWI.18.4_1",
      NAME_2: "TA Ngozi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.18.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.18_1",
      NAME_1: "Neno",
      NL_NAME_1: "None",
      GID_2: "MWI.18.5_1",
      NAME_2: "TA Symon",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.1_1",
      NAME_2: "Lake Malawi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.2_1",
      NAME_2: "SC Fukamalaza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.3_1",
      NAME_2: "SC Malanda",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.4_1",
      NAME_2: "SC Nyaluwanga",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.5_1",
      NAME_2: "SC Zilakoma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.6_1",
      NAME_2: "TA Fukamapiri",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.7_1",
      NAME_2: "TA Kabunduli",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.8_1",
      NAME_2: "TA Malenga Mzoma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.9_1",
      NAME_2: "TA Musisya",
      isoCode: "MW",
    },
    {
      region_id: "MWI.19.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.19_1",
      NAME_1: "Nkhata Bay",
      NL_NAME_1: "None",
      GID_2: "MWI.19.10_1",
      NAME_2: "TA Timbiri",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.1_1",
      NAME_2: "Lake Malawi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.2_1",
      NAME_2: "Nkhotakota Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.3_1",
      NAME_2: "Nkhotakota Game Reserve",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.4_1",
      NAME_2: "SC Kafuzila",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.5_1",
      NAME_2: "SC Mphonde",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.6_1",
      NAME_2: "SC Mwansambo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.7_1",
      NAME_2: "TA Kanyenda",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.8_1",
      NAME_2: "TA Malenga Chanzi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.20.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.20_1",
      NAME_1: "Nkhotakota",
      NL_NAME_1: "None",
      GID_2: "MWI.20.9_1",
      NAME_2: "TA Mwadzama",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.1_1",
      NAME_2: "Mwabvi Game Reserve",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.2_1",
      NAME_2: "Nsanje Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.3_1",
      NAME_2: "SC Makoka",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.4_1",
      NAME_2: "SC Mbenje",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.5_1",
      NAME_2: "TA Chimombo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.6_1",
      NAME_2: "TA Malemia",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.7_1",
      NAME_2: "TA Mlolo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.8_1",
      NAME_2: "TA Ndamera",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.9_1",
      NAME_2: "TA Ngabu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.10_1",
      NAME_2: "TA Nyachikadza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.21.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.21_1",
      NAME_1: "Nsanje",
      NL_NAME_1: "None",
      GID_2: "MWI.21.11_1",
      NAME_2: "TA Tengani",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.1_1",
      NAME_2: "Ntcheu Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.2_1",
      NAME_2: "SC Champiti",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.3_1",
      NAME_2: "SC Goodson Ganya",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.4_1",
      NAME_2: "SC Makwangwala",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.5_1",
      NAME_2: "TA Chakhumbira",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.6_1",
      NAME_2: "TA Kwataine",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.7_1",
      NAME_2: "TA Masasa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.8_1",
      NAME_2: "TA Mpando",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.9_1",
      NAME_2: "TA Njolomole",
      isoCode: "MW",
    },
    {
      region_id: "MWI.22.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.22_1",
      NAME_1: "Ntcheu",
      NL_NAME_1: "None",
      GID_2: "MWI.22.10_1",
      NAME_2: "TA Phambala",
      isoCode: "MW",
    },
    {
      region_id: "MWI.23.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.23_1",
      NAME_1: "Ntchisi",
      NL_NAME_1: "None",
      GID_2: "MWI.23.1_1",
      NAME_2: "Ntchisi Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.23.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.23_1",
      NAME_1: "Ntchisi",
      NL_NAME_1: "None",
      GID_2: "MWI.23.2_1",
      NAME_2: "SC Chilooko",
      isoCode: "MW",
    },
    {
      region_id: "MWI.23.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.23_1",
      NAME_1: "Ntchisi",
      NL_NAME_1: "None",
      GID_2: "MWI.23.3_1",
      NAME_2: "SC Nthondo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.23.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.23_1",
      NAME_1: "Ntchisi",
      NL_NAME_1: "None",
      GID_2: "MWI.23.4_1",
      NAME_2: "TA Chikho",
      isoCode: "MW",
    },
    {
      region_id: "MWI.23.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.23_1",
      NAME_1: "Ntchisi",
      NL_NAME_1: "None",
      GID_2: "MWI.23.5_1",
      NAME_2: "TA Kalumo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.23.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.23_1",
      NAME_1: "Ntchisi",
      NL_NAME_1: "None",
      GID_2: "MWI.23.6_1",
      NAME_2: "TA Kasakula",
      isoCode: "MW",
    },
    {
      region_id: "MWI.23.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.23_1",
      NAME_1: "Ntchisi",
      NL_NAME_1: "None",
      GID_2: "MWI.23.7_1",
      NAME_2: "TA Kasukula",
      isoCode: "MW",
    },
    {
      region_id: "MWI.24.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.24_1",
      NAME_1: "Phalombe",
      NL_NAME_1: "None",
      GID_2: "MWI.24.1_1",
      NAME_2: "Lake Chilwa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.24.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.24_1",
      NAME_1: "Phalombe",
      NL_NAME_1: "None",
      GID_2: "MWI.24.2_1",
      NAME_2: "TA Mkhumba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.24.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.24_1",
      NAME_1: "Phalombe",
      NL_NAME_1: "None",
      GID_2: "MWI.24.3_1",
      NAME_2: "TA Nazombe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.1_1",
      NAME_2: "Lake Malawi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.2_1",
      NAME_2: "Nyika National Park - Rumphi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.3_1",
      NAME_2: "Rumphi Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.4_1",
      NAME_2: "SC Chapinduka",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.5_1",
      NAME_2: "SC Kachulu",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.6_1",
      NAME_2: "SC Mwahenga",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.7_1",
      NAME_2: "SC Mwalweni",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.8_1",
      NAME_2: "SC Mwankhunikira",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.9_1",
      NAME_2: "TA Chikulamayembe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.10_1",
      NAME_2: "TA Katumbi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.11_1",
      NAME_2: "TA Mwamlowe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.25.12_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.25_1",
      NAME_1: "Rumphi",
      NL_NAME_1: "None",
      GID_2: "MWI.25.12_1",
      NAME_2: "Vwaza Marsh Game Reserve - Rumphi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.1_1",
      NAME_2: "Lake Malawi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.2_1",
      NAME_2: "Salima Town",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.3_1",
      NAME_2: "SC Kambalame",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.4_1",
      NAME_2: "SC Kambwiri",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.5_1",
      NAME_2: "SC Mwanza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.6_1",
      NAME_2: "TA Karonga",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.7_1",
      NAME_2: "TA Khombedza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.8_1",
      NAME_2: "TA Kuluunda",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.9_1",
      NAME_2: "TA Maganga",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.10_1",
      NAME_2: "TA Ndindi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.26.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.26_1",
      NAME_1: "Salima",
      NL_NAME_1: "None",
      GID_2: "MWI.26.11_1",
      NAME_2: "TA Pemba",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.1_1",
      NAME_2: "Luchenza Town",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.2_1",
      NAME_2: "SC Kwethemule",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.3_1",
      NAME_2: "SC Mbawela",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.4_1",
      NAME_2: "SC Mphuka",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.5_1",
      NAME_2: "SC Thukuta",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.6_1",
      NAME_2: "TA Bvumbwe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.7_1",
      NAME_2: "TA Changata",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.8_1",
      NAME_2: "TA Chimaliro",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.9_1",
      NAME_2: "TA Kapichi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.10_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.10_1",
      NAME_2: "TA Nchilamwela",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.11_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.11_1",
      NAME_2: "TA Nsabwe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.12_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.12_1",
      NAME_2: "TA Thomas",
      isoCode: "MW",
    },
    {
      region_id: "MWI.27.13_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.27_1",
      NAME_1: "Thyolo",
      NL_NAME_1: "None",
      GID_2: "MWI.27.13_1",
      NAME_2: "Thyolo Boma",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.1_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.1_1",
      NAME_2: "Lake Chilwa",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.2_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.2_1",
      NAME_2: "SC Mbiza",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.3_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.3_1",
      NAME_2: "SC Mkumbira",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.4_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.4_1",
      NAME_2: "TA Chikowi",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.5_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.5_1",
      NAME_2: "TA Kuntumanji",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.6_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.6_1",
      NAME_2: "TA Malemia",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.7_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.7_1",
      NAME_2: "TA Mlumbe",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.8_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.8_1",
      NAME_2: "TA Mwambo",
      isoCode: "MW",
    },
    {
      region_id: "MWI.28.9_1",
      GID_0: "MWI",
      NAME_0: "Malawi",
      GID_1: "MWI.28_1",
      NAME_1: "Zomba",
      NL_NAME_1: "None",
      GID_2: "MWI.28.9_1",
      NAME_2: "Zomba City",
      isoCode: "MW",
    },
    {
      region_id: "NAM.1.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.1_1",
      NAME_1: "!Karas",
      NL_NAME_1: "None",
      GID_2: "NAM.1.1_1",
      NAME_2: "Berseba",
      isoCode: "NA",
    },
    {
      region_id: "NAM.1.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.1_1",
      NAME_1: "!Karas",
      NL_NAME_1: "None",
      GID_2: "NAM.1.2_1",
      NAME_2: "Karas",
      isoCode: "NA",
    },
    {
      region_id: "NAM.1.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.1_1",
      NAME_1: "!Karas",
      NL_NAME_1: "None",
      GID_2: "NAM.1.3_1",
      NAME_2: "Keetmanshoop Rural",
      isoCode: "NA",
    },
    {
      region_id: "NAM.1.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.1_1",
      NAME_1: "!Karas",
      NL_NAME_1: "None",
      GID_2: "NAM.1.4_1",
      NAME_2: "Keetmanshoop Urban",
      isoCode: "NA",
    },
    {
      region_id: "NAM.1.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.1_1",
      NAME_1: "!Karas",
      NL_NAME_1: "None",
      GID_2: "NAM.1.5_1",
      NAME_2: "Luderitz",
      isoCode: "NA",
    },
    {
      region_id: "NAM.1.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.1_1",
      NAME_1: "!Karas",
      NL_NAME_1: "None",
      GID_2: "NAM.1.6_1",
      NAME_2: "Oranjemund",
      isoCode: "NA",
    },
    {
      region_id: "NAM.2.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.2_1",
      NAME_1: "Erongo",
      NL_NAME_1: "None",
      GID_2: "NAM.2.1_1",
      NAME_2: "Arandis",
      isoCode: "NA",
    },
    {
      region_id: "NAM.2.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.2_1",
      NAME_1: "Erongo",
      NL_NAME_1: "None",
      GID_2: "NAM.2.2_1",
      NAME_2: "Daures",
      isoCode: "NA",
    },
    {
      region_id: "NAM.2.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.2_1",
      NAME_1: "Erongo",
      NL_NAME_1: "None",
      GID_2: "NAM.2.3_1",
      NAME_2: "Karibib",
      isoCode: "NA",
    },
    {
      region_id: "NAM.2.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.2_1",
      NAME_1: "Erongo",
      NL_NAME_1: "None",
      GID_2: "NAM.2.4_1",
      NAME_2: "Omaruru",
      isoCode: "NA",
    },
    {
      region_id: "NAM.2.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.2_1",
      NAME_1: "Erongo",
      NL_NAME_1: "None",
      GID_2: "NAM.2.5_1",
      NAME_2: "Swakopmund",
      isoCode: "NA",
    },
    {
      region_id: "NAM.2.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.2_1",
      NAME_1: "Erongo",
      NL_NAME_1: "None",
      GID_2: "NAM.2.6_1",
      NAME_2: "Walvisbay Rural",
      isoCode: "NA",
    },
    {
      region_id: "NAM.2.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.2_1",
      NAME_1: "Erongo",
      NL_NAME_1: "None",
      GID_2: "NAM.2.7_1",
      NAME_2: "Walvisbay Urban",
      isoCode: "NA",
    },
    {
      region_id: "NAM.3.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.3_1",
      NAME_1: "Hardap",
      NL_NAME_1: "None",
      GID_2: "NAM.3.1_1",
      NAME_2: "Gibeon",
      isoCode: "NA",
    },
    {
      region_id: "NAM.3.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.3_1",
      NAME_1: "Hardap",
      NL_NAME_1: "None",
      GID_2: "NAM.3.2_1",
      NAME_2: "Mariental Rural",
      isoCode: "NA",
    },
    {
      region_id: "NAM.3.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.3_1",
      NAME_1: "Hardap",
      NL_NAME_1: "None",
      GID_2: "NAM.3.3_1",
      NAME_2: "Mariental Urban",
      isoCode: "NA",
    },
    {
      region_id: "NAM.3.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.3_1",
      NAME_1: "Hardap",
      NL_NAME_1: "None",
      GID_2: "NAM.3.4_1",
      NAME_2: "Rehoboth East",
      isoCode: "NA",
    },
    {
      region_id: "NAM.3.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.3_1",
      NAME_1: "Hardap",
      NL_NAME_1: "None",
      GID_2: "NAM.3.5_1",
      NAME_2: "Rehoboth Rural",
      isoCode: "NA",
    },
    {
      region_id: "NAM.3.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.3_1",
      NAME_1: "Hardap",
      NL_NAME_1: "None",
      GID_2: "NAM.3.6_1",
      NAME_2: "Rehoboth West",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.1_1",
      NAME_2: "Kahenge",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.2_1",
      NAME_2: "Kapako",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.3_1",
      NAME_2: "Mashare",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.4_1",
      NAME_2: "Mpungu",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.5_1",
      NAME_2: "Mukwe",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.6_1",
      NAME_2: "Ndiyona",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.7_1",
      NAME_2: "Rundu Rural East",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.8_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.8_1",
      NAME_2: "Rundu Rural West",
      isoCode: "NA",
    },
    {
      region_id: "NAM.4.9_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.4_1",
      NAME_1: "Kavango",
      NL_NAME_1: "None",
      GID_2: "NAM.4.9_1",
      NAME_2: "Rundu Urban",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.1_1",
      NAME_2: "Katutura Central",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.2_1",
      NAME_2: "Katutura East",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.3_1",
      NAME_2: "Khomasdal North",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.4_1",
      NAME_2: "Moses Garoeb",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.5_1",
      NAME_2: "Soweto",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.6_1",
      NAME_2: "Tobias Hainyeko",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.7_1",
      NAME_2: "Wanaheda",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.8_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.8_1",
      NAME_2: "Windhoek East",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.9_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.9_1",
      NAME_2: "Windhoek Rural",
      isoCode: "NA",
    },
    {
      region_id: "NAM.5.10_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.5_1",
      NAME_1: "Khomas",
      NL_NAME_1: "None",
      GID_2: "NAM.5.10_1",
      NAME_2: "Windhoek West",
      isoCode: "NA",
    },
    {
      region_id: "NAM.6.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.6_1",
      NAME_1: "Kunene",
      NL_NAME_1: "None",
      GID_2: "NAM.6.1_1",
      NAME_2: "Epupa",
      isoCode: "NA",
    },
    {
      region_id: "NAM.6.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.6_1",
      NAME_1: "Kunene",
      NL_NAME_1: "None",
      GID_2: "NAM.6.2_1",
      NAME_2: "Kamanjab",
      isoCode: "NA",
    },
    {
      region_id: "NAM.6.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.6_1",
      NAME_1: "Kunene",
      NL_NAME_1: "None",
      GID_2: "NAM.6.3_1",
      NAME_2: "Khorixas",
      isoCode: "NA",
    },
    {
      region_id: "NAM.6.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.6_1",
      NAME_1: "Kunene",
      NL_NAME_1: "None",
      GID_2: "NAM.6.4_1",
      NAME_2: "Opuwo",
      isoCode: "NA",
    },
    {
      region_id: "NAM.6.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.6_1",
      NAME_1: "Kunene",
      NL_NAME_1: "None",
      GID_2: "NAM.6.5_1",
      NAME_2: "Outjo",
      isoCode: "NA",
    },
    {
      region_id: "NAM.6.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.6_1",
      NAME_1: "Kunene",
      NL_NAME_1: "None",
      GID_2: "NAM.6.6_1",
      NAME_2: "Sesfontein",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.1_1",
      NAME_2: "Eenhana",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.2_1",
      NAME_2: "Endola",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.3_1",
      NAME_2: "Engela",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.4_1",
      NAME_2: "Epembe",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.5_1",
      NAME_2: "Ohangwena",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.6_1",
      NAME_2: "Okongo",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.7_1",
      NAME_2: "Omulonga",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.8_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.8_1",
      NAME_2: "Omundaungilo",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.9_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.9_1",
      NAME_2: "Ondobe",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.10_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.10_1",
      NAME_2: "Ongenga",
      isoCode: "NA",
    },
    {
      region_id: "NAM.7.11_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.7_1",
      NAME_1: "Ohangwena",
      NL_NAME_1: "None",
      GID_2: "NAM.7.11_1",
      NAME_2: "Oshikango",
      isoCode: "NA",
    },
    {
      region_id: "NAM.8.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.8_1",
      NAME_1: "Omaheke",
      NL_NAME_1: "None",
      GID_2: "NAM.8.1_1",
      NAME_2: "Aminius",
      isoCode: "NA",
    },
    {
      region_id: "NAM.8.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.8_1",
      NAME_1: "Omaheke",
      NL_NAME_1: "None",
      GID_2: "NAM.8.2_1",
      NAME_2: "Epukiro",
      isoCode: "NA",
    },
    {
      region_id: "NAM.8.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.8_1",
      NAME_1: "Omaheke",
      NL_NAME_1: "None",
      GID_2: "NAM.8.3_1",
      NAME_2: "Gobabis",
      isoCode: "NA",
    },
    {
      region_id: "NAM.8.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.8_1",
      NAME_1: "Omaheke",
      NL_NAME_1: "None",
      GID_2: "NAM.8.4_1",
      NAME_2: "Kalahari",
      isoCode: "NA",
    },
    {
      region_id: "NAM.8.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.8_1",
      NAME_1: "Omaheke",
      NL_NAME_1: "None",
      GID_2: "NAM.8.5_1",
      NAME_2: "Otjinene",
      isoCode: "NA",
    },
    {
      region_id: "NAM.8.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.8_1",
      NAME_1: "Omaheke",
      NL_NAME_1: "None",
      GID_2: "NAM.8.6_1",
      NAME_2: "Otjombinde",
      isoCode: "NA",
    },
    {
      region_id: "NAM.8.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.8_1",
      NAME_1: "Omaheke",
      NL_NAME_1: "None",
      GID_2: "NAM.8.7_1",
      NAME_2: "Steinhausen",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.1_1",
      NAME_2: "Anamulenge",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.2_1",
      NAME_2: "Elim",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.3_1",
      NAME_2: "Etayi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.4_1",
      NAME_2: "Ogongo",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.5_1",
      NAME_2: "Okahao",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.6_1",
      NAME_2: "Okalongo",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.7_1",
      NAME_2: "Onesi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.8_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.8_1",
      NAME_2: "Oshikuku",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.9_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.9_1",
      NAME_2: "Otamanzi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.10_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.10_1",
      NAME_2: "Outapi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.11_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.11_1",
      NAME_2: "Ruacana",
      isoCode: "NA",
    },
    {
      region_id: "NAM.9.12_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.9_1",
      NAME_1: "Omusati",
      NL_NAME_1: "None",
      GID_2: "NAM.9.12_1",
      NAME_2: "Tsandi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.1_1",
      NAME_2: "Okaku",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.2_1",
      NAME_2: "Okatana",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.3_1",
      NAME_2: "Okatyali",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.4_1",
      NAME_2: "Ompundja",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.5_1",
      NAME_2: "Ondangwa",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.6_1",
      NAME_2: "Ongwediva",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.7_1",
      NAME_2: "Oshakati East",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.8_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.8_1",
      NAME_2: "Oshakati West",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.9_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.9_1",
      NAME_2: "Uukwiyu",
      isoCode: "NA",
    },
    {
      region_id: "NAM.10.10_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.10_1",
      NAME_1: "Oshana",
      NL_NAME_1: "None",
      GID_2: "NAM.10.10_1",
      NAME_2: "Uuvudhiya",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.1_1",
      NAME_2: "Engodi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.2_1",
      NAME_2: "Guinas",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.3_1",
      NAME_2: "Okankolo",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.4_1",
      NAME_2: "Olukonda",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.5_1",
      NAME_2: "Omuntele",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.6_1",
      NAME_2: "Omuthiyagwipundi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.7_1",
      NAME_2: "Onayena",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.8_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.8_1",
      NAME_2: "Oniipa",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.9_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.9_1",
      NAME_2: "Onyaanya",
      isoCode: "NA",
    },
    {
      region_id: "NAM.11.10_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.11_1",
      NAME_1: "Oshikoto",
      NL_NAME_1: "None",
      GID_2: "NAM.11.10_1",
      NAME_2: "Tsumeb",
      isoCode: "NA",
    },
    {
      region_id: "NAM.12.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.12_1",
      NAME_1: "Otjozondjupa",
      NL_NAME_1: "None",
      GID_2: "NAM.12.1_1",
      NAME_2: "Grootfontein",
      isoCode: "NA",
    },
    {
      region_id: "NAM.12.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.12_1",
      NAME_1: "Otjozondjupa",
      NL_NAME_1: "None",
      GID_2: "NAM.12.2_1",
      NAME_2: "Okahandja",
      isoCode: "NA",
    },
    {
      region_id: "NAM.12.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.12_1",
      NAME_1: "Otjozondjupa",
      NL_NAME_1: "None",
      GID_2: "NAM.12.3_1",
      NAME_2: "Okakarara",
      isoCode: "NA",
    },
    {
      region_id: "NAM.12.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.12_1",
      NAME_1: "Otjozondjupa",
      NL_NAME_1: "None",
      GID_2: "NAM.12.4_1",
      NAME_2: "Omatako",
      isoCode: "NA",
    },
    {
      region_id: "NAM.12.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.12_1",
      NAME_1: "Otjozondjupa",
      NL_NAME_1: "None",
      GID_2: "NAM.12.5_1",
      NAME_2: "Otavi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.12.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.12_1",
      NAME_1: "Otjozondjupa",
      NL_NAME_1: "None",
      GID_2: "NAM.12.6_1",
      NAME_2: "Otjiwarongo",
      isoCode: "NA",
    },
    {
      region_id: "NAM.12.7_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.12_1",
      NAME_1: "Otjozondjupa",
      NL_NAME_1: "None",
      GID_2: "NAM.12.7_1",
      NAME_2: "Tsumkwe",
      isoCode: "NA",
    },
    {
      region_id: "NAM.13.1_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.13_1",
      NAME_1: "Zambezi",
      NL_NAME_1: "None",
      GID_2: "NAM.13.1_1",
      NAME_2: "Kabe",
      isoCode: "NA",
    },
    {
      region_id: "NAM.13.2_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.13_1",
      NAME_1: "Zambezi",
      NL_NAME_1: "None",
      GID_2: "NAM.13.2_1",
      NAME_2: "Katima Muliro Rural",
      isoCode: "NA",
    },
    {
      region_id: "NAM.13.3_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.13_1",
      NAME_1: "Zambezi",
      NL_NAME_1: "None",
      GID_2: "NAM.13.3_1",
      NAME_2: "Katima Muliro Urban",
      isoCode: "NA",
    },
    {
      region_id: "NAM.13.4_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.13_1",
      NAME_1: "Zambezi",
      NL_NAME_1: "None",
      GID_2: "NAM.13.4_1",
      NAME_2: "Kongola",
      isoCode: "NA",
    },
    {
      region_id: "NAM.13.5_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.13_1",
      NAME_1: "Zambezi",
      NL_NAME_1: "None",
      GID_2: "NAM.13.5_1",
      NAME_2: "Linyandi",
      isoCode: "NA",
    },
    {
      region_id: "NAM.13.6_1",
      GID_0: "NAM",
      NAME_0: "Namibia",
      GID_1: "NAM.13_1",
      NAME_1: "Zambezi",
      NL_NAME_1: "None",
      GID_2: "NAM.13.6_1",
      NAME_2: "Sibinda",
      isoCode: "NA",
    },
    {
      region_id: "SYC.1_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.1_1",
      NAME_1: "Anse aux Pins",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.2_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.2_1",
      NAME_1: "Anse Boileau",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.3_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.3_1",
      NAME_1: "Anse Étoile",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.4_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.4_1",
      NAME_1: "Anse Royale",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.5_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.5_1",
      NAME_1: "Au Cap",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.6_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.6_1",
      NAME_1: "Baie Lazare",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.7_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.7_1",
      NAME_1: "Baie Sainte Anne",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.8_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.8_1",
      NAME_1: "Beau Vallon",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.9_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.9_1",
      NAME_1: "Bel Air",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.10_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.10_1",
      NAME_1: "Belombre",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.11_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.11_1",
      NAME_1: "Cascade",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.12_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.12_1",
      NAME_1: "English River",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.13_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.13_1",
      NAME_1: "Glacis",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.14_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.14_1",
      NAME_1: "Grand' Anse",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.15_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.15_1",
      NAME_1: "Grand'Anse Praslin",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.16_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.16_1",
      NAME_1: "La Digue and Inner Islands",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.17_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.17_1",
      NAME_1: "Les Mamelles",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.18_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.18_1",
      NAME_1: "Mont Buxton",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.19_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.19_1",
      NAME_1: "Mont Fleuri",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.20_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.20_1",
      NAME_1: "Outer Islands",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.21_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.21_1",
      NAME_1: "Plaisance",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.22_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.22_1",
      NAME_1: "Pointe La Rue",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.23_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.23_1",
      NAME_1: "Port Glaud",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.24_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.24_1",
      NAME_1: "Roche Caïman",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.25_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.25_1",
      NAME_1: "Saint Louis",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "SYC.26_1",
      GID_0: "SYC",
      NAME_0: "Seychelles",
      GID_1: "SYC.26_1",
      NAME_1: "Takamaka",
      NL_NAME_1: "None",
      GID_2: "nan",
      NAME_2: "nan",
      isoCode: "SC",
    },
    {
      region_id: "TZA.1.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.1_1",
      NAME_2: "Arusha Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.1.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.2_1",
      NAME_2: "Arusha",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.1.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.3_1",
      NAME_2: "Karatu",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.1.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.4_1",
      NAME_2: "Lake Eyasi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.1.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.5_1",
      NAME_2: "Lake Manyara",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.1.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.6_1",
      NAME_2: "Longido",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.1.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.7_1",
      NAME_2: "Meru",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.1.8_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.8_1",
      NAME_2: "Monduli",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.1.9_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.1_1",
      NAME_1: "Arusha",
      NL_NAME_1: "None",
      GID_2: "TZA.1.9_1",
      NAME_2: "Ngorongoro",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.2.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.2_1",
      NAME_1: "Dar es Salaam",
      NL_NAME_1: "None",
      GID_2: "TZA.2.1_1",
      NAME_2: "Ilala",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.2.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.2_1",
      NAME_1: "Dar es Salaam",
      NL_NAME_1: "None",
      GID_2: "TZA.2.2_1",
      NAME_2: "Kinondoni",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.2.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.2_1",
      NAME_1: "Dar es Salaam",
      NL_NAME_1: "None",
      GID_2: "TZA.2.3_1",
      NAME_2: "Temeke",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.3.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.3_1",
      NAME_1: "Dodoma",
      NL_NAME_1: "None",
      GID_2: "TZA.3.1_1",
      NAME_2: "Bahi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.3.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.3_1",
      NAME_1: "Dodoma",
      NL_NAME_1: "None",
      GID_2: "TZA.3.2_1",
      NAME_2: "Chamwino",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.3.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.3_1",
      NAME_1: "Dodoma",
      NL_NAME_1: "None",
      GID_2: "TZA.3.3_1",
      NAME_2: "Chemba",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.3.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.3_1",
      NAME_1: "Dodoma",
      NL_NAME_1: "None",
      GID_2: "TZA.3.4_1",
      NAME_2: "Dodoma Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.3.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.3_1",
      NAME_1: "Dodoma",
      NL_NAME_1: "None",
      GID_2: "TZA.3.5_1",
      NAME_2: "Kondoa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.3.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.3_1",
      NAME_1: "Dodoma",
      NL_NAME_1: "None",
      GID_2: "TZA.3.6_1",
      NAME_2: "Kongwa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.3.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.3_1",
      NAME_1: "Dodoma",
      NL_NAME_1: "None",
      GID_2: "TZA.3.7_1",
      NAME_2: "Mpwapwa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.4.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.4_1",
      NAME_1: "Geita",
      NL_NAME_1: "None",
      GID_2: "TZA.4.1_1",
      NAME_2: "Bukombe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.4.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.4_1",
      NAME_1: "Geita",
      NL_NAME_1: "None",
      GID_2: "TZA.4.2_1",
      NAME_2: "Chato",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.4.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.4_1",
      NAME_1: "Geita",
      NL_NAME_1: "None",
      GID_2: "TZA.4.3_1",
      NAME_2: "Geita",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.4.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.4_1",
      NAME_1: "Geita",
      NL_NAME_1: "None",
      GID_2: "TZA.4.4_1",
      NAME_2: "Mbogwe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.4.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.4_1",
      NAME_1: "Geita",
      NL_NAME_1: "None",
      GID_2: "TZA.4.5_1",
      NAME_2: "Nyang'wale",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.5.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.5_1",
      NAME_1: "Iringa",
      NL_NAME_1: "None",
      GID_2: "TZA.5.1_1",
      NAME_2: "Iringa Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.5.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.5_1",
      NAME_1: "Iringa",
      NL_NAME_1: "None",
      GID_2: "TZA.5.2_1",
      NAME_2: "Iringa Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.5.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.5_1",
      NAME_1: "Iringa",
      NL_NAME_1: "None",
      GID_2: "TZA.5.3_1",
      NAME_2: "Kilolo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.5.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.5_1",
      NAME_1: "Iringa",
      NL_NAME_1: "None",
      GID_2: "TZA.5.4_1",
      NAME_2: "Mafinga Township Authority",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.5.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.5_1",
      NAME_1: "Iringa",
      NL_NAME_1: "None",
      GID_2: "TZA.5.5_1",
      NAME_2: "Mufindi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.1_1",
      NAME_2: "Biharamulo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.2_1",
      NAME_2: "Bukoba Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.3_1",
      NAME_2: "Bukoba Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.4_1",
      NAME_2: "Karagwe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.5_1",
      NAME_2: "Kyerwa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.6_1",
      NAME_2: "Lake Victoria",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.7_1",
      NAME_2: "Missenyi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.8_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.8_1",
      NAME_2: "Muleba",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.6.9_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.6_1",
      NAME_1: "Kagera",
      NL_NAME_1: "None",
      GID_2: "TZA.6.9_1",
      NAME_2: "Ngara",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.7.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.7_1",
      NAME_1: "Katavi",
      NL_NAME_1: "None",
      GID_2: "TZA.7.1_1",
      NAME_2: "Lake Rukwa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.7.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.7_1",
      NAME_1: "Katavi",
      NL_NAME_1: "None",
      GID_2: "TZA.7.2_1",
      NAME_2: "Lake Tanganyika",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.7.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.7_1",
      NAME_1: "Katavi",
      NL_NAME_1: "None",
      GID_2: "TZA.7.3_1",
      NAME_2: "Mlele",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.7.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.7_1",
      NAME_1: "Katavi",
      NL_NAME_1: "None",
      GID_2: "TZA.7.4_1",
      NAME_2: "Mpanda Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.7.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.7_1",
      NAME_1: "Katavi",
      NL_NAME_1: "None",
      GID_2: "TZA.7.5_1",
      NAME_2: "Mpanda",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.1_1",
      NAME_2: "Buhigwe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.2_1",
      NAME_2: "Kakonko",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.3_1",
      NAME_2: "Kasulu Township Authority",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.4_1",
      NAME_2: "Kasulu",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.5_1",
      NAME_2: "Kibondo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.6_1",
      NAME_2: "Kigoma Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.7_1",
      NAME_2: "Kigoma Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.8_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.8_1",
      NAME_2: "Lake Tanganyika",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.8.9_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.8_1",
      NAME_1: "Kigoma",
      NL_NAME_1: "None",
      GID_2: "TZA.8.9_1",
      NAME_2: "Uvinza",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.9.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.9_1",
      NAME_1: "Kilimanjaro",
      NL_NAME_1: "None",
      GID_2: "TZA.9.1_1",
      NAME_2: "Hai",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.9.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.9_1",
      NAME_1: "Kilimanjaro",
      NL_NAME_1: "None",
      GID_2: "TZA.9.2_1",
      NAME_2: "Moshi Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.9.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.9_1",
      NAME_1: "Kilimanjaro",
      NL_NAME_1: "None",
      GID_2: "TZA.9.3_1",
      NAME_2: "Moshi Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.9.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.9_1",
      NAME_1: "Kilimanjaro",
      NL_NAME_1: "None",
      GID_2: "TZA.9.4_1",
      NAME_2: "Mwanga",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.9.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.9_1",
      NAME_1: "Kilimanjaro",
      NL_NAME_1: "None",
      GID_2: "TZA.9.5_1",
      NAME_2: "Rombo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.9.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.9_1",
      NAME_1: "Kilimanjaro",
      NL_NAME_1: "None",
      GID_2: "TZA.9.6_1",
      NAME_2: "Same",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.9.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.9_1",
      NAME_1: "Kilimanjaro",
      NL_NAME_1: "None",
      GID_2: "TZA.9.7_1",
      NAME_2: "Siha",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.10.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.10_1",
      NAME_1: "Lindi",
      NL_NAME_1: "None",
      GID_2: "TZA.10.1_1",
      NAME_2: "Kilwa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.10.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.10_1",
      NAME_1: "Lindi",
      NL_NAME_1: "None",
      GID_2: "TZA.10.2_1",
      NAME_2: "Lindi Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.10.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.10_1",
      NAME_1: "Lindi",
      NL_NAME_1: "None",
      GID_2: "TZA.10.3_1",
      NAME_2: "Lindi Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.10.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.10_1",
      NAME_1: "Lindi",
      NL_NAME_1: "None",
      GID_2: "TZA.10.4_1",
      NAME_2: "Liwale",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.10.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.10_1",
      NAME_1: "Lindi",
      NL_NAME_1: "None",
      GID_2: "TZA.10.5_1",
      NAME_2: "Nachingwea",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.10.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.10_1",
      NAME_1: "Lindi",
      NL_NAME_1: "None",
      GID_2: "TZA.10.6_1",
      NAME_2: "Ruangwa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.11.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.11_1",
      NAME_1: "Manyara",
      NL_NAME_1: "None",
      GID_2: "TZA.11.1_1",
      NAME_2: "Babati Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.11.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.11_1",
      NAME_1: "Manyara",
      NL_NAME_1: "None",
      GID_2: "TZA.11.2_1",
      NAME_2: "Babati",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.11.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.11_1",
      NAME_1: "Manyara",
      NL_NAME_1: "None",
      GID_2: "TZA.11.3_1",
      NAME_2: "Hanang",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.11.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.11_1",
      NAME_1: "Manyara",
      NL_NAME_1: "None",
      GID_2: "TZA.11.4_1",
      NAME_2: "Kiteto",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.11.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.11_1",
      NAME_1: "Manyara",
      NL_NAME_1: "None",
      GID_2: "TZA.11.5_1",
      NAME_2: "Lake Manyara",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.11.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.11_1",
      NAME_1: "Manyara",
      NL_NAME_1: "None",
      GID_2: "TZA.11.6_1",
      NAME_2: "Mbulu",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.11.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.11_1",
      NAME_1: "Manyara",
      NL_NAME_1: "None",
      GID_2: "TZA.11.7_1",
      NAME_2: "Simanjiro",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.12.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.12_1",
      NAME_1: "Mara",
      NL_NAME_1: "None",
      GID_2: "TZA.12.1_1",
      NAME_2: "Bunda",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.12.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.12_1",
      NAME_1: "Mara",
      NL_NAME_1: "None",
      GID_2: "TZA.12.2_1",
      NAME_2: "Butiama",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.12.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.12_1",
      NAME_1: "Mara",
      NL_NAME_1: "None",
      GID_2: "TZA.12.3_1",
      NAME_2: "Lake Victoria",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.12.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.12_1",
      NAME_1: "Mara",
      NL_NAME_1: "None",
      GID_2: "TZA.12.4_1",
      NAME_2: "Musoma Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.12.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.12_1",
      NAME_1: "Mara",
      NL_NAME_1: "None",
      GID_2: "TZA.12.5_1",
      NAME_2: "Musoma Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.12.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.12_1",
      NAME_1: "Mara",
      NL_NAME_1: "None",
      GID_2: "TZA.12.6_1",
      NAME_2: "Rorya",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.12.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.12_1",
      NAME_1: "Mara",
      NL_NAME_1: "None",
      GID_2: "TZA.12.7_1",
      NAME_2: "Serengeti",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.12.8_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.12_1",
      NAME_1: "Mara",
      NL_NAME_1: "None",
      GID_2: "TZA.12.8_1",
      NAME_2: "Tarime",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.1_1",
      NAME_2: "Chunya",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.2_1",
      NAME_2: "Ileje",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.3_1",
      NAME_2: "Kyela",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.4_1",
      NAME_2: "Lake Rukwa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.5_1",
      NAME_2: "Mbarali",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.6_1",
      NAME_2: "Mbeya Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.7_1",
      NAME_2: "Mbeya Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.8_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.8_1",
      NAME_2: "Mbozi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.9_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.9_1",
      NAME_2: "Momba",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.10_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.10_1",
      NAME_2: "Rungwe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.13.11_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.13_1",
      NAME_1: "Mbeya",
      NL_NAME_1: "None",
      GID_2: "TZA.13.11_1",
      NAME_2: "Tunduma",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.14.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.14_1",
      NAME_1: "Morogoro",
      NL_NAME_1: "None",
      GID_2: "TZA.14.1_1",
      NAME_2: "Gairo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.14.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.14_1",
      NAME_1: "Morogoro",
      NL_NAME_1: "None",
      GID_2: "TZA.14.2_1",
      NAME_2: "Kilombero",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.14.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.14_1",
      NAME_1: "Morogoro",
      NL_NAME_1: "None",
      GID_2: "TZA.14.3_1",
      NAME_2: "Kilosa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.14.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.14_1",
      NAME_1: "Morogoro",
      NL_NAME_1: "None",
      GID_2: "TZA.14.4_1",
      NAME_2: "Morogoro Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.14.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.14_1",
      NAME_1: "Morogoro",
      NL_NAME_1: "None",
      GID_2: "TZA.14.5_1",
      NAME_2: "Morogoro Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.14.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.14_1",
      NAME_1: "Morogoro",
      NL_NAME_1: "None",
      GID_2: "TZA.14.6_1",
      NAME_2: "Mvomero",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.14.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.14_1",
      NAME_1: "Morogoro",
      NL_NAME_1: "None",
      GID_2: "TZA.14.7_1",
      NAME_2: "Ulanga",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.15.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.15_1",
      NAME_1: "Mtwara",
      NL_NAME_1: "None",
      GID_2: "TZA.15.1_1",
      NAME_2: "Masasi Township Authority",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.15.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.15_1",
      NAME_1: "Mtwara",
      NL_NAME_1: "None",
      GID_2: "TZA.15.2_1",
      NAME_2: "Masasi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.15.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.15_1",
      NAME_1: "Mtwara",
      NL_NAME_1: "None",
      GID_2: "TZA.15.3_1",
      NAME_2: "Mtwara Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.15.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.15_1",
      NAME_1: "Mtwara",
      NL_NAME_1: "None",
      GID_2: "TZA.15.4_1",
      NAME_2: "Mtwara Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.15.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.15_1",
      NAME_1: "Mtwara",
      NL_NAME_1: "None",
      GID_2: "TZA.15.5_1",
      NAME_2: "Nanyumbu",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.15.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.15_1",
      NAME_1: "Mtwara",
      NL_NAME_1: "None",
      GID_2: "TZA.15.6_1",
      NAME_2: "Newala",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.15.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.15_1",
      NAME_1: "Mtwara",
      NL_NAME_1: "None",
      GID_2: "TZA.15.7_1",
      NAME_2: "Tandahimba",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.16.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "TZA.16.1_1",
      NAME_2: "Ilemela",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.16.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "TZA.16.2_1",
      NAME_2: "Kwimba",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.16.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "TZA.16.3_1",
      NAME_2: "Lake Victoria",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.16.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "TZA.16.4_1",
      NAME_2: "Magu",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.16.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "TZA.16.5_1",
      NAME_2: "Misungwi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.16.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "TZA.16.6_1",
      NAME_2: "Nyamagana",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.16.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "TZA.16.7_1",
      NAME_2: "Sengerema",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.16.8_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.16_1",
      NAME_1: "Mwanza",
      NL_NAME_1: "None",
      GID_2: "TZA.16.8_1",
      NAME_2: "Ukerewe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.17.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.17_1",
      NAME_1: "Njombe",
      NL_NAME_1: "None",
      GID_2: "TZA.17.1_1",
      NAME_2: "Ludewa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.17.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.17_1",
      NAME_1: "Njombe",
      NL_NAME_1: "None",
      GID_2: "TZA.17.2_1",
      NAME_2: "Makambako Township Authority",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.17.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.17_1",
      NAME_1: "Njombe",
      NL_NAME_1: "None",
      GID_2: "TZA.17.3_1",
      NAME_2: "Makete",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.17.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.17_1",
      NAME_1: "Njombe",
      NL_NAME_1: "None",
      GID_2: "TZA.17.4_1",
      NAME_2: "Njombe Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.17.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.17_1",
      NAME_1: "Njombe",
      NL_NAME_1: "None",
      GID_2: "TZA.17.5_1",
      NAME_2: "Njombe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.17.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.17_1",
      NAME_1: "Njombe",
      NL_NAME_1: "None",
      GID_2: "TZA.17.6_1",
      NAME_2: "Wanging'ombe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.18.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.18_1",
      NAME_1: "Pemba North",
      NL_NAME_1: "None",
      GID_2: "TZA.18.1_1",
      NAME_2: "Micheweni",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.18.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.18_1",
      NAME_1: "Pemba North",
      NL_NAME_1: "None",
      GID_2: "TZA.18.2_1",
      NAME_2: "Wete",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.19.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.19_1",
      NAME_1: "Pemba South",
      NL_NAME_1: "None",
      GID_2: "TZA.19.1_1",
      NAME_2: "Chake",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.19.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.19_1",
      NAME_1: "Pemba South",
      NL_NAME_1: "None",
      GID_2: "TZA.19.2_1",
      NAME_2: "Mkoani",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.20.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.20_1",
      NAME_1: "Pwani",
      NL_NAME_1: "None",
      GID_2: "TZA.20.1_1",
      NAME_2: "Bagamoyo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.20.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.20_1",
      NAME_1: "Pwani",
      NL_NAME_1: "None",
      GID_2: "TZA.20.2_1",
      NAME_2: "Kibaha Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.20.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.20_1",
      NAME_1: "Pwani",
      NL_NAME_1: "None",
      GID_2: "TZA.20.3_1",
      NAME_2: "Kibaha",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.20.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.20_1",
      NAME_1: "Pwani",
      NL_NAME_1: "None",
      GID_2: "TZA.20.4_1",
      NAME_2: "Kisarawe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.20.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.20_1",
      NAME_1: "Pwani",
      NL_NAME_1: "None",
      GID_2: "TZA.20.5_1",
      NAME_2: "Mafia",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.20.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.20_1",
      NAME_1: "Pwani",
      NL_NAME_1: "None",
      GID_2: "TZA.20.6_1",
      NAME_2: "Mkuranga",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.20.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.20_1",
      NAME_1: "Pwani",
      NL_NAME_1: "None",
      GID_2: "TZA.20.7_1",
      NAME_2: "Rufiji",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.21.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.21_1",
      NAME_1: "Rukwa",
      NL_NAME_1: "None",
      GID_2: "TZA.21.1_1",
      NAME_2: "Kalambo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.21.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.21_1",
      NAME_1: "Rukwa",
      NL_NAME_1: "None",
      GID_2: "TZA.21.2_1",
      NAME_2: "Lake Rukwa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.21.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.21_1",
      NAME_1: "Rukwa",
      NL_NAME_1: "None",
      GID_2: "TZA.21.3_1",
      NAME_2: "Lake Tanganyika",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.21.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.21_1",
      NAME_1: "Rukwa",
      NL_NAME_1: "None",
      GID_2: "TZA.21.4_1",
      NAME_2: "Nkasi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.21.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.21_1",
      NAME_1: "Rukwa",
      NL_NAME_1: "None",
      GID_2: "TZA.21.5_1",
      NAME_2: "Sumbawanga Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.21.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.21_1",
      NAME_1: "Rukwa",
      NL_NAME_1: "None",
      GID_2: "TZA.21.6_1",
      NAME_2: "Sumbawanga Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.22.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.22_1",
      NAME_1: "Ruvuma",
      NL_NAME_1: "None",
      GID_2: "TZA.22.1_1",
      NAME_2: "Mbinga",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.22.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.22_1",
      NAME_1: "Ruvuma",
      NL_NAME_1: "None",
      GID_2: "TZA.22.2_1",
      NAME_2: "Namtumbo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.22.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.22_1",
      NAME_1: "Ruvuma",
      NL_NAME_1: "None",
      GID_2: "TZA.22.3_1",
      NAME_2: "Nyasa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.22.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.22_1",
      NAME_1: "Ruvuma",
      NL_NAME_1: "None",
      GID_2: "TZA.22.4_1",
      NAME_2: "Songea Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.22.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.22_1",
      NAME_1: "Ruvuma",
      NL_NAME_1: "None",
      GID_2: "TZA.22.5_1",
      NAME_2: "Songea Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.22.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.22_1",
      NAME_1: "Ruvuma",
      NL_NAME_1: "None",
      GID_2: "TZA.22.6_1",
      NAME_2: "Tunduru",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.23.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.23_1",
      NAME_1: "Shinyanga",
      NL_NAME_1: "None",
      GID_2: "TZA.23.1_1",
      NAME_2: "Kahama Township Authority",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.23.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.23_1",
      NAME_1: "Shinyanga",
      NL_NAME_1: "None",
      GID_2: "TZA.23.2_1",
      NAME_2: "Kahama",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.23.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.23_1",
      NAME_1: "Shinyanga",
      NL_NAME_1: "None",
      GID_2: "TZA.23.3_1",
      NAME_2: "Kishapu",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.23.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.23_1",
      NAME_1: "Shinyanga",
      NL_NAME_1: "None",
      GID_2: "TZA.23.4_1",
      NAME_2: "Shinyanga Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.23.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.23_1",
      NAME_1: "Shinyanga",
      NL_NAME_1: "None",
      GID_2: "TZA.23.5_1",
      NAME_2: "Shinyanga Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.24.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.24_1",
      NAME_1: "Simiyu",
      NL_NAME_1: "None",
      GID_2: "TZA.24.1_1",
      NAME_2: "Bariadi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.24.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.24_1",
      NAME_1: "Simiyu",
      NL_NAME_1: "None",
      GID_2: "TZA.24.2_1",
      NAME_2: "Busega",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.24.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.24_1",
      NAME_1: "Simiyu",
      NL_NAME_1: "None",
      GID_2: "TZA.24.3_1",
      NAME_2: "Itilima",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.24.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.24_1",
      NAME_1: "Simiyu",
      NL_NAME_1: "None",
      GID_2: "TZA.24.4_1",
      NAME_2: "Lake Eyasi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.24.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.24_1",
      NAME_1: "Simiyu",
      NL_NAME_1: "None",
      GID_2: "TZA.24.5_1",
      NAME_2: "Lake Victoria",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.24.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.24_1",
      NAME_1: "Simiyu",
      NL_NAME_1: "None",
      GID_2: "TZA.24.6_1",
      NAME_2: "Maswa",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.24.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.24_1",
      NAME_1: "Simiyu",
      NL_NAME_1: "None",
      GID_2: "TZA.24.7_1",
      NAME_2: "Meatu",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.25.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.25_1",
      NAME_1: "Singida",
      NL_NAME_1: "None",
      GID_2: "TZA.25.1_1",
      NAME_2: "Ikungi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.25.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.25_1",
      NAME_1: "Singida",
      NL_NAME_1: "None",
      GID_2: "TZA.25.2_1",
      NAME_2: "Iramba",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.25.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.25_1",
      NAME_1: "Singida",
      NL_NAME_1: "None",
      GID_2: "TZA.25.3_1",
      NAME_2: "Manyoni",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.25.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.25_1",
      NAME_1: "Singida",
      NL_NAME_1: "None",
      GID_2: "TZA.25.4_1",
      NAME_2: "Mkalama",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.25.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.25_1",
      NAME_1: "Singida",
      NL_NAME_1: "None",
      GID_2: "TZA.25.5_1",
      NAME_2: "Singida Rural",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.25.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.25_1",
      NAME_1: "Singida",
      NL_NAME_1: "None",
      GID_2: "TZA.25.6_1",
      NAME_2: "Singida Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.26.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.26_1",
      NAME_1: "Tabora",
      NL_NAME_1: "None",
      GID_2: "TZA.26.1_1",
      NAME_2: "Igunga",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.26.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.26_1",
      NAME_1: "Tabora",
      NL_NAME_1: "None",
      GID_2: "TZA.26.2_1",
      NAME_2: "Kaliua",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.26.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.26_1",
      NAME_1: "Tabora",
      NL_NAME_1: "None",
      GID_2: "TZA.26.3_1",
      NAME_2: "Nzega",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.26.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.26_1",
      NAME_1: "Tabora",
      NL_NAME_1: "None",
      GID_2: "TZA.26.4_1",
      NAME_2: "Sikonge",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.26.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.26_1",
      NAME_1: "Tabora",
      NL_NAME_1: "None",
      GID_2: "TZA.26.5_1",
      NAME_2: "Tabora Urban",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.26.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.26_1",
      NAME_1: "Tabora",
      NL_NAME_1: "None",
      GID_2: "TZA.26.6_1",
      NAME_2: "Urambo",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.26.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.26_1",
      NAME_1: "Tabora",
      NL_NAME_1: "None",
      GID_2: "TZA.26.7_1",
      NAME_2: "Uyui",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.1_1",
      NAME_2: "Handeni Township Authority",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.2_1",
      NAME_2: "Handeni",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.3_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.3_1",
      NAME_2: "Kilindi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.4_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.4_1",
      NAME_2: "Korogwe Township Authority",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.5_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.5_1",
      NAME_2: "Korogwe",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.6_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.6_1",
      NAME_2: "Lushoto",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.7_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.7_1",
      NAME_2: "Mkinga",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.8_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.8_1",
      NAME_2: "Muheza",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.9_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.9_1",
      NAME_2: "Pangani",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.27.10_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.27_1",
      NAME_1: "Tanga",
      NL_NAME_1: "None",
      GID_2: "TZA.27.10_1",
      NAME_2: "Tanga",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.28.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.28_1",
      NAME_1: "Zanzibar North",
      NL_NAME_1: "None",
      GID_2: "TZA.28.1_1",
      NAME_2: "Kaskazini 'A'",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.28.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.28_1",
      NAME_1: "Zanzibar North",
      NL_NAME_1: "None",
      GID_2: "TZA.28.2_1",
      NAME_2: "Kaskazini 'B'",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.29.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.29_1",
      NAME_1: "Zanzibar South and Central",
      NL_NAME_1: "None",
      GID_2: "TZA.29.1_1",
      NAME_2: "Kati",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.29.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.29_1",
      NAME_1: "Zanzibar South and Central",
      NL_NAME_1: "None",
      GID_2: "TZA.29.2_1",
      NAME_2: "Kusini",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.30.1_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.30_1",
      NAME_1: "Zanzibar West",
      NL_NAME_1: "None",
      GID_2: "TZA.30.1_1",
      NAME_2: "Magharibi",
      isoCode: "TZ",
    },
    {
      region_id: "TZA.30.2_1",
      GID_0: "TZA",
      NAME_0: "Tanzania",
      GID_1: "TZA.30_1",
      NAME_1: "Zanzibar West",
      NL_NAME_1: "None",
      GID_2: "TZA.30.2_1",
      NAME_2: "Mjini",
      isoCode: "TZ",
    },
    {
      region_id: "ZAF.1.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.1_1",
      NAME_1: "Eastern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.1.1_1",
      NAME_2: "Alfred Nzo",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.1.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.1_1",
      NAME_1: "Eastern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.1.2_1",
      NAME_2: "Amathole",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.1.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.1_1",
      NAME_1: "Eastern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.1.3_1",
      NAME_2: "Buffalo City",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.1.4_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.1_1",
      NAME_1: "Eastern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.1.4_1",
      NAME_2: "Cacadu",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.1.5_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.1_1",
      NAME_1: "Eastern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.1.5_1",
      NAME_2: "Chris Hani",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.1.6_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.1_1",
      NAME_1: "Eastern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.1.6_1",
      NAME_2: "Joe Gqabi",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.1.7_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.1_1",
      NAME_1: "Eastern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.1.7_1",
      NAME_2: "Nelson Mandela Bay",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.1.8_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.1_1",
      NAME_1: "Eastern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.1.8_1",
      NAME_2: "O.R.Tambo",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.2.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.2_1",
      NAME_1: "Free State",
      NL_NAME_1: "None",
      GID_2: "ZAF.2.1_1",
      NAME_2: "Fezile Dabi",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.2.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.2_1",
      NAME_1: "Free State",
      NL_NAME_1: "None",
      GID_2: "ZAF.2.2_1",
      NAME_2: "Lejweleputswa",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.2.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.2_1",
      NAME_1: "Free State",
      NL_NAME_1: "None",
      GID_2: "ZAF.2.3_1",
      NAME_2: "Mangaung",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.2.4_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.2_1",
      NAME_1: "Free State",
      NL_NAME_1: "None",
      GID_2: "ZAF.2.4_1",
      NAME_2: "Thabo Mofutsanyane",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.2.5_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.2_1",
      NAME_1: "Free State",
      NL_NAME_1: "None",
      GID_2: "ZAF.2.5_1",
      NAME_2: "Xhariep",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.3.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.3_1",
      NAME_1: "Gauteng",
      NL_NAME_1: "None",
      GID_2: "ZAF.3.1_1",
      NAME_2: "City of Johannesburg",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.3.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.3_1",
      NAME_1: "Gauteng",
      NL_NAME_1: "None",
      GID_2: "ZAF.3.2_1",
      NAME_2: "City of Tshwane",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.3.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.3_1",
      NAME_1: "Gauteng",
      NL_NAME_1: "None",
      GID_2: "ZAF.3.3_1",
      NAME_2: "Ekurhuleni",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.3.4_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.3_1",
      NAME_1: "Gauteng",
      NL_NAME_1: "None",
      GID_2: "ZAF.3.4_1",
      NAME_2: "Sedibeng",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.3.5_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.3_1",
      NAME_1: "Gauteng",
      NL_NAME_1: "None",
      GID_2: "ZAF.3.5_1",
      NAME_2: "West Rand",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.1_1",
      NAME_2: "Amajuba",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.2_1",
      NAME_2: "eThekwini",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.3_1",
      NAME_2: "iLembe",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.4_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.4_1",
      NAME_2: "Sisonke",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.5_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.5_1",
      NAME_2: "Ugu",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.6_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.6_1",
      NAME_2: "Umgungundlovu",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.7_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.7_1",
      NAME_2: "Umkhanyakude",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.8_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.8_1",
      NAME_2: "Umzinyathi",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.9_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.9_1",
      NAME_2: "Uthukela",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.10_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.10_1",
      NAME_2: "Uthungulu",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.4.11_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.4_1",
      NAME_1: "KwaZulu-Natal",
      NL_NAME_1: "None",
      GID_2: "ZAF.4.11_1",
      NAME_2: "Zululand",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.5.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.5_1",
      NAME_1: "Limpopo",
      NL_NAME_1: "None",
      GID_2: "ZAF.5.1_1",
      NAME_2: "Capricorn",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.5.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.5_1",
      NAME_1: "Limpopo",
      NL_NAME_1: "None",
      GID_2: "ZAF.5.2_1",
      NAME_2: "Mopani",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.5.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.5_1",
      NAME_1: "Limpopo",
      NL_NAME_1: "None",
      GID_2: "ZAF.5.3_1",
      NAME_2: "Sekhukhune",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.5.4_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.5_1",
      NAME_1: "Limpopo",
      NL_NAME_1: "None",
      GID_2: "ZAF.5.4_1",
      NAME_2: "Vhembe",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.5.5_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.5_1",
      NAME_1: "Limpopo",
      NL_NAME_1: "None",
      GID_2: "ZAF.5.5_1",
      NAME_2: "Waterberg",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.6.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.6_1",
      NAME_1: "Mpumalanga",
      NL_NAME_1: "None",
      GID_2: "ZAF.6.1_1",
      NAME_2: "Ehlanzeni",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.6.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.6_1",
      NAME_1: "Mpumalanga",
      NL_NAME_1: "None",
      GID_2: "ZAF.6.2_1",
      NAME_2: "Gert Sibande",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.6.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.6_1",
      NAME_1: "Mpumalanga",
      NL_NAME_1: "None",
      GID_2: "ZAF.6.3_1",
      NAME_2: "Nkangala",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.7.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.7_1",
      NAME_1: "North West",
      NL_NAME_1: "None",
      GID_2: "ZAF.7.1_1",
      NAME_2: "Bojanala",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.7.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.7_1",
      NAME_1: "North West",
      NL_NAME_1: "None",
      GID_2: "ZAF.7.2_1",
      NAME_2: "Dr Kenneth Kaunda",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.7.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.7_1",
      NAME_1: "North West",
      NL_NAME_1: "None",
      GID_2: "ZAF.7.3_1",
      NAME_2: "Dr Ruth Segomotsi Mompati",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.7.4_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.7_1",
      NAME_1: "North West",
      NL_NAME_1: "None",
      GID_2: "ZAF.7.4_1",
      NAME_2: "Ngaka Modiri Molema",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.8.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.8_1",
      NAME_1: "Northern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.8.1_1",
      NAME_2: "Frances Baard",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.8.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.8_1",
      NAME_1: "Northern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.8.2_1",
      NAME_2: "John Taolo Gaetsewe",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.8.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.8_1",
      NAME_1: "Northern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.8.3_1",
      NAME_2: "Namakwa",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.8.4_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.8_1",
      NAME_1: "Northern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.8.4_1",
      NAME_2: "Pixley ka Seme",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.8.5_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.8_1",
      NAME_1: "Northern Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.8.5_1",
      NAME_2: "Siyanda",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.9.1_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.9_1",
      NAME_1: "Western Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.9.1_1",
      NAME_2: "Cape Winelands",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.9.2_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.9_1",
      NAME_1: "Western Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.9.2_1",
      NAME_2: "Central Karoo",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.9.3_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.9_1",
      NAME_1: "Western Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.9.3_1",
      NAME_2: "City of Cape Town",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.9.4_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.9_1",
      NAME_1: "Western Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.9.4_1",
      NAME_2: "Eden",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.9.5_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.9_1",
      NAME_1: "Western Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.9.5_1",
      NAME_2: "Overberg",
      isoCode: "ZA",
    },
    {
      region_id: "ZAF.9.6_1",
      GID_0: "ZAF",
      NAME_0: "South Africa",
      GID_1: "ZAF.9_1",
      NAME_1: "Western Cape",
      NL_NAME_1: "None",
      GID_2: "ZAF.9.6_1",
      NAME_2: "West Coast",
      isoCode: "ZA",
    },
    {
      region_id: "ZMB.1.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "ZMB.1.1_1",
      NAME_2: "Chibombo",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.1.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "ZMB.1.2_1",
      NAME_2: "Kabwe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.1.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "ZMB.1.3_1",
      NAME_2: "Kapiri Mposhi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.1.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "ZMB.1.4_1",
      NAME_2: "Mkushi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.1.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "ZMB.1.5_1",
      NAME_2: "Mumbwa",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.1.6_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.1_1",
      NAME_1: "Central",
      NL_NAME_1: "None",
      GID_2: "ZMB.1.6_1",
      NAME_2: "Serenje",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.1_1",
      NAME_2: "Chililabombwe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.2_1",
      NAME_2: "Chingola",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.3_1",
      NAME_2: "Kalulushi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.4_1",
      NAME_2: "Kitwe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.5_1",
      NAME_2: "Luanshya",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.6_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.6_1",
      NAME_2: "Lufwanyama",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.7_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.7_1",
      NAME_2: "Masaiti",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.8_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.8_1",
      NAME_2: "MPongwe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.9_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.9_1",
      NAME_2: "Mufulira",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.2.10_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.2_1",
      NAME_1: "Copperbelt",
      NL_NAME_1: "None",
      GID_2: "ZMB.2.10_1",
      NAME_2: "Ndola",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.3.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.3_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "ZMB.3.1_1",
      NAME_2: "Chadiza",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.3.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.3_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "ZMB.3.2_1",
      NAME_2: "Chipata",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.3.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.3_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "ZMB.3.3_1",
      NAME_2: "Katete",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.3.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.3_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "ZMB.3.4_1",
      NAME_2: "Lundazi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.3.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.3_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "ZMB.3.5_1",
      NAME_2: "Mambwe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.3.6_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.3_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "ZMB.3.6_1",
      NAME_2: "Nyimba",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.3.7_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.3_1",
      NAME_1: "Eastern",
      NL_NAME_1: "None",
      GID_2: "ZMB.3.7_1",
      NAME_2: "Petauke",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.4.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.4_1",
      NAME_1: "Luapula",
      NL_NAME_1: "None",
      GID_2: "ZMB.4.1_1",
      NAME_2: "Chiengi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.4.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.4_1",
      NAME_1: "Luapula",
      NL_NAME_1: "None",
      GID_2: "ZMB.4.2_1",
      NAME_2: "Kawambwa",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.4.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.4_1",
      NAME_1: "Luapula",
      NL_NAME_1: "None",
      GID_2: "ZMB.4.3_1",
      NAME_2: "Mansa",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.4.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.4_1",
      NAME_1: "Luapula",
      NL_NAME_1: "None",
      GID_2: "ZMB.4.4_1",
      NAME_2: "Milenge",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.4.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.4_1",
      NAME_1: "Luapula",
      NL_NAME_1: "None",
      GID_2: "ZMB.4.5_1",
      NAME_2: "Mwense",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.4.6_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.4_1",
      NAME_1: "Luapula",
      NL_NAME_1: "None",
      GID_2: "ZMB.4.6_1",
      NAME_2: "Nchelenge",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.4.7_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.4_1",
      NAME_1: "Luapula",
      NL_NAME_1: "None",
      GID_2: "ZMB.4.7_1",
      NAME_2: "Samfya",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.5.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.5_1",
      NAME_1: "Lusaka",
      NL_NAME_1: "None",
      GID_2: "ZMB.5.1_1",
      NAME_2: "Chongwe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.5.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.5_1",
      NAME_1: "Lusaka",
      NL_NAME_1: "None",
      GID_2: "ZMB.5.2_1",
      NAME_2: "Kafue",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.5.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.5_1",
      NAME_1: "Lusaka",
      NL_NAME_1: "None",
      GID_2: "ZMB.5.3_1",
      NAME_2: "Luangwa",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.5.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.5_1",
      NAME_1: "Lusaka",
      NL_NAME_1: "None",
      GID_2: "ZMB.5.4_1",
      NAME_2: "Lusaka",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.6.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.6_1",
      NAME_1: "Muchinga",
      NL_NAME_1: "None",
      GID_2: "ZMB.6.1_1",
      NAME_2: "Chama",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.6.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.6_1",
      NAME_1: "Muchinga",
      NL_NAME_1: "None",
      GID_2: "ZMB.6.2_1",
      NAME_2: "Chinsali",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.6.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.6_1",
      NAME_1: "Muchinga",
      NL_NAME_1: "None",
      GID_2: "ZMB.6.3_1",
      NAME_2: "Isoka",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.6.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.6_1",
      NAME_1: "Muchinga",
      NL_NAME_1: "None",
      GID_2: "ZMB.6.4_1",
      NAME_2: "Mpika",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.6.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.6_1",
      NAME_1: "Muchinga",
      NL_NAME_1: "None",
      GID_2: "ZMB.6.5_1",
      NAME_2: "Nakonde",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.7.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.7_1",
      NAME_1: "North-Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.7.1_1",
      NAME_2: "Chavuma",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.7.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.7_1",
      NAME_1: "North-Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.7.2_1",
      NAME_2: "Kabompo",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.7.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.7_1",
      NAME_1: "North-Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.7.3_1",
      NAME_2: "Kasempa",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.7.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.7_1",
      NAME_1: "North-Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.7.4_1",
      NAME_2: "Mufumbwe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.7.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.7_1",
      NAME_1: "North-Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.7.5_1",
      NAME_2: "Mwinilunga",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.7.6_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.7_1",
      NAME_1: "North-Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.7.6_1",
      NAME_2: "Solwezi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.7.7_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.7_1",
      NAME_1: "North-Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.7.7_1",
      NAME_2: "Zambezi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.8.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.8_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "ZMB.8.1_1",
      NAME_2: "Chilubi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.8.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.8_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "ZMB.8.2_1",
      NAME_2: "Kaputa",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.8.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.8_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "ZMB.8.3_1",
      NAME_2: "Kasama",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.8.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.8_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "ZMB.8.4_1",
      NAME_2: "Luwingu",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.8.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.8_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "ZMB.8.5_1",
      NAME_2: "Mbala",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.8.6_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.8_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "ZMB.8.6_1",
      NAME_2: "Mporokoso",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.8.7_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.8_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "ZMB.8.7_1",
      NAME_2: "Mpulungu",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.8.8_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.8_1",
      NAME_1: "Northern",
      NL_NAME_1: "None",
      GID_2: "ZMB.8.8_1",
      NAME_2: "Mungwi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.1_1",
      NAME_2: "Choma",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.2_1",
      NAME_2: "Gwembe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.3_1",
      NAME_2: "Itezhi-Tezhi",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.4_1",
      NAME_2: "Kalomo",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.5_1",
      NAME_2: "Kazungula",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.6_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.6_1",
      NAME_2: "Livingstone",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.7_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.7_1",
      NAME_2: "Mazabuka",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.8_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.8_1",
      NAME_2: "Monze",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.9_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.9_1",
      NAME_2: "Namwala",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.10_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.10_1",
      NAME_2: "Siavonga",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.9.11_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.9_1",
      NAME_1: "Southern",
      NL_NAME_1: "None",
      GID_2: "ZMB.9.11_1",
      NAME_2: "Sinazongwe",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.10.1_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.10.1_1",
      NAME_2: "Kalabo",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.10.2_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.10.2_1",
      NAME_2: "Kaoma",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.10.3_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.10.3_1",
      NAME_2: "Lukulu",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.10.4_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.10.4_1",
      NAME_2: "Mongu",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.10.5_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.10.5_1",
      NAME_2: "Senanga",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.10.6_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.10.6_1",
      NAME_2: "Sesheke",
      isoCode: "ZM",
    },
    {
      region_id: "ZMB.10.7_1",
      GID_0: "ZMB",
      NAME_0: "Zambia",
      GID_1: "ZMB.10_1",
      NAME_1: "Western",
      NL_NAME_1: "None",
      GID_2: "ZMB.10.7_1",
      NAME_2: "Shangombo",
      isoCode: "ZM",
    },
    {
      region_id: "ZWE.1.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.1_1",
      NAME_1: "Bulawayo",
      NL_NAME_1: "None",
      GID_2: "ZWE.1.1_1",
      NAME_2: "Bulawayo",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.2.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.2_1",
      NAME_1: "Harare",
      NL_NAME_1: "None",
      GID_2: "ZWE.2.1_1",
      NAME_2: "Harare",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.3.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.3_1",
      NAME_1: "Manicaland",
      NL_NAME_1: "None",
      GID_2: "ZWE.3.1_1",
      NAME_2: "Buhera",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.3.2_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.3_1",
      NAME_1: "Manicaland",
      NL_NAME_1: "None",
      GID_2: "ZWE.3.2_1",
      NAME_2: "Chimanimani",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.3.3_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.3_1",
      NAME_1: "Manicaland",
      NL_NAME_1: "None",
      GID_2: "ZWE.3.3_1",
      NAME_2: "Chipinge",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.3.4_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.3_1",
      NAME_1: "Manicaland",
      NL_NAME_1: "None",
      GID_2: "ZWE.3.4_1",
      NAME_2: "Makoni",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.3.5_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.3_1",
      NAME_1: "Manicaland",
      NL_NAME_1: "None",
      GID_2: "ZWE.3.5_1",
      NAME_2: "Mutare",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.3.6_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.3_1",
      NAME_1: "Manicaland",
      NL_NAME_1: "None",
      GID_2: "ZWE.3.6_1",
      NAME_2: "Mutasa",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.3.7_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.3_1",
      NAME_1: "Manicaland",
      NL_NAME_1: "None",
      GID_2: "ZWE.3.7_1",
      NAME_2: "Nyanga",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.4.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.4_1",
      NAME_1: "Mashonaland Central",
      NL_NAME_1: "None",
      GID_2: "ZWE.4.1_1",
      NAME_2: "Bindura",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.4.2_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.4_1",
      NAME_1: "Mashonaland Central",
      NL_NAME_1: "None",
      GID_2: "ZWE.4.2_1",
      NAME_2: "Centenary",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.4.3_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.4_1",
      NAME_1: "Mashonaland Central",
      NL_NAME_1: "None",
      GID_2: "ZWE.4.3_1",
      NAME_2: "Guruve",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.4.4_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.4_1",
      NAME_1: "Mashonaland Central",
      NL_NAME_1: "None",
      GID_2: "ZWE.4.4_1",
      NAME_2: "Mazowe",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.4.5_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.4_1",
      NAME_1: "Mashonaland Central",
      NL_NAME_1: "None",
      GID_2: "ZWE.4.5_1",
      NAME_2: "Mount Darwin",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.4.6_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.4_1",
      NAME_1: "Mashonaland Central",
      NL_NAME_1: "None",
      GID_2: "ZWE.4.6_1",
      NAME_2: "Rushinga",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.4.7_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.4_1",
      NAME_1: "Mashonaland Central",
      NL_NAME_1: "None",
      GID_2: "ZWE.4.7_1",
      NAME_2: "Shamva",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.1_1",
      NAME_2: "Chikomba",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.2_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.2_1",
      NAME_2: "Goromonzi",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.3_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.3_1",
      NAME_2: "Marondera",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.4_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.4_1",
      NAME_2: "Mudzi",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.5_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.5_1",
      NAME_2: "Murehwa",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.6_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.6_1",
      NAME_2: "Mutoko",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.7_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.7_1",
      NAME_2: "Seke",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.8_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.8_1",
      NAME_2: "UMP",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.5.9_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.5_1",
      NAME_1: "Mashonaland East",
      NL_NAME_1: "None",
      GID_2: "ZWE.5.9_1",
      NAME_2: "Wedza",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.6.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.6_1",
      NAME_1: "Mashonaland West",
      NL_NAME_1: "None",
      GID_2: "ZWE.6.1_1",
      NAME_2: "Chegutu",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.6.2_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.6_1",
      NAME_1: "Mashonaland West",
      NL_NAME_1: "None",
      GID_2: "ZWE.6.2_1",
      NAME_2: "Hurungwe",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.6.3_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.6_1",
      NAME_1: "Mashonaland West",
      NL_NAME_1: "None",
      GID_2: "ZWE.6.3_1",
      NAME_2: "Kadoma",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.6.4_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.6_1",
      NAME_1: "Mashonaland West",
      NL_NAME_1: "None",
      GID_2: "ZWE.6.4_1",
      NAME_2: "Kariba",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.6.5_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.6_1",
      NAME_1: "Mashonaland West",
      NL_NAME_1: "None",
      GID_2: "ZWE.6.5_1",
      NAME_2: "Makonde",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.6.6_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.6_1",
      NAME_1: "Mashonaland West",
      NL_NAME_1: "None",
      GID_2: "ZWE.6.6_1",
      NAME_2: "Zvimba",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.7.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.7_1",
      NAME_1: "Masvingo",
      NL_NAME_1: "None",
      GID_2: "ZWE.7.1_1",
      NAME_2: "Bikita",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.7.2_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.7_1",
      NAME_1: "Masvingo",
      NL_NAME_1: "None",
      GID_2: "ZWE.7.2_1",
      NAME_2: "Chiredzi",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.7.3_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.7_1",
      NAME_1: "Masvingo",
      NL_NAME_1: "None",
      GID_2: "ZWE.7.3_1",
      NAME_2: "Chivi",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.7.4_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.7_1",
      NAME_1: "Masvingo",
      NL_NAME_1: "None",
      GID_2: "ZWE.7.4_1",
      NAME_2: "Gutu",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.7.5_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.7_1",
      NAME_1: "Masvingo",
      NL_NAME_1: "None",
      GID_2: "ZWE.7.5_1",
      NAME_2: "Masvingo",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.7.6_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.7_1",
      NAME_1: "Masvingo",
      NL_NAME_1: "None",
      GID_2: "ZWE.7.6_1",
      NAME_2: "Mwenezi",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.7.7_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.7_1",
      NAME_1: "Masvingo",
      NL_NAME_1: "None",
      GID_2: "ZWE.7.7_1",
      NAME_2: "Zaka",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.8.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.8_1",
      NAME_1: "Matabeleland North",
      NL_NAME_1: "None",
      GID_2: "ZWE.8.1_1",
      NAME_2: "Binga",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.8.2_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.8_1",
      NAME_1: "Matabeleland North",
      NL_NAME_1: "None",
      GID_2: "ZWE.8.2_1",
      NAME_2: "Bubi",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.8.3_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.8_1",
      NAME_1: "Matabeleland North",
      NL_NAME_1: "None",
      GID_2: "ZWE.8.3_1",
      NAME_2: "Hwange",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.8.4_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.8_1",
      NAME_1: "Matabeleland North",
      NL_NAME_1: "None",
      GID_2: "ZWE.8.4_1",
      NAME_2: "Lupane",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.8.5_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.8_1",
      NAME_1: "Matabeleland North",
      NL_NAME_1: "None",
      GID_2: "ZWE.8.5_1",
      NAME_2: "Nkayi",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.8.6_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.8_1",
      NAME_1: "Matabeleland North",
      NL_NAME_1: "None",
      GID_2: "ZWE.8.6_1",
      NAME_2: "Tsholotsho",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.8.7_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.8_1",
      NAME_1: "Matabeleland North",
      NL_NAME_1: "None",
      GID_2: "ZWE.8.7_1",
      NAME_2: "Umguza",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.9.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.9_1",
      NAME_1: "Matabeleland South",
      NL_NAME_1: "None",
      GID_2: "ZWE.9.1_1",
      NAME_2: "Beitbridge",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.9.2_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.9_1",
      NAME_1: "Matabeleland South",
      NL_NAME_1: "None",
      GID_2: "ZWE.9.2_1",
      NAME_2: "Bulilima (North)",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.9.3_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.9_1",
      NAME_1: "Matabeleland South",
      NL_NAME_1: "None",
      GID_2: "ZWE.9.3_1",
      NAME_2: "Gwanda",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.9.4_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.9_1",
      NAME_1: "Matabeleland South",
      NL_NAME_1: "None",
      GID_2: "ZWE.9.4_1",
      NAME_2: "Insiza",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.9.5_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.9_1",
      NAME_1: "Matabeleland South",
      NL_NAME_1: "None",
      GID_2: "ZWE.9.5_1",
      NAME_2: "Mangwe (South)",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.9.6_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.9_1",
      NAME_1: "Matabeleland South",
      NL_NAME_1: "None",
      GID_2: "ZWE.9.6_1",
      NAME_2: "Matobo",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.9.7_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.9_1",
      NAME_1: "Matabeleland South",
      NL_NAME_1: "None",
      GID_2: "ZWE.9.7_1",
      NAME_2: "Umzingwane",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.10.1_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.10_1",
      NAME_1: "Midlands",
      NL_NAME_1: "None",
      GID_2: "ZWE.10.1_1",
      NAME_2: "Chirumhanzu",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.10.2_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.10_1",
      NAME_1: "Midlands",
      NL_NAME_1: "None",
      GID_2: "ZWE.10.2_1",
      NAME_2: "Gokwe North",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.10.3_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.10_1",
      NAME_1: "Midlands",
      NL_NAME_1: "None",
      GID_2: "ZWE.10.3_1",
      NAME_2: "Gokwe South",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.10.4_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.10_1",
      NAME_1: "Midlands",
      NL_NAME_1: "None",
      GID_2: "ZWE.10.4_1",
      NAME_2: "Gweru",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.10.5_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.10_1",
      NAME_1: "Midlands",
      NL_NAME_1: "None",
      GID_2: "ZWE.10.5_1",
      NAME_2: "Kwekwe",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.10.6_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.10_1",
      NAME_1: "Midlands",
      NL_NAME_1: "None",
      GID_2: "ZWE.10.6_1",
      NAME_2: "Mberengwa",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.10.7_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.10_1",
      NAME_1: "Midlands",
      NL_NAME_1: "None",
      GID_2: "ZWE.10.7_1",
      NAME_2: "Shurugwi",
      isoCode: "ZW",
    },
    {
      region_id: "ZWE.10.8_1",
      GID_0: "ZWE",
      NAME_0: "Zimbabwe",
      GID_1: "ZWE.10_1",
      NAME_1: "Midlands",
      NL_NAME_1: "None",
      GID_2: "ZWE.10.8_1",
      NAME_2: "Zvishavane",
      isoCode: "ZW",
    },
  ],
  east: [],
};

/**
 * getUniqueRegionList is a function that extracts unique regions from a specified region data.
 * It adds latitude (lat) and longitude (lon) properties with empty strings.
 * @category Data
 * @param {string} region - The region key for which to extract unique regions.
 * @returns {Object[]} - An array of unique regions with added lat and lon properties.
 */

export const getUniqueRegionList = (region) =>
  REGIONS[region]?.reduce((result, current) => {
    if (!result.find((item) => item.NAME_0 === current.NAME_0)) {
      result.push({ ...current, lat: "", lon: "" });
    }
    return result;
  }, []);
