import { useEffect, useState } from "react";
import { LCOHCOSTPERCENTAGES_FACTSHEET } from "../../../constants/constants";
import { roundToDecimal } from "../../../utils/roundToDecimal";

/**
 * Custom React hook that returns hydrogen facts data for a given country.
 * @component
 * @category Hooks
 * @param {Object} options - Options object.
 * @param {Object} options.remoteNationalData - Object containing remote data for a given country.
 * @returns {Object} Object containing hydrogen facts data for a given country.
 */
const useHydrogenFacts = ({ remoteNationalData }) => {
  const [hydrogenFacts, setHydrogenFacts] = useState(null);

  /**
   * Returns an object containing hydrogen facts data for a given country.
   * @returns {Object} Object containing hydrogen facts data for a given country.
   */
  const getHydrogenFacts = () => ({
    heading: "Green hydrogen potential",
    main: [
      {
        title: "Minimum cost of green hydrogen",
        value: LCOHCOSTPERCENTAGES_FACTSHEET.map(
          (percentage) =>
            `${roundToDecimal(
              remoteNationalData.hydrogen[`lcoh2020_min_${percentage.value}`],
              2
            )}`
        ),
        year: 2020,
        unit: "€/kg",
        annotation: "",
      },
      {
        title: "Average cost of green hydrogen",
        value: LCOHCOSTPERCENTAGES_FACTSHEET.map(
          (percentage) =>
            `${roundToDecimal(
              remoteNationalData.hydrogen[`lcoh2020_${percentage.value}`],
              2
            )}`
        ),
        year: 2020,
        unit: "€/kg",
        annotation: "",
      },

      {
        title: "Maximum cost of green hydrogen",
        value: LCOHCOSTPERCENTAGES_FACTSHEET.map(
          (percentage) =>
            `${roundToDecimal(
              remoteNationalData.hydrogen[`lcoh2020_max_${percentage.value}`],
              2
            )}`
        ),
        year: 2020,
        unit: "€/kg",
        annotation: "",
      },
      {
        title: "Minimum cost of green hydrogen",
        value: LCOHCOSTPERCENTAGES_FACTSHEET.map(
          (percentage) =>
            `${roundToDecimal(
              remoteNationalData.hydrogen[`lcoh2050_min_${percentage.value}`],
              2
            )}`
        ),
        year: 2050,
        unit: "€/kg",
        annotation: "",
      },
      {
        title: "Average cost of green hydrogen",
        value: LCOHCOSTPERCENTAGES_FACTSHEET.map(
          (percentage) =>
            `${roundToDecimal(
              remoteNationalData.hydrogen[`lcoh2050_${percentage.value}`],
              2
            )}`
        ),
        year: 2050,
        unit: "€/kg",
        annotation: "",
      },
      {
        title: "Maximum cost of green hydrogen",
        value: LCOHCOSTPERCENTAGES_FACTSHEET.map(
          (percentage) =>
            `${roundToDecimal(
              remoteNationalData.hydrogen[`lcoh2050_max_${percentage.value}`],
              2
            )}`
        ),
        year: 2050,
        unit: "€/kg",
        annotation: "",
      },
      {
        title: "Total green hydrogen potential",
        value: LCOHCOSTPERCENTAGES_FACTSHEET.map(
          (percentage) =>
            `${roundToDecimal(
              remoteNationalData.hydrogen[`potential_${percentage.value}`],
              2
            )}`
        ),
        unit: "TWh/yr",
        annotation: "**",
      },
    ],
    potentialCurve: {
      data: [
        {
          id: "2020",
          data: remoteNationalData.hydrogen.potentialCurve2020,
        },
        {
          id: "2050",
          data: remoteNationalData.hydrogen.potentialCurve2050,
        },
      ],
      units: {
        cost: "€/kg",
        potential: "TWh/yr",
      },
      labels: {
        xAxis: "Generation Potential (yearly)",
        yAxis: "cost (LCOH)",
      },
    },
    footnotes: [
      {
        label: "h2First",
        noteAnnotation: "*",
      },
      {
        label: "h2Second",
        noteAnnotation: "**",
      },
      {
        label: "costNote",
      },
      // {
      //   label: "costCurveNote",
      // },
    ],
  });

  /**
   * Sets the hydrogen facts state using the getHydrogenFacts function.
   */
  useEffect(() => {
    if (!remoteNationalData) return;
    setHydrogenFacts(getHydrogenFacts);
  }, [remoteNationalData]);

  console.log(hydrogenFacts, "hydrogenFacts");

  return { hydrogenFacts };
};

export default useHydrogenFacts;
