import { useState, useEffect } from "react";

/**
 * Returns an object containing the width and height of the browser window.
 * @private
 * @returns {Object} An object containing the width and height of the window.
 */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * A custom React hook that returns the dimensions of the browser window.
 * @category Hooks
 * @component
 * @returns {Object} An object containing the width and height of the window.
 */
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  /**
   * Updates the window dimensions when the window is resized.
   */
  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
