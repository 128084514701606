import React, { useContext } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { UIContext } from "../../../contexts/UIContext";
import { createCookie } from "../../../utils/cookieCreator";
import useWelcome from "../../../hooks/Welcome/useWelcome";

/**
 * A component that displays a welcome dialog with options to skip or launch a guide.
 * @category Native
 * @subcategory Welcome
 * @component
 * @returns {JSX.Element} The Welcome component.
 */
const Welcome = () => {
  const { t } = useTranslation();
  const { openWelcomeDialog, setOpenWelcomeDialog, setRunGuide } =
    useContext(UIContext);
  const [, { handleWelcomeDialog }] = useWelcome({ createCookie });

  /**
   * Handles the click event for the "Start Guide" button.
   * Sets the "run guide" state to true, closes the welcome dialog, and creates a cookie.
   * @param {Event} e - The click event.
   */
  const handleClickStartGuide = (e) => {
    e.preventDefault();
    setRunGuide(true);
    setOpenWelcomeDialog(false);
    createCookie("tutorial", "true", 150);
  };

  return (
    <div>
      <Dialog open={openWelcomeDialog} onClose={handleWelcomeDialog}>
        <DialogTitle>{t("welcome.title")}</DialogTitle>
        <DialogContent>
          {t("welcome.part1")}
          <List>
            <ListItem>{t("welcome.part2")}</ListItem>
            <ListItem>{t("welcome.part3")}</ListItem>
            <ListItem>{t("welcome.part4")}</ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleWelcomeDialog}
            color="secondary"
          >
            {t("welcome.skip")}
          </Button>
          <Button
            variant="contained"
            onClick={handleClickStartGuide}
            color="primary"
          >
            {t("welcome.launch")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Welcome;
