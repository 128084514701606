import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AppContext } from "../../../contexts/AppContext";
import { getUniqueRegionList } from "../../../data/countries";
import { useContext } from "react";
import Loader from "../LoadScreen/Loader";

import PropTypes from "prop-types";

/**
 * A dialog component for taking screenshots of a selected country or region.
 * @category Native
 * @subcategory ScreenshotDialog
 * @component
 * @returns {JSX.Element}
 */
const ScreenshotDialog = ({
  open,
  onClose,
  onClick,
  handleScreenshotRegion,
  screenshotRegion,
  loadingScreenshot,
  formatOptions,
  setFormatOptions,
}) => {
  const { pathLocation } = useContext(AppContext);
  const countriesList = pathLocation.includes("sadc")
    ? getUniqueRegionList("sadc")
    : pathLocation.includes("ecowas")
    ? getUniqueRegionList("ecowas")
    : pathLocation.includes("east")
    ? getUniqueRegionList("east") //needs to be developed since there are no east african countries in the list
    : [];

  /**
   * Handles the change event of the select input.
   * @param {Object} event - The event object.
   */
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const country = countriesList.find((country) => country.NAME_0 === value);
    handleScreenshotRegion({
      name: value,
      isoCode: country.isoCode,
      gid0: country.GID_0,
    });
  };

  /**
   * Resets the screenshot region and format options.
   */
  const handleReset = () => {
    handleScreenshotRegion({ name: "", isoCode: "", gid0: "" });
    setFormatOptions((prevState) => ({
      ...prevState,
      pdf: true,
      image: false,
    }));
  };

  return (
    <Box>
      {loadingScreenshot && (
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "40%",
            p: 2,
          }}
        >
          <Loader size={50} thickness={5}>
            Preparing Screenshot
          </Loader>
        </Paper>
      )}
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Screenshot</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" my={1}>
            <TextField
              fullWidth
              size="small"
              select
              label="Countries"
              value={screenshotRegion.name}
              SelectProps={{
                MenuProps: { style: { maxHeight: 200 } },
              }}
              onChange={handleChange}
              helperText="Select a country"
            >
              {countriesList.map((option) => (
                <MenuItem dense key={option.GID_0} value={option.NAME_0}>
                  {option.NAME_0}
                </MenuItem>
              ))}
            </TextField>
            <FormControl sx={{ my: 2 }} component="fieldset" variant="standard">
              <FormLabel component="legend">Formats</FormLabel>
              <FormGroup sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        setFormatOptions({
                          ...formatOptions,
                          pdf: e.target.checked,
                        })
                      }
                      checked={formatOptions.pdf}
                    />
                  }
                  label="PDF"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        setFormatOptions({
                          ...formatOptions,
                          image: e.target.checked,
                        })
                      }
                      checked={formatOptions.image}
                    />
                  }
                  label="Image (only map, no legend)"
                />
              </FormGroup>
              <FormHelperText>
                You must select at least one format
              </FormHelperText>
            </FormControl>

            <Button
              variant="contained"
              size="small"
              color="error"
              sx={{ alignSelf: "flex-end" }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
          <Stack>
            <Alert sx={{ my: 1 }} variant="filled" severity="info">
              {" "}
              Remake the screenshot if the generated pdf or image is not as
              expected.
            </Alert>
            <Alert sx={{ my: 1 }} variant="filled" severity="info">
              {" "}
              If no country is selected, all{" "}
              {pathLocation.substring(1).toUpperCase()} countries will be
              selected by default.
            </Alert>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={Object.values(formatOptions).every(
              (value) => value === false
            )}
            variant="contained"
            onClick={onClick}
            color="primary"
          >
            Download Screenshot
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

//proptypes
ScreenshotDialog.propTypes = {
  /**
   * Determines if the dialog is open or not.
   */
  open: PropTypes.bool,
  /**
   * Callback function to close the dialog.
   */
  onClose: PropTypes.func,
  /**
   * Callback function to handle the click event.
   */
  onClick: PropTypes.func,
  /**
   * Callback function to handle the screenshot region.
   */
  handleScreenshotRegion: PropTypes.func,
  /**
   * The screenshot region.
   */
  screenshotRegion: PropTypes.object,
  /**
   * Determines if the screenshot is loading or not.
   */
  loadingScreenshot: PropTypes.bool,
  /**
   * The screenshot format options.
   */
  formatOptions: PropTypes.object,
  /**
   * Callback function to handle the screenshot format options.
   */
  setFormatOptions: PropTypes.func,
};

export default ScreenshotDialog;
