import { Box, List, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/**
 * Renders a component that displays a set of instructions for drawing shapes on a map.
 * @category Native
 * @subcategory Draw
 * @component
 * @returns {JSX.Element} The DrawTip component.
 */
const DrawTip = () => {
  /**
   * A hook that returns the translation function.
   * @function
   * @returns {Function} The translation function.
   */
  const { t } = useTranslation();

  return (
    <Box overflow="auto" height="50vh">
      <Typography variant="h6">
        {t("drawMenu.drawDialogMessage.heading")}
      </Typography>
      <Typography fontWeight={900} variant="subtitle1">
        {t("drawMenu.drawDialogMessage.disclaimer.title")}
      </Typography>
      <List disablePadding>
        <ListItem>
          <Typography gutterBottom variant="body2">
            <b>{t("drawMenu.drawDialogMessage.disclaimer.part1.subtitle")}</b>:
            {t("drawMenu.drawDialogMessage.disclaimer.part1.body")}
          </Typography>
        </ListItem>
        <ListItem>
          <Typography gutterBottom variant="body2">
            <b>{t("drawMenu.drawDialogMessage.disclaimer.part2.subtitle")}</b>:{" "}
            {t("drawMenu.drawDialogMessage.disclaimer.part2.body")}
          </Typography>
        </ListItem>
      </List>
      <Typography fontWeight={900} variant="subtitle1">
        {t("drawMenu.drawDialogMessage.gettingStarted.title")}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t("drawMenu.drawDialogMessage.gettingStarted.body")}
      </Typography>
      <Typography fontWeight={900} variant="subtitle1">
        {t("drawMenu.drawDialogMessage.drawingLines.title")}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t("drawMenu.drawDialogMessage.drawingLines.body")}
      </Typography>
      <Typography fontWeight={900} variant="subtitle1">
        {t("drawMenu.drawDialogMessage.drawingRectangles.title")}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t("drawMenu.drawDialogMessage.drawingRectangles.body")}
      </Typography>
      <Typography fontWeight={900} variant="subtitle1">
        {t("drawMenu.drawDialogMessage.drawingPolygons.title")}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t("drawMenu.drawDialogMessage.drawingPolygons.body")}
      </Typography>
      <Typography fontWeight={900} variant="subtitle1">
        {t("drawMenu.drawDialogMessage.editingAndDeleting.title")}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t("drawMenu.drawDialogMessage.editingAndDeleting.body")}
      </Typography>
      <Typography fontWeight={900} variant="subtitle1">
        {t("drawMenu.drawDialogMessage.viewingInfo.title")}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t("drawMenu.drawDialogMessage.viewingInfo.body")}
      </Typography>
      <Typography fontWeight={900} variant="subtitle1">
        {t("drawMenu.drawDialogMessage.multipleShapes.title")}
      </Typography>
      <Typography gutterBottom variant="body2">
        {t("drawMenu.drawDialogMessage.multipleShapes.body")}
      </Typography>
    </Box>
  );
};

DrawTip.propTypes = {
  /**
   * The props for the DrawTip component.
   */
  props: PropTypes.object,
};

export default DrawTip;
