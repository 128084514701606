import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../../contexts/AppContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import factsheet from "../../../../lib/national_facts_sheet_format.json";
import OtherFacts from "./OtherFacts";
import Export from "../../Export/Export";
import Loader from "../../../UI/LoadScreen/Loader";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import useHydrogenFacts from "../../../../hooks/ResultsPanel/NationalFactsSheet/useHydrogenFacts";
import useLandEligibilityFacts from "../../../../hooks/ResultsPanel/NationalFactsSheet/useLandEligibilityFacts";
import useIndicatorsFacts from "../../../../hooks/ResultsPanel/NationalFactsSheet/useIndicatorsFacts";
import useGroundwaterFacts from "../../../../hooks/ResultsPanel/NationalFactsSheet/useGroundwaterFacts";
import useRenewablesFacts from "../../../../hooks/ResultsPanel/NationalFactsSheet/useRenewablesFacts";
import TabPanel from "./TabPanel";
import useRemoteNationalData from "../../../../hooks/ResultsPanel/NationalFactsSheet/useRemoteNationalData";

/**
 * @component
 * @category Native
 * @subcategory NationalFactsSheet
 * @description A component that displays the national facts sheet of a selected region.
 */

const NationalFactsSheet = ({ resultPanelParams }) => {
  const { stepIndex, setStepIndex, pathLocation } = useContext(AppContext);

  const [
    {
      remoteNationalData,
      regionId,
      regionName,
      errorMessage,
      factsheetPanelLoading,
    },
    { handleRemoteNationalData },
  ] = useRemoteNationalData({ resultPanelParams });

  const { hydrogenFacts } = useHydrogenFacts({ remoteNationalData });
  const { landEligibilityFacts } = useLandEligibilityFacts({
    remoteNationalData,
  });
  const { indicatorsFacts } = useIndicatorsFacts({ remoteNationalData });
  const { groundwaterFacts } = useGroundwaterFacts({ remoteNationalData });
  const { renewableFacts } = useRenewablesFacts({ remoteNationalData });
  const { t } = useTranslation();

  /**
   * @function a11yProps
   * @private
   * @description A function that returns an object with accessibility props for a tab.
   * @param {number} index - The index of the tab.
   * @returns {object} - An object with accessibility props for a tab.
   */
  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  };

  /**
   * @description A function that returns a string that determines whether a component is displayed or not.
   * @param {number} value - The value to compare with the stepIndex.
   * @returns {string} - An empty string if the value is equal to the stepIndex, otherwise "none".
   */
  const isDisplayed = useCallback(
    (value) => (stepIndex === value ? "" : "none"),
    [stepIndex]
  );

  /**
   * @description A function that returns the facts for a category of the selected region.
   * @param {string} category - The category of the facts to retrieve.
   * @returns {object} - The facts for the specified category of the selected region.
   */
  const getFacts = (category) => {
    if (!remoteNationalData) return;
    return factsheet
      .map((fact) => ({
        ...fact,
        [category]: fact[category],
        "Green hydrogen potential": hydrogenFacts,
        "Land eligibility": landEligibilityFacts,
        "Renewable energy potential": renewableFacts,
        "Socio-economic impact assessment": indicatorsFacts,
        "Groundwater assessment": groundwaterFacts,
      }))
      .find((fact) => fact.CountryCode.includes(regionId));
  };

  /**
   * @description A function that handles the change of the tab value.
   * @param {object} event - The event object.
   * @param {number} newValue - The new value of the tab.
   * @returns {void}
   */
  const handleChangeTabValue = (event, newValue) => {
    setStepIndex(newValue);
  };

  return (
    <Box sx={{ mt: -1 }}>
      {/* {getFacts("Green hydrogen potential") && (
        <div
          style={{
            backgroundColor: "blue",
            position: "absolute",
            zIndex: "-10000",
          }}
        >
          <CostCurve
            idForExport={2}
            factsData={
              getFacts("Green hydrogen potential")["Green hydrogen potential"]
                .potentialCurve
            }
          />
        </div>
      )} */}

      <Box display="flex" justifyContent="space-between">
        <Typography
          gutterBottom
          variant="h5"
          textTransform="uppercase"
          textAlign="left"
          maxWidth="50%"
        >
          {regionName}
        </Typography>
        <Export
          factsheetPanelLoading={factsheetPanelLoading}
          regionName={regionName}
          pathLocation={pathLocation}
        />
      </Box>

      {factsheetPanelLoading ? (
        <Loader size={30} displayHeight="20vh" thickness={5}>
          {t(`nationalFactsheet.titles.Preparing factsheet`)}
        </Loader>
      ) : errorMessage ? (
        <Box>
          <Typography>{errorMessage}</Typography>
          <Button
            variant="contained"
            onClick={handleRemoteNationalData}
            startIcon={<RotateLeftIcon />}
          >
            {t("error.errorButton")}
          </Button>
        </Box>
      ) : (
        <>
          <Tabs
            value={stepIndex}
            onChange={handleChangeTabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab
              label={t(
                `nationalFactsheet.titles.Socio-economic impact assessment`
              )}
              {...a11yProps(0)}
              wrapped={true}
              sx={{
                minWidth: "10%",
                maxWidth: "30%",
                px: 0.5,
                py: 0,
              }}
            />
            <Tab
              label={t(`nationalFactsheet.titles.Groundwater assessment`)}
              {...a11yProps(1)}
              wrapped={true}
              selected={true}
              sx={{ minWidth: "10%", maxWidth: "30%", px: 0.5, py: 0 }}
            />
            <Tab
              label={t(`nationalFactsheet.titles.Green hydrogen potential`)}
              {...a11yProps(2)}
              wrapped={true}
              sx={{ minWidth: "10%", maxWidth: "30%", px: 0.5, py: 0 }}
            />
            <Tab
              label={t(`nationalFactsheet.titles.Land eligibility`)}
              {...a11yProps(3)}
              wrapped={true}
              sx={{ minWidth: "10%", maxWidth: "30%", px: 0.5, py: 0 }}
            />
            <Tab
              label={t(`nationalFactsheet.titles.Renewable energy`)}
              {...a11yProps(4)}
              wrapped={true}
              sx={{ minWidth: "10%", maxWidth: "30%", px: 0.5, py: 0 }}
            />
          </Tabs>

          {/* <TabPanel value={stepIndex} index={0}>
        <CountryProfile
          getFacts={getFacts}
          factCategory="Country profile"
          display={isDisplayed(0)}
        />
      </TabPanel> */}
          <TabPanel value={stepIndex} index={0}>
            <OtherFacts
              getFacts={getFacts}
              factCategory="Socio-economic impact assessment"
              idForExport={0}
              display={isDisplayed(0)}
            />
          </TabPanel>
          <TabPanel value={stepIndex} index={1}>
            <OtherFacts
              getFacts={getFacts}
              factCategory="Groundwater assessment"
              idForExport={1}
              display={isDisplayed(1)}
            />
          </TabPanel>
          <TabPanel value={stepIndex} index={2}>
            <OtherFacts
              getFacts={getFacts}
              factCategory="Green hydrogen potential"
              idForExport={2}
              display={isDisplayed(2)}
            />
          </TabPanel>
          <TabPanel value={stepIndex} index={3}>
            <OtherFacts
              getFacts={getFacts}
              factCategory="Land eligibility"
              idForExport={3}
              display={isDisplayed(3)}
            />
          </TabPanel>
          <TabPanel value={stepIndex} index={4}>
            <OtherFacts
              getFacts={getFacts}
              factCategory="Renewable energy potential"
              idForExport={4}
              display={isDisplayed(4)}
            />
          </TabPanel>
          {/* {errorMessage && !factsheetPanelLoading && (
            <Box mt={-6.2}>
              <Typography>{errorMessage}</Typography>
              <Button
                variant="contained"
                onClick={handleRemoteNationalData}
                startIcon={<RotateLeftIcon />}
              >
                {t("error.errorButton")}
              </Button>
            </Box>
          )} */}
        </>
      )}
    </Box>
  );
};
NationalFactsSheet.propTypes = {
  resultPanelParams: PropTypes.object,
  // An object containing the parameters for the result panel.
};

export default NationalFactsSheet;
