/**
 * Configuration object for defining the theme styling of the application.
 * @category MUI
 * @type {Object}
 * @override
 */

const themeConfig = {
  typography: {
    fontFamily: [
      '"Lato"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      "sans-serif",
    ].join(","),
    body1: {
      "@media (max-width:600px)": {
        fontSize: "80%",
      },
    },
    body2: {
      "@media (max-width:600px)": {
        fontSize: "80%",
      },
    },
    h5: {
      "@media (max-width:600px)": {
        fontSize: "80%",
      },
    },
    h6: {
      "@media (max-width:600px)": {
        fontSize: "80%",
      },
    },
  },
  palette: {
    background: {
      paper: "rgba(255,255,255,0.8)",
    },
    primary: {
      main: "#004176",
    },
    // error: {
    //   main: "#d3eec3",
    // },
    atlas: {
      main: "#95c11f",
      light: "#bfda79",
      extraLight: "#d5e6a5",
    },
  },
};

export default themeConfig;
