/**
 * Calculate the viewport settings for displaying the ECOWAS map based on screen width and height.
 *
 * @category Config
 * @param {number} width - The screen width.
 * @param {number} height - The screen height.
 *
 * @returns {object} - An object containing latitude, longitude, minZoom, and maxZoom properties.
 */

const getECOWASViewport = (width, height) =>
  width <= 600
    ? {
        latitude: 15.792254,
        longitude: 2.491211,
        minZoom: 3,
        maxZoom: 14,
      }
    : width <= 1280 && height <= 600
    ? {
        latitude: 14.3,
        longitude: 5.0,
        minZoom: 3.5,
        maxZoom: 14,
      }
    : width <= 1280
    ? {
        latitude: 15.5,
        longitude: 5.0,
        minZoom: 3.9,
        maxZoom: 14,
      }
    : width <= 1440
    ? {
        latitude: 15.5,
        longitude: 2.5,
        minZoom: 4.1,
        maxZoom: 16,
      }
    : width <= 1920
    ? {
        latitude: 15.5,
        longitude: 2.5,
        minZoom: 4.5,
        maxZoom: 16,
      }
    : {
        latitude: 14.6,
        longitude: -1.3,
        minZoom: 5.1,
        maxZoom: 16,
      };

/**
 * Calculate the viewport settings for displaying the SADC map based on screen width and height.
 *
 * @category Config
 * @param {number} width - The screen width.
 * @param {number} height - The screen height.
 *
 * @returns {object} - An object containing latitude, longitude, minZoom, and maxZoom properties.
 */
const getSADCViewport = (width, height) =>
  width <= 600
    ? {
        latitude: -12.3,
        longitude: 29.2,
        minZoom: 2.9,
        maxZoom: 14,
      }
    : width <= 1280 && height <= 600
    ? {
        latitude: -16.5,
        longitude: 34.9,
        minZoom: 2.7,
        maxZoom: 16,
      }
    : width <= 1280
    ? {
        latitude: -16.5,
        longitude: 34.9,
        minZoom: 3.3,
        maxZoom: 16,
      }
    : width <= 1440
    ? {
        latitude: -17.4,
        longitude: 31.5,
        minZoom: 3.5,
        maxZoom: 16,
      }
    : {
        latitude: -16.3,
        longitude: 24.4,
        minZoom: 3.9,
        maxZoom: 16,
      };

/**
 * Calculate the viewport settings for displaying some parts of East Africa map based on screen width and height.
 *
 * @category Config
 * @param {number} width - The screen width.
 * @param {number} height - The screen height.
 *
 * @returns {object} - An object containing latitude, longitude, minZoom, and maxZoom properties.
 */
const getEastViewport = (width, height) =>
  width <= 600
    ? {
        latitude: 2.4,
        longitude: 14.5,
        minZoom: 2.1,
        maxZoom: 14,
      }
    : width <= 1280 && height <= 600
    ? {
        latitude: 8.5,
        longitude: 25.5,
        minZoom: 3.05,
        maxZoom: 16,
      }
    : width <= 1280
    ? {
        latitude: 8.4,
        longitude: 19.3,
        minZoom: 3.3,
        maxZoom: 16,
      }
    : width <= 1440
    ? {
        latitude: 7.2,
        longitude: 16.2,
        minZoom: 3.6,
        maxZoom: 16,
      }
    : width <= 1920
    ? {
        latitude: 13.0,
        longitude: 10.6,
        minZoom: 3.5,
        maxZoom: 16,
      }
    : {
        latitude: 13.0,
        longitude: 10.6,
        minZoom: 3.5,
        maxZoom: 16,
      };

export { getECOWASViewport, getSADCViewport, getEastViewport };
