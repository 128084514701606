/**
 * This file contains the Material UI styling for the ResultsPanel component.
 * @name MUIStyle
 * @category MUI
 * @param {Object} theme - The Material UI theme.
 * @returns {Object} The Material UI styling.
 */
const style = (theme) => ({
  container: {
    position: "absolute",
    top: `10%`,
    left: 0,
    flexGrow: 1,
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    boxSizing: "border-box",
    boxShadow: "0px 0px 3px #434343",
    borderRadius: "5px",
    textAlign: "center",
    height: "60vh",
    "@media (width: 1280px) and (height: 600px)": {
      height: "50vh",
    },
    // [theme.breakpoints.up("xl")]: {
    //   height: "60vh",
    // },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.3),
    top: theme.spacing(0.3),
    padding: "0px",
    margin: "0px",
    color: theme.palette.common.black,
  },
  radioButton: {
    transform: "scale(0.65)",
  },
  radioLabel: {
    textTransform: "capitalize",
    fontSize: "0.8rem",
  },
  formRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "15ch",
    },

    "& .MuiFormHelperText-root": {
      textAlign: "center",
      fontSize: "0.6rem",
    },
  },
  layerTextField: {
    "& .MuiSelect-select.MuiSelect-select": {
      fontSize: "0.6rem",
    },
  },

  menuItemRoot: {
    "& .MuiSelect-select.MuiSelect-select": {
      fontSize: "10px",
    },
  },
  querySpecificsContainer: {
    "& .MuiSlider-root": {
      width: 100,
    },
    "& .MuiTextField-root": {
      width: 50,
    },
  },
  tableCell: {
    backgroundColor: "#023d6b",
    color: "#ffffff",
  },
});

export default style;
