import React, { useContext } from "react";

import makeStyles from "@mui/styles/makeStyles";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

import { UIContext } from "../../../contexts/UIContext";
import style from "./MUIStyle";

const useStyles = makeStyles(style);

/**
 * A dialog box that displays the cookies policy of the website.
 * @component
 * @category Native
 * @subcategory Cookies
 */
const CookiesPolicy = () => {
  /**
   * Translation function.
   * @type {Function}
   */
  const { t } = useTranslation();

  /**
   * State and functions for opening and closing the cookie policy dialog.
   * @type {Object}
   * @property {boolean} openCookiePolicyDialog - Determines if the cookie policy dialog is open.
   * @property {Function} setOpenCookieDialog - Function to set the state of openCookiePolicyDialog.
   */
  const { openCookiePolicyDialog, setOpenCookieDialog } = useContext(UIContext);

  /**
   * Material-UI styles.
   * @type {Function}
   */
  const classes = useStyles();

  /**
   * Function to handle closing the cookie policy dialog.
   */
  const handleCookiePolicyDialog = () => {
    setOpenCookieDialog(false);
  };

  return (
    <div>
      <Dialog
        data-testid="cookies-policy-panel"
        open={openCookiePolicyDialog}
        onClose={handleCookiePolicyDialog}
        className={classes.cookieDialogBox}
      >
        <DialogTitle>{t("cookiesPolicy.part1")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <>
              {" "}
              {t("cookiesPolicy.part2")}{" "}
              <Link
                href="https://www.cookiebot.com/en/tracking-cookies/"
                target="_blank"
              >
                TRACKING COOKIES
              </Link>
              .<br /> {t("cookiesPolicy.part3")}
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="cookies-policy-OK"
            variant="contained"
            onClick={handleCookiePolicyDialog}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CookiesPolicy;
