const superscripts = {
  0: "⁰",
  1: "¹",
  2: "²",
  3: "³",
  4: "⁴",
  5: "⁵",
  6: "⁶",
  7: "⁷",
  8: "⁸",
  9: "⁹",
  "+": "⁺",
  "-": "⁻",
  "=": "⁼",
  "(": "⁽",
  ")": "⁾",
  n: "ⁿ",
  i: "ⁱ",
};
const roundUp = (value, decimalPlace) => {
  const input =
    typeof value === "string" ? parseFloat(value.match(/\d+/)[0]) : value;
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPlace,
  });
  return formatter.format(input);
};

const convertToExponential = (input) => {
  const num =
    typeof input === "string" ? parseFloat(input.match(/\d+/)[0]) : input;
  if (num !== 0) {
    let exponentNum = num.toExponential();
    const index = exponentNum.indexOf("e") + 1;
    const beforeNum = exponentNum.slice(0, index);
    const roundedBeforeNum = roundUp(parseFloat(beforeNum), 2);

    const afterNum = exponentNum.slice(index);
    let regex = RegExp(`[${Object.keys(superscripts).join("")}]`, "g");
    const superscriptPart = afterNum.replace(regex, (c) => superscripts[c]);
    return superscriptPart.includes("⁰") ||
      superscriptPart.includes("¹") ||
      superscriptPart.includes("²") ||
      superscriptPart.includes("³")
      ? roundUp(num, 2)
      : roundedBeforeNum + ` x 10${superscriptPart}`;
  } else {
    return num;
  }
};
export default {
  convert: (currentUnit, convertibleUnit, value) => {
    const conversionTable = {
      "€ct/kWh": {
        "€ct/kWh": 1,
        "€/MWh": 10,
        "€/kWh": 0.01,
      },
      "€/MWh": {
        "€/MWh": 1,
        "€ct/kWh": 0.1,
        "€/kWh": 1000,
      },
      "€/kWh": {
        "€/kWh": 1,
        "€ct/kWh": 100,
        "€/MWh": 0.001,
        "€/kg": 33.33,
      },
      "GWh/yr": {
        "GWh/yr": 1,
        "TWh/yr": 0.001,
        "MWh/yr": 1e3,
      },
      "MWh/yr": {
        "MWh/yr": 1,
        "TWh/yr": 0.000001,
        "GWh/yr": 0.001,
      },
      "TWh/yr": {
        "TWh/yr": 1,
        "GWh/yr": 1000,
        "MWh/yr": 1e6,
      },
      "€/kg": {
        "€/kg": 1,
        "€/kWh": 0.03003,
      },
      "MWh/(a*m2)": {
        "MWh/(a*m2)": 1,
        "kWh/(a*m2)": 1000,
        "GWh/(a*m2)": 0.001,
      },
      "GWh/(a*m2)": {
        "GWh/(a*m2)": 1,
        "kWh/(a*m2)": 1e6,
        "MWh/(a*m2)": 1000,
      },
      "kWh/(a*m2)": {
        "kWh/(a*m2)": 1,
        "MWh/(a*m2)": 0.001,
        "GWh/(a*m2)": 0.000001,
      },
    };

    const conversionFactor = conversionTable[currentUnit]?.[convertibleUnit];
    if (conversionFactor === undefined) {
      return value?.toString();
    }
    const convertedValue = value * conversionFactor;

    if (convertedValue < 0.001 || convertedValue > 1000) {
      return convertToExponential(convertedValue);
    }
    return convertibleUnit.includes("€ct")
      ? roundUp(convertedValue, 2)
      : convertibleUnit.includes("€")
      ? roundUp(convertedValue, 4)
      : roundUp(convertedValue, 2);
  },
};
